import { useCallback, useMemo } from 'react';
import { makeStyles, Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import type { UseControllerReturn } from 'react-hook-form';
import clsx from 'clsx';

export type SwitchFieldProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * `react-hook-form` controller. Stores value internally as a boolean.
   */
  controller: UseControllerReturn<any, any>;
};

const useStyles = makeStyles(() => ({
  root: {
    // add styles here
  },
}));

export function SwitchField(props: SwitchFieldProps): React.ReactElement {
  const classes = useStyles();

  const value = props.controller.field.value as boolean;
  // onChange is referentially unstable; see other doc comments
  // eslint-disable-next-line
  const onChange = useMemo(() => props.controller.field.onChange, []) as (checked: boolean) => unknown;

  const handleSwitch = useCallback((_: unknown, checked: boolean) => onChange(checked), [onChange]);
  return <Switch checked={value} onChange={handleSwitch} />;
}
