import { FrequencyEnum } from 'codegen/graphql';
import { MutableNotification } from 'pages/SubmissionNotifications/types';

export function notificationExists(
  notifications: MutableNotification[],
  studioId: string,
  frequency: FrequencyEnum,
): boolean {
  return !!notifications.find(
    (notification) => notification.studio?.id === studioId && notification.frequency === frequency,
  );
}
