import { makeStyles, Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';

export type LoadingCardProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiCardHeader-root': {
      justifyContent: 'space-between',
      '& .MuiCardHeader-content': {
        flex: '0 0 50%',
        '& .MuiCardHeader-subheader': {
          marginTop: 8,
          width: '100%',
        },
      },
      '& .MuiCardHeader-action': {
        flexBasis: 70,
        height: 30,
      },
    },
    '& .MuiSkeleton-root': {
      width: '100%',
      height: '100%',
    },
  },
}));

export function LoadingCard(props: LoadingCardProps): React.ReactElement {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title={<Skeleton />} subheader={<Skeleton />} action={<Skeleton />} />
      <Divider />
      <CardContent>
        <Skeleton height={100} style={{ marginTop: -8 }} />
      </CardContent>
    </Card>
  );
}
