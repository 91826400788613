import { Card, CardContent, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useController, Control } from 'react-hook-form';
import type { StudioFormType } from 'src/pages/StudioFormPage/StudioFormPage';
import TextField from 'components/TextField';
import DateTimeField from 'components/DateTimeField';
import { SelectField, SelectItem } from 'components/SelectField';
import { CheckboxField } from 'components/CheckboxField';

export type StudioFormCardProps = {
  control: Control<StudioFormType>;
};

const localeOptions: SelectItem[] = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'Turkish',
    value: 'tr',
  },
  {
    label: 'Romanian',
    value: 'ro',
  },
  {
    label: 'Czech',
    value: 'cs',
  },
  {
    label: 'Dutch',
    value: 'nl',
  },
  {
    label: 'Norwegian',
    value: 'no',
  },
  {
    label: 'Portuguese',
    value: 'pt',
  },
  {
    label: 'German',
    value: 'de',
  },
];

export function StudioFormCard(props: StudioFormCardProps): React.ReactElement {
  const { t } = useTranslation('studiosRoute');

  const { control } = props;

  const nameController = useController({ name: 'name', control });
  const studioHandleController = useController({ name: 'studioHandle', control });
  const maxPhotosController = useController({ name: 'photosQuotaLimit', control });
  const startDateController = useController({ name: 'startDate', control });
  const endDateController = useController({ name: 'endDate', control });
  const localeController = useController({ name: 'locale', control });
  const enhanceController = useController({ name: 'enhance', control });

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} alignContent="space-around">
          <Grid item xs={12}>
            <TextField variant="outlined" label={t('studioName')} fullWidth maxChars={50} controller={nameController} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label={t('studioHandle')}
              fullWidth
              maxChars={50}
              InputLabelProps={{ shrink: !!studioHandleController.field.value }}
              controller={studioHandleController}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimeField
              label={t('startDate')}
              inputVariant="outlined"
              minutesStep={5}
              fullWidth
              required
              controller={startDateController}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimeField
              inputVariant="outlined"
              label={t('endDate')}
              minDate={startDateController.field.value}
              minutesStep={5}
              fullWidth
              clearable
              controller={endDateController}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              controller={maxPhotosController}
              variant="outlined"
              label={t('maxPhotos')}
              fullWidth
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField items={localeOptions} label="Language" controller={localeController} variant="outlined" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CheckboxField useSwitch label="Enable AI image enhancement" controller={enhanceController} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
