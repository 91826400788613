import { Dialog } from 'components/Dialog';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { MutableNotification } from 'pages/SubmissionNotifications/types';
import { NotificationContext } from 'pages/SubmissionNotifications';
import { useContext } from 'react';

type NotificationDeleteDialogProps = {
  open: boolean;
  onClose: () => void;
  onDelete: (deletedNotification: MutableNotification) => void;
  loading: boolean;
};

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'uppercase',
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));
export function NotificationDeleteDialog({ open, onClose, onDelete, loading }: NotificationDeleteDialogProps) {
  const context = useContext(NotificationContext);

  const classes = useStyles();

  return (
    <Dialog
      title="Are you sure you want to delete this notification?"
      width={720}
      open={open}
      onClose={onClose}
      actions={
        <>
          <Button
            className={classes.button}
            disabled={loading}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>

          <Button
            className={`${classes.button} ${classes.deleteButton}`}
            variant="contained"
            color="primary"
            onClick={() => {
              if (!context.selectedNotification) return;
              onDelete(context.selectedNotification);
            }}
            disabled={loading}
          >
            Delete
          </Button>
        </>
      }
    >
      <Typography>
        Deleting this notification will cancel all future email notifications for <strong>{context.userEmail}</strong>{' '}
        for this studio.
      </Typography>
    </Dialog>
  );
}
