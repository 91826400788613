import { useEffect, useRef } from 'react';

type UseSyncFieldsOpts<FormSchema> = {
  /**
   * Toggles on/off the syncing
   */
  on?: boolean;
  watch: (names: (keyof FormSchema)[]) => string[];
  setValue: (
    name: keyof FormSchema,
    value: string,
    opts?: { shouldDirty?: boolean; shouldTouch?: boolean; shouldValidate?: boolean },
  ) => void;
  source: string;
  target: string;
  transform?: (sourceValue: string) => string;
};

/**
 * Keeps two field values within a form in sync, with a permitted transformation.
 * Allows the target field to be manually changed, in which case the `transform` function will still be applied,
 * but the source field will no longer affect it.
 *
 * @param watch
 * @param setValue
 * @param source
 * @param target
 * @param transform
 *
 * @returns [string, string] The current values of the source and target fields.
 */
export function useSyncFields<S extends Record<string, unknown>>({
  watch,
  setValue,
  source,
  target,
  transform,
  on = true,
}: UseSyncFieldsOpts<S>): [string, string] {
  const [sourceValue, targetValue] = watch([source, target]);
  const lastSourceValue = useRef<string>(sourceValue);

  useEffect(() => {
    if (!on) return;

    const lastSourceValueTransformed = transform?.(lastSourceValue.current) || lastSourceValue.current;
    const currentSourceValueTransformed = transform?.(sourceValue) || sourceValue;
    const currentTargetValueTransformed = transform?.(targetValue) || targetValue;

    /*
      If the user hasn't changed the target's value to something else, it should currently equal the source value
      of the previous render with the transformation applied.
    */
    const shouldUpdateTarget = targetValue == lastSourceValueTransformed;
    lastSourceValue.current = sourceValue;

    // Make sure if they're inputting the target manually, that it is still transformed
    if (!shouldUpdateTarget) {
      if (targetValue != currentTargetValueTransformed) {
        setValue(target, currentTargetValueTransformed, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
      }
      return;
    }

    if (targetValue != currentSourceValueTransformed) {
      setValue(target, currentSourceValueTransformed, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    }
  }, [setValue, target, sourceValue, targetValue, transform, on]);

  return [sourceValue, targetValue];
}
