import { useCallback } from 'react';
import { toast } from 'components/GlobalSnackbar';

export type UseFormSubmissionOpts = {
  ids: string[];
  filename?: string;
  filenamePattern?: string;
  format?: string | null;
  originalPhotos?: boolean | null;
};

export type UseFormSubmissionResult = {
  streamExport: (opts: UseFormSubmissionOpts) => void;
};

function getSubmissionsExportForm(): HTMLFormElement {
  let $form = document.querySelector('form#submissions-export-form') as HTMLFormElement;

  if (!$form) {
    $form = document.createElement<'form'>('form');
    $form.setAttribute('action', '/submissions/export');
    $form.setAttribute('method', 'POST');
    $form.setAttribute('id', 'submissions-export-form');

    const $fields = document.createElement('div');
    $fields.setAttribute('id', 'submissions-export-fields');
    $form.appendChild($fields);

    document.body.appendChild($form);
  }

  return $form;
}

function hiddenInput(name: string, value: string): HTMLInputElement {
  const $input = document.createElement('input');
  $input.name = name;
  $input.type = 'hidden';
  $input.value = value;
  return $input;
}

export function useSubmissionsExport(): UseFormSubmissionResult {
  const streamExport = useCallback((opts: UseFormSubmissionOpts) => {
    const $form = getSubmissionsExportForm();
    const $fields = $form.querySelector('#submissions-export-fields');

    if (!$fields) throw new Error('Failed to find form fields');

    // Clear the slate
    $fields.innerHTML = '';

    const authenticityToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

    if (!authenticityToken) {
      throw new Error('Authenticity token not found');
    }

    $fields.appendChild(hiddenInput('authenticity_token', authenticityToken));

    opts.ids.forEach((id) => {
      $fields.appendChild(hiddenInput('export[ids][]', id));
    });

    if (opts.filename) {
      $fields.appendChild(hiddenInput('filename', opts.filename));
    }

    if (opts.format) {
      $fields.appendChild(hiddenInput('export[format]', opts.format));
    }

    if (opts.filenamePattern) {
      $fields.appendChild(hiddenInput('export[filename_pattern]', opts.filenamePattern));
    }

    if (opts.originalPhotos) {
      $fields.appendChild(hiddenInput('export[original_photos]', 'true'));
    }

    toast({
      message: 'Download starting...',
      snackbarProps: {
        autoHideDuration: 1200,
      },
    });

    $form.submit();
  }, []);

  return { streamExport };
}
