// @refresh reset
// above comment required to have HMR working; see https://github.com/ianstormtaylor/slate/issues/4081
import { makeStyles, Popover, TextField } from '@material-ui/core';
import { Transforms } from 'slate';

import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Plate,
  createHistoryPlugin,
  createReactPlugin,
  createBoldPlugin,
  createItalicPlugin,
  createUnderlinePlugin,
  createHeadingPlugin,
  createPlateComponents,
  createPlateOptions,
  MarkToolbarButton,
  getPlatePluginType,
  usePlateEditorRef,
  MARK_BOLD,
  MARK_UNDERLINE,
  MARK_ITALIC,
  ELEMENT_H1,
  ELEMENT_H2,
  BlockToolbarButton,
  createListPlugin,
  ELEMENT_OL,
  ListToolbarButton,
  ELEMENT_UL,
  createLinkPlugin,
  ToolbarButton,
  getAndUpsertLink,
  ELEMENT_LINK,
  someNode,
  serializeHTMLFromNodes,
  TDescendant,
  createParagraphPlugin,
  ELEMENT_PARAGRAPH,
  PlateRenderElementProps,
  LinkNodeData,
  AnyObject,
} from '@udecode/plate';
import { deserializeHTMLToDocumentFragment } from '@udecode/plate-html-serializer';
import { FormatListBulleted, FormatListNumbered } from '@material-ui/icons';
import { Bold, Italic, Link, Underline } from 'react-feather';
import clsx from 'clsx';
import { LinkElementWithPopover } from 'components/RichTextEditor/elements/LinkElementWithPopover';
import customTheme from 'src/theme';

export type RichTextEditorProps = {
  id: string;
  classes?: {
    toolbar?: string;
    editor?: string;
  };
  placeholder?: string;
  initialValue: string;
  onChange: (html: string) => void;
  minHeight?: number;
};

export const useRichTextStyles = makeStyles(() => ({
  rte: {
    fontFamily: 'Mulish, Helvetica, sans-serif',
  },
}));

const useStyles = makeStyles(() => ({
  editor: {
    border: '1px solid #ddd',
    borderRadius: 8,
    overflow: 'hidden',
  },
  editorContent: {
    padding: '0.8rem',
  },
  toolbar: {
    backgroundColor: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    display: 'flex',
    padding: '0.8rem',
    borderBottom: '1px solid #ddd',
    '& span': {
      marginRight: 8,
      lineHeight: 1,
    },
    '& .slate-ToolbarButton-active': {
      color: customTheme.palette.primary.main,
    },
  },
  toolbarButtonGroup: {
    borderLeft: '1px solid #ccc',
    display: 'flex',
    paddingLeft: '0.8rem',
  },
  textIcon: {
    fontSize: 18,
  },
  marksGroup: {
    borderRight: '1px solid #eee',
  },
  disabled: {
    '& .MuiIconButton-root': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },
}));

/**
 * A minimal rich text editor built with Slate.
 */
export function RichTextEditor(props: RichTextEditorProps): React.ReactElement {
  const classes = { ...useStyles(), ...useRichTextStyles() };
  const { onChange, initialValue } = props;

  const editor = usePlateEditorRef();

  const plugins = useMemo(
    () => [
      createReactPlugin(),
      createHistoryPlugin(),

      createParagraphPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createUnderlinePlugin(),

      createHeadingPlugin(),
      createListPlugin(),

      {
        ...createLinkPlugin(),
        serialize: {
          element: (props: PlateRenderElementProps) => (
            <a href={props.element.url} target="_blank" rel="noreferrer noopener">
              {props.children}
            </a>
          ),
        },
      },
    ],
    [],
  );

  const components = createPlateComponents({
    [ELEMENT_LINK]: ((props: PlateRenderElementProps<LinkNodeData>) => (
      <LinkElementWithPopover {...props} rerender={() => setValue((value) => [...value])} />
    )) as any,
    // The createPlateComponents type is invalid. It expects a Record<string, FunctionComponent> where FunctionComponent
    // defaults to only { children?: React.ReactNode } as the props type which is clearly wrong because `props` always
    // contains more. It doesn't seem to accept generics to override the expected props type
  });

  const options = createPlateOptions();

  const [value, setValue] = useState<TDescendant[]>([{ type: ELEMENT_PARAGRAPH, children: [{ text: '' }] }]);

  const html = useMemo(() => {
    if (!editor) return '';

    return serializeHTMLFromNodes(editor, {
      plugins,
      nodes: value,
    });
  }, [value, editor, plugins]);

  useEffect(() => {
    onChange(html);
  }, [html, onChange]);

  useEffect(() => {
    if (!editor) return;

    Transforms.deselect(editor);

    const initialFragment = deserializeHTMLToDocumentFragment(editor, {
      plugins,
      element: initialValue,
    });

    setValue(initialFragment);
  }, [editor, plugins, initialValue]);

  // Repair the value if there is no good root node.
  // This can happen if you try and spam a bunch of different marks and element types.
  useEffect(() => {
    const rootNode = value[0];

    if (!rootNode || !rootNode.type) {
      setValue([{ type: ELEMENT_PARAGRAPH, children: [{ text: rootNode?.text || '' }] }]);
    }
  }, [value]);

  return (
    <div className={clsx(classes.rte, classes.editor)}>
      <Plate
        id={props.id}
        value={value}
        onChange={setValue}
        plugins={plugins}
        components={components}
        options={options}
        renderEditable={(editable) => <div className={classes.editorContent}>{editable}</div>}
      >
        <div className={classes.toolbar}>
          <MarkToolbarButton icon={<Bold />} type={getPlatePluginType(editor, MARK_BOLD)} />
          <MarkToolbarButton icon={<Italic />} type={getPlatePluginType(editor, MARK_ITALIC)} />
          <MarkToolbarButton icon={<Underline />} type={getPlatePluginType(editor, MARK_UNDERLINE)} />
          <ToolbarButton active={currentSelectionIsLink()} icon={<Link />} onMouseDown={enableLink} />

          <div className={classes.toolbarButtonGroup}>
            <BlockToolbarButton
              type={getPlatePluginType(editor, ELEMENT_H1)}
              icon={<span className={classes.textIcon}>H1</span>}
            />
            <BlockToolbarButton
              type={getPlatePluginType(editor, ELEMENT_H2)}
              icon={<span className={classes.textIcon}>H2</span>}
            />
            <ListToolbarButton type={getPlatePluginType(editor, ELEMENT_UL)} icon={<FormatListBulleted />} />
            <ListToolbarButton type={getPlatePluginType(editor, ELEMENT_OL)} icon={<FormatListNumbered />} />
          </div>
        </div>
      </Plate>
    </div>
  );

  function currentSelectionIsLink(): boolean {
    return !!editor?.selection && someNode(editor, { match: { type: getPlatePluginType(editor, ELEMENT_LINK) } });
  }

  function enableLink(e: React.MouseEvent) {
    e.preventDefault();

    if (!editor) return console.error('Cannot activate link if editor is null');
    if (!editor.selection) return console.warn('Cannot activate link when nothing is selected');

    getAndUpsertLink(editor, () => new Promise((resolve) => resolve(NEW_LINK_INITIAL_URL)));
  }
}

export const NEW_LINK_INITIAL_URL = '__new_link';
