import useCustomTheme from './useCustomTheme';
import { useMediaQuery } from '@material-ui/core';

/**
 * Simple MUI hook that returns `true` if the screen size is below the `md`
 * breakpoint. Used for determining whether to render a mobile view.
 * @returns `true` if below `md` breakpoint. `false` otherwise.
 */
export default function useBreakpoint(): boolean {
  const theme = useCustomTheme();
  // yes, this is confusing. MUI's `theme.breakpoints.down` method actually uses
  // the breakpoint above the one you specify. it's stupid.
  return useMediaQuery(theme.breakpoints.down('sm'));
}
