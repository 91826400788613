import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import AppWrapperBar from 'components/AppWrapperBar';
import AppWrapperDrawer from 'components/AppWrapperDrawer';
import { useGetCurrentAccountQuery } from 'codegen/graphql';

export const DRAWER_WIDTH = 256;
export const APPBAR_HEIGHT = 64;
export const BODY_PADDING = 32;

type StylesProps = {
  drawerHidden: boolean;
};

const useStyles = makeStyles((theme) => ({
  bar: {
    height: APPBAR_HEIGHT,
  },
  body: (props: StylesProps) => ({
    padding: BODY_PADDING,
    minHeight: `calc(100% - ${APPBAR_HEIGHT}px)`,
    marginLeft: props.drawerHidden ? 0 : DRAWER_WIDTH,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  }),
  drawer: {
    width: DRAWER_WIDTH,
    marginTop: APPBAR_HEIGHT,
    height: `calc(100% - ${APPBAR_HEIGHT}px)`,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      height: '100%',
    },
  },
}));

export type AppView = 'user' | 'account' | 'admin';

export type AppWrapperProps = {
  children?: React.ReactNode;
  className?: string;
  /**
   * If set to 'admin', shows a grayed-out view.
   */
  view: AppView;
};

export function AppWrapper(props: AppWrapperProps): React.ReactElement {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerHidden = props.view !== 'account';
  const history = useHistory();
  const { pathname } = useLocation();
  const accountQuery = useGetCurrentAccountQuery();

  const classes = useStyles({ drawerHidden });

  const noCurrentAccount = !accountQuery.loading && !accountQuery.data?.currentAccount?.id;

  useEffect(() => {
    if (props.view == 'account' && noCurrentAccount && !history.location.state?.preventNoAccountRedirect) {
      // if done loading and no account selected, return to /
      // doNotRedirect is needed when cache fails to update synchronously after account selection.
      history.push('/dashboard');
    }
  }, [history, noCurrentAccount, pathname]);

  return (
    <>
      <AppWrapperBar className={classes.bar} toggleDrawer={() => setDrawerOpen(!drawerOpen)} view={props.view} />
      {!drawerHidden && (
        <AppWrapperDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} className={classes.drawer} />
      )}
      <main className={clsx(classes.body, props.className)}>{props.children}</main>
    </>
  );
}
