import { useCallback, useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { formatDistanceToNowStrict } from 'date-fns';
import { DataGrid, GridColDef, GridSortItem } from '@material-ui/data-grid';
import { useTranslation } from 'react-i18next';

import {
  PhotoPreviewFragment,
  useGetSubmissionRowsLazyQuery,
  SubmissionRowFragment,
  SubmissionState,
  SortDirection,
  SubmissionSortKey,
  GetSubmissionRowsQueryVariables,
} from 'codegen/graphql';
import useDialog from 'hooks/useDialog';
import SubmissionDialog from 'components/SubmissionDialog';
import SubmissionPreview from 'components/SubmissionPreview';
import SubmissionsDataGridToolbar from 'components/SubmissionsDataGridToolbar';
import { SubmissionTabValue } from 'pages/Submissions';
import { useDataGridController } from 'hooks/useDataGridController';
import ErrorCard from 'components/ErrorCard';
import { RetakeDialog } from '../RetakeDialog';
import { usePermissions } from 'hooks/usePermissions';

export type SubmissionsDataGridProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  tab: SubmissionTabValue;
  onStudioSelect: (studioId: string | null) => void;
};

const mapTabToState: Record<SubmissionTabValue, SubmissionState | null> = {
  ALL: null,
  REVIEW: SubmissionState.Review,
  ACCEPTED: SubmissionState.Accepted,
  REJECTED: SubmissionState.Rejected,
  INVITED: null,
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& [data-status="REJECTED"]': {
      color: theme.palette.warning.main,
    },
    '& [data-status="ACCEPTED"]': {
      color: theme.palette.success.main,
    },
    '& [data-status="REVIEW"]': {
      color: theme.palette.grey[500],
    },
  },
}));

export function SubmissionsDataGrid(props: SubmissionsDataGridProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const perms = usePermissions();
  const selectedSubmissionState = mapTabToState[props.tab];

  const [queryVariables, setQueryVariables] = useState<GetSubmissionRowsQueryVariables>({
    state: selectedSubmissionState,
    studioIds: null,
    sortBy: null,
    sortDirection: null,
    submittedAfter: null,
  });

  // call editQueryVariables on selectedSubmissionState update. do not bother
  // trying to memoize editQueryVariables, as it would trigger a render loop.
  useEffect(() => {
    editQueryVariables({ state: selectedSubmissionState });
    // eslint-disable-next-line
  }, [selectedSubmissionState]);

  // keep parent component informed of selected studio IDs
  useEffect(() => {
    if (!queryVariables.studioIds) {
      props.onStudioSelect(null);
    } else {
      props.onStudioSelect(queryVariables.studioIds?.[0] ?? null);
    }
  }, [props, queryVariables.studioIds]);

  const submissionsQuery = useGetSubmissionRowsLazyQuery({
    variables: queryVariables,
    // needed to prevent apollo-client from running network request twice (or
    // strangely not updating state in hook result?) see
    // https://github.com/apollographql/apollo-client/issues/7689
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const [clickedSubmission, setClickedSubmission] = useState<SubmissionRowFragment>();
  const {
    openDialog: openSubmissionDialog,
    closeDialog: closeSubmissionDialog,
    dialogProps: submissionDialogProps,
  } = useDialog();
  const { openDialog: openRetakeDialog, dialogProps: retakeDialogProps } = useDialog();

  const handleSubmissionClick = useCallback(
    (submission: SubmissionRowFragment) => {
      setClickedSubmission(submission);
      openSubmissionDialog();
    },
    [openSubmissionDialog],
  );

  // wrap this in a hook for now; will integrate with `useTranslation` hook in
  // the future.
  const mapSubmissionsToRows = useCallback((submission: SubmissionRowFragment) => {
    const { id, submittedAt, createdAt, photos, state: status } = submission;
    const studio = submission.studio?.name ?? '--';
    const email = submission.contact?.email || '--';
    const firstName = submission.contact?.firstName;
    const lastName = submission.contact?.lastName;
    return { id, firstName, lastName, email, studio, submittedAt, createdAt, photos, status };
  }, []);

  const SUBMISSIONS_COLUMNS: GridColDef[] = [
    {
      field: 'photos',
      headerName: 'Photos',
      renderCell: ({ value }) => {
        const photos = value as PhotoPreviewFragment[];
        return <SubmissionPreview photos={photos} />;
      },
      width: 130,
    } as GridColDef,
    {
      field: 'firstName',
      headerName: 'First name',
      width: 200,
      hideSortIcons: false,
      sortable: true,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 200,
      hideSortIcons: false,
      sortable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      hideSortIcons: false,
      sortable: true,
    },
    ...(perms.auth?.canViewStudio
      ? [
          {
            field: 'studio',
            headerName: 'Studio',
            width: 200,
            hideSortIcons: false,
            sortable: false,
          },
        ]
      : []),
    {
      field: 'submittedAt',
      headerName: 'Submitted at',
      width: 160,
      valueFormatter: ({ value }) => {
        const dateStr = value as string | null;
        return dateStr ? `${formatDistanceToNowStrict(new Date(dateStr))} ago` : '--';
      },
      hideSortIcons: false,
      sortable: true,
    } as GridColDef,
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: ({ value }) => {
        const status = value as string;
        const humanizedStatus = status[0] + status.slice(1).toLocaleLowerCase();
        return (
          <Typography variant="body2" data-status={status}>
            {humanizedStatus}
          </Typography>
        );
      },
    },
  ];

  const queryId = `${props.tab}:${queryVariables.sortBy}:${queryVariables.sortDirection}:${queryVariables.submittedAfter}`;
  const dataGridController = useDataGridController({
    className: classes.root,
    query: submissionsQuery,
    queryId,
    connectionField: 'submissions',
    mapNodesToRows: mapSubmissionsToRows,
    columns: SUBMISSIONS_COLUMNS,
    onRowClick: handleSubmissionClick,
    initialPageSize: 50,
  });

  if ('error' in dataGridController) {
    console.error(dataGridController.error);
    return <ErrorCard body="Could not load data. Please try refreshing the page." />;
  }

  const { dataGridProps, refreshCurrentPage, rowCount, selectedIds, total } = dataGridController;

  return (
    <>
      <DataGrid
        {...dataGridProps}
        rowsPerPageOptions={[dataGridController.pageSize]}
        sortingMode="server"
        onSortModelChange={({ sortModel }) => {
          // sort model is an array of sort items, but there is always only 1 sort item...
          handleSortModelChange(sortModel[0] || null);
        }}
        components={{
          ...dataGridProps.components,
          Toolbar: () => (
            <SubmissionsDataGridToolbar
              refreshCurrentPage={refreshCurrentPage}
              rowCount={rowCount}
              selectedIds={selectedIds}
              total={total}
              queryVariables={queryVariables}
              editQueryVariables={editQueryVariables}
              currentTab={props.tab}
            />
          ),
        }}
        autoHeight
      />
      {clickedSubmission && (
        <>
          <SubmissionDialog
            {...submissionDialogProps}
            submission={clickedSubmission}
            onReject={handleRejectSubmission}
            refreshCurrentPage={refreshCurrentPage}
          />
          <RetakeDialog {...retakeDialogProps} submissionId={clickedSubmission.id} />
        </>
      )}
    </>
  );

  function handleRejectSubmission() {
    closeSubmissionDialog();
    openRetakeDialog();
  }

  /**
   * Similar to `setVariables`, but merges incoming variables with existing
   * variables. Note that you should only call this once per render, since the
   * first invocation will be overriden by the second invocation.
   */
  function editQueryVariables(newQueryVariables: Partial<GetSubmissionRowsQueryVariables>) {
    setQueryVariables({
      ...queryVariables,
      ...newQueryVariables,
    });
  }

  function handleSortModelChange(sortItem: GridSortItem | null) {
    if (sortItem === null) {
      editQueryVariables({
        sortDirection: null,
        sortBy: null,
      });
      return;
    }

    const sortDirection = sortItem.sort === 'asc' ? SortDirection.Asc : SortDirection.Desc;
    let sortBy: SubmissionSortKey | null;

    switch (sortItem.field) {
      case 'firstName': {
        sortBy = SubmissionSortKey.FirstName;
        break;
      }
      case 'lastName': {
        sortBy = SubmissionSortKey.LastName;
        break;
      }
      case 'email': {
        sortBy = SubmissionSortKey.Email;
        break;
      }
      case 'submittedAt': {
        sortBy = SubmissionSortKey.SubmittedAt;
        break;
      }
      default: {
        sortBy = null;
        break;
      }
    }

    editQueryVariables({ sortDirection, sortBy });
  }
}
