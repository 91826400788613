import { LinearProgress, makeStyles, Paper, Tooltip } from '@material-ui/core';

import { PhotoPreviewFragment } from 'codegen/graphql';
import { useState } from 'react';
import { EnhancementStatusTip } from 'components/SubmissionPhotos/EnhancementStatusTip';

export type SubmissionPreviewProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  photos: PhotoPreviewFragment[];
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: 75,
    height: 75,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  paper: {
    borderRadius: 0,
    maxWidth: 75,
    maxHeight: 75,
    position: 'relative',
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      top: 0,
      right: 0,
      transform: 'translate(50%, -50%)',
      background: 'white',
      borderRadius: '100%',
      padding: '4px',
      zIndex: '10',
      boxShadow: '1px 1px 3px rgba(0,0,0,0.2)',
    },
    '& .MuiLinearProgress-root': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
  },
  previewImg: {
    display: 'block',
    maxWidth: 'inherit',
    maxHeight: 'inherit',
  },
}));

/**
 * Renders a preview of a submission's photos in the `SubmissionsDataGrid`.
 */
export function SubmissionPreview(props: SubmissionPreviewProps): JSX.Element | null {
  const classes = useStyles();
  const photo = props.photos?.[0];
  const url = photo?.appliedPresets?.[0]?.publicUrl;
  const [loading, setLoading] = useState(true);

  return url ? (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {loading && <LinearProgress />}
        <img onLoad={() => setLoading(false)} src={url} alt="preview" className={classes.previewImg} />
        <EnhancementStatusTip status={photo.enhancementStatus} />
      </Paper>
    </div>
  ) : null;
}
