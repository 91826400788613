import { useMemo, useState, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import type { ApolloError, QueryTuple } from '@apollo/client';
import type { GridColDef, GridPageChangeParams, DataGridProps, GridRowParams } from '@material-ui/data-grid';
import clsx from 'clsx';
import { useEffect } from 'react';
import { get } from 'lodash-es';

import { PageInfo } from 'codegen/graphql';

type Connection<Node extends { id: string }> = {
  edges: Array<{
    cursor: string;
    node: Node;
  }>;
  pageInfo: PageInfo;
  totalCount?: number | null;
};

type ConnectionVars = {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
};

export type UseDataGridControllerProps<
  QueryResp,
  ConnectionFieldPath extends string,
  QueryVars extends ConnectionVars,
  Node extends { id: string },
> = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Columns to render in this data grid.
   *
   * See https://material-ui.com/components/data-grid/columns/.
   */
  columns: GridColDef[];
  /**
   * An ID that identifies the query passed as a prop. When this changes, this
   * hook will start over and re-render with the specified query.
   */
  queryId?: string;
  /**
   * Callback to be executed on row click whose only parameter is an object of
   * type Node.
   */
  onRowClick?: (node: Node) => unknown;
  /**
   * Specifies the initial page size.
   */
  initialPageSize?: number;
} & (DeepPick<QueryResp, ConnectionFieldPath, never> extends Connection<Node>
  ? {
      /**
       * Query tuple returned from an Apollo `useLazyQuery()` hook.
       */
      query: QueryTuple<QueryResp & NestedObject<ConnectionFieldPath, Connection<Node>>, QueryVars>;
      /**
       * The connection field that is to be rendered. For example, if you wish to
       * render the submissions returned by the following query document:
       *
       * ```
       * query {
       *   currentAccount {
       *     id
       *   }
       *   submissions(first: 50) {
       *     edges {
       *       nodes {
       *         ...
       *       }
       *     }
       *   }
       * }
       * ````
       *
       * then set `props.connectionField = 'submissions'`.
       */
      connectionField: ConnectionFieldPath;
      /**
       * Function that maps nodes to rows. This is necessary if the types of
       * `Node` and `Row` are different. Defaults to the identity function
       * `(node) => node`.
       */
      mapNodesToRows?: (node: Node, index: number) => Record<string, unknown>;
    }
  : never);

type UseDataGridControllerError = {
  error: Error | ApolloError;
};

export type UseDataGridControllerReturn =
  | UseDataGridControllerError
  | {
      /**
       * IDs of the currently selected rows.
       */
      selectedIds: string[];
      /**
       * Total rows on the current page. This is different from `pageSize` if
       * you are on the last page of the table.
       */
      rowCount: number;
      /**
       * Size of the current page.
       */
      pageSize: number;
      /**
       * String representing the total rows in the entire table. Could be
       * unknown, e.g. '600+', hence why it is a string.
       */
      total: string;
      /**
       * Callback that refreshes the current page.
       */
      refreshCurrentPage: () => Promise<void>;
      /**
       * Props that should be passed to the MUI `DataGrid` commponent.
       */
      dataGridProps: DataGridProps;
    };

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: 'unset',
    border: 'none',
    '& .MuiDataGrid-overlay': {
      // stop loading overlay from being behind table on some browsers. tested
      // on Version 89.0.4389.90 (Official Build) Arch Linux (64-bit)
      zIndex: 1,
    },
  },
}));

const DEFAULT_INITIAL_PAGE_SIZE = 10;

/**
 * A super-useful hook for the Material-UI data grid component that
 * automagically handles Relay-style, cursor-based pagination.
 *
 * Troubleshooting:
 * - Connection type cannot be nullable
 * - Make sure you're requesting `Connection.pageInfo` and `edge.cursor`
 * - Make sure field is specified to use `relayStylePagination()` in `InMemoryCache`
 */
export function useDataGridController<
  QueryResp,
  ConnectionFieldPath extends string,
  QueryVars extends ConnectionVars,
  Node extends { id: string },
>(props: UseDataGridControllerProps<QueryResp, ConnectionFieldPath, QueryVars, Node>): UseDataGridControllerReturn {
  const classes = useStyles();
  const { t } = useTranslation();

  const [
    query,
    {
      loading,
      error,
      data,
      previousData,
      called,
      /**
       * Is `undefined` before first call of `query()`, hence the this type is
       * possibly `undefined`.
       */
      fetchMore,
    },
  ] = props.query;

  const connectionField = props.connectionField.replace('?', '') as ConnectionFieldPath;
  const connection = useMemo(() => {
    // use previous data if loading to avoid flashing "no rows" when re-running
    // query, since data is set to `undefined` while loading. see
    // https://github.com/apollographql/apollo-client/issues/7038
    return get(loading ? previousData : data, connectionField);
  }, [loading, previousData, data, connectionField]);
  const edges = connection?.edges;
  const pageInfo = connection?.pageInfo;

  // map connection edges to nodes.
  const nodes = useMemo(() => {
    if (edges) {
      return edges.map((edge) => edge.node);
    } else {
      return [];
    }
  }, [edges]);

  const initialPageSize = props.initialPageSize ?? DEFAULT_INITIAL_PAGE_SIZE;

  // state variable controlling the page size of the data grid.
  const [pageSize, setPageSize] = useState(initialPageSize);

  // the index of the first item on this page, one-indexed
  const [startIndex, setStartIndex] = useState(1);
  // the number of rows loaded on the current page.
  const pageRows = nodes.slice(startIndex - 1, startIndex - 1 + pageSize).length;
  // the index of the last item on this page, one-indexed
  const endIndex = startIndex + pageRows - 1;
  // state variable storing the ids of selected rows
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  // the number of rows currently stored in the cache
  const totalLoaded = edges?.length ?? 0;

  useEffect(() => {
    query({ variables: { first: initialPageSize } as QueryVars });
  }, [initialPageSize, query]);

  // on initial render or if queryId gets updated, set page to first page and
  // completely reset the data grid.
  useEffect(() => {
    setPageSize(initialPageSize);
    setStartIndex(1);
    setSelectedIds([]);
  }, [initialPageSize, props.queryId, query]);

  // disable column menu and sort icons unless explicitly told otherwise
  const columns = useMemo(
    () =>
      props.columns.map((col) => {
        if (col.disableColumnMenu !== false) col.disableColumnMenu = true;
        if (col.hideSortIcons !== false) col.hideSortIcons = true;
        if (col.sortable !== true) col.sortable = false;
        return col;
      }),
    [props.columns],
  );

  // serialize the nodes into MUI DataGrid-compatible rows. notice that this
  // ONLY serializes and renders rows between `startIndex` and `endIndex`.
  const rows = useMemo(() => {
    const mapNodesToRows = props.mapNodesToRows ?? ((node) => node);
    return nodes.slice(startIndex - 1, endIndex).map(mapNodesToRows);
  }, [endIndex, nodes, props.mapNodesToRows, startIndex]);

  const { onRowClick } = props;
  const handleRowClick = useCallback(
    ({ id }: GridRowParams) => {
      const selectedNode = edges?.find(({ node }) => node.id === id)?.node;
      selectedNode && onRowClick?.(selectedNode);
    },
    [edges, onRowClick],
  );

  // if backend returns total number of rows, then use that. otherwise just use
  // how many we have cached.
  const total =
    (connection?.totalCount || totalLoaded).toString() + (!connection?.totalCount && pageInfo?.hasNextPage ? '+' : '');

  const queryFailed = called && ((!loading && !data) || error);
  if (queryFailed) {
    if (error) {
      return { error };
    } else {
      return { error: new Error('Unknown error') };
    }
  }

  // TODO: delete after duplicate rows bug in submissions table is fixed. this
  // is to help Nick debug what's going on in the backend.
  const findDuplicates = (arr: { id: string }[]) =>
    arr.filter((item, index) => {
      const foundIndex = arr.map(({ id }) => id).indexOf(item.id);
      if (foundIndex != index) {
        console.log(foundIndex, arr[foundIndex]);
        console.log(index, arr[index]);
        return true;
      } else {
        return false;
      }
    });

  console.log('DUPLICATES', findDuplicates(rows as { id: string }[]));

  return {
    selectedIds,
    rowCount: rows.length,
    pageSize,
    total: total.toString(),
    refreshCurrentPage,
    dataGridProps: {
      rowsPerPageOptions: [10, 25, 50],
      className: clsx(classes.root, props.className),
      columns,
      rows,
      loading,
      rowHeight: 100,
      checkboxSelection: true,
      disableSelectionOnClick: true,
      page: 0,
      pageSize,
      pagination: true,
      onPageChange: handlePageChange,
      onPageSizeChange: handlePageSizeChange,
      disableColumnReorder: true,
      onSelectionModelChange: ({ selectionModel }) => {
        const rowIds = selectionModel as string[];
        setSelectedIds(rowIds);
      },
      componentsProps: {
        pagination: {
          count: -1,
          labelDisplayedRows: () => (endIndex < startIndex ? '' : `${startIndex}-${endIndex} of ${total}`),
          backIconButtonProps: {
            disabled: loading || startIndex <= 1,
          },
          nextIconButtonProps: {
            disabled: loading || (endIndex >= totalLoaded && pageInfo?.hasNextPage === false),
          },
        },
      },
      onRowClick: handleRowClick,
    },
  };

  async function handlePageSizeChange({ pageSize: newPageSize }: GridPageChangeParams) {
    if (pageInfo?.hasNextPage === false) {
      setPageSize(newPageSize);
      return;
    }

    if (!fetchMore) return;
    let newEndIndex = startIndex + newPageSize - 1;
    const expectedNewRows = newEndIndex - totalLoaded;
    const lastEdgeCursor = connection?.edges[nodes.length - 1].cursor;
    if (expectedNewRows > 0) {
      const { data: newRowsData } = await fetchMore({
        variables: { first: expectedNewRows, after: lastEdgeCursor },
      });
      const newRows = get(newRowsData as typeof data, connectionField)?.edges?.length;

      if (newRows) {
        newEndIndex = endIndex + newRows;
      }
    }
    setPageSize(newPageSize);
  }

  async function handlePageChange({ page }: GridPageChangeParams) {
    if ((page !== 1 && page !== -1) || !fetchMore) return;
    const pageIntent: 'BACK' | 'NEXT' = page === 1 ? 'NEXT' : 'BACK';

    if (pageIntent === 'NEXT') {
      handleNextPage();
    } else {
      handlePreviousPage();
    }
  }

  async function handleNextPage() {
    if (!fetchMore || !pageInfo) return;
    const rowsToRequest = !pageInfo.hasNextPage ? 0 : endIndex + pageSize - totalLoaded;
    if (rowsToRequest > 0) {
      // if results are not cached, run query and then async update state
      const resp = await fetchMore({
        variables: { first: rowsToRequest, after: pageInfo?.endCursor },
      });
      const connection = get(resp.data as typeof data, connectionField);
      const newRows = connection?.edges.length;
      if (!newRows) return;

      setStartIndex(startIndex + pageSize);
    } else {
      // otherwise just increment the page no and feed cached results
      setStartIndex(endIndex + 1);
    }
  }

  function handlePreviousPage() {
    if (startIndex - pageSize < 1) {
      // if going back too far, just reset to page 1 and start again
      setStartIndex(1);
    } else {
      // otherwise go back `pageSize` items
      const newStartIndex = startIndex - pageSize;
      setStartIndex(newStartIndex);
    }
  }

  async function refreshCurrentPage() {
    if (!fetchMore || !edges) return;
    // first clear selected ids
    setSelectedIds([]);
    // then refresh the page
    if (startIndex === 1) {
      await fetchMore({ variables: { first: pageSize } });
    } else {
      await fetchMore({ variables: { first: pageSize, after: edges[startIndex - 2].cursor } });
    }
  }
}
