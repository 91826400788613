import { makeStyles, Typography } from '@material-ui/core';

type FieldDescriptionProps = {
  children?: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  description: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: 12,
    lineHeight: '16px',
    marginTop: 6,
  },
}));

export function FieldDescription(props: FieldDescriptionProps): React.ReactElement {
  const classNames = useStyles();

  return <Typography className={classNames.description}>{props.children}</Typography>;
}
