import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { DataGrid, GridColDef, GridSortItem } from '@material-ui/data-grid';
import { formatDistanceToNowStrict } from 'date-fns';

import {
  useContactsDataGridLazyQuery,
  ContactRowFragment,
  ContactList,
  ContactsDataGridQueryVariables,
  SortDirection,
  ContactSortKey,
} from 'codegen/graphql';
import { id } from 'lib/gid';
import { useHistory } from 'react-router-dom';
import { useDataGridController } from 'hooks/useDataGridController';
import { ContactsDataGridToolbar } from 'components/ContactsDataGridToolbar';
import ErrorCard from 'components/ErrorCard';

export type ContactsDataGridProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Model ID of the contact list to show. Set to 'all' if all contacts should
   * be shown.
   */
  contactList?: Pick<ContactList, 'id' | 'name'>;
};

const useStyles = makeStyles(() => ({
  root: {
    // add styles here
  },
}));

const CONTACTS_DATA_COLUMNS: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'First name',
    sortable: true,
    width: 200,
    hideSortIcons: false,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    sortable: true,
    width: 200,
    hideSortIcons: false,
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    width: 300,
    hideSortIcons: false,
  },
  {
    field: 'submissionsCount',
    headerName: 'Submissions count',
    sortable: true,
    width: 200,
    hideSortIcons: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    sortable: true,
    hideSortIcons: false,
    width: 160,
    valueFormatter: ({ value }) => {
      const createdAt = value as string | null;
      return createdAt ? `${formatDistanceToNowStrict(new Date(createdAt))} ago` : '--';
    },
  },
];

export function ContactsDataGrid(props: ContactsDataGridProps): React.ReactElement {
  const [queryKey, setQueryKey] = useState(0);
  const [queryVariables, setQueryVariables] = useState<ContactsDataGridQueryVariables>({
    contactListId: props.contactList?.id,
    sortBy: null,
    sortDirection: null,
  });

  const history = useHistory();

  const contactsQuery = useContactsDataGridLazyQuery({
    variables: queryVariables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const mapContactsToRows = useCallback((contact: ContactRowFragment) => {
    const { id, createdAt } = contact;
    const firstName = contact.firstName;
    const lastName = contact.lastName;
    const email = contact.email || '--';
    const submissionsCount = contact.submissionsCount;
    return { id, firstName, lastName, createdAt, email, submissionsCount };
  }, []);

  const handleContactRowClick = useCallback(
    (contact: ContactRowFragment) => {
      history.push(`/contacts/${id(contact.id)}/view`);
    },
    [history],
  );

  useEffect(() => {
    editQueryVariables({
      contactListId: props.contactList?.id,
    });
  }, [props.contactList]);

  const queryId = `${queryKey}:${JSON.stringify(queryVariables)}`;

  const controller = useDataGridController({
    columns: CONTACTS_DATA_COLUMNS,
    query: contactsQuery,
    queryId,
    connectionField: 'contacts',
    mapNodesToRows: mapContactsToRows,
    onRowClick: handleContactRowClick,
    initialPageSize: 50,
  });

  if ('error' in controller) {
    return <ErrorCard body="Could not load contacts. Please try refreshing the page." />;
  }

  const { rowCount, selectedIds, total, refreshCurrentPage } = controller;

  return (
    <DataGrid
      {...controller.dataGridProps}
      onSortModelChange={({ sortModel }) => {
        // sort model is an array of sort items, but there is always only 1 sort item...
        handleSortModelChange(sortModel[0] || null);
      }}
      autoHeight
      sortingMode="server"
      rowHeight={50}
      components={{
        Toolbar: () => (
          <ContactsDataGridToolbar
            rowCount={rowCount}
            selectedIds={selectedIds}
            total={total}
            contactList={props.contactList}
            refreshCurrentPage={refreshCurrentPage}
            reloadDataGrid={reloadDataGrid}
          />
        ),
      }}
    />
  );

  /**
   * Callback that reloads the currently viewed data grid.
   */
  function reloadDataGrid() {
    setQueryKey(queryKey + 1);
  }

  /**
   * Similar to `setVariables`, but merges incoming variables with existing
   * variables. Note that you should only call this once per render, since the
   * first invocation will be overriden by the second invocation.
   */
  function editQueryVariables(newQueryVariables: Partial<ContactsDataGridQueryVariables>) {
    setQueryVariables({
      ...queryVariables,
      ...newQueryVariables,
    });
  }

  function handleSortModelChange(sortItem: GridSortItem | null) {
    if (sortItem === null) {
      editQueryVariables({
        sortDirection: null,
        sortBy: null,
      });
      return;
    }

    const sortDirection = sortItem.sort === 'asc' ? SortDirection.Asc : SortDirection.Desc;
    let sortBy: ContactSortKey | null;

    switch (sortItem.field) {
      case 'firstName': {
        sortBy = ContactSortKey.FirstName;
        break;
      }
      case 'lastName': {
        sortBy = ContactSortKey.LastName;
        break;
      }
      case 'email': {
        sortBy = ContactSortKey.Email;
        break;
      }
      case 'submissionsCount': {
        sortBy = ContactSortKey.SubmissionsCount;
        break;
      }
      case 'createdAt': {
        sortBy = ContactSortKey.CreatedAt;
        break;
      }
      default: {
        sortBy = null;
        break;
      }
    }

    editQueryVariables({ sortDirection, sortBy });
  }
}
