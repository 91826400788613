import { useState, useRef, useEffect } from 'react';
import { makeStyles, ButtonBase, Menu } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Edit, Trash2 } from 'react-feather';

import Preview, { PreviewProps } from 'components/Preview';

export type EditablePreviewProps = PreviewProps & {
  /**
   * Callback called when the edit button is clicked. This is ignored if
   * `props.editMenuContent` is specified.
   */
  onEdit?: () => void;
  /**
   * Content of a menu that pops up when the edit button is clicked. This
   * overrides `props.onEdit` if specified.
   */
  editMenuContent?: (props: { onClose: () => void }) => JSX.Element;
  /**
   * Callback called when the delete button is clicked.
   */
  onDelete?: () => void;
};

const hoverButtonStyles = {
  zIndex: 1000,
  width: '100%',
  height: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  borderRadius: 0,
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  previewWrapper: (props: EditablePreviewProps) => ({
    width: props.size ?? 100,
    height: props.size ?? 100,
    borderRadius: 16,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  }),
  darken: {
    filter: 'brightness(70%)',
    transition: 'all 0.5s ease',
  },
  editButton: {
    position: 'absolute',
    top: 0,
    ...hoverButtonStyles,
  },
  deleteButton: {
    position: 'absolute',
    bottom: 0,
    ...hoverButtonStyles,
  },
}));

/**
 * A wrapper around the `Preview` component, which shows an edit and delete
 * button on hover.
 */
export function EditablePreview(props: EditablePreviewProps): React.ReactElement {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const [hover, setHover] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const editButtonRef = useRef<HTMLButtonElement>(null);
  const { onEdit, onDelete, className: classNameInProps, ...previewProps } = props;

  return (
    <>
      <div
        className={clsx(classes.root, classNameInProps)}
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div style={{ display: hover || menuOpen ? 'block' : 'none' }}>
          <ButtonBase
            className={classes.editButton}
            onClick={props.editMenuContent ? () => setMenuOpen(true) : onEdit}
            ref={editButtonRef}
          >
            <Edit color="white" />
          </ButtonBase>
          <ButtonBase className={classes.deleteButton} onClick={onDelete}>
            <Trash2 color="white" />
          </ButtonBase>
        </div>
        <div className={clsx(classes.previewWrapper, hover && classes.darken)}>
          <Preview {...previewProps} />
        </div>
      </div>
      {/* menu has to be sibling of root div to not trigger mouse events in root div */}
      {editButtonRef.current && props.editMenuContent && (
        <Menu
          open={menuOpen}
          onClose={closeMenu}
          anchorEl={editButtonRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {props.editMenuContent({ onClose: closeMenu })}
        </Menu>
      )}
    </>
  );

  function closeMenu() {
    setMenuOpen(false);
  }
}
