import { PresignCloudinaryFilePayload } from 'codegen/graphql';

async function convertImageBase64ToFile(base64: string, fileName: string): Promise<File> {
  const blob = await (await fetch(base64)).blob();
  const file = new File([blob], fileName, { type: 'image/png' });

  return file;
}

export type CloudinaryImage = {
  asset_id: string;
  bytes: number;
  created_at: string;
  etag: string;
  format: string;
  height: number;
  original_filename: string;
  placeholder: false;
  public_id: string;
  resource_type: string;
  secure_url: string;
  signature: string;
  tags: string[];
  type: string;
  url: string;
  version: number;
  version_id: string;
  width: number;
};

/**
 * Async function that uploads an image to Cloudinary provided the response from
 * a `presignCloudinaryFile` mutation. Will throw an exception if any fields in
 * the presignature are `undefined`.
 */
export async function uploadSignedImage(
  presign: Omit<PresignCloudinaryFilePayload, 'errors'>,
  image: string | File,
  fileName: string,
  onPercent?: (percent: number) => void,
): Promise<CloudinaryImage> {
  const { apiKey, params, signature, publicId, uploadUrl } = presign;
  if (!apiKey || !params || !signature || !publicId || !uploadUrl) {
    throw new Error('Received incomplete presign response from Admin API.');
  }

  const formData = new FormData();
  const file = typeof image == 'string' ? await convertImageBase64ToFile(image, fileName) : image;

  formData.append('api_key', apiKey);
  formData.append('signature', signature);
  formData.append('public_id', publicId);
  formData.append('file', file);

  const paramsToSign = JSON.parse(params as unknown as string);

  for (const key in paramsToSign) {
    formData.append(key, paramsToSign[key]);
  }

  return await new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('POST', uploadUrl);

    if (onPercent) {
      request.upload.addEventListener('progress', (e) => {
        const percentUploaded = Math.round((e.loaded / e.total) * 100);

        onPercent(percentUploaded);
      });
    }

    // Request done
    request.addEventListener('load', () => {
      // HTTP status message (200, 404 etc)
      resolve(JSON.parse(request.response));
    });

    request.addEventListener('error', () => {
      console.error(request.response);
      reject('Failed to upload image to Cloudinary');
    });

    // send POST request to server
    request.send(formData);
  });
}
