import { Card, CardHeader, CardContent, makeStyles } from '@material-ui/core';
import Page, { Breadcrumb } from 'pages/Page';
import { useContactPageQuery } from 'codegen/graphql';
import { ContactModel } from 'models/ContactModel';
import { gid, id } from 'lib/gid';
import { ContactDetailsCard } from 'components/ContactDetailsCard';
import ContactSubmissionCard from 'src/components/ContactSubmissionCard';
import { Skeleton } from '@material-ui/lab';

import ErrorCard from 'components/ErrorCard';

export type ContactProps = {
  /**
   * Model ID of the contact to view.
   */
  id: string;
};

const useStyles = makeStyles(() => ({
  root: {},
  deleteButton: {
    alignSelf: 'flex-start',
  },
  divider: {
    border: 0,
    height: 1,
    width: 'calc(100% - 64px)',
    backgroundColor: '#ddd',
    margin: '2em 32px 0 32px',
  },
  cardHeader: {
    marginBottom: '0.5em',
    '& .MuiCardHeader-title': {
      fontSize: '1.25em',
      lineHeight: 1,
    },
  },
  submissionsHeading: {
    paddingLeft: 32,
    fontWeight: 400,
    margin: '2em 0 1em',
    color: '#263238',
    paddingBottom: '0.5em',
    borderBottom: '1px solid #dedede',
  },
  submissionsCard: {
    marginTop: '2em',
    '& .MuiCard-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
      boxShadow: 'none',
      border: '1px solid #dddddd',
    },
  },
}));

export function Contact(props: ContactProps): React.ReactElement {
  const classes = useStyles();

  const contactId = gid(props.id, 'Contact');

  const { loading, error, data } = useContactPageQuery({ variables: { id: contactId } });

  if (!loading && (error || !data || !data.contact)) {
    console.error(error);
    return <ErrorCard body="We weren't able to fetch details for this contact." />;
  }

  const contact = new ContactModel({ ...data?.contact });
  const name = contact.name;

  const breadcrumbs: Breadcrumb[] = [
    {
      to: '/contacts/lists/all',
      body: 'Contacts',
    },
    {
      to: `/contacts/${id(contactId)}/view`,
      body: name || contact.email || 'Unnamed contact',
    },
  ];

  if (loading) return <ContactSkeleton />;

  return (
    <Page title={name} maxWidth="lg" subtitle={`Contact since ${contact.creationDate}`} breadcrumbs={breadcrumbs}>
      {data?.contact && <ContactDetailsCard contact={data.contact} />}

      <Card className={classes.submissionsCard}>
        <CardHeader className={classes.cardHeader} title="Photo submissions" />

        <CardContent>
          {data?.contact?.submissions.map((submission) => (
            <ContactSubmissionCard key={submission.id} submission={submission} />
          ))}
        </CardContent>
      </Card>
    </Page>
  );
}

function ContactSkeleton(): React.ReactElement {
  const classes = useStyles();

  return (
    <Page
      maxWidth="xl"
      title={<Skeleton variant="text" width={300} />}
      subtitle={<Skeleton variant="text" width={200} />}
      breadcrumbs={[
        {
          to: '/contacts/lists/all',
          body: 'Contacts',
        },
        {
          to: '/contacts/lists/all',
          body: <Skeleton variant="text" width={100} />,
        },
      ]}
      pageActions={<Skeleton variant="text" />}
    >
      <ContactDetailsCard.Skeleton />

      <Card className={classes.submissionsCard}>
        <CardHeader className={classes.cardHeader} title="Photo submissions" />

        <CardContent>
          <ContactSubmissionCard.Skeleton />
        </CardContent>
      </Card>

      <Skeleton variant={'text'} width={130} height={60} />
    </Page>
  );
}
