import { Dialog } from 'components/Dialog';
import { SelectField, SelectItem } from 'components/SelectField';
import RadioField from 'components/RadioField';
import { Box, Button, Divider, FormControl, FormLabel, makeStyles, Typography } from '@material-ui/core';
import { FrequencyEnum } from 'codegen/graphql';
import { useController, useForm } from 'react-hook-form';
import { notificationExists } from 'pages/SubmissionNotifications/utils';
import { useContext, useEffect } from 'react';
import { NotificationContext } from 'pages/SubmissionNotifications';
import { RadioItem } from 'components/RadioField/RadioField';

type NotificationDialogProps = {
  open: boolean;
  onClose: () => void;
  onCreate: (input: { studioId: string; frequency: FrequencyEnum }) => void;
  onUpdate: (input: { notificationId: string; frequency: FrequencyEnum }) => void;
  loading: boolean;
};

const useStyles = makeStyles({
  frequencyLabel: {
    marginTop: '1.5rem',
    marginBottom: '0.5rem',
    textTransform: 'uppercase',
  },
  button: {
    textTransform: 'uppercase',
  },
  studioLabel: {
    textTransform: 'uppercase',
    marginBottom: '0.5rem',
  },
  studioName: {
    paddingBottom: '1rem',
  },
  intervalSelect: {
    paddingTop: '1rem',
  },
  intervalDescription: {
    minHeight: '25px',
    paddingTop: '0.25rem',
    paddingLeft: '1rem',
  },
});

export function NotificationDialog({ open, onClose, onCreate, onUpdate, loading }: NotificationDialogProps) {
  const context = useContext(NotificationContext);
  const classes = useStyles();

  const selectedNotification = context.selectedNotification;

  const {
    formState: { dirtyFields },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      studioId: '',
      notificationId: '',
      frequencyRadioOption: '',
      interval: '',
    },
  });

  useEffect(() => {
    if (!selectedNotification) return;

    setValue('studioId', '');
    setValue('notificationId', selectedNotification.id);
    setValue(
      'frequencyRadioOption',
      selectedNotification.frequency === FrequencyEnum.Immediate ? 'IMMEDIATE' : 'SUMMARY',
    );
    setValue(
      'interval',
      selectedNotification.frequency === FrequencyEnum.Immediate ? '' : (selectedNotification.frequency as string),
    );
  }, [selectedNotification, setValue]);

  const notificationValues = getValues();

  const studioIdController = useController({ control, name: 'studioId' });
  const frequencyRadioController = useController({ control, name: 'frequencyRadioOption' });
  const intervalController = useController({ control, name: 'interval' });

  const correctStudioId = selectedNotification?.studio?.id || notificationValues.studioId;

  const immediateExists = notificationExists(context.notifications, correctStudioId, FrequencyEnum.Immediate);
  const dailyExists = notificationExists(context.notifications, correctStudioId, FrequencyEnum.Daily);
  const weeklyExists = notificationExists(context.notifications, correctStudioId, FrequencyEnum.Weekly);
  const monthlyExists = notificationExists(context.notifications, correctStudioId, FrequencyEnum.Monthly);

  const studioItems: SelectItem[] =
    context.studios.map((edge) => ({ label: edge.node.name, value: edge.node.id })) || [];

  const frequencyRadioOptions: RadioItem[] = [
    {
      label: 'IMMEDIATE',
      value: FrequencyEnum.Immediate,
      description: 'Send unique emails for each submission.',
      disabled: selectedNotification
        ? immediateExists
        : !dirtyFields.studioId || (dirtyFields.studioId && immediateExists),
    },
    {
      label: 'SUMMARY',
      value: 'SUMMARY',
      description: 'One email that is sent on a desired interval.',
      disabled: selectedNotification
        ? dailyExists && weeklyExists && monthlyExists
        : !dirtyFields.studioId || (dirtyFields.studioId && dailyExists && weeklyExists && monthlyExists),
    },
  ];

  const intervalOptions: (SelectItem & {
    description?: string;
  })[] = [
    {
      label: 'Daily',
      value: FrequencyEnum.Daily,
      disabled: dailyExists,
    },
    {
      label: 'Weekly',
      value: FrequencyEnum.Weekly,
      disabled: weeklyExists,
      description: 'Sent every Monday.',
    },
    {
      label: 'Monthly',
      value: FrequencyEnum.Monthly,
      disabled: monthlyExists,
      description: 'Sent on the first of each month.',
    },
  ];

  const fieldsAreFilled =
    !!dirtyFields.studioId &&
    (notificationValues.frequencyRadioOption === FrequencyEnum.Immediate || !!dirtyFields.interval);

  const fieldsReadyForUpdate =
    !!selectedNotification &&
    ((notificationValues.frequencyRadioOption === 'SUMMARY' && notificationValues.interval !== '') ||
      (notificationValues.frequencyRadioOption === 'IMMEDIATE' && notificationValues.interval === ''));

  return (
    <Dialog
      title={selectedNotification ? 'Manage studio notification' : 'Add new studio notification'}
      width={480}
      open={open}
      onClose={() => {
        reset();
        onClose();
      }}
      actions={
        <>
          <Button
            className={classes.button}
            disabled={loading}
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>

          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
              reset();
            }}
            disabled={(selectedNotification ? !fieldsReadyForUpdate : !fieldsAreFilled) || loading}
          >
            {selectedNotification ? 'Save Changes' : 'Add'}
          </Button>
        </>
      }
    >
      {!selectedNotification && (
        <FormControl fullWidth>
          <SelectField variant="outlined" label="Select studio" items={studioItems} controller={studioIdController} />
        </FormControl>
      )}
      <FormControl fullWidth>
        {!!selectedNotification && (
          <>
            <FormLabel className={classes.studioLabel}>Studio</FormLabel>
            <Typography className={classes.studioName}>{selectedNotification?.studio?.name}</Typography>
            <Divider />
          </>
        )}
        <FormLabel className={classes.frequencyLabel}>Frequency</FormLabel>
        <RadioField
          fullWidthItems
          controller={frequencyRadioController}
          items={frequencyRadioOptions}
          onChange={(value) => {
            if (value === 'SUMMARY') {
              setValue(
                'interval',
                selectedNotification && selectedNotification.frequency !== FrequencyEnum.Immediate
                  ? selectedNotification.frequency
                  : '',
              );
            }
            if (value === 'IMMEDIATE') {
              setValue('interval', '');
            }
          }}
        />
      </FormControl>
      {!['', 'IMMEDIATE'].includes(notificationValues.frequencyRadioOption) && (
        <FormControl fullWidth className={classes.intervalSelect}>
          <SelectField
            variant="outlined"
            label="Desired interval"
            items={intervalOptions}
            controller={intervalController}
          />
          <Box className={classes.intervalDescription}>
            <Typography variant="caption" color={'textSecondary'}>
              {intervalOptions.find((x) => x.value == notificationValues.interval)?.description}
            </Typography>
          </Box>
        </FormControl>
      )}
    </Dialog>
  );

  function handleSubmit() {
    if (selectedNotification) {
      if (!fieldsReadyForUpdate) return;
      onUpdate({
        notificationId: selectedNotification.id,
        frequency:
          notificationValues.frequencyRadioOption === FrequencyEnum.Immediate
            ? FrequencyEnum.Immediate
            : (notificationValues.interval as FrequencyEnum),
      });
    } else {
      if (!fieldsAreFilled) return;
      onCreate({
        studioId: notificationValues.studioId,
        frequency:
          notificationValues.frequencyRadioOption === FrequencyEnum.Immediate
            ? FrequencyEnum.Immediate
            : (notificationValues.interval as FrequencyEnum),
      });
    }
  }
}
