import { useRef, useCallback, useState } from 'react';
import { ButtonBase, CircularProgress, Paper, makeStyles, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import { Plus, Upload } from 'react-feather';
import clsx from 'clsx';
import { Palette } from '@material-ui/icons';
import { Color, ColorBox, ColorBoxProps } from 'material-ui-color';

import { ColorInput } from '../BackgroundField';

export type FileOrColorSelectButtonProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * MIME types to accept in file input field. Defaults to `image/*`.
   */
  accept?: string;
  /**
   * Callback to execute on file upload. If this callback is asynchronous, this
   * component renders a loading spinner until the callback resolves.
   */
  onChange: (file: File | ColorInput) => unknown;
  colorBoxProps?: Omit<ColorBoxProps, 'onChange' | 'value'>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 100,
    height: 100,
    borderRadius: 16,
    '& .MuiTouchRipple-root': {
      borderRadius: 16,
    },
  },
  paper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 100,
    height: 100,
    '& > img': {
      maxHeight: 100,
      maxWidth: 100,
      borderRadius: 16,
    },
  },
}));

/**
 * A button, which shockingly, allows users to upload files. You can access the
 * uploaded file via the `onUpload` callback.
 */
export function FileOrColorSelectButton(props: FileOrColorSelectButtonProps): React.ReactElement {
  const classes = useStyles();
  const paperRef = useRef<HTMLElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [colorMenuOpen, setColorMenuOpen] = useState(false);
  const [color, setColor] = useState<Color>();
  const [loading, setLoading] = useState(false);

  const { onChange } = props;

  const handleFileUpload = useCallback(async () => {
    const newUploadedFile = fileInputRef.current?.files?.[0];
    if (!newUploadedFile) return;
    setMenuOpen(false);
    setLoading(true);
    await onChange(newUploadedFile);
    setLoading(false);
  }, [onChange]);

  const handleColorSelectIntent = useCallback(() => {
    setMenuOpen(false);
    setColorMenuOpen(true);
  }, []);

  const handleColorChange = useCallback((color: Color) => {
    setColor(color);
  }, []);

  return (
    <>
      <ButtonBase className={clsx(classes.root, props.className)} disabled={loading} onClick={() => setMenuOpen(true)}>
        <Paper className={classes.paper} ref={paperRef}>
          {loading ? <CircularProgress size={24} /> : <Plus />}
        </Paper>
      </ButtonBase>
      <Menu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={paperRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem component="label">
          <ListItemIcon>
            <Upload />
          </ListItemIcon>
          Upload image
          <input type="file" accept={props.accept || 'image/*'} hidden ref={fileInputRef} onChange={handleFileUpload} />
        </MenuItem>
        <MenuItem onClick={handleColorSelectIntent}>
          <ListItemIcon>
            <Palette />
          </ListItemIcon>
          Select color
        </MenuItem>
      </Menu>
      <Menu
        open={colorMenuOpen}
        onClose={() => {
          if (color) props.onChange({ hex: `#${color.hex}` });
          setColorMenuOpen(false);
        }}
        anchorEl={paperRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ColorBox {...props.colorBoxProps} value={color} onChange={handleColorChange} />
      </Menu>
    </>
  );
}
