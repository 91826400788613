import { IconButton, makeStyles, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { SettingsCard } from 'components/SettingsCard';
import { PresetsTable } from 'components/PresetsTable';
import { Preset as CompletePreset, useUpdatePoseMutation } from 'codegen/graphql';
import { toast } from 'components/GlobalSnackbar';
import { useMenu } from 'hooks/useMenu';
import { Plus } from 'react-feather';
import { id } from 'lib/gid';
import { usePermissions } from 'hooks/usePermissions';

type Preset = Pick<CompletePreset, 'id' | 'name' | 'resize' | 'default'>;

export type PosePresetsCardProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * GID of the current pose.
   */
  poseId: string;
  /**
   * GID of the current studio.
   */
  studioId: string;
  /**
   * Globally available presets.
   */
  presets: Preset[];
  /**
   * Presets that belong to the current pose.
   */
  appliedPresets: Preset[];
};

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 4,
  },
}));

export function PosePresetsCard(props: PosePresetsCardProps): React.ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { auth } = usePermissions();
  const [presetRemovalLoading, setPresetRemovalLoading] = useState(false);
  const [updatePose] = useUpdatePoseMutation();
  const applicationButtonRef = useRef<HTMLButtonElement>(null);
  const { menuProps: applicationMenuProps, openMenu: openApplicationMenu } = useMenu({
    anchorEl: () => applicationButtonRef.current as Element,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  });
  const classes = useStyles();

  const appliedPresetIds = props.appliedPresets.map((appliedPreset) => appliedPreset.id);

  const comparePresetsByName = (presetA: Preset, presetB: Preset) => {
    const nameA = presetA.name.toLowerCase();
    const nameB = presetB.name.toLowerCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  };

  const applicationMenuItems = props.presets
    .filter((preset) => !appliedPresetIds.includes(preset.id))
    .sort(comparePresetsByName)
    .map((preset, index) => (
      <ApplicationMenuItem
        preset={preset}
        appliedPresets={props.appliedPresets}
        studioId={props.studioId}
        poseId={props.poseId}
        key={index}
      />
    ));

  return (
    <>
      <SettingsCard
        title={`Applied ${t('preset_plural')}`}
        cardActions={
          !!applicationMenuItems.length && (
            <IconButton onClick={openApplicationMenu} ref={applicationButtonRef} className={classes.iconButton}>
              <Plus />
            </IconButton>
          )
        }
      >
        <PresetsTable
          presets={props.appliedPresets}
          handlePresetReorder={handlePresetReorder}
          renderMenu={({ presetId, ...menuProps }) => (
            <Menu {...menuProps}>
              <Tooltip title={props.appliedPresets.length <= 1 ? `Cannot remove last applied ${t('preset')}` : ''}>
                <span>
                  {auth?.canChangePreset && (
                    <MenuItem onClick={() => history.push(`/presets/${id(presetId)}/edit`)}>
                      Edit {t('preset')}
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => handlePresetRemoval(presetId, menuProps.onClose)}
                    disabled={presetRemovalLoading || props.appliedPresets.length <= 1}
                  >
                    {presetRemovalLoading ? 'Removing...' : 'Remove'}
                  </MenuItem>
                </span>
              </Tooltip>
            </Menu>
          )}
        />
      </SettingsCard>
      <Menu {...applicationMenuProps}>{applicationMenuItems}</Menu>
    </>
  );

  async function handlePresetRemoval(removedPresetId: string, onClose: () => void) {
    setPresetRemovalLoading(true);
    const filteredPresetIds = props.appliedPresets
      .filter((appliedPreset) => appliedPreset.id !== removedPresetId)
      .map((p) => p.id);
    let success = false;

    try {
      const { data, errors: gqlErrors } = await updatePose({
        variables: { input: { id: props.poseId, studioId: props.studioId, presetIds: filteredPresetIds } },
      });
      const userErrors = data?.updatePose?.errors;
      if (!gqlErrors?.length && !userErrors?.length) success = true;
    } catch (networkError) {
      console.error('Network error', networkError);
    }

    setPresetRemovalLoading(false);
    onClose();
    if (success) {
      toast(`${t('preset_capital')} removed`, 'success');
    } else {
      toast(`Could not remove ${t('preset')}`, 'error');
    }
  }

  async function handlePresetReorder(reorderedPresets: Preset[]) {
    const reorderedPresetIds = reorderedPresets.map((preset) => preset.id);
    let success = false;

    try {
      const { data, errors: gqlErrors } = await updatePose({
        variables: { input: { id: props.poseId, studioId: props.studioId, presetIds: reorderedPresetIds } },
      });
      const userErrors = data?.updatePose?.errors;
      if (!userErrors?.length && !gqlErrors?.length) success = true;
    } catch (networkError) {
      console.error('Network error', networkError);
    }
    if (success) {
      toast(`${t('preset_plural_capital')} saved`, 'success');
    } else {
      toast(`Could not save ${t('preset_plural')}`, 'error');
    }
  }
}

type ApplicationMenuItemProps = {
  appliedPresets: Preset[];
  preset: Preset;
  poseId: string;
  studioId: string;
};

/**
 * A menu item in the preset application menu that shows when you click the "+"
 * button. These need to be a separate component because it needs hooks to
 * manage a loading and error state for each menu item.
 */
function ApplicationMenuItem(props: ApplicationMenuItemProps): JSX.Element {
  const { t } = useTranslation();
  const { preset } = props;
  const [updatePose, { loading }] = useUpdatePoseMutation();

  return (
    <MenuItem disabled={loading} onClick={handlePresetApplication}>
      {loading ? 'Applying...' : preset.name}
    </MenuItem>
  );

  async function handlePresetApplication() {
    const appliedPresetIds = props.appliedPresets.map((preset) => preset.id);
    const newPresetIds = [...appliedPresetIds, props.preset.id];
    let success = false;

    // do nothing if preset is already applied
    if (appliedPresetIds.includes(props.preset.id)) return;

    try {
      const { data, errors: gqlErrors } = await updatePose({
        variables: { input: { id: props.poseId, studioId: props.studioId, presetIds: newPresetIds } },
      });
      const userErrors = data?.updatePose?.errors;
      if (!userErrors?.length && !gqlErrors?.length) success = true;
    } catch (networkError) {
      console.error('Network error', networkError);
    }
    if (success) {
      toast(`${t('preset_capital')} applied`, 'success');
    } else {
      toast(`Could not apply ${t('preset')} `, 'error');
    }
  }
}
