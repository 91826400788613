import { makeStyles, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import TextField from 'components/TextField';
import Dialog from 'components/Dialog';
import {
  useCreateContactListMutation,
  useUpdateContactListMutation,
  GetContactListDocument,
  GetContactListQuery,
} from 'codegen/graphql';
import { toast } from 'components/GlobalSnackbar';
import { gid, id as modelId } from 'src/lib/gid';
import { handleMutation } from 'src/lib/handleMutation';
import { useApolloClient } from '@apollo/client';

export type ContactListFormDialogProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Toggles opening of the dialog.
   */
  open: boolean;
  /**
   * Callback executed on dialog close.
   */
  onClose: () => void;
  /**
   * Model ID of the contact to update. If not provided, contact list creation
   * intent is assumed.
   */
  id?: string;
};

const ContactListFormSchema = z.object({
  name: z.string().min(1, 'Must not be blank'),
});

type ContactListForm = z.infer<typeof ContactListFormSchema>;

const useStyles = makeStyles(() => ({
  root: {
    // add styles here
  },
}));

export function ContactListFormDialog(props: ContactListFormDialogProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const id = props.id;
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const [createContactList, createContactListState] = useCreateContactListMutation();
  const [updateContactList, updateContactListState] = useUpdateContactListMutation();

  const { control, reset, trigger, getValues } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(ContactListFormSchema),
  });

  const nameController = useController<ContactListForm>({ control, name: 'name' });

  // if provided an ID, hydrate form with existing values
  useEffect(() => {
    async function hydrateForm() {
      if (id) {
        setLoading(true);
        const { data } = await client.query<GetContactListQuery>({
          query: GetContactListDocument,
          variables: { id },
        });
        const contactList = data?.contactList;
        if (!contactList) {
          setLoading(false);
          toast('Could not load existing contact list', 'error');
          props.onClose();
          return;
        }

        const newForm: ContactListForm = {
          name: contactList.name,
        };

        reset(newForm);
      }

      setLoading(false);
    }
    hydrateForm();
    // only run on initial render
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      onExited={() => reset()}
      title={id ? 'Rename list ' : 'New list'}
      width={300}
      actions={
        <>
          <Button onClick={props.onClose}>CANCEL</Button>
          {id ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUpdate(id)}
              disabled={loading || updateContactListState.loading}
              style={{ textTransform: 'uppercase' }}
            >
              {updateContactListState.loading ? 'Renaming list...' : 'Rename list'}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreate}
              disabled={loading || createContactListState.loading}
              style={{ textTransform: 'uppercase' }}
            >
              {createContactListState.loading ? 'Creating list...' : 'Create list'}
            </Button>
          )}
        </>
      }
    >
      {loading ? <Skeleton height={57} /> : <TextField controller={nameController} label="List name" fullWidth />}
    </Dialog>
  );

  async function handleCreate() {
    if (!(await trigger())) return;
    const form = getValues();
    await handleMutation({
      mutate: () => createContactList({ variables: { name: form.name } }),
      payloadFieldPath: 'createContactList?',
      successCondition: (data) => !!data.createContactList?.contactList?.id,
      onSuccess: (data) => {
        const newListId = data.createContactList?.contactList?.id;
        if (newListId) {
          toast('Contact list created', 'success');
          history.push(`/contacts/lists/${modelId(newListId)}`);
        }
      },
      onError: () => {
        toast('Could not create contact list', 'error');
      },
    });
    props.onClose();
  }

  async function handleUpdate(id: string) {
    if (!(await trigger())) return;
    const form = getValues();
    await handleMutation({
      mutate: () => updateContactList({ variables: { input: { id, name: form.name } } }),
      payloadFieldPath: 'updateContactList?',
      successCondition: (data) => !!data.updateContactList?.contactList?.id,
      onSuccess: () => {
        toast('Contact list updated', 'success');
      },
      onError: () => {
        toast('Could not create contact list', 'error');
      },
    });
    props.onClose();
  }
}
