import { useTheme } from '@material-ui/styles';
import type { Theme } from '@material-ui/core';

/**
 * Basic typed hook that returns the theme as a MUI Theme object.
 * @returns The theme object provided in the root ThemeProvider component.
 */
export default function useCustomTheme(): Theme {
  return useTheme<Theme>();
}
