import { useMemo, useState, useEffect } from 'react';
import { makeStyles, Grid, FormLabel, FormControl, FormHelperText, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UseControllerReturn } from 'react-hook-form';
import { Check } from 'react-feather';
import clsx from 'clsx';

export type SwatchFieldProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Items to render in the swatch field.
   */
  items: SwatchItem[];
  /**
   * Label for this swatch field.
   */
  label?: string;
  /**
   * `react-hook-form` controller.
   */
  controller: UseControllerReturn<any, 'profileId'>;
};

export type SwatchItem = {
  /**
   * Internal value of the swatch field when this item is selected.
   */
  value: unknown;
  /**
   * Label of this item.
   */
  label: string;
  /**
   * Image URL of this item
   */
  url: string;
};

const useStyles = makeStyles(() => ({
  swatchImage: {
    maxWidth: 128,
    maxHeight: 128,
    border: '2px solid transparent',
    cursor: 'pointer',
  },
  swatchImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 128,
    height: 128,
  },
  swatchLabel: {
    maxWidth: 128,
    fontSize: 11,
    textAlign: 'center',
  },
  selectedSwatchImage: {
    border: '2px solid #5850EC',
  },
  selectedCheck: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#5850EC',
    lineHeight: 0,
  },
}));

export function SwatchField(props: SwatchFieldProps): React.ReactElement {
  const classes = useStyles();
  const value = props.controller.field.value as unknown;
  const onChange = props.controller.field.onChange as (value: unknown) => void;
  const [focused, setFocused] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    props.controller.field.onChange(value);
  }, [props.controller.field, value]);

  const swatchItems = useMemo(
    () =>
      props.items.map((item, index) => (
        <Grid item key={index} onClick={() => onChange(item.value)} tabIndex={0}>
          <div className={classes.swatchImageWrapper}>
            <div style={{ position: 'relative' }}>
              <img
                src={item.url}
                alt={item.label}
                className={clsx(classes.swatchImage, value === item.value && classes.selectedSwatchImage)}
              ></img>
              {value === item.value && (
                <div className={classes.selectedCheck}>
                  <Check color="white" />
                </div>
              )}
            </div>
          </div>
          <Typography className={classes.swatchLabel}>{item.label}</Typography>
        </Grid>
      )),
    [classes, onChange, props.items, value],
  );

  return (
    <FormControl
      className={props.className}
      error={!!props.controller.fieldState.error}
      focused={focused}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <FormLabel>{props.label}</FormLabel>
      <Grid container spacing={1}>
        {swatchItems}
      </Grid>
      {!!props.controller.fieldState.error && (
        <FormHelperText>{props.controller.fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  );
}
