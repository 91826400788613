import { useState, useEffect } from 'react';
import { makeStyles, FormControl, FormLabel, Button, Typography, Switch } from '@material-ui/core';
import { Control, FormState, useController } from 'react-hook-form';
import PageActionButton from 'components/PageActionButton';
import TextField from 'components/TextField';
import SwatchField from 'components/SwatchField';
import FileField from 'components/FileField';
import BackgroundField from 'src/components/BackgroundField';
import Dialog from 'components/Dialog';
import { PoseFormState } from 'pages/PosesPage';
import { Preset, PoseProfileDetailsFragment } from 'codegen/graphql';
import RadioField from 'components/RadioField';
import { RichTextEditor } from 'components/RichTextEditor';
import { FormErrors } from 'components/FormErrors/FormErrors';
import { CheckboxField } from 'components/CheckboxField';

export type PoseFormDialogProps = {
  /**
   * Title of the pose fields dialog.
   */
  title: string;
  /**
   * `react-hook-form` control.
   */
  control: Control<PoseFormState>;
  /**
   * Toggles opening of the dialog.
   */
  open: boolean;
  /**
   * Callback executed on dialog close.
   */
  onClose: () => void;
  /**
   * Callback executed on dialog executed (after the close animation completes)
   */
  onExited: () => void;
  /**
   * Callback executed on form submission.
   */
  onSubmit: () => void;
  /**
   * Whether the submission button should be in a loading state.
   */
  loading?: boolean;
  /**
   * Globally available pose profiles.
   */
  poseProfiles: PoseProfileDetailsFragment[];
  presets: Pick<Preset, 'id' | 'name' | 'default'>[];
  savedGuidelines: string;
  formState: FormState<PoseFormState>;
  ref?: HTMLDivElement;
};

const useStyles = makeStyles(() => ({
  form: {
    '& > *:not(:last-child)': {
      marginBottom: 24,
    },
  },
  customProfileField: {
    marginTop: 8,
  },
  nestedBackgroundField: {
    marginLeft: 32,
    width: 'calc(100% - 32px)',
  },
  globalPoseProfiles: {
    marginTop: 8,
  },
  rte: {
    border: '1px solid #eee',
    borderRadius: '0 0 16px 16px',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 200,
  },
  rteToolbar: {
    position: 'static',
    border: '1px solid #eee',
    borderBottom: 'none',
    borderRadius: '16px 16px 0 0',
  },
}));

const MAX_NAME_CHARS = 50;

export function PoseFormDialog(props: PoseFormDialogProps): React.ReactElement {
  const { control } = props;
  const classes = useStyles();
  const [profileFocused, setProfileFocused] = useState(false);

  const nameController = useController({
    control,
    name: 'name',
    rules: {
      required: { value: true, message: 'Must not be blank' },
      maxLength: { value: MAX_NAME_CHARS, message: `Must not exceed ${MAX_NAME_CHARS} characters` },
    },
  });
  const profileIdController = useController({ control, name: 'profileId' });
  const guidelinesController = useController({ control, name: 'guidelines' });
  const customExampleImagesController = useController({
    control,
    name: 'customProfile.exampleImages',
    rules: {
      validate: (images) => {
        if (images) return images.length > 10 ? 'Must not exceed 10 images' : undefined;
      },
    },
  });
  const customOutlineImageController = useController({ control, name: 'customProfile.outlineImage' });
  const removeBgController = useController({
    control: props.control,
    name: 'removeBackground',
    rules: {
      validate: (setting) => {
        if (setting === 'enabled-with-backdrops' && backdropsController.field.value.length < 2) {
          return 'You must provide at least two backdrops, or choose another background option';
        }
      },
    },
  });
  const backdropsController = useController({ control, name: 'backdrops' });
  const allowPhotoUploadController = useController({ control, name: 'allowPhotoUpload' });

  const [custom, setCustom] = useState(!profileIdController.field.value);
  const firstProfile = props.poseProfiles?.[0];

  // keep custom in sync when switching between poses
  useEffect(() => {
    setCustom(!profileIdController.field.value);
  }, [profileIdController.field.value]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      onExited={props.onExited}
      title={props.title}
      actions={
        <>
          <Button onClick={props.onClose}>CANCEL</Button>
          <PageActionButton onClick={props.onSubmit} loading={props.loading}>
            Save changes
          </PageActionButton>
        </>
      }
      width={600}
    >
      <div className={classes.form}>
        <FormErrors errors={props.formState.errors} />
        <TextField label="Name" variant="outlined" fullWidth maxChars={MAX_NAME_CHARS} controller={nameController} />
        <FormControl
          focused={profileFocused}
          onFocus={() => setProfileFocused(true)}
          onBlur={() => setProfileFocused(false)}
          style={{ width: '100%' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <FormLabel>Profile</FormLabel>
              <Typography variant="body2" color="textSecondary">
                Choose a profile for contacts to use when posing for the photo.
              </Typography>
            </div>
            <div>
              <Switch checked={custom} onChange={(e) => handleCustomToggle(e.target.checked)} />
              <Typography variant="body2" component="span">
                Custom
              </Typography>
            </div>
          </div>
          {custom ? (
            <>
              <FileField
                fileName="custom_pose_profile_outline"
                className={classes.customProfileField}
                label="Outline image"
                description="Upload a transparent overlay image for pose alignment."
                controller={customOutlineImageController}
              />
              <FileField
                fileName="custom_pose_profile_example"
                className={classes.customProfileField}
                label="Example images"
                description="Upload example images to show contacts the desired outcome."
                controller={customExampleImagesController}
                multiple
              />
            </>
          ) : (
            <SwatchField
              className={classes.globalPoseProfiles}
              controller={profileIdController}
              items={props.poseProfiles.map(({ id, name, exampleImages }) => ({
                value: id,
                label: name || 'Unnamed',
                url: exampleImages?.[0]?.publicUrl,
              }))}
            />
          )}
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>Guidelines</FormLabel>
          <Typography variant="body2" color="textSecondary" style={{ margin: '0.1em 0 0.4em' }}>
            Provide additional instructions to contacts (e.g. preferred attire, facial expressions).
          </Typography>
          <RichTextEditor
            id="pose-guidelines-content"
            classes={{
              editor: classes.rte,
              toolbar: classes.rteToolbar,
            }}
            initialValue={props.savedGuidelines}
            onChange={guidelinesController.field.onChange}
          />
        </FormControl>
        <CheckboxField
          useSwitch
          label="Allow photo upload"
          description="Contacts can upload a photo from their device when enabled. Disable this option to enforce new photos per submission."
          controller={allowPhotoUploadController}
        />
        <RadioField
          label="Background"
          controller={removeBgController}
          items={[
            {
              value: 'enabled',
              label: 'Remove background',
            },
            {
              value: 'enabled-with-backdrops',
              label: 'Remove background and allow contacts to select a backdrop',
              contentBelowLabel: removeBgController.field.value === 'enabled-with-backdrops' && (
                <BackgroundField
                  className={classes.nestedBackgroundField}
                  fileName="pose_backdrop"
                  label="Backdrops"
                  controller={backdropsController}
                  multiple
                />
              ),
            },
            {
              value: 'disabled',
              label: 'Do not remove background',
            },
          ]}
          fullWidthItems
        />
      </div>
    </Dialog>
  );

  function handleCustomToggle(newCustom: boolean) {
    if (newCustom) {
      profileIdController.field.onChange(null);
    } else {
      profileIdController.field.onChange(firstProfile.id ?? null);
    }

    setCustom(newCustom);
  }
}
