import { Prompt } from 'react-router-dom';
import { useEffect } from 'react';

type UseLeaveWarningOpts = {
  warn: boolean;
  message: string;
};

export function useLeaveWarning(opts: UseLeaveWarningOpts) {
  const { warn, message } = opts;

  useEffect(() => {
    if (!warn) return;

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();

      return (e.returnValue = message);
    };

    window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });

    return () => window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });
  }, [warn]);

  return {
    prompt: <Prompt when={warn} message={message} />,
  };
}
