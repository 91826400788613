import { useState } from 'react';
import { makeStyles, Paper, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import TransparencyCheckerboard from 'src/assets/Transparency_Default.svg';

export type PreviewProps = {
  /**
   * Class name attached to each preview image.
   */
  className?: string;
  /**
   * URL to render as previews. If a valid hex value is provided, a solid
   * background of that color is rendered.
   */
  url?: null | string;
  /**
   * Width and height of this preview. Set to 100px by default.
   */
  size?: number;
  /**
   * Border radius of this preview. Set to 16px by default.
   */
  borderRadius?: number;
  /**
   * Whether these previews should all show a "Loading" spinner.
   */
  loading?: boolean;
};

const useStyles = makeStyles(() => ({
  root: (props: PreviewProps) => ({
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    overflow: 'hidden',
    borderRadius: props.borderRadius ?? 16,
    '& > img': {
      display: 'block',
      height: props.size ?? 100,
      width: props.size ?? 100,
      objectFit: 'contain',
      borderRadius: props.borderRadius ?? 16,
    },
  }),
  spinner: {
    position: 'absolute',
  },
  fullSize: {
    width: 100,
    height: 100,
  },
  hexColor: {
    color: 'white',
    textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
  },
}));

/**
 * Component that accepts an image URL as a small, floating image preview. Not
 * to be confused with `PreviewCard`, which is a large card that generates
 * previews of new filters and presets.
 */
export function Preview(props: PreviewProps): JSX.Element | null {
  const classes = useStyles(props);
  const isHex = props.url?.startsWith('#');
  const [imageLoading, setImageLoading] = useState(!isHex);

  if (!props.url) return null;

  const loading = imageLoading || props.loading;

  return (
    <Paper
      elevation={5}
      className={clsx(classes.root, props.className, (loading || isHex) && classes.fullSize)}
      style={{ backgroundColor: (isHex && props.url) || undefined }}
    >
      {loading && <CircularProgress className={classes.spinner} />}
      {/* do not show if props.loading is true, but keep rendering if image URL not loaded */}
      {isHex && <Typography className={clsx(classes.hexColor)}>{props.url}</Typography>}
      {!isHex && !props.loading && (
        <img
          src={props.url}
          alt="Outline"
          onLoad={() => setImageLoading(false)}
          style={{ backgroundImage: `url(${TransparencyCheckerboard})` }}
        />
      )}
    </Paper>
  );
}
