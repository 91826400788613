import { makeStyles, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import NoStudios from 'assets/NoStudios.svg';
import { usePermissions } from 'hooks/usePermissions';

export type NoStudiosNoticeProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inner: {
    position: 'relative',
    width: '100%',
  },
  textBox: {
    position: 'absolute',
    width: '50%',
    marginTop: 100,
    marginLeft: 90,
    [theme.breakpoints.down('md')]: {
      marginTop: 80,
      marginLeft: 60,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
      marginLeft: 50,
    },
  },
  title: {
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 24,
    [theme.breakpoints.down('md')]: {
      marginBottom: 16,
    },
  },
  svg: {
    width: '100%',
  },
}));

export function NoStudiosNotice(props: NoStudiosNoticeProps): React.ReactElement {
  const classes = useStyles();
  const perms = usePermissions();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.textBox}>
          <Typography variant="h4" className={classes.title}>
            Create your first studio
          </Typography>
          <Typography className={classes.subtitle}>
            Start capturing beautiful and consistent photos from your team.
          </Typography>
          {perms.auth?.canAddStudio ? (
            <Button color="primary" variant="contained" component={Link} to="/studios/new">
              NEW STUDIO
            </Button>
          ) : (
            <Button color="primary" variant="contained" href="mailto:us@thesnapbar.com">
              REQUEST STUDIO
            </Button>
          )}
        </div>
        <img src={NoStudios} alt="No studios" className={classes.svg} />
      </div>
    </div>
  );
}
