import { useState, useRef } from 'react';
import { makeStyles, Menu, MenuItem, Button, Typography, TextField } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ArrowLeft, Search } from 'react-feather';
import { debounce } from 'lodash-es';

import {
  AccountMenuQuery,
  useAccountMenuQuery,
  useDeselectAccountMutation,
  useSelectAccountMutation,
} from 'codegen/graphql';
import { toast } from 'components/GlobalSnackbar';
import { useEffect } from 'react';

export type AccountMenuProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
};

const ACCOUNT_LIMIT = 8;
const useStyles = makeStyles((theme) => ({
  accountName: {
    letterSpacing: 0,
  },
  deselectAccount: {
    marginTop: -8,
    marginBottom: 20,
    '& > *:not(:last-child)': {
      color: theme.palette.primary.main,
      marginRight: 8,
    },
  },
  searchField: {
    marginLeft: 16,
    marginRight: 16,
    // idk why i need this to get fullWidth to work. yet another MUI quirk.
    display: 'block',
    width: 'auto',
    borderBottom: '1px solid lightgrey',
    paddingBottom: 16,
    marginBottom: 12,
  },
  noResults: {
    margin: '5px 16px',
  },
}));

type SnapbarAccounts = AccountMenuQuery['searchSnapbarAccounts'];
type Account = AccountMenuQuery['currentAccount'];

export function AccountMenu(props: AccountMenuProps): React.ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // not set to `null` as `null` implies that we received a response
  const [snapbarAccounts, setSnapbarAccounts] = useState<SnapbarAccounts>();
  const [currentAccount, setCurrentAccount] = useState<Account>();

  const { loading, error, data } = useAccountMenuQuery({ variables: { query: searchQuery } });

  const [selectAccount] = useSelectAccountMutation();
  const [deselectAccount] = useDeselectAccountMutation();

  // only update snapbarAccounts and currentAccount when query resolves. this
  // prevents the list of accounts suddenly disappearing on search, where `data
  // = undefined` and `loading = true`.
  useEffect(() => {
    if (loading || !data) return;
    setSnapbarAccounts(data.searchSnapbarAccounts?.slice(0, ACCOUNT_LIMIT));
    setCurrentAccount(data.currentAccount);
  }, [data, loading]);

  // redirect to /login if snapbar login required
  useEffect(() => {
    if (error?.graphQLErrors?.some((gqlError) => gqlError?.name === 'SNAPBAR_LOGIN_REQUIRED')) {
      window.location.href = '/auth/snapbar';
    }
  }, [error?.graphQLErrors]);

  const currentAccountName =
    currentAccount === undefined ? '...' : currentAccount === null ? 'Select an account...' : currentAccount.name;

  const debouncedSearch = debounce(search, 500);

  // If there's less than two accounts, don't show the menu
  if (!accountMenuOpen && !searchQuery && !snapbarAccounts?.[1]) {
    return <></>;
  }

  return (
    <>
      <Button
        className={props.className}
        onClick={() => {
          setAccountMenuOpen(!accountMenuOpen);
        }}
        ref={anchorRef}
      >
        <Typography className={classes.accountName}>{currentAccountName}</Typography>
        {accountMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>

      <Menu
        open={accountMenuOpen}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        onClose={handleClose}
        onExited={handleExited}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {currentAccount && (
          <MenuItem onClick={handleDeselectAccount} className={classes.deselectAccount}>
            <ArrowLeft size={16} />
            <Typography>Back to dashboard</Typography>
          </MenuItem>
        )}
        <TextField
          onChange={(e) => debouncedSearch(e.target.value)}
          variant="outlined"
          label="Search accounts"
          margin="dense"
          InputProps={{
            startAdornment: <Search style={{ marginRight: 8 }} />,
          }}
          fullWidth
          className={classes.searchField}
          // needed to stop bubbling to MUI menu and selecting other items on keyboard input
          onKeyDown={(e) => e.stopPropagation()}
        />
        {snapbarAccounts?.length ? (
          snapbarAccounts.map((sa) => (
            <MenuItem key={sa.snapbarId} onClick={() => handleSelectAccount(sa.snapbarId)}>
              {sa.name}
            </MenuItem>
          ))
        ) : (
          <div className={classes.noResults}>
            <Typography>No results</Typography>
          </div>
        )}
      </Menu>
    </>
  );

  function search(newSearchQuery: string) {
    setSearchQuery(newSearchQuery);
  }

  function handleClose() {
    setAccountMenuOpen(false);
  }

  function handleExited() {
    setSearchQuery('');
    debouncedSearch.cancel();
  }

  async function handleSelectAccount(snapbarId: string) {
    let success = false;
    try {
      const { data, errors: gqlErrors } = await selectAccount({ variables: { snapbarId } });
      const userErrors = data?.selectAccount?.errors;
      if (userErrors?.length) {
        console.error('User errors', userErrors);
      } else if (gqlErrors?.length) {
        console.error('GraphQL errors', gqlErrors);
      } else if (data?.selectAccount?.account?.id) {
        success = true;
      }
    } catch (networkError) {
      console.error(networkError);
    }

    setAccountMenuOpen(false);
    setSearchQuery('');
    if (success) {
      toast('Account selected');
      history.push('/');
    } else {
      toast('Could not select account', 'error');
    }
  }

  async function handleDeselectAccount() {
    let success = false;
    try {
      const { data, errors: gqlErrors } = await deselectAccount();
      const userErrors = data?.deselectAccount?.errors;
      if (userErrors?.length) {
        console.error('User errors', userErrors);
      } else if (gqlErrors?.length) {
        console.error('GraphQL errors', gqlErrors);
      } else {
        success = true;
      }
    } catch (networkError) {
      console.error(networkError);
    }

    setAccountMenuOpen(false);
    setSearchQuery('');
    if (success) {
      // no toast since whole admin UI changing is plenty of visual feedback
      history.push('/dashboard');
    } else {
      toast('Could not deselect account', 'error');
    }
  }
}
