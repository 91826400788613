import { Dialog } from 'src/components/Dialog';
import { makeStyles, Box, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Clipboard } from 'react-feather';
import { toast } from 'components/GlobalSnackbar';

type NewTokenDialogProps = {
  open: boolean;
  onClose: () => void;
  newToken: string;
};

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'uppercase',
  },
  tokenDisplay: {
    display: 'flex',
    marginBottom: '1.5rem',
    border: '1px solid #B0BEC5',
    borderRadius: '1rem',
    '& > :first-child': {
      borderTopLeftRadius: 'inherit',
      borderBottomLeftRadius: 'inherit',
    },
    '& > :last-child': {
      borderTopRightRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
      borderLeft: '1px solid #B0BEC5',
    },
  },
  tokenLabel: {
    flex: 1,
    padding: '1rem',
    background: '#FAFAFA',
  },
  copyButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  alert: {
    border: '1px solid #FF9800',
  },
}));

export function NewTokenDialog({ open, onClose, newToken }: NewTokenDialogProps): React.ReactElement {
  const classes = useStyles();
  return (
    <Dialog
      title="Save your new token"
      width={600}
      open={open}
      onClose={onClose}
      actions={
        <Button variant="contained" color="primary" className={classes.button} onClick={onClose}>
          close
        </Button>
      }
    >
      <Box className={classes.tokenDisplay}>
        <Box className={classes.tokenLabel}>
          <Typography>{newToken}</Typography>
        </Box>
        <Button onClick={copyTokenToClipboard} className={classes.copyButton}>
          <Clipboard />
        </Button>
      </Box>
      <Alert severity="warning" className={classes.alert}>
        Once this modal is closed, the token cannot be viewed again.
      </Alert>
    </Dialog>
  );

  async function copyTokenToClipboard(): Promise<void> {
    try {
      await navigator.clipboard.writeText(newToken);
      toast('Access token copied', 'info');
    } catch (e) {
      console.error(e);
      toast('Could not copy token', 'error');
    }
  }
}
