import { Card, CardContent, makeStyles, Link, Typography, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Control, useController } from 'react-hook-form';

import {
  CROP_FACTORS,
  CROP_GRAVITIES,
  PHOTO_SIZES,
  CropFactorNames,
  CropGravityNames,
  PhotoSizeNames,
} from 'models/Preset';
import { PresetFormType } from 'pages/PresetFormPage/PresetFormPage';
import TextField from 'components/TextField';
import RadioField from 'components/RadioField';
import { FileField } from 'components/FileField';
import { BackgroundField } from 'src/components/BackgroundField';
import SelectField, { SelectItem } from '../SelectField';
import { Skeleton } from '@material-ui/lab';
import { PhotoFilter } from 'src/codegen/graphql';
import { SwitchField } from 'components/SwitchField';
import { usePermissions } from 'hooks/usePermissions';

const cropFactorRadioItems = CROP_FACTORS.map((factor) => ({ value: factor, label: CropFactorNames[factor] }));
const cropGravityRadioItems = CROP_GRAVITIES.map((gravity) => ({ value: gravity, label: CropGravityNames[gravity] }));
const photoSizeRadioItems = PHOTO_SIZES.map((size) => ({ value: size, label: PhotoSizeNames[size] }));

interface PresetFormCardProps {
  photoFilters: Pick<PhotoFilter, 'id' | 'name'>[];
  control: Control<PresetFormType>;
}

const useStyles = makeStyles(() => ({
  content: {
    '& > *:not(:last-child)': {
      marginBottom: 16,
    },
  },
  backgroundField: {
    marginRight: '2em',
  },
}));

export function PresetFormCard(props: PresetFormCardProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control } = props;

  const perms = usePermissions();

  const nameController = useController({ name: 'name', control });
  const photoFilterIdController = useController({ name: 'photoFilterId', control });
  const cropFactorController = useController({ name: 'cropFactor', control });
  const cropGravityController = useController({ name: 'cropGravity', control });
  const resizeController = useController({ name: 'resize', control });
  const overlayImageController = useController({ name: 'overlayImage', control });
  const cloudinaryTransformController = useController({ name: 'cloudinaryTransform', control });
  const backdropController = useController({ name: 'backdrop', control });
  const visibleToContactController = useController({ name: 'visibleToContact', control });
  const imageFormatController = useController({ name: 'imageFormat', control });
  const autoEnhanceController = useController({ name: 'autoEnhance', control });

  const photoFilterSelectItems: SelectItem[] = [
    { label: 'None', value: 'NONE' },
    ...props.photoFilters.map(({ id, name }) => ({ label: name, value: id })),
  ];

  const imageFormatItems: SelectItem[] = [
    {
      label: 'JPEG',
      value: 'jpeg',
    },
    {
      label: 'PNG',
      value: 'png',
    },
  ];

  return (
    <PresetFormCardWrapper>
      <TextField
        label={t('presetsRoute:presetName')}
        variant="outlined"
        fullWidth
        maxChars={50}
        controller={nameController}
      />
      <BackgroundField
        fileName="preset_backdrop"
        className={classes.backgroundField}
        label="Backdrop"
        controller={backdropController}
        multiple={false}
      />
      <FileField fileName="preset_overlay" label="Overlay image" controller={overlayImageController} multiple={false} />
      <SelectField
        label="Photo filter"
        controller={photoFilterIdController}
        items={photoFilterSelectItems}
        helperText="Apply additional effects to the image subject. Example: Add a black-and-white filter to the person, while leaving the backdrop as-is."
      />
      <RadioField label="Crop factor" controller={cropFactorController} items={cropFactorRadioItems} />
      <SelectField label="Image format" controller={imageFormatController} items={imageFormatItems} />

      <RadioField
        label="Crop position"
        controller={cropGravityController}
        items={cropGravityRadioItems}
        disabled={cropFactorController.field.value === 'LANDSCAPE'}
        helpText={
          cropFactorController.field.value === 'LANDSCAPE'
            ? `Custom crop position is not supported when cropping with a landscape aspect ratio.`
            : undefined
        }
      />

      <RadioField label="Resize" controller={resizeController} items={photoSizeRadioItems} />

      <FormControlLabel
        control={<SwitchField controller={visibleToContactController} />}
        label="Display to contact in Studio"
      />

      <br />

      <FormControlLabel control={<SwitchField controller={autoEnhanceController} />} label="Auto-enhance image" />
      {perms.staff && (
        <>
          <br />

          <Typography variant="button">Cloudinary transformations</Typography>
          <Typography variant="body2">
            {t('filtersRoute:transformationsDesc')}{' '}
            <Link href="https://cloudinary.com/documentation/transformation_reference" target="_blank">
              See reference
            </Link>
          </Typography>
          <TextField
            label="Cloudinary transforms"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            controller={cloudinaryTransformController}
          />
        </>
      )}
    </PresetFormCardWrapper>
  );
}

export function PresetFormCardSkeleton(): JSX.Element {
  return (
    <PresetFormCardWrapper>
      <Skeleton height={56} />
      <Skeleton width={134} height={134} style={{ display: 'inline-block', marginRight: '2em' }} />
      <Skeleton width={134} height={134} style={{ display: 'inline-block' }} />
      <Skeleton height={56} />
      <Skeleton height={56} />
      <Skeleton height={56} />
      <Skeleton height={56} />
      <Skeleton height={112} />
    </PresetFormCardWrapper>
  );
}

/**
 * Local component that's used to keep styles between loaded and skeleton
 * components consistent.
 */
function PresetFormCardWrapper(props: { children: React.ReactNode }): JSX.Element {
  const classes = useStyles();

  return (
    <Card>
      <CardContent className={classes.content}>{props.children}</CardContent>
    </Card>
  );
}
