import i18next, { InitOptions, TFunction } from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const i18nConfig: InitOptions = {
  lng: 'en-US',
  fallbackLng: 'en-US',
  ns: ['glossary', 'filtersRoute', 'presetsRoute', 'studiosRoute', 'profilesRoute'],
  defaultNS: 'glossary',
  debug: false, //process.env.NODE_ENV === 'development',
  nonExplicitSupportedLngs: true, // use `en-US` if receiving `en` in supported languages
  load: 'currentOnly', // do not load `en` if `en-US` is already being loaded
  interpolation: {
    escapeValue: false, // not needed for react
  },
  react: {
    useSuspense: false, // do not use experimental react suspense component
  },
  postProcess: 'sentenceCaser',
};

/**
 * Initialize the i18n instance in a browser runtime. Currently, i18n will make
 * network requests to `/locales`, but the translation files can be hosted on a
 * CDN like Netlify later for optimization.
 */
export default function initI18n(): Promise<TFunction> {
  return i18next
    .use(initReactI18next) // initialize react hooks
    .use(HttpBackend) // load locales from /public
    .use(LanguageDetector) // automatically detect language
    .init(i18nConfig);
}

/**
 * Initialize the i18n instance in a node runtime. The translation files need
 * to be manually loaded here, since no network requests are being made.
 */
export async function initI18nTesting(): Promise<TFunction> {
  const glossary = await import('public/locales/en-US/glossary.json');
  const filtersRoute = await import('public/locales/en-US/filtersRoute.json');
  const presetsRoute = await import('public/locales/en-US/presetsRoute.json');
  const studiosRoute = await import('public/locales/en-US/studiosRoute.json');
  const profilesRoute = await import('public/locales/en-US/profilesRoute.json');

  return i18next.use(initReactI18next).init({
    ...i18nConfig,
    resources: {
      'en-US': {
        glossary,
        filtersRoute,
        presetsRoute,
        studiosRoute,
        profilesRoute,
      },
    },
  });
}
