import { makeStyles, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import RocketShip from 'assets/RocketShip.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    paddingTop: 16,
    paddingBottom: 16,
    '& .MuiTypography-root': {
      fontSize: '1rem',
      color: '#455a64',
      letterSpacing: '0.34px',
    },
  },
  cardContent: {
    paddingTop: 0,
  },
  text: {
    marginLeft: 16,
    marginRight: 16,
    flex: '1 1 auto',
  },
}));

export function StudiosNeededCard(): React.ReactElement {
  const { t } = useTranslation('studiosRoute');
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container alignItems="center" justify="space-between" wrap="nowrap">
          <Grid item>
            <img src={RocketShip} alt="card-decoration" />
          </Grid>

          <Grid item className={classes.text}>
            <Typography>{t('needAnotherStudio')}</Typography>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: 'uppercase' }}
              href="mailto:us@thesnapbar.com"
            >
              {t('requestStudio')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
