import { Card, Table, TableHead, TableBody, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useProfilesCardQuery } from 'codegen/graphql';
import { useHistory } from 'react-router-dom';

import { id } from 'lib/gid';
import { ErrorCard } from 'components/ErrorCard';
import Preview from 'components/Preview';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 16,
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
}));

const heading = (
  <TableHead>
    <TableRow>
      <TableCell>
        <Typography variant="button">Name</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="button">Example images</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="button">Outline image</Typography>
      </TableCell>
    </TableRow>
  </TableHead>
);

export function ProfilesCard(): React.ReactElement {
  const { t } = useTranslation('glossary');
  const classes = useStyles();
  const history = useHistory();

  const { loading, error, data } = useProfilesCardQuery();

  if (loading) {
    return <ProfilesCardSkeleton />;
  }

  if (error || !data) {
    return <ErrorCard body="Could not load global pose profiles." />;
  }

  const rows = data?.poseProfiles
    .filter((profile) => !!profile.id)
    .map((profile, index) => (
      <TableRow
        className={classes.row}
        key={index}
        onClick={() => history.push(`/admin/profiles/${id(profile.id as string)}/edit`)}
      >
        <TableCell>
          <Typography>{profile.name}</Typography>
        </TableCell>
        <TableCell>
          <Preview url={profile?.exampleImages?.[0]?.publicUrl} />
        </TableCell>
        <TableCell>
          <Preview url={profile?.outlineImage?.publicUrl} />
        </TableCell>
      </TableRow>
    ));

  return (
    <Card className={classes.card}>
      <Table>
        {heading}
        <TableBody>{rows}</TableBody>
      </Table>
    </Card>
  );
}

function ProfilesCardSkeleton() {
  const classes = useStyles();

  const tableRowSkeleton = (
    <TableRow>
      <TableCell>
        <Skeleton width={180} />
      </TableCell>
      <TableCell>
        <Skeleton width={100} height={100} />
      </TableCell>
      <TableCell>
        <Skeleton width={100} height={100} />
      </TableCell>
    </TableRow>
  );

  return (
    <Card className={classes.card}>
      <Table>
        {heading}
        {tableRowSkeleton}
        {tableRowSkeleton}
        {tableRowSkeleton}
      </Table>
    </Card>
  );
}
