import { useTranslation } from 'react-i18next';
import { makeStyles, Paper, Grid, Typography, Link as MuiLink } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Aperture, BarChart } from 'react-feather';
import { FilterFrames } from '@material-ui/icons';
import { KeyIcon } from 'src/components/KeyIcon/KeyIcon';
import Page from 'pages/Page';

export type AdminPageProps = {
  // add props here
};

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: 40,
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexShrink: 0,
    height: 56,
    width: 56,
    borderRadius: 28,
    backgroundColor: theme.palette.primary.main,
    marginRight: 16,
  },
  linkWrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

type GridItem = {
  icon: JSX.Element;
  name: string;
  description: string;
  to?: string;
};

const GRID_ITEMS: GridItem[] = [
  {
    icon: <FilterFrames style={{ color: 'white' }} />,
    name: 'Pose profiles',
    description: 'Create new global pose profiles',
    to: '/admin/profiles',
  },
  {
    icon: <Aperture color="white" />,
    name: 'Photo filters',
    description: 'Create new global photo filters',
    to: '/admin/filters',
  },
  {
    icon: <BarChart color="white" />,
    name: 'Metrics',
    description: 'See global system metrics',
    to: '/admin/metrics',
  },
  {
    icon: <KeyIcon color="white" />,
    name: 'API tokens',
    description: 'Create and manage private access tokens',
    to: '/admin/tokens',
  },
];

export function AdminPage(props: AdminPageProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  const gridItems = GRID_ITEMS.map((item, index) => (
    <Grid item xs={6} className={classes.gridItem} key={index}>
      <MuiLink component={Link} className={classes.iconWrapper} to={item.to}>
        {item.icon}
      </MuiLink>
      <div className={classes.linkWrapper}>
        <MuiLink component={Link} to={item.to}>
          <Typography style={{ fontWeight: 600 }}>{item.name}</Typography>
        </MuiLink>
        <Typography>{item.description}</Typography>
      </div>
    </Grid>
  ));

  return (
    <Page title="Admin settings">
      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          {gridItems}
        </Grid>
      </Paper>
    </Page>
  );
}
