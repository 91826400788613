import { useState } from 'react';
import { makeStyles, Select, MenuItem, ListItemIcon, Typography, Button, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Globe, Lock, Clipboard, Link, Eye } from 'react-feather';
import { toast } from 'components/GlobalSnackbar';
import { StudioOverviewFragment, SharingStatus, useUpdateStudioMutation } from 'codegen/graphql';
import { usePermissions } from 'hooks/usePermissions';

type SharingPreviewCardProps = {
  studio: StudioOverviewFragment;
};

const useStyles = makeStyles(() => ({
  root: {
    // add styles here
  },
  firstRow: {
    height: 50,
    marginTop: 20,
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: 16,
    },
  },
  select: {
    display: 'flex',
    alignItems: 'center',
  },
  selectWrapper: {
    border: 0,
    minWidth: 150,
    flexShrink: 0,
    flexGrow: 0,
  },
  linkWrapper: {
    display: 'flex',
    flexGrow: 1,
    // required for ellipsis overflow to work
    minWidth: 0,
  },
  linkBox: {
    display: 'inline-flex',
    flexGrow: 1,
    width: 'auto',
    height: '100%',
    background: '#FAFAFA',
    border: '1px solid #B0BEC5',
    borderRight: 0,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    padding: 16,
    alignItems: 'center',
    // required for ellipsis overflow to work
    minWidth: 0,
  },
  shortcutButton: {
    display: 'inline-flex',
    minWidth: 50,
    marginLeft: -1,
    flexGrow: 0,
    flexShrink: 0,
    height: '100%',
    border: '1px solid #B0BEC5',
    borderRadius: 0,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  secondRow: {
    marginTop: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

type SelectItem = {
  icon: JSX.Element;
  name: string;
  desc: string;
  value: SharingStatus;
};

const MENU_ITEMS: Record<SharingStatus, SelectItem> = {
  PUBLIC: {
    icon: <Globe />,
    name: 'Public',
    desc: 'Anyone with a link can submit a photo',
    value: SharingStatus.Public,
  },
  PRIVATE: {
    icon: <Lock />,
    name: 'Private',
    desc: 'Only users with an invitation can submit a photo',
    value: SharingStatus.Private,
  },
};

function selectText(target: HTMLElement): void {
  window.getSelection()?.selectAllChildren(target);
}

export function SharingPreview({ studio }: SharingPreviewCardProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const [sharingStatus, setSharingStatus] = useState<SharingStatus>(studio.sharingStatus);
  const [updateSharingStatus] = useUpdateStudioMutation();
  const [loading, setLoading] = useState(false);
  const perms = usePermissions();

  return (
    <>
      <div className={classes.firstRow}>
        <Tooltip title={!perms.auth?.canChangeStudio ? 'Locked by Admin' : ''}>
          {/* 
            Tooltip is forcing its children's onBlur prop to their buggy handleLeave function. 
            The Select component in this case can call onBlur() without an event when handleLeave within Tooltip
            expects an event, causing a 'cannot read property "type" of undefined' blah blah blah.
            
            Wrap Select with a div so that onBlur is always provided an event by the browser.
          */}
          <div>
            <Select
              variant="outlined"
              value={sharingStatus}
              className={classes.selectWrapper}
              classes={{ select: classes.select }}
              onChange={(e) => handleSharingStatusChange(e.target.value as SharingStatus)}
              disabled={loading || !perms.auth?.canChangeStudio}
              renderValue={
                ((status: SharingStatus) => <MenuItemContent item={MENU_ITEMS[status]} />) as (
                  item: unknown,
                ) => React.ReactNode
              }
            >
              <MenuItem value="PUBLIC">
                <MenuItemContent item={MENU_ITEMS['PUBLIC']} expanded />
              </MenuItem>
              <MenuItem value="PRIVATE">
                <MenuItemContent item={MENU_ITEMS['PRIVATE']} expanded />
              </MenuItem>
            </Select>
          </div>
        </Tooltip>
        <div className={classes.linkWrapper}>
          <div
            role="link"
            tabIndex={0}
            className={classes.linkBox}
            onKeyDown={(e) => selectText(e.currentTarget)}
            onClick={(e) => selectText(e.currentTarget)}
          >
            <Link style={{ marginRight: 12, flexShrink: 0 }} />
            <Typography noWrap>{studio.publicUrl}</Typography>
          </div>
          <Button className={classes.shortcutButton} onClick={copyUrlToClipboard}>
            <Clipboard />
          </Button>
          <Button
            className={classes.shortcutButton}
            href={studio.adminAccessUrl}
            target="_blank"
            style={{ borderTopRightRadius: 16, borderBottomRightRadius: 16 }}
          >
            <Eye />
          </Button>
        </div>
      </div>
      <div className={classes.secondRow}>
        <Typography variant="body2" style={{ color: '#546E7A' }}></Typography>
      </div>
    </>
  );

  async function handleSharingStatusChange(newSharingStatus: SharingStatus) {
    try {
      setLoading(true);
      const { data, errors } = await updateSharingStatus({
        variables: { input: { id: studio.id, sharingStatus: newSharingStatus } },
      });
      if (data?.updateStudio?.studio?.sharingStatus === newSharingStatus) {
        toast('Sharing status updated', 'success');
        setSharingStatus(newSharingStatus);
        setLoading(false);
        return;
      } else {
        console.error(errors);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
    toast('Could not update sharing status', 'error');
  }

  async function copyUrlToClipboard(): Promise<void> {
    try {
      navigator.clipboard.writeText(studio.publicUrl);
      toast('Studio URL copied', 'info');
    } catch (e) {
      console.error(e);
      toast('Could not copy studio URL', 'error');
    }
  }
}

type MenuItemContentProps = { item: SelectItem; expanded?: boolean };

function MenuItemContent({ item, expanded }: MenuItemContentProps) {
  return (
    <>
      <ListItemIcon style={{ marginRight: 8 }}>{item.icon}</ListItemIcon>
      <Typography component="span" variant="body2" style={{ fontWeight: 600 }}>
        {item.name}
        {expanded && ' - '}
      </Typography>
      {expanded && (
        <Typography component="span" variant="body2" style={{ marginLeft: 5 }}>
          {item.desc}
        </Typography>
      )}
    </>
  );
}
