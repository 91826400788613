import { CircularProgress, Button, Typography } from '@material-ui/core';
import { Upload } from 'react-feather';

import { CSRF_TOKEN } from 'entrypoints/App';
import FileUploadButton from 'components/FileUploadButton';
import { toast } from 'components/GlobalSnackbar';
import { gid } from 'lib/gid';
import Dialog from 'components/Dialog';
import { useEffect, useState } from 'react';
import useDialog from 'hooks/useDialog';

export type ContactListImportButtonProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Model ID of the currently selected contact list.
   */
  listId?: string;
  /**
   * Callback that reloads the currently viewed data grid.
   */
  onImportStarted: () => void;
};

type ContactListImportResponse = {
  lines: number;
  skipped: number;
  created: number;
  updated: number;
  failed: number;
  errors?: string;
};

export function ContactListImportButton(props: ContactListImportButtonProps): React.ReactElement {
  return (
    <FileUploadButton
      className={props.className}
      accept="text/csv"
      onChange={handleCsvUpload}
      maxFileSizeMb={20}
      render={({ loading }) => (
        <Button startIcon={loading ? <CircularProgress size={24} /> : <Upload />} component="span">
          Import
        </Button>
      )}
    />
  );

  async function handleCsvUpload(file: File) {
    if (!CSRF_TOKEN) {
      toast('Could not upload CSV. Please try refreshing the page.', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('authenticity_token', CSRF_TOKEN);
    props.listId && formData.append('list_id', props.listId);
    formData.append('file', file);

    try {
      const raw = await fetch('/contacts/import', {
        method: 'post',
        body: formData,
      });
      const response = (await raw.json()) as ContactListImportResponse;

      if (raw.ok) {
        props.onImportStarted();
      } else {
        console.error('Failed', response.failed);
        toast(`Could not import contacts. ${response.errors}`, 'error');
      }
    } catch (e) {
      if (e instanceof Error) {
        toast(`Could not import contacts. ${e.message}`, 'error');
      } else {
        toast('An unknown error occurred while importing contacts.', 'error');
      }
    }
  }
}
