/**
 * Function that inserts a new item in an array at the given index.
 * @param array Array to insert into.
 * @param newItem Item to insert.
 * @param index Index to insert at.
 * @returns A new array with `newItem` inserted at `index`.
 */
export function insertAt<T>(array: T[], newItem: T, index: number): T[] {
  const mutable = [...array];
  mutable[index] = newItem;
  return mutable;
}

/**
 * Function that deletes an item in an array at the given index.
 * @param array Array to delete item from.
 * @param index Index to delete at.
 * @returns A new array with the item at `index` deleted. If the operation
 * failed, this will just return the original `array`.
 */
export function deleteAt<T>(array: T[], index: number): T[] {
  const mutable = [...array];
  mutable.splice(index, 1);
  return mutable;
}
