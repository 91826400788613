import { Contact, ContactList, Scalars, Submission } from 'codegen/graphql';
import { DeepPartial } from 'react-hook-form';
import { format } from 'date-fns';

export class ContactModel implements DeepPartial<Contact> {
  __typename: 'Contact';
  id: Scalars['GID'];
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  contactLists: DeepPartial<ContactList>[];
  submissions: DeepPartial<Submission>[];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];

  constructor(contact: DeepPartial<Contact>) {
    this.__typename = contact.__typename || 'Contact';
    this.id = contact.id || '';
    this.firstName = contact.firstName || '';
    this.lastName = contact.lastName || '';
    this.email = contact.email || '';
    this.phone = contact.phone || '';
    this.contactLists = contact.contactLists || [];
    this.submissions = contact.submissions || [];
    this.createdAt = contact.createdAt || new Date().toISOString();
    this.updatedAt = contact.updatedAt || new Date().toISOString();
  }

  get name(): string {
    return `${this.firstName} ${this.lastName}`.trim();
  }

  get creationDate(): string {
    return format(new Date(this.createdAt), 'MMMM d, yyyy');
  }

  get submissionsCount(): number {
    return this.submissions.length;
  }
}
