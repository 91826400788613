import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from '@material-ui/core';
import { Link as RouterLink, Link } from 'react-router-dom';
import { Camera, List, Send } from 'react-feather';

import Page, { Breadcrumb } from 'pages/Page';
import { useGetStudioDetailsQuery } from 'codegen/graphql';
import SettingsCard from 'components/SettingsCard';
import LoadingPage from '../LoadingPage';
import LoadingCard from 'src/components/LoadingCard';
import ErrorCard from 'src/components/ErrorCard';
import BrandingCard from 'src/components/BrandingCard';
import { id, gid } from 'lib/gid';
import { ReviewSettingsCard } from 'components/ReviewSettingsCard';
import { DisclaimerSettingsCard } from 'components/DisclaimerSettingsCard';
import { ContactMailOutlined } from '@material-ui/icons';
import { StudioOverviewCard } from 'src/components/StudioOverviewCard';
import { useRef } from 'react';
import { useMenu } from 'src/hooks/useMenu';
import useDialog from 'src/hooks/useDialog';
import { InviteContactListDialog } from 'src/components/InviteContactListDialog';
import { InviteContactsDialog } from 'src/components/InviteContactsDialog';
import { ReminderSettingsCard } from 'components/ReminderSettingsCard/ReminderSettingsCard';
import { usePermissions } from 'hooks/usePermissions';

const useStyles = makeStyles(() => ({
  root: {},
  posesWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  roundedLabel: {
    padding: '8px 16px',
    background: '#eceff1',
    borderRadius: 16,
    marginTop: 8,
    display: 'inline-block',
  },
  manageButton: {
    padding: '6px 16px',
    marginRight: '1rem',
    fontSize: 15,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
}));

type StudioSettingsPageProps = {
  /**
   * Model ID of the studio to display settings for.
   */
  id: string;
};

export function StudioSettingsPage(props: StudioSettingsPageProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const perms = usePermissions();

  const studioId = gid(props.id, 'Studio');
  const { loading, error, data } = useGetStudioDetailsQuery({ variables: { id: studioId } });

  if (loading) {
    return (
      <LoadingPage>
        <LoadingCard />
      </LoadingPage>
    );
  }

  if (error || !data || !data.studio) {
    return (
      <Page title="Settings">
        <ErrorCard body={`Could not load studio with GID ${studioId}`} />
      </Page>
    );
  }

  const breadcrumbs: Breadcrumb[] = [
    {
      to: '/studios',
      body: t('studio_plural_capital'),
    },
    {
      to: `/studios/${id(studioId)}/settings`,
      body: `${data?.studio?.name}`,
    },
  ];

  const studio = data.studio;
  const noOfPoses = studio.poses.length;
  const { disclaimer } = studio;

  const SettingsButtons = (
    <>
      {perms.staff && (
        <Button style={{ marginRight: '1rem' }} component={Link} to={`/studios/${id(studioId)}/edit`}>
          EDIT
        </Button>
      )}
      <InviteButton studioId={studio.id} />
    </>
  );

  return (
    <Page title="Studio settings" breadcrumbs={breadcrumbs}>
      <StudioOverviewCard studio={studio} actions={SettingsButtons} />
      <Divider style={{ marginBottom: '30px' }} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <ReviewSettingsCard studio={studio} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ReminderSettingsCard
            studioId={studioId}
            reminderDuration={studio.reminderDuration || null}
            remindersEnabled={studio.remindersEnabled}
          />
        </Grid>
        <Grid item xs={12}>
          <SettingsCard
            title="Photo configuration"
            icon={<Camera />}
            cardActions={
              <Button component={Link} to={`/studios/${id(studioId)}/poses`}>
                EDIT
              </Button>
            }
          >
            <Typography variant="overline">Poses</Typography>
            <Typography variant="body2" style={{ color: '#546e7a' }}>
              Configure photo poses and their associated outputs.
            </Typography>
            <div className={classes.roundedLabel}>
              <Typography variant="body2">
                {noOfPoses} {t('pose', { count: noOfPoses, properNouns: ['pose', 'poses'] })} configured
              </Typography>
            </div>
          </SettingsCard>
        </Grid>

        <Grid item xs={12}>
          <DisclaimerSettingsCard studioId={studio.id} disclaimer={disclaimer} />
        </Grid>

        <Grid item xs={12} lg={6}>
          <BrandingCard studio={studio} />
        </Grid>

        <Grid item xs={12} lg={6}>
          <SettingsCard
            title="Form fields"
            icon={<ContactMailOutlined />}
            cardActions={
              <Button component={RouterLink} to={`/studios/${props.id}/fields`}>
                EDIT
              </Button>
            }
            contentPadding={false}
          >
            {studio.formFields.length > 0 && (
              <Table>
                <TableBody>
                  {studio.formFields.map((field) => (
                    <TableRow key={field.contactAttribute.key}>
                      <TableCell>{field.label}</TableCell>
                      <TableCell>{field.required ? 'Required' : 'Optional'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </SettingsCard>
        </Grid>
      </Grid>
    </Page>
  );
}
type InviteButtonProps = { studioId: string };

function InviteButton({ studioId }: InviteButtonProps) {
  const inviteButtonRef = useRef<HTMLButtonElement>(null);
  const {
    openMenu: openInviteMenu,
    closeMenu: closeInviteMenu,
    menuProps: inviteMenuProps,
  } = useMenu({
    anchorEl: () => inviteButtonRef.current as Element,
    anchorOrigin: {
      horizontal: 'right',
      vertical: 'bottom',
    },
    transformOrigin: {
      horizontal: 'right',
      vertical: 'top',
    },
  });

  const { openDialog: openInviteContactListDialog, dialogProps: inviteContactListDialogProps } = useDialog();
  const { openDialog: openInviteContactsDialog, dialogProps: inviteContactsDialogProps } = useDialog();

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        style={{ textTransform: 'uppercase' }}
        onClick={openInviteMenu}
        ref={inviteButtonRef}
      >
        Invite
      </Button>
      <Menu {...inviteMenuProps}>
        <MenuItem onClick={handleContactListInviteIntent}>
          <ListItemIcon>
            <List />
          </ListItemIcon>
          <Typography variant="overline">Invite contact list</Typography>
        </MenuItem>
        <MenuItem onClick={handleContactsInviteIntent}>
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          <Typography variant="overline">Invite contacts</Typography>
        </MenuItem>
      </Menu>
      <InviteContactListDialog {...inviteContactListDialogProps} studioId={studioId} />
      <InviteContactsDialog {...inviteContactsDialogProps} studioId={studioId} />
    </>
  );

  function handleContactListInviteIntent() {
    openInviteContactListDialog();
    closeInviteMenu();
  }

  function handleContactsInviteIntent() {
    openInviteContactsDialog();
    closeInviteMenu();
  }
}
