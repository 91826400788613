import { LinearProgress, makeStyles, Paper, Switch, Typography, FormControlLabel } from '@material-ui/core';
import { PhotoPreviewFragment } from 'codegen/graphql';
import { ComponentProps, CSSProperties, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { cloneDeep } from 'lodash-es';
import { EnhancementStatusTip } from 'components/SubmissionPhotos/EnhancementStatusTip';

export type SubmissionPhotosProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  submissionId: string;
  enhanced?: boolean;
  photos: PhotoPreviewFragment[];
  previewSize?: number;
  poseHeadingTypographyProps?: ComponentProps<typeof Typography>;
  poseRowStyle?: CSSProperties;
};

const useStyles = makeStyles((props: SubmissionPhotosProps) => ({
  root: {
    // add styles here
  },
  posesRow: {
    '&:not(:last-child)': {
      marginBottom: 30,
    },
  },
  posesRowContent: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    overflowX: 'auto',
    width: '100%',
    padding: 6,
    marginLeft: -6,
    gap: 8,
    '& > .MuiPaper-root': {
      flexShrink: 0,
    },
  },
  paper: {
    height: props.previewSize ?? 300,
    width: props.previewSize ?? 300,
    borderRadius: 0,
    position: 'relative',
    '& .MuiLinearProgress-root': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9,
    },
  },
}));

const usePreviewStyles = makeStyles({
  previewLink: {
    position: 'relative',
    padding: '10px 10px 0 0',
    '&:hover': {
      filter: 'brightness(70%)',
      transition: 'all 0.25s ease',
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      top: 0,
      right: 0,
      transform: 'translate(50%, -50%)',
      background: 'white',
      borderRadius: '100%',
      padding: '4px',
      zIndex: '10',
      boxShadow: '1px 1px 3px rgba(0,0,0,0.2)',
    },
  },
  downloadButton: {
    float: 'right',
    marginTop: -10,
  },
  previewImage: (props: SubmissionPhotosProps) => ({
    display: 'block',
    position: 'relative',
    zIndex: 2,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  }),
});

export function SubmissionPhotos(props: SubmissionPhotosProps): React.ReactElement {
  const { photos, poseHeadingTypographyProps } = props;
  const classes = { ...useStyles(), ...usePreviewStyles(props) };
  const [imagesLoading, setImagesLoading] = useState<boolean[][]>([]);

  return (
    <div className={props.className}>
      {photos.map((photo, index) => (
        <div className={classes.posesRow} key={index} style={props.poseRowStyle}>
          <Typography {...poseHeadingTypographyProps}>{photo.name}</Typography>

          <div className={classes.posesRowContent} key={index}>
            {photo[props.enhanced ? 'appliedPresetsEnhanced' : 'appliedPresets'].map((appliedPreset, pIndex) => (
              <a
                href={appliedPreset.publicUrl}
                key={pIndex}
                target="_blank"
                rel="noreferrer"
                className={classes.previewLink}
              >
                <Paper className={classes.paper}>
                  {imagesLoading[index]?.[pIndex] != true && <LinearProgress />}
                  {props.enhanced && <EnhancementStatusTip status={photo.enhancementStatus} />}
                  <img
                    onLoad={() => handleImageLoaded(index, pIndex)}
                    className={classes.previewImage}
                    alt="preview"
                    src={appliedPreset.publicUrl}
                  />
                </Paper>
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  function handleImageLoaded(photoIndex: number, presetIndex: number) {
    setImagesLoading((prevState) => {
      const newState = cloneDeep(prevState);
      if (newState[photoIndex] == undefined) newState[photoIndex] = [];
      newState[photoIndex][presetIndex] = true;

      return newState;
    });
  }
}

function SubmissionPhotosSkeleton() {
  const classes = useStyles();

  const poses = [1, 2];
  const photos = [1, 2, 3, 4];

  return (
    <div>
      {poses.map((pose) => (
        <div className={classes.posesRow} key={pose}>
          <Skeleton variant="text" width={100} />

          <div className={classes.posesRowContent}>
            {photos.map((photo) => (
              <Skeleton key={photo} variant="rect" width={128} height={128} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

SubmissionPhotos.Skeleton = SubmissionPhotosSkeleton;
