import { Button, Typography } from '@material-ui/core';
import { TextField } from 'components/TextField';
import { useController, useForm } from 'react-hook-form';
import { Dialog } from 'components/Dialog';
import {
  ContactAttribute,
  ContactAttributeField,
  ContactAttributeValidation,
  CreateContactAttributeInput,
  Maybe,
  UpdateContactAttributeInput,
  useCreateContactAttributeMutation,
  UserError,
  useUpdateContactAttributeMutation,
} from 'codegen/graphql';
import { toast } from 'components/GlobalSnackbar';
import { useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

type ContactAttributeDialogProps = {
  attribute?: Pick<ContactAttribute, 'key' | 'type'> & {
    validation?: Maybe<Pick<ContactAttributeValidation, 'regex' | 'errorMessage'>>;
  };
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
};

// Not sure if there's a better way feed dynamic information into the validation schema
let hasValidationRegex = false;

const MAX_ATTR_CHARS = 30;

const contactAttributeSchema = z.object({
  key: z
    .string()
    .min(3)
    .max(MAX_ATTR_CHARS)
    .regex(
      /^[a-zA-Z\d]+([-_]*[a-zA-Z\d]+)*$/,
      'Attribute name can only contain letters, numbers, underscores, and dashes (no spaces)',
    ),
  type: z.string(),
  validationRegex: z.string().refine((regex) => {
    try {
      new RegExp(regex);
    } catch (err) {
      return false;
    }

    return true;
  }, 'Must be a valid regular expression'),
  validationErrorMessage: z.string().refine((message) => {
    if (!hasValidationRegex) return true;
    if (!message.trim()) return false;

    return true;
  }, 'You must provide an error message when using a regular expression'),
});

export function ContactAttributeDialog(props: ContactAttributeDialogProps): React.ReactElement {
  const { open, attribute, onClose, onComplete } = props;

  const {
    control,
    trigger: validate,
    getValues,
    setValue,
    watch,
  } = useForm({
    resolver: zodResolver(contactAttributeSchema),
    defaultValues: {
      key: '',
      type: ContactAttributeField.SingleLineText, // ugh this naming is so bad.
      validationRegex: '',
      validationErrorMessage: '',
    },
  });

  const regex = watch('validationRegex');
  const key = watch('key');

  if (regex) {
    hasValidationRegex = true;
  } else {
    hasValidationRegex = false;
  }

  const keyController = useController({ control, name: 'key' });
  const validationRegexController = useController({ control, name: 'validationRegex' });
  const validationErrorMessageController = useController({ control, name: 'validationErrorMessage' });

  const [createAttribute, { loading: creating }] = useCreateContactAttributeMutation();
  const [updateAttribute, { loading: updating }] = useUpdateContactAttributeMutation();

  const [errors, setErrors] = useState<UserError[]>([]);

  const isEditingAttribute = !!attribute?.key;

  useEffect(() => {
    if (attribute) {
      setValue('key', attribute.key);
      setValue('type', attribute.type);
      setValue('validationRegex', attribute.validation?.regex || '');
      setValue('validationErrorMessage', attribute.validation?.errorMessage || '');
    } else {
      resetForm();
    }
  }, [attribute]);

  const loading = creating || updating;

  return (
    <Dialog
      title={attribute ? `Edit attribute "${attribute.key}"` : 'Add attribute'}
      width={700}
      open={open}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" color="primary" size="large" onClick={handleAddClick} disabled={loading}>
            {isEditingAttribute ? (
              <>{updating ? 'Updating...' : 'Update'}</>
            ) : (
              <>{creating ? 'Creating...' : 'Create'}</>
            )}
          </Button>
        </>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          padding: '1.4rem 0',
          gap: '1.4rem',
        }}
      >
        {errors.length > 0 && (
          <Alert severity="error">
            <AlertTitle>Please resolve the following error(s):</AlertTitle>

            <ul style={{ margin: 0, paddingLeft: '2em' }}>
              {errors.map((error) => (
                <li key={error.message}>{error.message}</li>
              ))}
            </ul>
          </Alert>
        )}

        <TextField
          variant="outlined"
          label="Attribute name"
          fullWidth
          controller={keyController}
          maxChars={MAX_ATTR_CHARS}
          disabled={isEditingAttribute}
          error={errors.some((error) => error.path?.includes('key'))}
        />

        <Typography style={{ fontWeight: 500, marginBottom: '-0.5rem' }}>Validation</Typography>

        <TextField
          variant="outlined"
          label="Regular expression"
          fullWidth
          controller={validationRegexController}
          error={errors.some((error) => error.path?.includes('regex'))}
          helperText="Set custom validation using regex to ensure values match your desired format"
        />

        <TextField
          variant="outlined"
          label="Error message"
          fullWidth
          controller={validationErrorMessageController}
          error={errors.some((error) => error.path?.includes('errorMessage'))}
          helperText="The error that will show on your studio when a contact enters invalid information"
        />
      </div>
    </Dialog>
  );

  function resetForm() {
    setValue('key', '');
    setValue('type', ContactAttributeField.SingleLineText);
    setValue('validationRegex', '');
    setValue('validationErrorMessage', '');
  }

  async function handleAddClick() {
    setErrors([]);

    if (!(await validate())) return;

    const { key, type, validationErrorMessage, validationRegex } = getValues();

    const input: CreateContactAttributeInput = {
      key,
      type,
      validation: {
        regex: validationRegex,
        errorMessage: validationErrorMessage,
      },
    };

    let succeeded: boolean, errors: UserError[];

    if (isEditingAttribute) {
      if (!attribute) throw new Error('Cannot update attribute without the prop');
      const updateInput: UpdateContactAttributeInput = {
        key: attribute.key,
        validation: {
          regex: validationRegex,
          errorMessage: validationErrorMessage,
        },
      };

      const response = await updateAttribute({
        variables: {
          input: updateInput,
        },
      });

      succeeded = !!response.data?.updateContactAttribute?.contactAttribute?.key;
      errors = response.data?.updateContactAttribute?.errors ?? [];
    } else {
      const response = await createAttribute({
        variables: {
          input,
        },
      });

      succeeded = !!response.data?.createContactAttribute?.contactAttribute?.key;
      errors = response.data?.createContactAttribute?.errors ?? [];
    }

    if (succeeded) {
      onComplete();
      onClose();
      setErrors([]);
      setValue('key', '');
      setValue('validationRegex', '');
      setValue('validationErrorMessage', '');

      toast(isEditingAttribute ? `Updated attribute "${attribute?.key}"` : 'Attribute created', 'success');
    } else {
      setErrors(errors);
    }
  }
}
