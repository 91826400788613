import { Avatar, Typography, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useGetCurrentAccountQuery } from 'codegen/graphql';

export type CurrentUserAvatarProps = {
  // add props here
};

const AVATAR_SIZE = 64;

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    margin: '0 auto 16px auto',
  },
  avatarWrapper: {
    textAlign: 'center',
    padding: 16,
    color: theme.palette.text.primary,
    '& > #name': {
      fontSize: '1rem',
    },
    '& > #org': {
      fontSize: '0.75rem',
    },
  },
}));

export function CurrentUserAvatar(props: CurrentUserAvatarProps): React.ReactElement {
  const classes = useStyles();
  const { loading, error, data } = useGetCurrentAccountQuery();

  if (error) {
    console.error(error);
    return <></>;
  }

  return (
    <div className={classes.avatarWrapper}>
      {loading || !data ? (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </>
      ) : (
        <>
          <Avatar className={classes.avatar}>{toTwoLetters(data.currentAccount?.name)}</Avatar>
          <Typography id="name">{data.currentAccount?.name}</Typography>
        </>
      )}
    </div>
  );
}

function toTwoLetters(accountName: string | undefined | null) {
  if (!accountName) return '';

  return accountName
    .split(' ')
    .slice(0, 2)
    .map((s) => s.charAt(0))
    .join('')
    .toUpperCase();
}
