import { useState } from 'react';
import { makeStyles, Button, FormControlLabel, Checkbox, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Dialog } from 'components/Dialog';
import { toast } from 'components/GlobalSnackbar';
import { SubmissionState, useRejectSubmissionsMutation } from 'codegen/graphql';

export type RetakeDialogProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  open: boolean;
  onClose: () => void;
  /**
   * GID of the submission to reject.
   */
  submissionId: string;
};

const useStyles = makeStyles(() => ({
  root: {
    // add styles here
  },
}));

export function RetakeDialog(props: RetakeDialogProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  const { ...dialogProps } = props;
  const [shouldEmail, setShouldEmail] = useState(true);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rejectSubmission, { loading }] = useRejectSubmissionsMutation();

  return (
    <Dialog
      title="Reject submission"
      width={400}
      {...dialogProps}
      actions={
        <>
          <Button onClick={props.onClose}>Close</Button>
          <Button variant="contained" color="primary" onClick={handleRejectSubmission} disabled={loading}>
            {loading ? 'Rejecting...' : shouldEmail ? 'Reject & request retake' : 'Reject'}
          </Button>
        </>
      }
      onExited={handleExited}
    >
      <FormControlLabel
        label={'Send a "request retake" email'}
        checked={shouldEmail}
        onChange={(_, checked) => setShouldEmail(checked)}
        control={<Checkbox />}
        style={{ display: 'block' }}
      />
      {shouldEmail && (
        <TextField
          label="Message"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
            setErrorMessage('');
          }}
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          error={!!errorMessage}
          helperText={errorMessage}
          margin="normal"
        />
      )}
      <Typography variant="subtitle2" style={{ marginTop: '5px' }}>
        You can request up to 5 retakes per contact without using additional credits.
      </Typography>
    </Dialog>
  );

  function handleExited() {
    setShouldEmail(true);
    setMessage('');
    setErrorMessage('');
  }

  async function handleRejectSubmission() {
    let success = false;

    try {
      if (shouldEmail && message.trim() === '') {
        setErrorMessage('Message must not be blank');
        return;
      }
      const { data, errors: gqlErrors } = await rejectSubmission({
        variables: { ids: [props.submissionId], retake: shouldEmail ? { message } : null },
      });
      const userErrors = data?.rejectSubmissions?.errors;
      const rejectedSubmission = data?.rejectSubmissions?.submissions?.[0];
      if (gqlErrors?.length) {
        console.error('GraphQL errors', gqlErrors);
      } else if (userErrors?.length) {
        console.error('User errors', userErrors);
      } else if (rejectedSubmission && rejectedSubmission.state === SubmissionState.Rejected) {
        success = true;
      }
    } catch (networkError) {
      console.error(networkError);
    }

    props.onClose();
    if (success) {
      toast('Submission rejected', 'success');
    } else {
      toast('Could not reject submission', 'error');
    }
  }
}
