export type Preset = {
  __typename: 'Preset';
  id: string;
  name: string;
  resize: PhotoSize;
  crop: Crop;
};

/**
 * Preset but with only the minimal number of properties needed to view in
 * `/presets` route.
 */
export type BasicPreset = Pick<Preset, '__typename' | 'id' | 'name' | 'resize'>;

export interface PresetInput {
  name: string;
  resize: PhotoSize;
  crop: Crop;
}

export const PHOTO_SIZES = ['LARGE', 'MEDIUM', 'SMALL', 'THUMB'] as const;
export const CROP_FACTORS = ['SQUARE', 'PORTRAIT', 'LANDSCAPE', 'CIRCLE'] as const;
export const CROP_GRAVITIES = ['CENTER', 'FACE'] as const;
export const IMAGE_FORMATS = ['jpeg', 'png', 'gif'] as const;

export type PhotoSize = typeof PHOTO_SIZES[number];
export type CropFactor = typeof CROP_FACTORS[number];
export type CropGravity = typeof CROP_GRAVITIES[number];

/**
 * Mapping between PhotoSize enum and a human-readable name.
 */
export const PhotoSizeNames: Record<PhotoSize, string> = {
  LARGE: 'Large (2000px)',
  MEDIUM: 'Medium (1200px)',
  SMALL: 'Small (600px)',
  THUMB: 'Thumbnail (250px)',
};

export const CropFactorNames: Record<CropFactor, string> = {
  SQUARE: 'Square',
  PORTRAIT: 'Portrait (3:4)',
  LANDSCAPE: 'Landscape (16:9)',
  CIRCLE: 'Circle',
};

export const CropGravityNames: Record<CropGravity, string> = {
  CENTER: 'Center',
  FACE: 'Face',
};

export interface Crop {
  factor: CropFactor;
  gravity: CropGravity;
}
