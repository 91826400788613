const outerDivStyles = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  background: 'whitesmoke',
};

const innerDivStyles = {
  maxWidth: 600,
  borderRadius: 16,
  background: 'white',
  padding: 16,
  boxShadow: '0px 1px 2px rgb(0 0 0 / 12%), 0px 0px 0px 1px rgb(0 0 0 / 5%)',
};

/**
 * Component that serves as an error boundary for the entire app. Uses no
 * dependencies.
 */
export function PageErrorBoundary(): React.ReactElement {
  return (
    <div style={outerDivStyles}>
      <div style={innerDivStyles}>
        <h1 style={{ fontFamily: 'sans-serif' }}>
          <span role="img" aria-label="boom">
            💥
          </span>{' '}
          Oh no!
        </h1>
        <p style={{ fontFamily: 'sans-serif' }}>The page couldn&apos;t load. Try again by refreshing your browser.</p>
      </div>
    </div>
  );
}
