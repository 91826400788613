import { makeStyles, Tooltip, colors } from '@material-ui/core';

export type PipProps = {
  status: 'active' | 'inactive';
  message: string;
};

const useStyles = makeStyles(() => ({
  pip: {
    height: 12,
    width: 12,
    borderRadius: 9000,
    marginRight: '.7rem',
    backgroundColor: colors.grey[300],
  },
  active: {
    backgroundColor: colors.green[500],
  },
}));

export function Pip(props: PipProps): React.ReactElement {
  const classes = useStyles();
  const pipDesign = `${classes.pip} ${props.status == 'active' ? classes.active : ''}`;

  return (
    <>
      <Tooltip title={props.message}>
        <span>
          <div className={pipDesign} />
        </span>
      </Tooltip>
    </>
  );
}
