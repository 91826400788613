import { Container, Typography, makeStyles, Grid, Breadcrumbs, Link } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import { BODY_PADDING, APPBAR_HEIGHT } from 'layouts/AppWrapper';
import { useEffect } from 'react';

export type PageProps = {
  /**
   * Title rendered at the top of the page.
   */
  title: string | JSX.Element;
  /**
   * Small, grey text to display beneath the title.
   */
  subtitle?: string | JSX.Element;
  /**
   * Text in HTML <title></title> tags that comes before " | Company Headshots by Snapbar" (usually exact same value as 'title')
   */
  htmlTitle?: string;
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * JSX element rendered to the right of the title. Usually used for page
   * action elements, such as buttons that perform a page's primary action.
   */
  pageActions?: React.ReactNode;
  /**
   * Navigation breadcrumbs to show above page title. Should be an array of MUI Link elements.
   */
  breadcrumbs?: Breadcrumb[];
  /**
   * If true, cap the page height to 100vh, preventing the page from overflowing vertically.
   */
  capHeight?: boolean;
  /**
   * Max width of the root container.
   */
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children?: React.ReactNode;
  /**
   * hides the MuiGrid Header container at the top of the page for places like the login page where our designs override this style
   * */
  hideTitleBar?: boolean;
};

export interface Breadcrumb {
  /**
   * Route that the breadcrumb directs to.
   */
  to: string;
  /**
   * Name of the breadcrumb.
   */
  body: React.ReactNode;
}

const TOP_PADDING = 40;

const useStyles = makeStyles({
  page: {
    paddingTop: TOP_PADDING,
    paddingLeft: 0,
    paddingRight: 0,
    height: (props: PageProps) => (props.capHeight ? `calc(100vh - ${BODY_PADDING * 2 + APPBAR_HEIGHT}px)` : 'unset'),
    display: 'flex',
    flexDirection: 'column',
  },
  pageActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    // alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: '1em',
    },
  },
  title: {
    marginBottom: 16,
    width: '100%',
  },
  subtitle: {
    fontWeight: 400,
    color: '#546E7A',
  },
  breadcrumbs: {
    height: 24,
    marginTop: -24,
    marginBottom: 5,
    color: '#546e7a',
    '& .MuiBreadcrumbs-li:last-child': {
      color: 'black',
    },
    '& .MuiLink-root': {
      color: 'inherit',
    },
  },
});

/**
 * Renders common page data (e.g. title and title buttons) in a standard layout
 * used on most pages.
 */
export function Page(props: PageProps): React.ReactElement {
  useEffect(() => {
    let prefix = '';
    if (props.htmlTitle) {
      prefix = props.htmlTitle;
    } else if (typeof props.title === 'string') {
      prefix = props.title;
    }
    if (prefix) {
      prefix += ' | ';
    }
    document.title = `${prefix} Snapbar Studio`;
  }, [props.title, props.htmlTitle]);

  const classes = useStyles(props);
  const maxWidth = props?.maxWidth ?? 'md';

  return (
    <Container maxWidth={maxWidth} className={clsx(classes.page, props.className)}>
      {props.breadcrumbs && (
        <Breadcrumbs separator={<ChevronRight fontSize="small" />} className={classes.breadcrumbs}>
          {props.breadcrumbs.map((breadcrumb, index) => (
            <Link component={RouterLink} to={breadcrumb.to} key={index}>
              {breadcrumb.body}
            </Link>
          ))}
        </Breadcrumbs>
      )}
      {!props.hideTitleBar && (
        <Grid container justify="space-between" className={classes.title} alignItems="center">
          <Grid item xs={props.pageActions ? 9 : 12}>
            <Typography variant="h3">{props.title}</Typography>
            {props.subtitle && (
              <Typography className={classes.subtitle} variant="h6">
                {props.subtitle}
              </Typography>
            )}
          </Grid>
          {props.pageActions && (
            <Grid item xs={3} className={classes.pageActions}>
              {props.pageActions}
            </Grid>
          )}
        </Grid>
      )}
      {props.children}
    </Container>
  );
}
