import { Typography, Button } from '@material-ui/core';
import Dialog from 'components/Dialog';

type PhotoExportSuccessDialogProps = {
  open: boolean;
  onClose: () => void;
};

export function PhotoExportAwaitDialog(props: PhotoExportSuccessDialogProps): JSX.Element {
  const { ...dialogProps } = props;

  return (
    <Dialog
      title="Export in progress..."
      {...dialogProps}
      width={500}
      actions={
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.onClose();
          }}
        >
          Okay
        </Button>
      }
    >
      <Typography>
        Your submissions are currently being exported. We&apos;ll send you an email once the export is completed.
      </Typography>
    </Dialog>
  );
}
