import { makeStyles, Card, CardHeader, CardContent, Divider, Typography, colors } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useHumanizedTimes from 'hooks/useHumanizedTimes';
import { StudioPhotosInfoGrid } from 'components/StudioPhotosInfoGrid';
import { StudioOverviewFragment } from 'codegen/graphql';
import { SharingPreview } from '../SharingPreview';
import { Pip } from '../Pip';

type StudioOverviewCardProps = {
  /**
   * The studio that this card belongs to.
   */
  studio: StudioOverviewFragment;
  actions?: React.ReactElement;
};

const useStyles = makeStyles(() => ({
  verticalDivider: {
    display: 'inline-block',
    marginLeft: 10,
    marginRight: 10,
    height: '1rem',
  },
  orange: {
    color: colors.orange[700],
  },
  green: {
    color: colors.green[500],
  },
  pip: {
    height: '10px',
    width: '10px',
    backgroundColor: colors.green[500],
    borderRadius: 8000,
    marginRight: '.5rem',
  },
}));

export function StudioOverviewCard({ studio, actions }: StudioOverviewCardProps): React.ReactElement {
  const { t } = useTranslation();
  const [start, end, timeLeft] = useHumanizedTimes(studio.startDate, studio.endDate);
  const classes = useStyles();
  const now = new Date().getTime();
  const startDate = new Date(studio.startDate).getTime();
  const endDate = studio.endDate ? new Date(studio.endDate).getTime() : Infinity;
  const studioIsActive = now > startDate && now < endDate;
  const studioIsExpiring = (endDate - now) / 1000 / 3600 / 24 < 30;

  const getStudioStatusTooltipText = () => {
    if (startDate > now) {
      return `This studio starts ${start.value}`;
    } else if (startDate < now && endDate > now) {
      return 'This studio is live';
    } else {
      return 'This studio has expired';
    }
  };

  const Subheader = (
    <>
      <Pip status={studioIsActive ? 'active' : 'inactive'} message={getStudioStatusTooltipText()} />
      <Typography component="span" variant="body2">
        {start.description} {start.value}
      </Typography>
      <Divider orientation="vertical" className={classes.verticalDivider} />
      <Typography component="span" variant="body2">
        {end ? `${end.description} ${end.value}` : 'No end date'}
      </Typography>
      {studioIsExpiring && (
        <>
          <Divider orientation="vertical" className={classes.verticalDivider} />
          <Typography component="span" variant="body2" className={classes.orange}>
            {timeLeft.description} {timeLeft.value}
          </Typography>
        </>
      )}
    </>
  );

  return (
    <>
      <Card>
        <CardHeader title={studio.name} subheader={Subheader} action={actions} />
        <SharingPreview studio={studio} />
        <Divider />
        <CardContent>
          <StudioPhotosInfoGrid studio={studio} />
        </CardContent>
      </Card>
    </>
  );
}
