import { useState } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Send } from 'react-feather';
import { Dialog } from 'components/Dialog';
import ErrorCard from 'components/ErrorCard';
import {
  InviteContactListDialogQuery,
  useInviteContactListDialogQuery,
  useInviteContactListMutation,
} from 'codegen/graphql';
import { ContactsTable } from 'components/ContactsTable';
import { toast } from 'components/GlobalSnackbar';

export type InviteContactListDialogProps = {
  open: boolean;
  onClose: () => void;
  /**
   * GID of the studio to invite to.
   */
  studioId: string;
};

const useStyles = makeStyles(() => ({
  loadingWrapper: {
    width: '100%',
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    flexGrow: 1,
  },
}));

type ContactList = InviteContactListDialogQuery['contactLists'][number];

export function InviteContactListDialog(props: InviteContactListDialogProps): React.ReactElement {
  const { t } = useTranslation();

  const { studioId, ...dialogProps } = props;
  const [inviteContactList, inviteContactListState] = useInviteContactListMutation();
  const [selectedContactList, setSelectedContactList] = useState<ContactList | null>(null);

  const dialogActions = (
    <>
      <Button onClick={props.onClose}>Close</Button>
      {selectedContactList && (
        <Button
          startIcon={<Send />}
          color="primary"
          variant="contained"
          onClick={handleInvite}
          disabled={inviteContactListState.loading}
        >
          SEND INVITATION
        </Button>
      )}
    </>
  );

  return (
    <Dialog
      {...dialogProps}
      title="Invite contact list"
      actions={dialogActions}
      width={600}
      onExited={() => setSelectedContactList(null)}
    >
      <SelectContactList selectedContactList={selectedContactList} setSelectedContactList={setSelectedContactList} />
      {selectedContactList && <ContactsTable selectedContactList={selectedContactList} />}
    </Dialog>
  );

  async function handleInvite() {
    if (!selectedContactList?.id) return;
    let success = false;
    try {
      const { data, errors: gqlErrors } = await inviteContactList({
        variables: { studioId, contactListId: selectedContactList.id },
      });
      const userErrors = data?.inviteContactList?.errors;
      const invitedContactListId = data?.inviteContactList?.contactList?.id;

      if (invitedContactListId) {
        success = true;
      } else if (gqlErrors?.length) {
        console.error('GraphQL error(s):', gqlErrors);
      } else if (userErrors?.length) {
        console.error('User error(s):', userErrors);
      }
    } catch (networkError) {
      console.error('Network error:', networkError);
    }
    props.onClose();
    success ? toast('Contact list invited', 'success') : toast('Could not invite contact list', 'error');
  }
}

type SelectContactListProps = {
  selectedContactList: ContactList | null;
  setSelectedContactList: (newContactList: ContactList) => void;
};

function SelectContactList(props: SelectContactListProps) {
  const classes = useStyles();
  const { loading, error, data } = useInviteContactListDialogQuery();
  if (loading) {
    return (
      <div className={classes.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }

  if (error || !data) {
    console.error(error);
    return <ErrorCard body="Could not load contact lists." />;
  }

  const selectItems = data.contactLists.map((contactList, index) => (
    <MenuItem
      value={contactList.id}
      key={index}
      onClick={() => {
        props.setSelectedContactList(contactList);
      }}
    >
      {contactList.name}
    </MenuItem>
  ));

  return (
    <div className={classes.selectWrapper}>
      {data.contactLists.length ? (
        <>
          <FormControl className={classes.select}>
            <InputLabel variant="outlined">Contact list</InputLabel>
            <Select variant="outlined" label="Contact list" value={props.selectedContactList?.id ?? ''}>
              {selectItems}
            </Select>
          </FormControl>
          {props.selectedContactList && (
            <Typography variant="body2" style={{ marginLeft: 8 }}>
              {`${props.selectedContactList.contactsCount} total contacts`}
            </Typography>
          )}
        </>
      ) : (
        <Typography>
          You don&apos;t have any contact lists associated with this account.
          <br />
          Create one on the{' '}
          <Link href="/contacts/lists/all" underline="none">
            contact page
          </Link>
          .
        </Typography>
      )}
    </div>
  );
}
