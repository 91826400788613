import { makeStyles, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UserError } from 'codegen/graphql';
import { Alert } from '@material-ui/lab';
import { sentenceCase } from 'change-case';

export type ServerErrorsCardProps = {
  title?: string;
  errors: UserError[];
};

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '1em',
  },
  heading: {
    fontWeight: 600,
  },
  errorMessage: {},
}));

export function UserErrorsCard(props: ServerErrorsCardProps): React.ReactElement {
  const { errors, title = 'Please resolve the following issue(s):' } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.root}>
      <Alert severity="error">
        <Typography className={classes.heading}>
          Please address the following issue{errors.length > 1 && '(s)'}:
        </Typography>

        {errors.map((error, index) => (
          <Typography key={index} className={classes.errorMessage}>
            {error.path?.length && `${translatePath(error.path)}:`} {error.message}
          </Typography>
        ))}
      </Alert>
    </Paper>
  );
}

export function translatePath(path: string[]): string {
  return path.reduce((translated: string, pathItem: string, index: number): string => {
    const translatedPathItem = sentenceCase(pathItem);

    if (index > 0) {
      return `${translated}, ${translatedPathItem.toLowerCase()}`;
    }

    return translatedPathItem;
  }, '');
}
