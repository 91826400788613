import { useEffect, useMemo, useRef, useState } from 'react';
import {
  InputAdornment,
  makeStyles,
  TextField,
  Popper,
  Paper,
  CircularProgress,
  ButtonBase,
  Typography,
  Avatar,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Search } from 'react-feather';
import { SearchContactsDocument, SearchContactsQuery, SearchContactsQueryVariables } from 'codegen/graphql';
import { useApolloClient } from '@apollo/client';
import { id } from 'lib/gid';
import { debounce } from 'lodash-es';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  searchContainer: {
    position: 'relative',
  },
  searchField: {
    width: '320px',
  },
  searchResults: {
    zIndex: 1101,
    marginTop: '-5px',
    width: '400px',
    padding: '15px',
  },
  innerSearchResults: {
    maxHeight: '320px',
    overflow: 'auto',
  },
  searchResultsLoading: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  searchScreen: {
    position: 'fixed',
    zIndex: 100,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0,0,0,0.5)',
  },
  searchResult: {
    justifyContent: 'flex-start',
    textAlign: 'left',
    width: '100%',
    padding: '8px 10px',
    '& .MuiAvatar-root': {
      marginRight: '10px',
      width: '30px',
      height: '30px',
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)',
    },
  },
}));

type Contact = SearchContactsQuery['searchContacts']['nodes'][number];

export function ContactsSearchField(): JSX.Element {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [mostRecentQuery, setMostRecentQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const fieldRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const client = useApolloClient();

  const debouncedSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        setLoading(true);
        const { data } = await client.query<SearchContactsQuery, SearchContactsQueryVariables>({
          query: SearchContactsDocument,
          variables: { query: value },
        });
        setMostRecentQuery(value);
        setContacts(data?.searchContacts?.nodes ?? []);
        setLoading(false);
        // only initialize on initial render
        // eslint-disable-next-line
      }, 500), []);

  useEffect(() => {
    if (value === '') {
      setLoading(false);
      setMostRecentQuery('');
      setContacts([]);
    } else {
      debouncedSearch(value);
    }
  }, [debouncedSearch, value]);

  const results = useMemo(() => {
    return contacts.map((contact, index) => (
      <ButtonBase
        className={classes.searchResult}
        key={index}
        onClick={() => {
          setValue('');
          history.push(`/contacts/${id(contact.id)}/view`);
        }}
      >
        <Avatar />
        <div>
          <Typography variant="subtitle2">
            {contact.firstName} {contact.lastName}
          </Typography>
          <Typography variant="caption">{contact.email}</Typography>
        </div>
      </ButtonBase>
    ));
  }, [classes.searchResult, contacts, history]);

  return (
    <div className={classes.searchContainer}>
      <TextField
        className={classes.searchField}
        ref={fieldRef}
        variant="standard"
        placeholder="Search contacts"
        type="search"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {loading ? <CircularProgress size={24} color="inherit" /> : <Search />}
            </InputAdornment>
          ),
        }}
      />
      {value && (
        <div
          role="presentation"
          className={classes.searchScreen}
          onClick={() => {
            setValue('');
          }}
        />
      )}
      <Popper
        className={classes.searchResults}
        open={!!mostRecentQuery && !!value}
        anchorEl={fieldRef.current}
        placement="bottom"
      >
        <Paper className={classes.innerSearchResults}>
          <div className={clsx(loading && classes.searchResultsLoading)}>
            {!results.length ? (
              <Typography style={{ padding: '10px 15px' }}>{`No results for '${mostRecentQuery}'`}</Typography>
            ) : (
              results
            )}
          </div>
        </Paper>
      </Popper>
    </div>
  );
}
