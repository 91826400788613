import { Alarm } from '@material-ui/icons';
import { SettingsCard } from 'components/SettingsCard';
import { Button, FormControlLabel, Input, makeStyles, MenuItem, Select, Switch, Typography } from '@material-ui/core';
import { useState } from 'react';
import useDialog from 'hooks/useDialog';
import { Dialog } from 'components/Dialog';
import { toast } from 'components/GlobalSnackbar';
import { useUpdateStudioMutation } from 'codegen/graphql';
import PageActionButton from 'components/PageActionButton';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    marginTop: '1rem',
  },
  reminderOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    marginLeft: '-0.5rem',
  },
  dialogContent: {
    marginTop: '-0.5rem',
  },
}));

type ReminderSettingsCardProps = {
  studioId: string;
  reminderDuration: string | null;
  remindersEnabled?: boolean | null;
};

export function ReminderSettingsCard(props: ReminderSettingsCardProps): React.ReactElement {
  const { reminderDuration, studioId, remindersEnabled } = props;
  const classNames = useStyles();
  const { open, closeDialog, openDialog } = useDialog();

  const [updateStudio] = useUpdateStudioMutation({
    onError: () => toast('Failed to update reminders', 'error'),
  });
  const interval = durationToInterval(reminderDuration);
  const [reminderInterval, setReminderInterval] = useState(interval || 1);
  const [reminderUnit, setReminderUnit] = useState(reminderUnitFromDuration(reminderDuration) || 'W');

  return (
    <>
      <SettingsCard title="Invite reminders" icon={<Alarm />} cardActions={<Button onClick={openDialog}>EDIT</Button>}>
        <div className={classNames.dialogContent}>
          <FormControlLabel
            control={
              <Switch checked={remindersEnabled ?? false} onChange={(_, checked) => toggleReminders(checked, true)} />
            }
            label={
              <>
                <Typography variant="overline" component="p">
                  Send automatic reminders
                </Typography>
                <Typography variant="body2" style={{ color: '#546e7a' }}>
                  Automatically send reminder emails to contacts who have been invited and have yet to submit.
                </Typography>
              </>
            }
          />
          {remindersEnabled && (
            <>
              <Typography variant={'overline'} component="p" style={{ marginTop: '1rem' }}>
                Frequency
              </Typography>
              <Typography style={{ opacity: 0.5 }}>
                Sending reminder emails every {interval == 1 ? '' : interval}{' '}
                {humanizedUnit(reminderUnitFromDuration(reminderDuration), interval)}
              </Typography>
            </>
          )}
        </div>
      </SettingsCard>
      <Dialog
        open={open}
        title="Edit reminder frequency"
        onClose={closeDialog}
        width={450}
        actions={
          <>
            <Button onClick={closeDialog}>CANCEL</Button>
            <PageActionButton onClick={updateDuration}>Save changes</PageActionButton>
          </>
        }
      >
        <div className={classNames.selectContainer}>
          <div className={classNames.reminderOptions} style={{ marginLeft: 10 }}>
            <Typography>Email every</Typography>
            <Input
              value={reminderInterval}
              type="number"
              onChange={(e) => {
                const number = parseInt(e.target.value);

                if (number > 31) {
                  setReminderInterval(31);
                } else if (number < 1) {
                  setReminderInterval(1);
                } else {
                  setReminderInterval(number);
                }
              }}
              style={{ width: '3rem' }}
              inputProps={{
                min: 1,
                max: 31,
              }}
            />

            <Select value={reminderUnit} onChange={(e) => setReminderUnit(e.target.value as string)}>
              <MenuItem value="D">{humanizedUnit('D', reminderInterval)}</MenuItem>
              <MenuItem value="W">{humanizedUnit('W', reminderInterval)}</MenuItem>
              <MenuItem value="M">{humanizedUnit('M', reminderInterval)}</MenuItem>
            </Select>
          </div>
        </div>
        {!remindersEnabled && (
          <Alert variant="outlined" severity="info" style={{ marginTop: 30 }}>
            Invite reminders are currently turned off. Turn this setting on to start sending reminder emails.
          </Alert>
        )}
      </Dialog>
    </>
  );

  function buildDuration() {
    return `P${reminderInterval}${reminderUnit}` || null;
  }

  async function updateDuration(showToast?: boolean) {
    const result = await updateStudio({
      variables: {
        input: {
          id: studioId,
          reminderDuration: buildDuration(),
        },
      },
    });
    if (result.errors) {
      toast('Error updating reminder', 'error');
      return;
    }
    if (!showToast) return;

    toast('Updated frequency', 'success');

    closeDialog();
  }

  async function toggleReminders(enabled: boolean, showToast?: boolean) {
    const result = await updateStudio({
      variables: {
        input: {
          id: studioId,
          remindersEnabled: enabled,
          reminderDuration: buildDuration(),
        },
      },
    });
    if (result.errors) {
      toast('Error updating reminder', 'error');
      return;
    }
    if (!showToast) return;

    if (enabled) {
      toast('Enabled reminders', 'success');
    } else {
      toast('Disabled reminders', 'success');
    }
  }

  function humanizedUnit(unit?: string | null, count?: number) {
    if (!unit) return '';
    let humanized: string;
    switch (unit) {
      case 'D':
        humanized = 'day';
        break;
      case 'W':
        humanized = 'week';
        break;
      case 'M':
        humanized = 'month';
        break;
      default:
        return '';
    }
    if (count && count > 1) humanized += 's';
    return humanized;
  }

  function reminderUnitFromDuration(duration?: string | null) {
    if (!duration) return;
    return duration?.[duration.length - 1];
  }

  function durationToInterval(duration?: string | null) {
    if (!duration) return;
    return parseInt(duration.replace(/[^0-9]/g, ''));
  }
}
