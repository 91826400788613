import {
  Typography,
  makeStyles,
  ButtonBase,
  Menu,
  MenuItem,
  ListItemIcon,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Check, ChevronDown, X, Trash2, Download } from 'react-feather';
import { useCallback, useState } from 'react';
import { useRef } from 'react';

import {
  useGetStudioOverviewsQuery,
  GetSubmissionRowsQueryVariables,
  useAcceptSubmissionsMutation,
  useRejectSubmissionsMutation,
  useDeleteSubmissionsMutation,
  useEnhanceSubmissionsMutation,
} from 'codegen/graphql';
import { sub } from 'date-fns';
import { toast } from 'components/GlobalSnackbar';
import type { SubmissionTabValue } from 'pages/Submissions';
import useDialog from 'hooks/useDialog';
import { PhotoExportDialog } from 'components/PhotoExportDialog';
import { PhotoExportAwaitDialog } from 'components/PhotoExportSuccessDialog';
import { handleMutation } from 'lib/handleMutation';
import { usePermissions } from 'hooks/usePermissions';
import { Assistant } from '@material-ui/icons';

export type SubmissionsDataGridToolbarProps = {
  rowCount: number;
  selectedIds: string[];
  total: string;
  refreshCurrentPage: () => void;
  queryVariables: GetSubmissionRowsQueryVariables;
  editQueryVariables: (newQueryVariables: Partial<GetSubmissionRowsQueryVariables>) => unknown;
  currentTab: SubmissionTabValue;
};

type BulkActionKey = 'ACCEPT' | 'REJECT' | 'EXPORT' | 'DELETE' | 'ENHANCE';

type BulkAction = {
  label: string;
  icon: JSX.Element;
  loading?: boolean;
  onClick: () => unknown;
};

/**
 * Mapping between tab values and valid bulk actions.
 */
const VALID_BULK_ACTION_KEYS: Record<SubmissionTabValue, BulkActionKey[]> = {
  ALL: ['EXPORT', 'ENHANCE', 'DELETE'],
  REVIEW: ['ACCEPT', 'REJECT', 'EXPORT', 'DELETE'],
  ACCEPTED: ['REJECT', 'ENHANCE', 'EXPORT', 'DELETE'],
  REJECTED: ['ACCEPT', 'ENHANCE', 'EXPORT', 'DELETE'],
  INVITED: [],
};

export const useToolbarStyles = makeStyles({
  root: {
    margin: '16px 16px 0 16px',
  },
  // of course MUI doesn't support children in the `Chip` component so I have to
  // style my own.
  chip: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    background: '#F1F3FB',
    borderRadius: 25,
    marginLeft: -8,
    padding: '8px 16px',
  },
  chipActions: {
    marginLeft: 12,
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 16,
  },
  text: {
    display: 'inline-block',
    fontSize: 15,
    fontWeight: 600,
  },
  firstRow: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  filtersGroup: {
    '& > *:not(:last-child)': {
      marginRight: 8,
    },
  },
  select: {
    minWidth: 180,
    '& .MuiOutlinedInput-input': {
      padding: '8px 32px 8px 14px',
    },
    '& .MuiInputLabel-outlined[data-shrink="false"]': {
      transform: 'translate(14px, 10px) scale(1)',
    },
  },
});

const PAST_DAY = sub(Date.now(), { days: 1 }).toISOString();
const PAST_WEEK = sub(Date.now(), { weeks: 1 }).toISOString();
const PAST_MONTH = sub(Date.now(), { months: 1 }).toISOString();
const PAST_YEAR = sub(Date.now(), { years: 1 }).toISOString();

export function SubmissionsDataGridToolbar(props: SubmissionsDataGridToolbarProps): JSX.Element {
  const classes = useToolbarStyles();
  const { t } = useTranslation();
  const perms = usePermissions();

  const [acceptSelectedSubmissions, acceptSelectedSubmissionsState] = useAcceptSubmissionsMutation({
    variables: { ids: props.selectedIds },
  });
  const [rejectSelectedSubmissions, rejectSelectedSubmissionsState] = useRejectSubmissionsMutation({
    variables: { ids: props.selectedIds },
  });
  const [deleteSelectedSubmissions, deleteSelectedSubmissionsState] = useDeleteSubmissionsMutation({
    variables: { ids: props.selectedIds },
  });
  const [enhanceSubmissions, enhanceSubmissionsState] = useEnhanceSubmissionsMutation({
    variables: { ids: props.selectedIds },
  });
  const [exportUrl, setExportUrl] = useState('');
  const { openDialog: openExportDialog, closeDialog: closeExportDialog, dialogProps: exportDialogProps } = useDialog();
  const { openDialog: openExportSuccessDialog, dialogProps: exportSuccessDialogProps } = useDialog();

  const [menuOpen, setMenuOpen] = useState(false);
  const openMenu = useCallback(() => setMenuOpen(true), []);
  const closeMenu = useCallback(() => setMenuOpen(false), []);
  const actionsButtonRef = useRef<HTMLButtonElement>(null);

  const capitalPluralized = props.selectedIds.length > 1 ? 'submission_capital_plural' : 'submission_capital';
  const pluralized = props.selectedIds.length > 1 ? 'submission_plural' : 'submission';

  const allBulkActions: Record<BulkActionKey, BulkAction> = {
    ACCEPT: {
      icon: <Check />,
      label: acceptSelectedSubmissionsState.loading ? 'Accepting...' : 'Accept',
      loading: acceptSelectedSubmissionsState.loading,
      onClick: handleBulkAccept,
    },
    REJECT: {
      icon: <X />,
      label: rejectSelectedSubmissionsState.loading ? 'Rejecting...' : 'Reject',
      loading: rejectSelectedSubmissionsState.loading,
      onClick: handleBulkReject,
    },
    DELETE: {
      icon: <Trash2 />,
      label: deleteSelectedSubmissionsState.loading ? 'Deleting...' : 'Delete',
      loading: deleteSelectedSubmissionsState.loading,
      onClick: handleBulkDelete,
    },
    EXPORT: {
      icon: <Download />,
      label: 'Export',
      loading: false,
      onClick: openExportDialog,
    },
    ENHANCE: {
      icon: <Assistant />,
      label: 'AI Enhance',
      loading: false,
      onClick: handleBulkEnhance,
    },
  };

  const validActions = VALID_BULK_ACTION_KEYS[props.currentTab]
    .filter((key) => {
      if (key === 'ACCEPT' || key === 'REJECT') {
        return perms.auth?.canChangeSubmission;
      } else if (key === 'DELETE') {
        return perms.auth?.canDeleteSubmission;
      } else if (key === 'EXPORT') {
        return perms.auth?.canExportSubmission;
      } else if (key == 'ENHANCE') {
        return perms.staff;
      }
    })
    .map((key) => allBulkActions[key]);

  const DATE_MENU_ITEMS = [
    {
      label: <em>None</em>,
      value: '',
    },
    {
      label: 'Past day',
      value: PAST_DAY,
    },
    {
      label: 'Past week',
      value: PAST_WEEK,
    },
    {
      label: 'Past month',
      value: PAST_MONTH,
    },
    {
      label: 'Past year',
      value: PAST_YEAR,
    },
  ];

  return (
    <>
      <div className={classes.root}>
        <div className={classes.firstRow}>
          {props.selectedIds.length ? (
            <div className={classes.chip}>
              <Typography className={classes.text}>
                {props.selectedIds.length} of {props.rowCount} selected
              </Typography>
              {!!validActions.length && (
                <>
                  <ButtonBase className={classes.chipActions} onClick={openMenu} ref={actionsButtonRef}>
                    <Typography variant="body2">Actions</Typography>
                    <ChevronDown size={12} style={{ display: 'inline-block', marginLeft: 8 }} />
                  </ButtonBase>
                  <Menu
                    open={menuOpen}
                    onClose={closeMenu}
                    anchorEl={actionsButtonRef.current}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {validActions.map((action, index) => (
                      <MenuItem key={index} onClick={action.onClick} disabled={action.loading}>
                        <ListItemIcon>{action.icon}</ListItemIcon>
                        <Typography variant="body2">{action.label}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </div>
          ) : (
            <div className={classes.chip} style={{ backgroundColor: 'white' }}>
              <Typography className={classes.text}>
                Showing {props.rowCount} of {props.total}
              </Typography>
            </div>
          )}
          <div className={classes.filtersGroup}>
            {perms.auth?.canViewStudio && (
              <StudioSelect queryVariables={props.queryVariables} editQueryVariables={props.editQueryVariables} />
            )}
            <FormControl variant="outlined" className={classes.select}>
              <InputLabel>Submitted after</InputLabel>
              <Select
                value={props.queryVariables.submittedAfter ?? ''}
                onChange={(e) => handleSubmittedAfterChange(e.target.value as string)}
                variant="outlined"
                label="Submitted after"
              >
                {DATE_MENU_ITEMS.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <PhotoExportDialog
        {...exportDialogProps}
        submissionIds={props.selectedIds}
        onSuccess={handleBulkExportSuccess}
        onFail={handleBulkExportFail}
      />
      <PhotoExportAwaitDialog {...exportSuccessDialogProps} />
    </>
  );

  async function handleBulkAccept() {
    await handleMutation({
      mutate: acceptSelectedSubmissions,
      payloadFieldPath: 'acceptSubmissions?',
      successCondition: (data) => data.acceptSubmissions?.errors?.length === 0,
      onSuccess: () => {
        toast(t(capitalPluralized) + ' accepted', 'success');
        props.refreshCurrentPage();
      },
      onError: () => {
        toast('Could not accept all ' + t(pluralized), 'error');
      },
    });
    closeMenu();
  }

  async function handleBulkReject() {
    await handleMutation({
      mutate: rejectSelectedSubmissions,
      payloadFieldPath: 'rejectSubmissions?',
      successCondition: (data) => data.rejectSubmissions?.errors?.length === 0,
      onSuccess: () => {
        toast(t(capitalPluralized) + ' rejected', 'success');
        props.refreshCurrentPage();
      },
      onError: () => {
        toast('Could not reject all ' + t(pluralized), 'error');
      },
    });
    closeMenu();
  }

  async function handleBulkDelete() {
    const len = props.selectedIds.length;
    await handleMutation({
      mutate: deleteSelectedSubmissions,
      payloadFieldPath: 'deleteSubmissions?',
      confirm: `Are you sure you want to delete ${len} ${len === 1 ? 'submission' : 'submissions'}?`,
      successCondition: (data) => (data.deleteSubmissions?.deletedIds?.length || 0) > 0,
      onSuccess: () => {
        toast('Submissions deleted', 'success');
        props.refreshCurrentPage();
      },
      onError: () => {
        toast(t(`Could not delete $t(${pluralized})`), 'error');
      },
    });
    closeMenu();
  }

  async function handleBulkEnhance() {
    const len = props.selectedIds.length;
    await handleMutation({
      mutate: enhanceSubmissions,
      payloadFieldPath: 'enhanceSubmissions?',
      confirm: `Are you sure you want to enhance ${len} ${len === 1 ? 'submission' : 'submissions'}?`,
      successCondition: (data) => (data.enhanceSubmissions?.submissions?.length || 0) > 0,
      onSuccess: () => {
        toast('Submissions queued for enhancement', 'success');
      },
      onError: () => {
        toast(t(`The $t(${pluralized}) you selected are unavailable for enhancement`), 'error');
      },
    });
    closeMenu();
  }

  function handleBulkExportSuccess(url: string) {
    closeExportDialog();
    setExportUrl(url);
    openExportSuccessDialog();
  }

  function handleBulkExportFail() {
    closeExportDialog();
    toast('Could not delete ' + t(pluralized), 'error');
  }

  function handleSubmittedAfterChange(newDate: string) {
    props.editQueryVariables({
      submittedAfter: newDate === '' ? null : newDate,
    });
  }
}

function StudioSelect(props: Pick<SubmissionsDataGridToolbarProps, 'queryVariables' | 'editQueryVariables'>) {
  const { data } = useGetStudioOverviewsQuery();
  const classes = useToolbarStyles();

  const STUDIO_MENU_ITEMS = [
    {
      label: 'All studios',
      value: 'all',
    },
    ...(data ? data.studios.edges.map((edge) => ({ label: edge.node.name, value: edge.node.id })) : []),
  ];

  return (
    <FormControl variant="outlined" className={classes.select}>
      <InputLabel>Studio</InputLabel>
      <Select
        value={props.queryVariables.studioIds ?? 'all'}
        onChange={(e) => handleStudioIdChange(e.target.value as string)}
        variant="outlined"
        label="Studio"
      >
        {STUDIO_MENU_ITEMS.map((item, index) => (
          <MenuItem value={item.value === null ? 'all' : item.value} key={index}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  function handleStudioIdChange(newId: string) {
    props.editQueryVariables({
      studioIds: newId === 'all' ? null : [newId],
    });
  }
}
