import { useTranslation } from 'react-i18next';
import { BODY_PADDING, APPBAR_HEIGHT } from 'layouts/AppWrapper';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';

export type NotAuthorizedPageProps = {
  // add props here
};

const useStyles = makeStyles(() => ({
  root: {
    height: `calc(100vh - ${BODY_PADDING * 2 + APPBAR_HEIGHT}px)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  paper: {
    maxWidth: 700,
    padding: 32,
  },
  title: {
    marginBottom: 8,
  },
}));

/**
 * Component that serves as an error boundary for the entire app. Uses no
 * dependencies.
 */
export function NotAuthorizedPage(): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h2" className={classes.title}>
          <span role="img" aria-label="Not authorized" style={{ marginRight: 8 }}>
            🔒
          </span>
          Not authorized
        </Typography>
        <Typography>
          You&apos;re not authorized to view this page. Please ask your administrator for access if you believe this is
          an error.
        </Typography>
      </Paper>
    </div>
  );
}
