import { Button, makeStyles } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Plus, Trash } from 'react-feather';
import { zodResolver } from '@hookform/resolvers/zod';
import { compareAsc } from 'date-fns';
import { z } from 'zod';
import { toast } from 'components/GlobalSnackbar';

import Page, { Breadcrumb } from 'pages/Page';
import { StudioFormCard } from 'src/components/StudioFormCard';
import {
  useCreateStudioMutation,
  useDeleteStudioMutation,
  useUpdateStudioMutation,
  useGetStudioDetailsLazyQuery,
  StudioExistsQuery,
  StudioExistsDocument,
  UserError,
} from 'codegen/graphql';
import ErrorCard from 'components/ErrorCard';
import { UserErrorsCard } from 'components/UserErrorsCard';
import { debounceAsync } from 'lib/debounceAsync';
import { kebabCase } from 'lib/kebabCase';
import { useSyncFields } from 'hooks/useSyncFields';
import { handleMutation } from 'lib/handleMutation';
import { gid, id } from 'lib/gid';
import { useState } from 'react';
import { usePermissions } from 'src/hooks/usePermissions';

type StudioFormPageProps = {
  /**
   * Model ID of the studio to edit. If not provided, a new studio is assumed.
   */
  id?: string;
};

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export type StudioFormType = {
  name: string;
  startDate: Date;
  endDate?: Date | null;
  photosQuotaLimit: string;
  studioHandle: string;
  locale: string;
  enhance: boolean;
};

export function StudioFormPage(props: StudioFormPageProps): React.ReactElement {
  const classes = useStyles();
  const studioId = props.id ? gid(props.id, 'Studio') : null;

  const { t } = useTranslation();
  const history = useHistory();
  const perms = usePermissions();

  const [getStudioDetails, { data: studioData }] = useGetStudioDetailsLazyQuery();

  const [createStudio, createStudioState] = useCreateStudioMutation({
    onCompleted: () => toast('Studio created', 'success'),
  });
  const [updateStudio, updateStudioState] = useUpdateStudioMutation({
    onCompleted: () => toast('Studio updated', 'success'),
  });

  const [deleteStudio, deleteStudioState] = useDeleteStudioMutation();

  const error = createStudioState.error || updateStudioState.error;
  const [userErrors, setUserErrors] = useState<UserError[]>([]);

  const apolloClient = useApolloClient();

  const debouncedStudioExistsByHandle = useMemo(
    () =>
      debounceAsync(async (handle: string) => {
        if (handle === studioData?.studio?.handle) return true;
        const exists = (
          await apolloClient.query<StudioExistsQuery>({
            query: StudioExistsDocument,
            variables: { handle },
          })
        ).data.studioExists;
        return !exists;
      }, 500),
    [apolloClient, studioData?.studio?.handle],
  );

  const {
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { dirtyFields, isValid, errors },
  } = useForm<StudioFormType>({
    resolver: zodResolver(
      z
        .object({
          name: z
            .string()
            .max(50)
            .refine((name) => name.trim().length, 'Must not be blank'),
          studioHandle: z
            .string()
            .max(50)
            .min(3)
            .refine((handle) => handle.trim().length, 'Handle must not be blank')
            .refine(
              (handle) => /^[a-z0-9]+(-[a-z0-9]+)*$/.test(handle),
              'Must be in slug format, i.e. "example-studio-1"',
            )
            .refine(
              (handle) => debouncedStudioExistsByHandle(handle),
              'This handle is already taken by another studio',
            ),
          startDate: z.date(),
          endDate: z.date().optional().nullable(),
          photosQuotaLimit: z
            .string()
            .regex(/^[0-9]*$/, 'Must be a whole number')
            .refine((name) => name.trim().length, 'Must not be blank'),
          locale: z.string(),
          enhance: z.boolean(),
        })
        .refine((form) => (form.endDate ? compareAsc(form.endDate, form.startDate) !== -1 : true), {
          message: 'Must be after start date',
          path: ['endDate'],
        }),
    ),
    defaultValues: {
      name: '',
      studioHandle: '',
      photosQuotaLimit: '',
      locale: 'en',
      enhance: true,
    },
    // revalidate on any change event, as we want to show a field error
    // immediately if the handle is invalid or non-unique
    mode: 'onChange',
  });

  // we prefer using an empty `handleSubmit` over `trigger` because `trigger`
  // doesn't revalidate errors after user input. yay...
  // https://github.com/react-hook-form/react-hook-form/issues/3425
  const validate = handleSubmit(() => {
    /* noop */
  });

  // request existing studio data if provided with studio id
  useEffect(() => {
    if (studioId) getStudioDetails({ variables: { id: studioId } });
  }, [studioId, getStudioDetails]);

  // set form values when receiving existing studio data
  useEffect(() => {
    if (!studioData || !studioData.studio) return;

    const { name, handle, startDate, endDate, photosQuotaLimit, locale, aiEnhancementEnabled } = studioData.studio;
    setValue('name', name);
    setValue('studioHandle', handle);
    setValue('startDate', new Date(startDate));
    setValue('locale', locale);
    setValue('enhance', aiEnhancementEnabled);
    if (endDate) setValue('endDate', new Date(endDate));
    if (photosQuotaLimit) setValue('photosQuotaLimit', photosQuotaLimit.toString());
  }, [studioData, setValue]);

  useSyncFields<StudioFormType>({
    watch,
    setValue,
    source: 'name',
    target: 'studioHandle',
    transform: kebabCase,
    on: !studioId,
  });

  const breadcrumbs: Breadcrumb[] = useMemo(() => {
    const crumbles = [
      {
        to: '/studios',
        body: t('glossary:studio_plural_capital'),
      },
    ];

    if (studioId) {
      crumbles.push(
        {
          to: `/studios/${id(studioId)}/settings`,
          body: studioData?.studio?.name || '...',
        },
        {
          to: `/studios/${id(studioId)}/edit`,
          body: t('edit_capital'),
        },
      );
    } else {
      crumbles.push({
        to: '/studios/new',
        body: t('new_capital'),
      });
    }

    return crumbles;
  }, [t, studioData, studioId]);

  const save = (
    <>
      {studioId && perms.auth?.canDeleteStudio && (
        <Button
          onClick={() => handleDelete(studioId)}
          variant="contained"
          startIcon={<Trash />}
          className={classes.deleteButton}
          disabled={deleteStudioState.loading}
        >
          {deleteStudioState.loading ? 'Deleting...' : 'Delete'}
        </Button>
      )}
      <Button
        color="primary"
        variant="contained"
        onClick={studioId ? () => handleUpdate(studioId) : handleCreate}
        startIcon={<Plus />}
        disabled={!!Object.entries(errors).length || createStudioState.loading || updateStudioState.loading}
      >
        {createStudioState.loading || updateStudioState.loading ? 'SAVING...' : 'SAVE'}
      </Button>
    </>
  );

  return (
    <Page
      title={studioId ? t('studiosRoute:editStudio') : t('studiosRoute:newStudio')}
      breadcrumbs={breadcrumbs}
      pageActions={save}
    >
      {error ? <ErrorCard body={error.message} /> : <StudioFormCard control={control} />}
      {!!userErrors.length && <UserErrorsCard errors={userErrors} />}
    </Page>
  );

  async function handleCreate() {
    await validate();
    if (!isValid) return;
    const formValues = getValues();
    await handleMutation({
      mutate: () =>
        createStudio({
          variables: {
            input: {
              name: formValues.name,
              handle: formValues.studioHandle,
              startDate: formValues.startDate.toISOString(),
              endDate: formValues.endDate?.toISOString() || null,
              photosQuotaLimit: parseInt(formValues.photosQuotaLimit),
              locale: formValues.locale,
              aiEnhancementEnabled: formValues.enhance,
            },
          },
        }),
      payloadFieldPath: 'createStudio?',
      successCondition: (data) => !!data.createStudio?.studio?.id,
      onSuccess: () => {
        history.push('/studios');
        toast('Studio created', 'success');
      },
      onError: (userErrors) => {
        toast('Could not create studio', 'error');
        setUserErrors(userErrors);
      },
    });
  }

  async function handleUpdate(studioId: string) {
    await validate();
    if (!isValid) return;
    const formValues = getValues();
    await handleMutation({
      mutate: () =>
        updateStudio({
          variables: {
            input: {
              id: studioId,
              name: formValues.name,
              handle: formValues.studioHandle,
              startDate: formValues.startDate.toISOString(),
              endDate: formValues.endDate?.toISOString() || null,
              photosQuotaLimit: parseInt(formValues.photosQuotaLimit),
              locale: formValues.locale,
              aiEnhancementEnabled: formValues.enhance,
            },
          },
        }),
      payloadFieldPath: 'updateStudio?',
      confirm: dirtyFields['studioHandle'] ? t('studiosRoute:handleChanged') : '',
      successCondition: (data) => !!data.updateStudio?.studio?.id,
      onSuccess: () => {
        setUserErrors([]);
        toast('Studio updated', 'success');
      },
      onError: (userErrors) => {
        toast('Could not update studio', 'error');
        setUserErrors(userErrors);
      },
    });
  }

  async function handleDelete(studioId: string) {
    await handleMutation({
      mutate: async () => await deleteStudio({ variables: { input: { id: studioId } } }),
      payloadFieldPath: 'deleteStudio?',
      confirm: 'Are you sure you want to delete this studio?',
      onSuccess: () => {
        history.push('/studios');
        toast('Studio deleted', 'success');
      },
      onError: (userErrors) => {
        toast('Could not delete studio', 'error');
        setUserErrors(userErrors);
      },
      successCondition: (data) => !!data.deleteStudio?.deletedId,
    });
  }
}
