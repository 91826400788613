type KeyIconProps = {
  color?: string;
};

export function KeyIcon({ color }: KeyIconProps): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 9.00011C24.0004 10.4059 23.6715 11.7922 23.0397 13.0479C22.4078 14.3037 21.4906 15.394 20.3615 16.2314C19.2325 17.0689 17.9229 17.6302 16.5378 17.8704C15.1527 18.1106 13.7306 18.023 12.3855 17.6146L12 18.0001L10.5 19.5001L9 21.0001H6V24.0001H0V18.0001L6.3855 11.6146C6.01117 10.3771 5.90785 9.07353 6.08256 7.79253C6.25728 6.51154 6.70593 5.28322 7.39799 4.19118C8.09004 3.09915 9.00925 2.16903 10.093 1.46414C11.1768 0.759253 12.3998 0.296139 13.6786 0.106321C14.9575 -0.0834966 16.2622 0.00443821 17.504 0.36414C18.7458 0.723843 19.8956 1.34687 20.875 2.19081C21.8544 3.03475 22.6405 4.0798 23.1797 5.25482C23.719 6.42985 23.9988 7.70726 24 9.00011ZM15 3.00011C14.6022 3.00011 14.2206 3.15815 13.9393 3.43945C13.658 3.72076 13.5 4.10229 13.5 4.50011C13.5 4.89794 13.658 5.27947 13.9393 5.56077C14.2206 5.84208 14.6022 6.00011 15 6.00011C15.7956 6.00011 16.5587 6.31618 17.1213 6.87879C17.6839 7.4414 18 8.20446 18 9.00011C18 9.39794 18.158 9.77947 18.4393 10.0608C18.7206 10.3421 19.1022 10.5001 19.5 10.5001C19.8978 10.5001 20.2794 10.3421 20.5607 10.0608C20.842 9.77947 21 9.39794 21 9.00011C21 7.40882 20.3679 5.88269 19.2426 4.75747C18.1174 3.63225 16.5913 3.00011 15 3.00011Z"
        fill={color || 'white'}
      />
    </svg>
  );
}
