import { Card, CardContent, Typography, makeStyles, Button } from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import { Aperture } from 'react-feather';
import { useTranslation } from 'react-i18next';

export type PreviewCardProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Original image to show.
   */
  originalUrl: string | null;
  /**
   * Preview image to show.
   */
  sampleUrl: string | null;
  /**
   * Whether the preview card should be in a loading state.
   */
  loading: boolean;
  /**
   * Callback to run when "generate preview" button is pressed.
   */
  onPreviewGenerate: () => void;
};

const useStyles = makeStyles(() => ({
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiButton-root': {
      '& .MuiTypography-button': {
        fontSize: '0.75rem',
      },
    },
  },
  withBottomSpacing: {
    marginBottom: 16,
  },
  previewImage: {
    width: '100%',
    maxWidth: 256,
    boxShadow: '2px 2px 4px rgba(0,0,0,0.05)',
    borderRadius: '4px',
    border: '1px solid #ddd',
    marginBottom: 16,
    animation: '$fadeInAnimation ease 0.5s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  },
  '@keyframes fadeInAnimation': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  heading: {
    marginBottom: 8,
  },
}));

export function PreviewCard(props: PreviewCardProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  const [sampleUrl, setSampleUrl] = useState(props.sampleUrl);
  const [originalUrl, setOriginalUrl] = useState(props.originalUrl);
  // props.loading is time it takes for GraphQL mutation to resolve, while
  // imageLoading is time it takes to fetch the actual image data from the
  // provided Cloudinary URL.
  const [originalImageLoading, setOriginalImageLoading] = useState(false);
  const [sampleImageLoading, setSampleImageLoading] = useState(false);

  // only update preview if new preview url is not null; prevents old preview
  // from disappearing while generating a new one
  useEffect(() => {
    // Loads both the original image and the sample preview. Note that we're adding
    // a timestamp to each image to avoid browser caching, or the `onLoad` event
    // won't fire and we're stuck in a never ending loading state
    if (props.originalUrl) {
      setOriginalUrl(`${props.originalUrl}?t=${new Date().getTime()}`);
      setOriginalImageLoading(true);
    }

    if (props.sampleUrl) {
      setSampleUrl(`${props.sampleUrl}?t=${new Date().getTime()}`);
      setSampleImageLoading(true);
    }
  }, [props.sampleUrl, props.originalUrl]);

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        {originalUrl && (
          <div style={{ opacity: props.loading || originalImageLoading ? '0.5' : '1' }}>
            <Typography className={classes.heading} variant="button" style={{ alignSelf: 'flex-start' }}>
              {t('original')}
            </Typography>
            <img
              src={originalUrl}
              alt="preview"
              className={classes.previewImage}
              onLoad={() => setOriginalImageLoading(false)}
            />
          </div>
        )}

        {sampleUrl && (
          <div style={{ opacity: props.loading || sampleImageLoading ? '0.5' : '1' }}>
            <Typography className={classes.heading} variant="button" style={{ alignSelf: 'flex-start' }}>
              {t('preview')}
            </Typography>
            <a href={sampleUrl} target="_blank" rel="noreferrer" style={{ display: 'inline-bock' }}>
              <img
                src={sampleUrl}
                alt="preview"
                className={classes.previewImage}
                onLoad={() => setSampleImageLoading(false)}
              />
            </a>
          </div>
        )}

        <Button variant="contained" startIcon={<Aperture />} disabled={props.loading} onClick={props.onPreviewGenerate}>
          <Typography variant="button">{t('generatePreview')}</Typography>
        </Button>
      </CardContent>
    </Card>
  );
}
