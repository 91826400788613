import Page from 'pages/Page';
import { useTranslation } from 'react-i18next';
import FiltersCard from 'components/FiltersCard';
import PageActionButton from 'src/components/PageActionButton';

export function Filters(): React.ReactElement {
  const { t } = useTranslation('glossary');

  return (
    <Page
      title={t('photoFilter_capital_plural')}
      pageActions={<PageActionButton to="/admin/filters/new">{t('filtersRoute:addFilter')}</PageActionButton>}
      breadcrumbs={[
        {
          body: 'Admin settings',
          to: '/admin',
        },
        {
          body: 'Photo filters',
          to: '/admin/filters',
        },
      ]}
    >
      <FiltersCard />
    </Page>
  );
}
