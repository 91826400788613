import { IconButton, makeStyles, Menu, Typography, Theme } from '@material-ui/core';
import { Menu as MenuIcon, MoreVert } from '@material-ui/icons';
import { useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

type ResourceListProps = {
  headings: string[];
  rows: React.ReactNode[][];
  renderMenuItems?: ({ rowIndex }: { rowIndex: number }) => React.ReactNode[];
  canReorder?: boolean;
  onReorder?: ({ from, to }: { from: number; to: number }) => void;
};

const useStyles = makeStyles<Theme, ResourceListProps>(() => ({
  ResourceList: {
    width: '100%',
    padding: '1rem',
  },
  ResourceListHeader: ({ canReorder }) => ({
    display: 'flex',
    paddingBottom: '1rem',
    paddingLeft: canReorder ? '4rem' : 0,
  }),
  ResourceListHeaderColumn: {
    padding: '0 1rem',
    marginRight: '1rem',
  },
  ResourceListBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  ResourceListItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '1rem',
  },
  ResourceListItemDragHandle: {
    marginRight: '1rem',
  },
  ResourceListItemColumns: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '3.5rem',
    border: '1px solid #E0E0E0',
    borderRadius: '0.6rem',
    padding: '0.1875rem 1rem',
    backgroundColor: '#FAFAFA',
  },
  ResourceListItemColumn: {
    flex: 1,
    paddingRight: '1rem',
  },
  ResourceListItemActionsColumn: {
    marginLeft: 'auto',
    flex: 0,
  },
}));

export function ResourceList(props: ResourceListProps): React.ReactElement {
  const { rows, headings, canReorder, onReorder, renderMenuItems } = props;
  const classes = useStyles(props);

  const [menuIndexShown, setMenuIndexShown] = useState<number | null>(null);
  const fieldMenuAnchor = useRef<HTMLElement>();

  const renderedRows = rows.map((columns, rowIndex) => renderRow(columns, rowIndex));

  return (
    <div className={classes.ResourceList}>
      <div className={classes.ResourceListHeader}>
        {headings.map((heading) => (
          <div key={heading} className={[classes.ResourceListItemColumn, classes.ResourceListHeaderColumn].join(' ')}>
            <Typography>{heading}</Typography>
          </div>
        ))}

        {renderMenuItems && (
          <div
            className={[classes.ResourceListItemColumn, classes.ResourceListItemActionsColumn].join(' ')}
            style={{ marginRight: '1rem' }}
          >
            <Typography>Actions</Typography>
          </div>
        )}
      </div>

      {canReorder ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="resource-list">
            {(provided) => (
              <div className={classes.ResourceListBody} ref={provided.innerRef} {...provided.droppableProps}>
                {renderedRows}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className={classes.ResourceListBody}>{renderedRows}</div>
      )}
    </div>
  );

  function handleDragEnd(result: DropResult) {
    if (!onReorder) {
      throw new Error('onReorder must be provided when allowing reorder');
    }

    if (!result.destination) return;

    onReorder({
      from: result.source.index,
      to: result.destination.index,
    });
  }

  function renderRow(columns: React.ReactNode[], rowIndex: number) {
    const menuItems = renderMenuItems?.({ rowIndex }) || [];

    const rowContents = (
      <div className={classes.ResourceListItemColumns}>
        {columns.map((column, index) => (
          <div key={index} className={classes.ResourceListItemColumn}>
            {column}
          </div>
        ))}

        {menuItems.length > 0 && (
          <div className={[classes.ResourceListItemColumn, classes.ResourceListItemActionsColumn].join(' ')}>
            <IconButton
              onClick={(e) => {
                setMenuIndexShown(rowIndex);
                fieldMenuAnchor.current = e.target as HTMLElement;
              }}
            >
              <MoreVert />
            </IconButton>

            <Menu
              anchorEl={fieldMenuAnchor.current}
              open={menuIndexShown == rowIndex}
              onClose={() => setMenuIndexShown(null)}
              MenuListProps={{
                onClick: () => setMenuIndexShown(null),
              }}
            >
              {menuItems}
            </Menu>
          </div>
        )}
      </div>
    );

    if (canReorder) {
      return (
        <Draggable
          key={`resource-list-draggable-${rowIndex}`}
          draggableId={`resource-list-draggable-${rowIndex}`}
          index={rowIndex}
        >
          {(provided) => (
            <div className={classes.ResourceListItem} ref={provided.innerRef} {...provided.draggableProps}>
              <IconButton className={classes.ResourceListItemDragHandle} {...provided.dragHandleProps}>
                <MenuIcon />
              </IconButton>
              {rowContents}
            </div>
          )}
        </Draggable>
      );
    }

    return <div className={classes.ResourceListItem}>{rowContents}</div>;
  }
}
