import { useMemo, useState, useCallback, useEffect } from 'react';
import { Tab, Tabs, Paper, makeStyles, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Plus } from 'react-feather';
import { useContactsPageQuery } from 'codegen/graphql';
import Page from 'pages/Page';
import LoadingPage from 'pages/LoadingPage';
import { ContactsDataGrid } from 'components/ContactsDataGrid';
import useDialog from 'hooks/useDialog';
import ContactListFormDialog from 'components/ContactListFormDialog';
import { usePermissions } from 'hooks/usePermissions';
import { id } from 'lib/gid';

export type ContactListsProps = {
  /**
   * Model (not global) ID of the contact list to display. If set to 'all', this
   * component will show all contacts.
   */
  id: string;
};

const useStyles = makeStyles(() => ({
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    borderBottom: '1px solid lightgrey',
  },
}));

export function ContactsPage(props: ContactListsProps): React.ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const perms = usePermissions();
  const contactsPageQuery = useContactsPageQuery();

  const { openDialog, dialogProps } = useDialog();
  const [tab, setTab] = useState(props.id || 'all');

  // do not directly call setTab, just push onto the history stack
  const handleTabChange = useCallback(
    (_, newTab: string) => {
      history.push(`/contacts/lists/${newTab}`);
    },
    [history],
  );

  // when props.selectedTab changes, call setTab()
  useEffect(() => {
    props.id && setTab(props.id);
  }, [props.id]);

  const contactListsTabs = useMemo(
    () =>
      contactsPageQuery.data?.contactLists?.map((contactList, index) => (
        <Tab value={contactList.id.split('/').pop()} label={contactList.name} key={index} />
      )) || [],
    [contactsPageQuery.data?.contactLists],
  );

  const contactList = useMemo(() => {
    return contactsPageQuery.data?.contactLists?.find((list) => {
      return id(list.id) == tab;
    });
  }, [tab, contactsPageQuery.data?.contactLists]);

  if (contactsPageQuery.loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Page
        title="Contact lists"
        maxWidth="lg"
        pageActions={
          perms.auth?.canAddContact && (
            <>
              <Button onClick={openDialog} startIcon={<Plus />}>
                ADD LIST
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push('/contacts/new', { selectedListId: props.id })}
              >
                ADD CONTACT
              </Button>
            </>
          )
        }
      >
        <Paper className={classes.paper}>
          <Tabs value={tab} onChange={handleTabChange} variant="scrollable" className={classes.tabs}>
            <Tab value="all" label="All contacts" />
            {contactListsTabs}
          </Tabs>
          <ContactsDataGrid contactList={contactList} />
        </Paper>
      </Page>
      <ContactListFormDialog {...dialogProps} />
    </>
  );
}
