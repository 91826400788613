import { makeStyles, TextField, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Search } from 'react-feather';
import { useRef } from 'react';

export type StudiosDataGridToolbarProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Callback that is called when a new search is performed.
   */
  onSearch: (search: string) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
}));

export function StudiosDataGridToolbar(props: StudiosDataGridToolbarProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={classes.root}>
      <TextField
        onChange={(e) => props.onSearch(e.target.value)}
        placeholder="Search studios"
        variant="outlined"
        fullWidth
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
