import { Key } from 'ts-key-enum';
import React, { useCallback } from 'react';

export function useKeyboardHandler(key: Key, cb: () => unknown) {
  return useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key != key) return;

      return cb();
    },
    [key, cb],
  );
}
