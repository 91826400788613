import { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Box,
  Checkbox,
  makeStyles,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { toast } from 'components/GlobalSnackbar';
import SettingsCard from 'src/components/SettingsCard';
import TextField from 'src/components/TextField';
import { NewTokenDialog } from './NewTokenDialog';
import { useGetAccessTokenScopesQuery, useCreateAccessTokenMutation, SnapbarAccount } from 'src/codegen/graphql';
import { useController, useForm } from 'react-hook-form';
import { UsableAccessToken } from './types';
import { AccountSearch } from 'components/AccountSearch';

type TokenInput = {
  snapbarAccount: SnapbarAccount | null;
  name: string;
};

type CreateTokenCardProps = {
  onTokenCreate: (token: UsableAccessToken) => void;
};

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'uppercase',
  },
  tokenDisplay: {
    display: 'flex',
    marginBottom: '1.5rem',
    border: '1px solid #B0BEC5',
    borderRadius: '1rem',
    '& > :first-child': {
      borderTopLeftRadius: 'inherit',
      borderBottomLeftRadius: 'inherit',
    },
    '& > :last-child': {
      borderTopRightRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
      borderLeft: '1px solid #B0BEC5',
    },
  },
  tokenLabel: {
    flex: 1,
    padding: '1rem',
    background: '#FAFAFA',
  },
  copyButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  autocomplete: {
    marginBottom: '1rem',
  },
  textField: {
    marginBottom: '1rem',
  },
  box: {
    marginTop: '1rem',
    marginLeft: 'auto',
  },
  alert: {
    border: '1px solid #FF9800',
  },
}));

export function CreateTokenCard({ onTokenCreate }: CreateTokenCardProps): React.ReactElement {
  const { data: scopesData } = useGetAccessTokenScopesQuery({
    variables: { applicationName: 'api_tokens' },
    onError: () => toast('Could not retrieve scopes', 'error'),
  });
  const [createAccessToken, { loading: createAccessTokenLoading, data: createAccessTokenData }] =
    useCreateAccessTokenMutation();

  const createdToken = createAccessTokenData?.createAccessToken?.accessToken;
  const scopes = scopesData?.accessTokenScopes || [];

  const [allowedScopes, setAllowedScopes] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    control,
    formState: { dirtyFields },
    getValues,
  } = useForm<TokenInput>({
    defaultValues: {
      snapbarAccount: null,
      name: '',
    },
  });

  const tokenValues = getValues();

  const accountController = useController({ control, name: 'snapbarAccount' });
  const tokenNameController = useController({ control, name: 'name' });
  const hasChanges = dirtyFields.snapbarAccount || dirtyFields.name || allowedScopes.length > 0;
  const isInvalid = !dirtyFields.snapbarAccount || !dirtyFields.name || allowedScopes.length == 0;
  const classes = useStyles();

  return (
    <SettingsCard title="Create new token">
      <Grid container spacing={8}>
        <Grid container item xs={6}>
          <Typography>
            You can generate a private API token for all kinds of great things, such as frying bacon or other wonderful
            things.
            <br />
            <br />
            The private API token is unique and generated upon creating the token. You can create a token by selecting
            an account, a name, and applicable scopes.
          </Typography>
        </Grid>
        <Grid container item xs={6}>
          <FormControl fullWidth>
            <AccountSearch snapbarAccount={tokenValues.snapbarAccount} onChange={accountController.field.onChange} />
            <TextField
              variant="outlined"
              label="Token name"
              fullWidth
              className={classes.textField}
              maxChars={30}
              controller={tokenNameController}
            />
            <FormLabel>Scopes and permissions</FormLabel>
            <FormGroup row>
              {scopes.map((scope) => (
                <FormControlLabel
                  key={scope}
                  label={<code>{formatScope(scope)}</code>}
                  control={
                    <Checkbox checked={allowedScopes.includes(scope)} onChange={() => handleScopeChange(scope)} />
                  }
                />
              ))}
            </FormGroup>
            <Box className={classes.box}>
              {hasChanges && (
                <Button className={classes.button} onClick={resetFields}>
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleCreate}
                disabled={isInvalid}
              >
                {createAccessTokenLoading ? 'Creating' : 'Create'}
              </Button>
            </Box>
            {createdToken?.token && (
              <NewTokenDialog
                open={modalOpen}
                onClose={() => {
                  setModalOpen(false);
                }}
                newToken={createdToken.token}
              />
            )}
          </FormControl>
        </Grid>
      </Grid>
    </SettingsCard>
  );

  function formatScope(scope: string) {
    return scope.split(':').slice(1).join('_');
  }

  function handleScopeChange(scope: string) {
    const newScopes = [...allowedScopes];
    if (newScopes.includes(scope)) {
      newScopes.splice(newScopes.indexOf(scope), 1);
    } else {
      newScopes.push(scope);
    }
    setAllowedScopes(newScopes);
  }

  function resetFields() {
    accountController.field.onChange(null);
    tokenNameController.field.onChange('');
    setAllowedScopes([]);
  }

  async function handleCreate() {
    if (!tokenValues?.snapbarAccount?.snapbarId) return;
    const orderedScopes = scopes.filter((scope) => allowedScopes.includes(scope));
    const scopesString = orderedScopes.join(',');

    const result = await createAccessToken({
      variables: {
        input: { snapbarId: tokenValues.snapbarAccount.snapbarId, name: tokenValues?.name, scopes: scopesString },
      },
    });

    if (!result.data?.createAccessToken?.accessToken) {
      toast('Token creation failed', 'error');
      return;
    }
    onTokenCreate(result.data.createAccessToken.accessToken);
    setModalOpen(true);
    toast('API token created', 'success');
    resetFields();
  }
}
