import { Paper, Snackbar, SnackbarProps } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';
import { useCallback, useState } from 'react';

type ToastOpts =
  | string
  | {
      message: string;
      action?: React.ReactNode;
      snackbarProps?: SnackbarProps;
    };

let toastCallback: ((opts: ToastOpts, severity: AlertProps['severity']) => void) | null = null;

export function toast(opts: ToastOpts, severity: AlertProps['severity'] = 'info'): void {
  toastCallback?.(opts, severity);
}

export function GlobalSnackbar(): React.ReactElement {
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({});

  const [open, setOpen] = useState<boolean>(false);
  const [action, setAction] = useState<React.ReactNode | null>(null);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertProps['severity']>('info');

  const close = useCallback(() => setOpen(false), []);
  toastCallback = useCallback((opts: ToastOpts, severity: AlertProps['severity'] = 'info') => {
    setOpen(true);
    setSeverity(severity);

    if (typeof opts === 'string') {
      setMessage(opts);
      setAction(null);
      setSnackbarProps({});
    } else {
      setMessage(opts.message);
      setAction(opts.action);
      // Always reset back to our defaults when not provided extra props
      setSnackbarProps(opts.snackbarProps || {});
    }
  }, []);

  return (
    <Snackbar
      open={open}
      onClose={close}
      action={action}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      {...snackbarProps}
    >
      <Paper elevation={3}>
        <Alert severity={severity} onClose={close}>
          {message}
        </Alert>
      </Paper>
    </Snackbar>
  );
}
