import { parseISO, format, formatDistanceStrict, compareAsc } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface HumanizedTime {
  /**
   * The translated phrase that precedes and describes a time or time interval,
   * e.g. "Starting on" or "Ended on".
   */
  description: string;
  /**
   * The translated phrase that represents a time or time interval, e.g. "Jan
   * 03" or "3 days left".
   */
  value: string;
}

/**
 * Hook that accepts a date range and returns a tuple useful humanized strings,
 * including the humanized start date, humanized end date, and humanized time
 * left until start/end (whichever comes first) respectively.
 *
 * @param startDate Start date as an ISO date string.
 * @param endDate End date as an ISO date string.
 * @returns Array of humanized values.
 */
export default function useHumanizedTimes(
  startDate: string,
  endDate?: string | null,
  opts?: Partial<{ fullDates: boolean }>,
): readonly [HumanizedTime, HumanizedTime | null, HumanizedTime] {
  const { t } = useTranslation();

  // serialize ISO date strings into Date objects
  const start = parseISO(startDate);
  const now = Date.now();
  const end = endDate ? parseISO(endDate) : null;

  const startInFuture: boolean = compareAsc(now, start) !== 1 ? true : false;
  const humanizedStart: HumanizedTime = {
    description: t('start', { context: startInFuture ? 'future' : 'past' }),
    // eslint-disable-next-line quotes
    value: format(start, "MMM d',' y"),
  };

  let humanizedEnd: HumanizedTime | null;
  let endInFuture: boolean | null;
  if (end) {
    endInFuture = compareAsc(now, end) !== 1 ? true : false;
    humanizedEnd = {
      description: t('end', { context: compareAsc(now, end) !== 1 ? 'future' : 'past' }),
      // eslint-disable-next-line quotes
      value: format(end, "MMM d',' y"),
    };
  } else {
    endInFuture = null;
    humanizedEnd = null;
  }

  let humanizedTimeLeft: HumanizedTime;
  if (startInFuture) {
    // start is in the future
    humanizedTimeLeft = {
      description: 'Starts',
      value: formatDistanceStrict(start, now).trim(),
    };
  } else if (end) {
    if (endInFuture) {
      // end exists and is in the future
      humanizedTimeLeft = {
        description: 'Ends in',
        value: formatDistanceStrict(end, now).trim(),
      };
    } else {
      // end exists and has past
      humanizedTimeLeft = {
        description: 'Ended',
        // appends 'ago', so the final output is 'Ended 4 days ago'.
        value: formatDistanceStrict(end, now, { addSuffix: true }).trim(),
      };
    }
  } else {
    // start is in the past, end does not exist
    humanizedTimeLeft = {
      description: 'Began',
      // appends 'ago', so the final output is 'Began 4 days ago'.
      value: formatDistanceStrict(start, now, { addSuffix: true }).trim(),
    };
  }

  return [humanizedStart, humanizedEnd, humanizedTimeLeft] as const;
}
