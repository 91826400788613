import './Scrollable.sass';
import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

export type ScrollableProps = {
  className?: string;
  maxHeight?: string;
  children?: React.ReactNode;
};

export function Scrollable(props: ScrollableProps): React.ReactElement {
  const { maxHeight = '100%', children } = props;

  const scrollableContainer = useRef<HTMLDivElement>(null);

  const [topShadowStrength, setTopShadowStrength] = useState(0);
  const [bottomShadowStrength, setBottomShadowStrength] = useState(0);

  const setShadowStrengths = useCallback(() => {
    const scrollable = scrollableContainer.current;
    if (!scrollable) return;

    const { scrollHeight, scrollTop, clientHeight } = scrollable;
    const maxScrollDistance = Math.abs(clientHeight - scrollHeight);

    if (maxScrollDistance === 0) {
      setTopShadowStrength(0);
      setBottomShadowStrength(0);
      return;
    }

    const distanceFromTop = scrollTop;
    const distanceFromBottom = scrollHeight - clientHeight - scrollTop;

    const topStrength = distanceFromTop / maxScrollDistance;
    const bottomStrength = distanceFromBottom / maxScrollDistance;

    setTopShadowStrength(topStrength);
    setBottomShadowStrength(bottomStrength);
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(setShadowStrengths);
    if (scrollableContainer.current) {
      observer.observe(scrollableContainer.current, { subtree: true, childList: true });
    }

    return observer.disconnect;
  }, [setShadowStrengths]);

  useEffect(() => {
    // The elements' bounds aren't going to be defined sometimes when the component mounts,
    // so we need to ensure everything has been painted properly. requestAnimationFrame seems to do the trick here.
    requestAnimationFrame(() => setShadowStrengths());
  }, [setShadowStrengths]);

  return (
    <div
      ref={scrollableContainer}
      className={clsx('Scrollable', props.className)}
      onScroll={setShadowStrengths}
      style={{ maxHeight }}
    >
      <div className="Scrollable__Shadow Shadow--top" style={{ opacity: topShadowStrength }} />
      <div className="Scrollable__Content">{children}</div>
      <div className="Scrollable__Shadow Shadow--bottom" style={{ opacity: bottomShadowStrength }} />
    </div>
  );
}
