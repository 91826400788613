import { useMemo, useState } from 'react';
import {
  Button,
  Typography,
  makeStyles,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Paper,
  IconButton,
} from '@material-ui/core';
import { SettingsCard, tableStyles } from 'components/SettingsCard';
import { BackgroundInput, CloudFileInput, StudioDetailsFragment, useUpdateBrandingMutation } from 'codegen/graphql';
import { useForm, useController } from 'react-hook-form';
import { handleMutation } from 'lib/handleMutation';
import ColorField from 'components/ColorField';
import Preview from 'components/Preview';
import useDialog from 'hooks/useDialog';
import PageActionButton from 'components/PageActionButton';
import FileField from 'components/FileField';
import Dialog from 'components/Dialog';
import { toast } from 'components/GlobalSnackbar';
import BackgroundField from 'src/components/BackgroundField';
import { LaptopMac, PhoneIphone, ColorLensOutlined } from '@material-ui/icons';
import { StudioEmbed } from 'components/StudioEmbed/StudioEmbed';

export type BrandingCardProps = {
  studio: StudioDetailsFragment;
};

const useStyles = makeStyles({
  ...tableStyles,
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 100,
    padding: 8,
    border: '1px solid grey',
    width: '100%',
  },
  brandingDialog: {
    '& .MuiDialogContent-root': {
      padding: 0,
      display: 'flex',
    },
  },
  brandingFormContainer: {
    display: 'flex',
    width: '100%',
    maxHeight: '100%',
  },
  brandingForm: {
    width: 300,
    flexShrink: 0,
    padding: '1.6rem',
    gap: '1.2rem',
    display: 'flex',
    maxHeight: '100%',
    flexDirection: 'column',
    overflowY: 'auto',
    '& > .MuiFormControl-root': {
      display: 'flex',
    },
  },
  brandingPreviewContainer: {
    width: '100%',
    backgroundColor: '#F5F5F5',
    padding: '1.8rem',
    height: 'calc(667px + 3.6rem)',
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  brandingPreviewIframe: {
    width: '100%',
    maxHeight: '100%',
    border: 0,
    boxShadow: '0 8px 12px -4px rgba(0, 0, 0, 0.25)',
    transition: 'width 200ms ease-out',
  },
  colorPreview: {
    display: 'inline-block',
    width: 16,
    height: 16,
    marginBottom: -3,
    marginRight: 4,
  },
});

export type BrandingFormType = {
  primaryColor: string | null;
  secondaryColor: string | null;
  tertiaryColor: string | null;
  background: BackgroundInput | null;
  logo: CloudFileInput | null;
};

export function BrandingCard(props: BrandingCardProps): React.ReactElement {
  const classes = useStyles(props);

  const defaultValues = useMemo(
    () => ({
      primaryColor: props.studio.branding.primaryColor,
      secondaryColor: props.studio.branding.secondaryColor,
      tertiaryColor: props.studio.branding.tertiaryColor,
      background:
        props.studio.branding.background == null
          ? null
          : 'hex' in props.studio.branding.background
          ? { color: props.studio.branding.background.hex }
          : { image: props.studio.branding.background },
      logo: props.studio.branding.logo,
    }),
    [props.studio],
  );

  const { control, getValues, reset } = useForm<BrandingFormType>({ defaultValues });

  const { open, closeDialog, openDialog } = useDialog();

  const primaryColorController = useController({ name: 'primaryColor', control });
  const secondaryColorController = useController({ name: 'secondaryColor', control });
  const tertiaryColorController = useController({ name: 'tertiaryColor', control });
  const backgroundController = useController({ name: 'background', control });
  const logoController = useController({ name: 'logo', control });

  const [updateBranding, updateBrandingState] = useUpdateBrandingMutation();

  const background = getValues('background');
  const studioPreviewData: StudioDetailsFragment = {
    ...props.studio,
    branding: {
      ...props.studio.branding,
      logo: getValues('logo'),
      primaryColor: getValues('primaryColor'),
      secondaryColor: getValues('secondaryColor'),
      tertiaryColor: getValues('tertiaryColor'),
      background: background?.image ? { __typename: 'CloudFile', ...background?.image } : { hex: background?.color },
    },
  } as any;

  const [previewSize, setPreviewSize] = useState<'desktop' | 'mobile'>('mobile');

  return (
    <SettingsCard
      title="Site branding"
      icon={<ColorLensOutlined />}
      cardActions={<Button onClick={openDialog}>EDIT</Button>}
      contentPadding={false}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography className={classes.labelColumn}>Background</Typography>
            </TableCell>
            {props.studio.branding.background?.__typename === 'CloudFile' ? (
              <TableCell>
                <Preview url={props.studio.branding.background.publicUrl} />
              </TableCell>
            ) : (
              <TableColorCell color={props.studio.branding.background?.hex} />
            )}
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography className={classes.labelColumn}>Logo</Typography>
            </TableCell>
            <TableCell>
              {props.studio.branding.logo?.publicUrl ? (
                <Preview url={props.studio.branding.logo.publicUrl} />
              ) : (
                <Typography className={classes.valueColumn}>none</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography className={classes.labelColumn}>Primary color</Typography>
            </TableCell>
            <TableColorCell color={props.studio.branding.primaryColor} />
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography className={classes.labelColumn}>Secondary color</Typography>
            </TableCell>
            <TableColorCell color={props.studio.branding.secondaryColor} />
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography className={classes.labelColumn}>Filled button text color</Typography>
            </TableCell>
            <TableColorCell color={props.studio.branding.tertiaryColor} />
          </TableRow>
        </TableBody>
      </Table>

      <Dialog
        className={classes.brandingDialog}
        open={open}
        onClose={closeDialog}
        title="Edit branding"
        width={1080}
        onExited={handleExited}
        actions={
          <>
            <Button onClick={closeDialog}>CANCEL</Button>
            <PageActionButton onClick={handleBrandingUpdate} loading={updateBrandingState.loading}>
              Save changes
            </PageActionButton>
          </>
        }
        titleActions={
          <>
            <IconButton
              onClick={() => setPreviewSize('mobile')}
              color={previewSize == 'mobile' ? 'primary' : 'default'}
            >
              <PhoneIphone />
            </IconButton>
            <IconButton
              onClick={() => setPreviewSize('desktop')}
              color={previewSize == 'desktop' ? 'primary' : 'default'}
            >
              <LaptopMac />
            </IconButton>
          </>
        }
      >
        <div className={classes.brandingFormContainer}>
          <div className={classes.brandingForm}>
            <BackgroundField
              label="Background"
              fileName="branding_background"
              controller={backgroundController}
              description={
                <>
                  Pick a color or upload an image.
                  <br />
                  Minimum image dimensions: 2000x2000px
                </>
              }
            />

            <FileField
              label="Logo"
              fileName="branding_logo"
              controller={logoController}
              description={
                <>
                  Minimum height: 60px.
                  <br />
                  Aspect ratio: 1:1 to 3:1 for best results
                </>
              }
            />

            <ColorField
              label="Primary color"
              controller={primaryColorController}
              description={<>Main headings and primary button color</>}
            />

            <ColorField
              label="Secondary color"
              controller={secondaryColorController}
              description={<>Subheadings and body text</>}
            />

            <ColorField
              label="Filled button text color"
              controller={tertiaryColorController}
              description={<>Primary button label</>}
            />
          </div>
          <div className={classes.brandingPreviewContainer}>
            <StudioEmbed
              className={classes.brandingPreviewIframe}
              style={{
                width: previewSize === 'mobile' ? '375px' : '100%',
              }}
              studio={studioPreviewData}
            />
          </div>
        </div>
      </Dialog>
    </SettingsCard>
  );

  function handleExited() {
    // when dialog closes, always reset to values provided by props.
    // this is different from the branding form dialog's current values if the
    // user clicks "cancel" or if the `updateBranding` request fails.
    reset(defaultValues);
  }

  async function handleBrandingUpdate() {
    const form = getValues();
    await handleMutation({
      mutate: () =>
        updateBranding({
          variables: {
            input: {
              id: props.studio.id,
              branding: form,
            },
          },
        }),
      payloadFieldPath: 'updateStudio?',
      successCondition: (data) => !!data?.updateStudio?.studio?.id,
      onSuccess: () => toast('Branding settings updated', 'success'),
      onError: () => toast('Could not update branding settings', 'error'),
    });
    closeDialog();
  }
}

type TableColorCellProps = {
  color?: string | null;
};

/**
 * Internal component rendering a table cell containing a color.
 */
function TableColorCell(props: TableColorCellProps) {
  const classes = useStyles();

  return (
    <TableCell style={{ whiteSpace: 'nowrap' }}>
      {props.color && <Paper className={classes.colorPreview} style={{ background: props.color }} />}
      <Typography component="span" className={classes.valueColumn}>
        {props.color || 'none'}
      </Typography>
    </TableCell>
  );
}
