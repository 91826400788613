import { Link } from 'react-router-dom';
import { makeStyles, AppBar, Button, IconButton, Toolbar, Typography } from '@material-ui/core';
import { ArrowLeft, Settings } from 'react-feather';
import { Menu as MenuIcon } from '@material-ui/icons';
import clsx from 'clsx';

import SnapbarStudioLogo from 'assets/SnapbarStudioLogo.svg';

import useBreakpoint from 'hooks/useBreakpoint';
import UserButton from 'components/UserButton';
import { AccountMenu } from 'components/AccountMenu';
import { usePermissions } from 'hooks/usePermissions';
import { ContactsSearchField } from 'components/ContactsSearchField/ContactsSearchField';
import { useGetCurrentAccountQuery } from 'codegen/graphql';
import { AppView } from 'layouts/AppWrapper';

const BRAND = '#233C5E';
const BRAND_DARK = '#102537';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: (props: AppWrapperBarProps) => (props.view === 'admin' ? theme.palette.grey[600] : BRAND),
    '& .MuiIconButton-root,.MuiButton-root': {
      color: 'white',
    },
  },
  appBreadcrumbs: {
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
  },
  appBarActions: {
    display: 'flex',
    marginRight: 8,
    // do not include extra kerning space
    fontSize: '0px',
  },
  bulletShaped: {
    marginLeft: -20,
    marginTop: -23,
    paddingLeft: 35,
    paddingRight: 25,
    borderTopRightRadius: 55,
    borderBottomRightRadius: 55,
    height: 110,
    display: 'flex',
    alignItems: 'center',
  },
  drawerButton: {
    backgroundColor: 'white',
    borderRight: '1px solid lightgrey',
    zIndex: 1,
    height: '100%',
    borderRadius: 0,
    marginRight: 20,
    color: BRAND,
    '&:hover': {
      backgroundColor: 'whitesmoke',
    },
    '& svg': {
      color: BRAND,
    },
  },
  headshots: {
    backgroundColor: (props: AppWrapperBarProps) => (props.view === 'admin' ? theme.palette.grey[800] : BRAND_DARK),
    lineHeight: '28px',
    color: 'white',
    textDecoration: 'none',
    zIndex: 3,
    textTransform: 'uppercase',
    letterSpacing: 1.5,
    '& > p': {
      fontSize: 15,
      fontWeight: '600 !important',
    },
  },
  searchMenu: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    '& .MuiInputBase-root': {
      color: 'rgba(36, 60, 94, 0.5)',
      '&.Mui-focused': {
        color: 'rgba(36, 60, 94, 1)',
      },
    },
    '& .MuiTextField-root': {
      background: 'rgba(255,255,255,.9)',
      padding: '2px 12px',
      borderRadius: '23px',
      zIndex: 101,
    },
    '& .MuiInput-underline': {
      '&:before, &:after': {
        display: 'none',
      },
    },
    '& .MuiInput-input::placeholder': {
      color: BRAND,
    },
  },
  accountMenu: {
    color: 'white',
    marginLeft: 12,
    zIndex: 2,
    '&.MuiButtonBase-root:hover': {
      backgroundColor: BRAND_DARK,
    },
    '& .MuiSvgIcon-root': {
      paddingLeft: 8,
      marginRight: -8,
      fontSize: '1.75rem',
    },
  },
  backButton: {
    marginLeft: 12,
    zIndex: 2,
  },
  spacer: {
    flexGrow: 1,
  },
}));

export type AppWrapperBarProps = {
  /**
   * Class name applied to the AppBar component.
   */
  className?: string;
  /**
   * Callback to execute when the drawer button on the top left is pressed on
   * narrow screen widths. This function, should obviously toggle the drawer.
   */
  toggleDrawer: () => void;
  /**
   * If set to 'admin', shows a grayed-out view.
   */
  view: AppView;
};

export function AppWrapperBar(props: AppWrapperBarProps): React.ReactElement {
  const classes = useStyles(props);
  const showDrawerButton = useBreakpoint();
  const perms = usePermissions();
  const { data: accountQueryResult } = useGetCurrentAccountQuery();

  return (
    <AppBar className={clsx('AppWrapperBar', classes.appBar, props.className)} position="sticky">
      <Toolbar disableGutters>
        <div className={classes.appBreadcrumbs}>
          {showDrawerButton && (
            <Button className={classes.drawerButton} onClick={props.toggleDrawer}>
              <MenuIcon />
            </Button>
          )}

          <Link to="/dashboard" className={clsx(classes.headshots, classes.bulletShaped)}>
            <img src={SnapbarStudioLogo} alt="logo" />
          </Link>
        </div>

        {props.view === 'admin' ? (
          <Button startIcon={<ArrowLeft />} component={Link} to="/" className={classes.backButton}>
            Back
          </Button>
        ) : (
          <AccountMenu className={classes.accountMenu} />
        )}

        <div className={classes.searchMenu}>
          {props.view === 'account' && accountQueryResult?.currentAccount && <ContactsSearchField />}
        </div>

        <div className={classes.appBarActions}>
          {perms.staff && (
            <IconButton component={Link} to="/admin">
              <Settings size={20} />
            </IconButton>
          )}
          <UserButton accountSelected={!!accountQueryResult?.currentAccount} />
        </div>
      </Toolbar>
    </AppBar>
  );
}
