import {
  makeStyles,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Typography,
  Switch,
} from '@material-ui/core';
import { UseControllerReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// Props type expected when storing just a single checkbox value.
type SingleCheckboxFieldProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Label of this `CheckboxField`.
   */
  label: string | JSX.Element;
  /**
   * `react-hook-form` controller. The single Checkbox field stores its value as
   * a boolean.
   */
  controller: UseControllerReturn<any, any>;
  description?: string | JSX.Element;
  multiple?: false | null;
  useSwitch?: boolean;
};

// Props type expected when storing multiple checkbox values.
type MultipleCheckboxFieldProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Checkbox items to render as children.
   */
  items: CheckboxItem[];
  /**
   * Label of this `CheckboxField`.
   */
  label: string | JSX.Element;
  /**
   * `react-hook-form` controller. The multiple Checkbox field stores its value
   * as a Record of `CheckboxItem` keys and booleans.
   */
  controller: UseControllerReturn<any, any>;
  description?: string | JSX.Element;
  multiple: true;
};

export type CheckboxItem = {
  /**
   * Label of this checkbox item.
   */
  label: string | JSX.Element;
  /**
   * Internal key of this checkbox item. This is a key in the Checkbox field
   * value object.
   */
  key: string;
};

export type CheckboxFieldProps = SingleCheckboxFieldProps | MultipleCheckboxFieldProps;

const useStyles = makeStyles(() => ({
  formGroup: {
    flexDirection: 'row',
  },
}));

export function CheckboxField(props: CheckboxFieldProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  let checkboxItemEls: JSX.Element[] | JSX.Element;
  const error = props.controller.fieldState.error;

  if (!props.multiple) {
    const value = props.controller.field.value as boolean;
    const onChange = props.controller.field.onChange as (value: boolean) => void;

    checkboxItemEls = (
      <FormControlLabel
        label={props.label}
        checked={value}
        onChange={(_, checked) => onChange(checked)}
        control={props.useSwitch ? <Switch /> : <Checkbox />}
      />
    );
  } else {
    const value = props.controller.field.value as Record<string, boolean>;
    const onChange = props.controller.field.onChange as (value: Record<string, boolean>) => void;

    checkboxItemEls = props.items.map((item, index) => (
      <FormControlLabel
        label={<Typography variant="body2">{item.label}</Typography>}
        checked={value[item.key]}
        onChange={(_, checked) => onChange({ ...value, [item.key]: checked })}
        key={index}
        control={<Checkbox />}
      />
    ));
  }

  return (
    <FormControl error={!!error} fullWidth className={clsx(props.className)}>
      {/* only show another form label if this field has multiple checkbox fields */}
      <FormLabel>{props.multiple && props.label}</FormLabel>
      <FormGroup className={classes.formGroup}>{checkboxItemEls}</FormGroup>
      {props.description && (
        <Typography variant="body2" style={{ opacity: 0.7 }}>
          {props.description}
        </Typography>
      )}
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}
