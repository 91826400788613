import { makeStyles, Chip } from '@material-ui/core';
import clsx from 'clsx';

import { useReviewChipQuery } from 'codegen/graphql';

export type ReviewChipProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
};

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'unset',
    marginLeft: 8,
  },
}));

export function ReviewChip(props: ReviewChipProps): JSX.Element | null {
  const classes = useStyles();
  const { data } = useReviewChipQuery();
  const count = data?.submissions?.totalCount;

  if (!count) return null;

  return <Chip className={classes.root} color="primary" label={count} size="small" />;
}
