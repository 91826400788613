import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Page from 'pages/Page';
import { APPBAR_HEIGHT, BODY_PADDING } from 'layouts/AppWrapper';

export type ResourcesPageProps = {
  // add props here
};

const useStyles = makeStyles(() => ({
  page: {},
  resourceWrapper: {
    display: 'block',
    width: `calc(100% + ${BODY_PADDING * 2}px)`,
    height: `calc(100vh - ${APPBAR_HEIGHT}px)`,
    border: 'none',
    margin: `-${BODY_PADDING}px`,
  },
}));

export function ResourcesPage(props: ResourcesPageProps): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();

  // Setting the page title here because we don't have a Page component (where we typically established the page title)
  document.title = 'Resources | Snapbar Studio';

  return (
    <iframe
      className={classes.resourceWrapper}
      title="Resources"
      src="https://www.snapbar.co/studio-app-resources"
    ></iframe>
  );
}
