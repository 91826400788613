import { makeStyles, Select, FormControl, FormHelperText, MenuItem, InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import type { UseControllerReturn } from 'react-hook-form';
import { useMemo } from 'react';

export type SelectFieldProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Items to render in this select field.
   */
  items: SelectItem[];
  /**
   * Label of this select field.
   */
  label: string;
  helperText?: string;
  /**
   * Variant of MUI Select field.
   */
  variant?: 'outlined' | 'standard' | 'filled';
  /**
   * `react-hook-form` Controller.
   */
  controller: UseControllerReturn<any, any>;
  /**
   * Whether to allow multiple items to be selected. In this case, `value` will
   * be an array.
   */
  multiple?: boolean;
  disabled?: boolean;
};

export type SelectItem = {
  /**
   * Internal value of the select field when this item is selected.
   */
  value: string;
  /**
   * Label of this item in the select field.
   */
  label: string;
  disabled?: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    // do not inline by default
    display: 'flex',
  },
}));

/**
 * Component that wraps the MUI `Select` component and provides sensible
 * defaults for usage with `react-hook-form`.
 */
export function SelectField(props: SelectFieldProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectItems = useMemo(
    () =>
      props.items.map(({ value, label, disabled }, index) => (
        <MenuItem value={value} key={index} disabled={disabled}>
          {label}
        </MenuItem>
      )),
    [props.items],
  );

  const error = !!props.controller.fieldState.error;
  const errorMessage = props.controller.fieldState.error?.message;

  return (
    <FormControl variant={props.variant} className={classes.root} error={error}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        variant={props.variant}
        label={props.label}
        displayEmpty
        {...props.controller.field}
        multiple={props.multiple}
        disabled={props.disabled}
      >
        {selectItems}
      </Select>
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
