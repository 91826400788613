import { useTranslation } from 'react-i18next';
import { makeStyles, Button } from '@material-ui/core';

import Page from 'pages/Page';
import PageActionButton from 'components/PageActionButton';
import TwoCardGrid from 'components/TwoCardGrid';
import FilterFormCard from 'components/FilterFormCard';
import PreviewCard from 'components/PreviewCard';
import {
  useCreatePhotoFilterMutation,
  useGetPhotoFilterLazyQuery,
  useUpdatePhotoFilterMutation,
  useDeletePhotoFilterMutation,
} from 'codegen/graphql';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { id, gid } from 'lib/gid';
import { UserErrorsCard } from 'components/UserErrorsCard';
import { toast } from 'components/GlobalSnackbar';
import { Disabled } from 'components/Disabled';
import { handleMutation } from 'src/lib/handleMutation';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

type FilterFormPageProps = {
  /**
   * Model ID of the filter to edit. If not provided, a new filter is assumed.
   */
  id?: string;
};

const filterFormSchema = z.object({
  name: z.string(),
  cloudinaryArtisticFilter: z.string().optional(),
  cloudinaryTransform: z.string().optional(),
});

export type FilterFormType = z.infer<typeof filterFormSchema>;

export function FilterFormPage(props: FilterFormPageProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  /**
   * GID of the filter being editted. If this value is `null`, then a new filter
   * is being created.
   */
  const filterGid = props.id ? gid(props.id, 'PhotoFilter') : null;

  const alertFilterCreated = useCallback(() => toast('Filter created', 'success'), []);
  const alertFilterUpdated = useCallback(() => toast('Filter updated', 'success'), []);

  const [getExistingFilter, { data: existingFilterData }] = useGetPhotoFilterLazyQuery();
  const [createPhotoFilter, { loading: createFilterLoading, error: createFilterError, data: createFilterData }] =
    useCreatePhotoFilterMutation({ onCompleted: alertFilterCreated });
  const [updatePhotoFilter, { loading: updateFilterLoading, error: updateFilterError, data: updateFilterData }] =
    useUpdatePhotoFilterMutation({ onCompleted: alertFilterUpdated });
  const [deletePhotoFilter, deletePhotoFilterState] = useDeletePhotoFilterMutation();

  const {
    control,
    handleSubmit: generateSubmitHandler,
    setValue,
  } = useForm<FilterFormType>({
    resolver: zodResolver(filterFormSchema),
    defaultValues: {
      name: '',
      cloudinaryArtisticFilter: '',
      cloudinaryTransform: '',
    },
  });

  // get existing filter on initial render only if updating an existing filter.
  useEffect(() => {
    if (filterGid) {
      getExistingFilter({ variables: { id: filterGid } });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!existingFilterData || !existingFilterData?.photoFilter) return;

    const { name, cloudinaryTransform, cloudinaryArtisticFilter } = existingFilterData.photoFilter;

    setValue('name', name);
    if (cloudinaryTransform) setValue('cloudinaryTransform', cloudinaryTransform);
    if (cloudinaryArtisticFilter) setValue('cloudinaryArtisticFilter', cloudinaryArtisticFilter);
  }, [existingFilterData, setValue]);

  const handlePhotoFilterSaveClick = useMemo(
    () =>
      generateSubmitHandler(async (data: FilterFormType) => {
        if (filterGid) {
          await updatePhotoFilter({ variables: { input: { ...data, id: filterGid } } });
        } else {
          await createPhotoFilter({ variables: { input: data } });
        }
      }),
    [generateSubmitHandler, updatePhotoFilter, createPhotoFilter, filterGid],
  );

  const errors = createFilterData?.createPhotoFilter?.errors || updateFilterData?.updatePhotoFilter?.errors || [];

  // if a new filter is created, return to the `/admin/filters` page.
  useEffect(() => {
    if (createFilterData?.createPhotoFilter?.photoFilter?.id) {
      history.push('/admin/filters');
    }
  }, [createFilterData, history]);

  return (
    <Page
      title={filterGid ? 'Update photo filter' : t('filtersRoute:newPhotoFilter')}
      breadcrumbs={[
        {
          body: 'Admin settings',
          to: '/admin',
        },
        {
          to: '/admin/filters',
          body: t('photoFilter_capital_plural'),
        },
        filterGid
          ? {
              to: `/admin/filters/${id(filterGid)}/edit`,
              body: 'Update',
            }
          : {
              to: '/admin/filters/new',
              body: t('new_capital'),
            },
      ]}
      pageActions={
        <>
          {filterGid && (
            <Button
              disabled={deletePhotoFilterState.loading}
              onClick={handleDelete}
              className={classes.deleteButton}
              variant="contained"
            >
              {deletePhotoFilterState.loading ? 'DELETING...' : 'DELETE'}
            </Button>
          )}
          <PageActionButton onClick={handlePhotoFilterSaveClick} loading={createFilterLoading || updateFilterLoading}>
            Save
          </PageActionButton>
        </>
      }
    >
      <TwoCardGrid>
        <>
          {errors.length > 0 && <UserErrorsCard errors={errors} />}

          <FilterFormCard formControl={control} />
        </>

        <Disabled>
          <PreviewCard
            originalUrl={null}
            sampleUrl={null}
            onPreviewGenerate={() => {
              /* noop */
            }}
            loading={false}
          />
        </Disabled>
      </TwoCardGrid>
    </Page>
  );

  async function handleDelete() {
    if (!filterGid) return;

    await handleMutation({
      mutate: () => deletePhotoFilter({ variables: { input: { id: filterGid } } }),
      payloadFieldPath: 'deletePhotoFilter?',
      confirm: 'Are you sure you want to delete this photo filter?',
      successCondition: (data) => data.deletePhotoFilter?.deletedId === filterGid,
      onSuccess: () => {
        history.push('/admin/filters');
        toast('Photo filter deleted', 'success');
      },
      onError: () => {
        toast('Could not delete photo filter', 'error');
      },
    });
  }
}
