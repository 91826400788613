import { ELEMENT_LINK, LinkElement, LinkNodeData, PlateRenderElementProps, TDescendant } from '@udecode/plate';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Popover, TextField, IconButton, makeStyles } from '@material-ui/core';
import { Link, LinkOff } from '@material-ui/icons';
import { ElementInterface, Transforms } from 'slate';
import { NEW_LINK_INITIAL_URL } from 'components/RichTextEditor';
import { useKeyboardHandler } from 'hooks/useKeyboardHandler';
import { Key } from 'ts-key-enum';

type LinkElementWithPopoverProps = PlateRenderElementProps<LinkNodeData> & {
  rerender: () => void;
};

const useStyles = makeStyles({
  popover: {
    '& > .MuiPaper-root': {
      backgroundColor: '#E9F5FE',
    },
  },
  popoverContent: {
    maxWidth: '500px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.5rem',
    overflow: 'hidden',
    '& > *': {
      margin: '0.25rem',
    },
  },
  autoWidth: {
    height: 0,
    opacity: 0,
    fontSize: '1rem',
    display: 'block',
  },
});

export function LinkElementWithPopover(props: LinkElementWithPopoverProps) {
  const classNames = useStyles();

  const linkWasJustCreated = props.element.url == NEW_LINK_INITIAL_URL;

  const linkElementRef = useRef<HTMLSpanElement>(null);
  const textFieldRef = useRef<HTMLDivElement>(null);

  const [showPopover, setShowPopover] = useState(linkWasJustCreated);
  const [url, setUrl] = useState(props.element.url);
  const [focusInput, setFocusInput] = useState(false);

  const togglePopoverOnEnterPress = useKeyboardHandler(
    Key.Enter,
    useCallback(() => {
      setShowPopover((current) => !current);
    }, []),
  );

  useEffect(() => {
    if (!linkWasJustCreated) return;

    setLink('https://');
    setFocusInput(true);
  }, []);

  useEffect(() => {
    if (!focusInput) return;

    setTimeout(() => {
      const $input = textFieldRef.current?.querySelector('input');
      if (!$input) return;

      $input.focus();
    });
  }, [focusInput]);

  return (
    <>
      <span
        role="button"
        tabIndex={0}
        ref={linkElementRef}
        onClick={() => setShowPopover(true)}
        onKeyUp={togglePopoverOnEnterPress}
      >
        <LinkElement {...props} />
      </span>
      {linkElementRef.current && (
        <Popover
          className={classNames.popover}
          open={showPopover}
          onClose={() => setShowPopover(false)}
          anchorEl={linkElementRef.current}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <div className={classNames.popoverContent}>
            <TextField
              ref={textFieldRef}
              value={url}
              onChange={(e) => setLink(e.target.value)}
              onKeyUp={togglePopoverOnEnterPress}
              placeholder='i.e. "https://google.com/'
              /* Output the value in the helperText so that we can grow the container based on the width of the value */
              helperText={<span className={classNames.autoWidth}>{url}</span>}
            />
            <IconButton size="small" onClick={disableLink}>
              <LinkOff />
            </IconButton>
          </div>
        </Popover>
      )}
    </>
  );

  function disableLink() {
    Transforms.unwrapNodes(props.editor, { match: (node: TDescendant) => node.type === ELEMENT_LINK });
  }

  function setLink(url: string) {
    // This is a lame way to do this. However, I was unable to update the element through other means,
    // i.e. Transforms.setNodes, upsertLinkAtSelection, etc. This seems to work.
    setUrl(url);
    props.element.url = url;
    props.rerender();
  }
}
