import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Page } from '../Page/Page';
import { PageActionButton } from 'components/PageActionButton/PageActionButton';
import { ProfilesCard } from 'components/ProfilesCard/ProfilesCard';

export type ProfilesPageProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
};

const useStyles = makeStyles(() => ({
  root: {
    // add styles here
  },
}));

export function ProfilesPage(props: ProfilesPageProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation('glossary');

  return (
    <Page
      title={t('pose_profile_capital_plural')}
      pageActions={<PageActionButton to="/admin/profiles/new">{t('profilesRoute:addProfile')}</PageActionButton>}
      breadcrumbs={[
        {
          body: 'Admin settings',
          to: '/admin',
        },
        {
          body: t('pose_profile_capital_plural'),
          to: '/admin/profiles',
        },
      ]}
    >
      <ProfilesCard />
    </Page>
  );
}
