import { makeStyles, TextField as MuiTextField } from '@material-ui/core';
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab';
import { ChangeEvent, useState } from 'react';
import { SnapbarAccount, useAccountMenuQuery } from 'codegen/graphql';
import { debounce } from 'lodash-es';

export type AccountSearchProps = {
  snapbarAccount: SnapbarAccount | null;

  onChange: (value: SnapbarAccount | null) => void;
};

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    marginBottom: '1rem',
  },
}));

export function AccountSearch(props: AccountSearchProps): React.ReactElement {
  const { snapbarAccount, onChange } = props;

  const classes = useStyles();
  const [accountNameQuery, setAccountNameQuery] = useState('');
  const { error: accountsError, data: accountsData } = useAccountMenuQuery({ variables: { query: accountNameQuery } });
  if (accountsError) throw new Error('Could not retrieve Snapbar accounts');

  const accounts = accountsData?.searchSnapbarAccounts || [];

  const debouncedSearch = debounce(search, 500);

  return (
    <Autocomplete
      className={classes.autocomplete}
      value={snapbarAccount}
      clearOnBlur={false}
      options={accounts}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.snapbarId === value.snapbarId}
      renderInput={(params) => <MuiTextField {...params} variant="outlined" label="Search for account" />}
      onInputChange={(event, value) => debouncedSearch(value)}
      onChange={(event, value) => onChange(value)}
    />
  );

  function search(newSearchQuery: string) {
    setAccountNameQuery(newSearchQuery);
  }
}
