import { useTranslation } from 'react-i18next';
import { useGetStudioOverviewsQuery } from 'codegen/graphql';
import Page from 'pages/Page';
import { StudioOverviewCard } from 'components/StudioOverviewCard';
import { StudiosNeededCard } from 'components/StudiosNeededCard';
import LoadingCard from 'components/LoadingCard';
import PageActionButton from 'components/PageActionButton';
import { usePermissions } from 'hooks/usePermissions';
import { NoStudiosNotice } from 'components/NoStudiosNotice';
import { Button, ListItemIcon, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import { useMenu } from 'src/hooks/useMenu';
import { useRef } from 'react';
import useDialog from 'src/hooks/useDialog';
import { List, Send } from 'react-feather';
import { InviteContactListDialog } from 'src/components/InviteContactListDialog';
import { InviteContactsDialog } from 'src/components/InviteContactsDialog';
import { Link } from 'react-router-dom';
import { id } from 'src/lib/gid';

const useStyles = makeStyles(() => ({
  manageButton: {
    padding: '6px 16px',
    marginRight: '1rem',
    fontSize: 15,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
}));

export function StudiosPage(): JSX.Element | null {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, error, data } = useGetStudioOverviewsQuery();
  const perms = usePermissions();

  const newStudio = <PageActionButton to="/studios/new">New studio</PageActionButton>;

  if (loading || perms.loading) {
    return (
      <Page title="Studios">
        <LoadingCard />
      </Page>
    );
  }

  if (error || perms.error || !data || !perms.auth) {
    console.error('Could not load studios.');
    return null;
  }

  const studios = data.studios?.edges.map((edge) => edge.node) ?? [];

  return (
    <Page
      title={studios.length ? 'Studios' : ''}
      pageActions={!!studios.length && perms.auth.canAddStudio && newStudio}
    >
      {studios.length ? (
        <>
          {studios.map((studio, index) => (
            <StudioOverviewCard
              studio={studio}
              key={index}
              actions={
                <>
                  {perms.auth?.canChangeStudio && (
                    <Button
                      className={classes.manageButton}
                      variant="outlined"
                      style={{ textTransform: 'uppercase' }}
                      component={Link}
                      to={`/studios/${id(studio.id)}/settings`}
                    >
                      {t('glossary:manage')}
                    </Button>
                  )}
                  <InviteButton studioId={studio.id} />
                </>
              }
            />
          ))}
          {!perms.auth.canAddStudio && <StudiosNeededCard />}
        </>
      ) : (
        <NoStudiosNotice />
      )}
    </Page>
  );
}
type InviteButtonProps = { studioId: string };

function InviteButton({ studioId }: InviteButtonProps) {
  const inviteButtonRef = useRef<HTMLButtonElement>(null);
  const {
    openMenu: openInviteMenu,
    closeMenu: closeInviteMenu,
    menuProps: inviteMenuProps,
  } = useMenu({
    anchorEl: () => inviteButtonRef.current as Element,
    anchorOrigin: {
      horizontal: 'right',
      vertical: 'bottom',
    },
    transformOrigin: {
      horizontal: 'right',
      vertical: 'top',
    },
  });

  const { openDialog: openInviteContactListDialog, dialogProps: inviteContactListDialogProps } = useDialog();
  const { openDialog: openInviteContactsDialog, dialogProps: inviteContactsDialogProps } = useDialog();

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        style={{ textTransform: 'uppercase' }}
        onClick={openInviteMenu}
        ref={inviteButtonRef}
      >
        Invite
      </Button>
      <Menu {...inviteMenuProps}>
        <MenuItem onClick={handleContactListInviteIntent}>
          <ListItemIcon>
            <List />
          </ListItemIcon>
          <Typography variant="overline">Invite contact list</Typography>
        </MenuItem>
        <MenuItem onClick={handleContactsInviteIntent}>
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          <Typography variant="overline">Invite contacts</Typography>
        </MenuItem>
      </Menu>
      <InviteContactListDialog {...inviteContactListDialogProps} studioId={studioId} />
      <InviteContactsDialog {...inviteContactsDialogProps} studioId={studioId} />
    </>
  );

  function handleContactListInviteIntent() {
    openInviteContactListDialog();
    closeInviteMenu();
  }

  function handleContactsInviteIntent() {
    openInviteContactsDialog();
    closeInviteMenu();
  }
}
