import { Typography, Table, TableBody, TableRow, TableCell, makeStyles, Button, Tooltip } from '@material-ui/core';

import { SettingsCard, tableStyles } from 'components/SettingsCard';
import Preview from 'components/Preview';
import { PoseDetailsFragment, useDeletePoseMutation } from 'codegen/graphql';
import { handleMutation } from 'src/lib/handleMutation';
import { toast } from 'components/GlobalSnackbar';
import { RichTextPreview } from 'components/RichTextPreview';
import { usePermissions } from 'hooks/usePermissions';

export type PoseCardProps = {
  pose: PoseDetailsFragment;
  studioId: string;
  onEdit: (pose: PoseDetailsFragment) => unknown;
  disableDelete?: boolean;
};

const useStyles = makeStyles((theme) => ({
  ...tableStyles,
  previewsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 16,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  richTextPreviewContainer: {
    borderRadius: 16,
  },
}));

export function PoseCard(props: PoseCardProps): React.ReactElement {
  const pose = props.pose;
  const classes = useStyles();
  const perms = usePermissions();
  const [deletePose] = useDeletePoseMutation({ variables: { input: { id: pose.id, studioId: props.studioId } } });

  const outlineImageUrl = pose.profile?.outlineImage?.publicUrl;
  const exampleImageUrls = pose.profile?.exampleImages?.map((image) => image.publicUrl) || [];

  return (
    <SettingsCard title="Details">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography className={classes.labelColumn}>Profile</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.valueColumn}>
                {pose.profile?.id ? pose.profile?.name : 'Custom'}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography className={classes.labelColumn}>Outline image</Typography>
            </TableCell>
            <TableCell>
              <Preview url={outlineImageUrl} />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography className={classes.labelColumn}>Example images</Typography>
            </TableCell>
            <TableCell>
              <div className={classes.previewsWrapper}>
                {exampleImageUrls.map((url, index) => (
                  <Preview url={url} key={index} />
                ))}
              </div>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography className={classes.labelColumn}>Guidelines</Typography>
            </TableCell>
            <TableCell>
              <RichTextPreview
                classes={{ container: classes.richTextPreviewContainer }}
                content={props.pose.guidelines}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography className={classes.labelColumn}>Photo upload</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.valueColumn}>{pose.allowPhotoUpload ? 'Enabled' : 'Disabled'}</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography className={classes.labelColumn}>Remove background</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.valueColumn}>{pose.removeBackground ? 'Enabled' : 'Disabled'}</Typography>
            </TableCell>
          </TableRow>

          {pose.removeBackground && (
            <TableRow>
              <TableCell>
                <Typography className={classes.labelColumn}>Backdrops</Typography>
              </TableCell>
              <TableCell>
                <div className={classes.previewsWrapper}>
                  {pose.backdrops?.length ? (
                    pose.backdrops.map((backdrop, index) => (
                      <Preview url={backdrop.__typename === 'Color' ? backdrop.hex : backdrop.publicUrl} key={index} />
                    ))
                  ) : (
                    <Typography className={classes.valueColumn}>None</Typography>
                  )}
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Button variant="contained" onClick={() => props.onEdit(props.pose)} style={{ marginRight: 8 }}>
        Edit
      </Button>
      {perms.staff && (
        <Tooltip title={props.disableDelete ? 'Cannot delete the last pose in a studio.' : ''}>
          <span>
            <Button
              variant="contained"
              onClick={handleDelete}
              disabled={props.disableDelete}
              className={classes.deleteButton}
            >
              Delete
            </Button>
          </span>
        </Tooltip>
      )}
    </SettingsCard>
  );

  async function handleDelete() {
    await handleMutation({
      mutate: deletePose,
      payloadFieldPath: 'deletePose?',
      confirm: 'Are you sure you want to delete this pose?',
      successCondition: (data) => data.deletePose?.deletedId === props.pose.id,
      onSuccess: () => {
        toast('Pose deleted', 'success');
      },
      onError: () => {
        toast('Could not delete pose', 'error');
      },
    });
  }
}
