import { useCallback, useState } from "react";

import { AccessTypeEnum, useGetCurrentAccountQuery, usePresignCloudinaryFileMutation } from "codegen/graphql";
import { CloudinaryImage, uploadSignedImage } from "lib/uploadSignedImage";

/**
 * An amazingly useful hook for getting a presigned Cloudinary upload URL and
 * uploading a file, all in one go! The return type is similar to that of an
 * Apollo client `useMutation` hook, being a tuple of an upload callback and
 * an upload state primitive object.
 *
 * The upload callback accepts a `File`, usually obtainable from a
 * `FileUploadButton`. The upload callback can throw an exception, so be sure to
 * catch it.
 *
 * @example
 * const [upload, { loading, data }] = useCloudinaryUpload('cute_doge');
 * try {
 *   upload(inputRef.current.files[0])
 * } catch (e) {
 *    console.error(e)
 * }
 *
 * @param fileName Name of the file.
 */
export default function useCloudinaryUpload(fileName: string) {
  const accountId = useGetCurrentAccountQuery()?.data?.currentAccount?.id;
  const [uploadLoading, setUploadLoading] = useState(false);
  const [cloudImage, setCloudImage] = useState<CloudinaryImage>();
  const [presignCloudinaryFile] = usePresignCloudinaryFileMutation();

  const upload = useCallback(
    async (image: File) => {
      if (!accountId) {
        throw new Error('Could not retrieve current account ID.');
      }
      setUploadLoading(true);
      const { data, errors } = await presignCloudinaryFile({
        variables: {
          filename: fileName,
          accountId,
          accessType: AccessTypeEnum.Upload,
        },
      });
      if (errors) {
        console.error(errors);
        throw new Error('GraphQL error. See above.');
      }
      const presignature = data?.presignCloudinaryFile;
      if (!presignature) {
        throw new Error('Could not obtain presignature from backend.');
      }
      const cloudImage = await uploadSignedImage(presignature, image, fileName);
      setCloudImage(cloudImage);
      setUploadLoading(false);
      return cloudImage;
    },
    [accountId, fileName, presignCloudinaryFile],
  );

  return [upload, { loading: uploadLoading, data: cloudImage }] as const;
}
