import { useCallback, useMemo, useRef, useState } from 'react';
import { FormControl, FormLabel, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import type { UseControllerReturn } from 'react-hook-form';
import { DebouncedColorBox } from 'components/DebouncedColorBox/DebouncedColorBox';

import EditablePreview from 'components/EditablePreview';
import useCloudinaryUpload from 'hooks/useCloudinaryUpload';
import { BackgroundInput, CloudFileInput, CloudProvider } from 'codegen/graphql';
import { deleteAt, insertAt } from 'lib/array';

import FileOrColorSelectButton from 'components/FileOrColorSelectButton';
import { FieldDescription } from 'components/FieldDescription/FieldDescription';

export type ColorInput = {
  hex: string;
};

export type BackgroundFieldProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Filename of the file being uploaded.
   */
  fileName: string;
  /**
   * Label for this cloud file field.
   */
  label: string;
  /**
   * A brief description displayed under the label.
   */
  description?: React.ReactNode;
  /**
   * Whether to allow multiple backgrounds to be stored as values.
   */
  multiple?: boolean;
  /**
   * `react-hook-form` controller. Stores value internally as a `BackgroundInput`,
   * or as a `BackgroundInput[]` if `props.multiple === true`.
   */
  controller: UseControllerReturn<any, any>;
};

const useStyles = makeStyles(() => ({
  previewWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    minHeight: 100,
    width: '100%',
    gap: 16,
    marginTop: 6,
  },
  preview: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    maxWidth: 100,
    maxHeight: 100,
    '& > img': {
      maxHeight: 100,
      maxWidth: 100,
      borderRadius: 16,
    },
  },
}));

/**
 * A controlled form field that allows users to upload one or more
 * `BackgroundInput`s (which can be a color or a file), and shows a preview of
 * each.
 */
export function BackgroundField(props: BackgroundFieldProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);
  const [cloudinaryUpload] = useCloudinaryUpload(props.fileName);

  const multiple = !!props.multiple;

  const value = props.controller.field.value as BackgroundInput | BackgroundInput[] | null;
  // the `onChange` callback provided by a `react-hook-form` controller is not
  // referentially stable. see
  // https://github.com/react-hook-form/react-hook-form/pull/5113.
  // eslint-disable-next-line
  const onChange = useMemo(() => props.controller.field.onChange, []) as (
    value: BackgroundInput | BackgroundInput[] | null,
  ) => unknown;

  // this internally normalizes `value` as a `CloudFileInput[]` instead of
  // `CloudFileInput | CloudFileInput[] | null`.
  const valueAsArray: BackgroundInput[] = useMemo(() => {
    if (value instanceof Array) {
      return value;
    } else if (value) {
      return [value];
    } else {
      return [];
    }
  }, [value]);

  const handleFileSelect = useCallback(
    async (file: File, index?: number) => {
      try {
        const cloudImage = await cloudinaryUpload(file);
        const newCloudFile: CloudFileInput = {
          bytes: `${cloudImage.bytes}`,
          provider: CloudProvider.Cloudinary,
          providerId: cloudImage.public_id,
          publicUrl: cloudImage.secure_url,
          type: cloudImage.resource_type,
        };
        const newBackgroundInput: BackgroundInput = { color: null, image: newCloudFile };

        if (!multiple) {
          onChange(newBackgroundInput);
        } else {
          if (typeof index === 'number') {
            // called when editing an existing background
            onChange([...valueAsArray.slice(0, index), newBackgroundInput, ...valueAsArray.slice(index + 1)]);
          } else {
            onChange([...valueAsArray, newBackgroundInput]);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    [cloudinaryUpload, multiple, onChange, valueAsArray],
  );

  const handleColorSelect = useCallback(
    (color: ColorInput, index?: number) => {
      const newBackgroundInput: BackgroundInput = { image: null, color: color.hex };

      if (multiple) {
        if (typeof index === 'number') {
          onChange(insertAt(valueAsArray, newBackgroundInput, index));
        } else {
          onChange([...valueAsArray, newBackgroundInput]);
        }
      } else {
        onChange(newBackgroundInput);
      }
    },
    [multiple, onChange, valueAsArray],
  );

  const handleFileOrColorSelect = useCallback(
    async (fileOrColor: File | ColorInput) => {
      if ('hex' in fileOrColor) {
        handleColorSelect(fileOrColor);
      } else {
        await handleFileSelect(fileOrColor);
      }
    },
    [handleColorSelect, handleFileSelect],
  );

  const fileEditIndex = useRef<number>(0);
  const fileEditInputRef = useRef<HTMLInputElement>(null);
  const [fileEditUploading, setFileEditUploading] = useState(false);

  // callback executed when the edit button is clicked on a file background.
  const handleFileEdit = useCallback((index: number) => {
    fileEditIndex.current = index;
    fileEditInputRef.current?.click();
  }, []);

  // callback executed when the input ref receives an uploaded file.
  const handleFileEditSelect = useCallback(async () => {
    const newFile = fileEditInputRef.current?.files?.[0];
    if (!newFile) return;
    setFileEditUploading(true);
    // delegate rest of upload stuff to `handleFileSelect`
    await handleFileSelect(newFile, fileEditIndex.current);
    setFileEditUploading(false);
  }, [handleFileSelect]);

  const handleBackgroundDelete = useCallback(
    (index: number) => {
      if (multiple) {
        onChange(deleteAt(valueAsArray, index));
      } else {
        onChange(null);
      }
    },
    [multiple, onChange, valueAsArray],
  );

  const previewImages = useMemo(() => {
    return valueAsArray.map((colorOrImage, index) => {
      return colorOrImage.color ? (
        <EditablePreview
          url={colorOrImage.color}
          editMenuContent={() => {
            return (
              <DebouncedColorBox
                colorBoxProps={{
                  disableAlpha: true,
                }}
                value={colorOrImage.color || null}
                onChange={(color) => handleColorSelect({ hex: `#${color.hex}` }, index)}
              />
            );
          }}
          onDelete={() => handleBackgroundDelete(index)}
          key={index}
        />
      ) : (
        <EditablePreview
          url={colorOrImage.image?.publicUrl}
          loading={index === fileEditIndex.current && fileEditUploading}
          onEdit={() => handleFileEdit(index)}
          onDelete={() => handleBackgroundDelete(index)}
          key={index}
        />
      );
    });
  }, [fileEditUploading, handleBackgroundDelete, handleColorSelect, handleFileEdit, valueAsArray]);

  return (
    <FormControl
      className={props.className}
      focused={focused}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <FormLabel>{props.label}</FormLabel>
      <input type="file" accept="image/*" hidden ref={fileEditInputRef} onChange={handleFileEditSelect} />

      {props.description && <FieldDescription>{props.description}</FieldDescription>}

      <div className={classes.previewWrapper}>
        {previewImages}
        {(previewImages.length == 0 || multiple) && (
          <FileOrColorSelectButton colorBoxProps={{ disableAlpha: true }} onChange={handleFileOrColorSelect} />
        )}
      </div>
    </FormControl>
  );
}
