import { makeStyles, Box, Typography } from '@material-ui/core';

type CharCounterProps = {
  currentChars: number;
  maxChars: number;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '4rem',
  },
}));

export function CharCounter({ currentChars, maxChars }: CharCounterProps): React.ReactElement {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography>
        {currentChars > 0 ? currentChars : 0}/{maxChars}
      </Typography>
    </Box>
  );
}
