import { PopoverOrigin } from '@material-ui/core';
import { useState, useCallback } from 'react';

type UseMenuProps = {
  /**
   * Function that returns an Element for this menu to anchor on.
   */
  anchorEl: () => Element;
  /**
   * Where the anchor should connect to the menu. See MUI docs for more info.
   */
  anchorOrigin: PopoverOrigin;
  /**
   * Where the menu should connect to the anchor. See MUI docs for more info.
   */
  transformOrigin: PopoverOrigin;
};

type UseMenuReturn = {
  /**
   * Whether this menu is open.
   */
  open: boolean;
  /**
   * Callback that opens the menu.
   */
  openMenu: () => void;
  /**
   * Callback that closes the menu.
   */
  closeMenu: () => void;
  /**
   * Props that should be passed to the MUI `Menu` component.
   */
  menuProps: {
    open: boolean;
    onClose: () => void;
    anchorEl: () => Element;
    getContentAnchorEl: null;
    anchorOrigin: PopoverOrigin;
    transformOrigin: PopoverOrigin;
  };
};

/**
 * Hook that provides stateful logic for the MUI controlled `Menu` component.
 *
 * Troubleshooting:
 * - MUI Menus, Dialogs, and over "popovers" cannot be defined inside of another
 * functional component or a class component's render method. They must be
 * extracted into a separate top-level component. Otherwise weird glitches like
 * transitions not applying occur. See
 * https://github.com/mui-org/material-ui/issues/9116.
 */
export function useMenu(props: UseMenuProps): UseMenuReturn {
  const [open, setOpen] = useState(false);

  const openMenu = useCallback(() => {
    setOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    openMenu,
    closeMenu,
    menuProps: {
      open,
      onClose: closeMenu,
      anchorEl: props.anchorEl,
      getContentAnchorEl: null,
      anchorOrigin: props.anchorOrigin,
      transformOrigin: props.transformOrigin,
    },
  };
}
