import { Button, FormControlLabel, Link, Typography } from '@material-ui/core';
import { TextField } from 'components/TextField';
import { useController, useForm } from 'react-hook-form';
import { Dialog } from 'components/Dialog';
import { ContactAttribute, FormFieldKind } from 'codegen/graphql';
import { useEffect } from 'react';
import { SelectField, SelectItem } from 'components/SelectField';
import { SwitchField } from 'components/SwitchField';
import { Link as RouterLink } from 'react-router-dom';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditableFormField, MANDATORY_FIELD_KEYS } from 'pages/StudioContactFieldsPage/StudioContactFieldsPage';
import { usePermissions } from 'src/hooks/usePermissions';
import ErrorCard from 'components/ErrorCard';

type CreateFormFieldDialogProps = {
  field?: EditableFormField;
  open: boolean;
  onClose: () => void;
  onExited?: () => void;
  attributes: Pick<ContactAttribute, 'key' | 'custom'>[];
  usedAttributes: Pick<ContactAttribute, 'key'>[];
  onComplete: (field: EditableFormField) => void;
};

const MAX_LABEL_CHARS = 50;

const contactFieldSchema = z.object({
  label: z.string().min(3).max(MAX_LABEL_CHARS),
  required: z.boolean(),
  contactAttributeKey: z.string(),
  kind: z.enum(['text', 'select', 'radio']),
  choices: z.string(),
});

const kindItems: SelectItem[] = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Dropdown',
    value: 'select',
  },
  {
    label: 'Radio',
    value: 'radio',
  },
];

export function FormFieldDialog(props: CreateFormFieldDialogProps): React.ReactElement | null {
  const { attributes, usedAttributes, open, field, onClose, onExited, onComplete } = props;

  const {
    control,
    trigger: validate,
    getValues,
    setValue,
    watch,
  } = useForm({
    resolver: zodResolver(contactFieldSchema),
    defaultValues: {
      label: '',
      required: true,
      contactAttributeKey: null as string | null,
      kind: 'text',
      choices: '',
    },
  });

  const contactAttributeKey = watch('contactAttributeKey');

  const labelController = useController({ control, name: 'label' });
  const requiredController = useController({ control, name: 'required' });
  const contactAttributeKeyController = useController({
    control,
    name: 'contactAttributeKey',
  });
  const kindController = useController({ control, name: 'kind' });
  const choicesController = useController({ control, name: 'choices' });

  useEffect(() => {
    if (field) {
      setValue('label', field.label);
      setValue('required', field.required);
      setValue('contactAttributeKey', field.contactAttribute.key);
      setValue('kind', field.kind);
      setValue('choices', field.choices?.join('\n') || '');
    } else {
      setValue('label', '');
      setValue('required', true);
      setValue('contactAttributeKey', null);
      setValue('kind', 'text');
      setValue('choices', '');
    }
  }, [field, setValue]);

  const creatingNew = !field;
  const mandatory = contactAttributeKey ? MANDATORY_FIELD_KEYS.includes(contactAttributeKey) : true;

  const perms = usePermissions();

  if (perms.loading) return null;

  if (perms.error) {
    return <ErrorCard body="Fatal error: unable to fetch membership permissions. Please contact support." />;
  }

  const { staff } = perms;

  return (
    <Dialog
      title={field ? `Edit field "${field.label}"` : 'Add field'}
      width={700}
      open={open}
      onClose={onClose}
      onExited={onExited}
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" color="primary" size="large" onClick={handleDoneClick}>
            Done
          </Button>
        </>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          padding: '1.4rem 0',
          gap: '1.4rem',
        }}
      >
        {(attributes.length > 0 || !creatingNew) && (
          <>
            <SelectField
              label="Contact attribute"
              variant="outlined"
              controller={contactAttributeKeyController}
              items={attributes.map((attr) => ({
                label: attr.key,
                value: attr.key,
                disabled: usedAttributes.some((usedAttr) => usedAttr.key === attr.key),
              }))}
              disabled={!creatingNew}
            />

            <Typography style={{ fontSize: 14, marginTop: '-1rem', marginBottom: '1rem' }}>
              {creatingNew ? (
                <>
                  Need to create a new contact attribute?{' '}
                  {staff ? (
                    <Link component={RouterLink} to="/contacts/attributes">
                      <Typography style={{ display: 'inline', fontSize: 'inherit' }}>See attributes page</Typography>{' '}
                    </Link>
                  ) : (
                    <Link href="mailto:us@thesnapbar.com">
                      <Typography style={{ display: 'inline', fontSize: 'inherit' }}>
                        Contact our support team
                      </Typography>
                    </Link>
                  )}
                </>
              ) : (
                <>
                  You cannot change the contact attribute of a field that is already in use.{' '}
                  {field?.contactAttribute?.custom === true && (
                    <Link component={RouterLink} to={`/contacts/attributes?editKey=${field?.contactAttribute?.key}`}>
                      <Typography style={{ display: 'inline', fontSize: 'inherit' }}>Edit attribute</Typography>
                    </Link>
                  )}
                </>
              )}
            </Typography>

            <TextField
              variant="outlined"
              label="Label"
              fullWidth
              maxChars={MAX_LABEL_CHARS}
              controller={labelController}
            />

            <SelectField items={kindItems} label="Input type" controller={kindController} />

            {kindController.field.value != 'text' && (
              <TextField fullWidth multiline={true} label="Choices (one per line)" controller={choicesController} />
            )}

            {!mandatory && (
              <FormControlLabel control={<SwitchField controller={requiredController} />} label="Required" />
            )}
          </>
        )}

        {attributes.length === 0 && creatingNew && (
          <Typography>
            You are utilizing all of your contact attributes already, you must create a new one. <br />
            <Link component={RouterLink} to="/contacts/attributes">
              <Typography style={{ display: 'inline', fontSize: 'inherit' }}>See attributes page</Typography>
            </Link>
          </Typography>
        )}
      </div>
    </Dialog>
  );

  async function handleDoneClick() {
    if (!(await validate())) return;

    const { label, required, contactAttributeKey, kind, choices } = getValues();

    if (!contactAttributeKey) return;

    onComplete({
      __typename: 'FormField',
      label: label,
      required: required,
      contactAttribute: {
        __typename: 'ContactAttribute',
        custom: true,
        key: contactAttributeKey,
      },
      kind: kind as FormFieldKind,
      choices: choices.split('\n').map((choice) => choice.trim()),
    });

    onClose();
    setValue('label', '');
    setValue('required', true);
    setValue('contactAttributeKey', null);
  }
}
