import { makeStyles, Card, CardHeader } from '@material-ui/core';
import DesktopMonitor from 'assets/DesktopMonitor.svg';

export type DashboardConnectCardProps = {
  // add props here
};

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  cardHeader: {
    flex: '0 1 auto',
  },
  desktopSvg: {
    flex: '1 0 auto',
    marginLeft: 16,
  },
}));

export function DashboardConnectCard(props: DashboardConnectCardProps): React.ReactElement {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardHeader
        title="Connect your website"
        subheader="Show a gallery of photos on your website. It should take less than 5 minutes of your time."
        className={classes.cardHeader}
      />
      <img src={DesktopMonitor} alt="card-decoration" className={classes.desktopSvg} />
    </Card>
  );
}
