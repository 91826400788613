import { merge } from 'lodash-es';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import type { Direction, Theme, ThemeOptions } from '@material-ui/core';
import { lightShadows, darkShadows } from './shadows';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme: string;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  /**
   * CUSTOM
   * set md breakpoint to 880px
   */
  palette: {
    primary: {
      main: '#0ca07d',
    },
    secondary: {
      main: '#4578BC',
    },
    success: {
      contrastText: '#ffffff',
      main: '#4caf50',
    },
    warning: {
      contrastText: '#ffffff',
      main: '#ff9800',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 880,
      lg: 1180,
      xl: 1920,
    },
  },
  overrides: {
    MuiAvatar: {
      fallback: {
        height: '75%',
        width: '75%',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        // always make buttons perfectly round
        borderRadius: 100,
      },
      /**
       * CUSTOM
       * - set start icon size to 18
       */
      startIcon: {
        alignItems: 'center',
        width: 18,
        height: 18,
      },
      contained: {
        backgroundColor: 'rgb(245, 245, 245)',
      },
    },
    /**
     * CUSTOM
     * - style card padding
     * - add margins to dividers inside of cards
     */
    MuiCard: {
      root: {
        padding: 32,
        '& .MuiDivider-root:not(.MuiDivider-vertical)': {
          marginTop: 16,
          marginBottom: 16,
        },
      },
    },
    /**
     * CUSTOM
     * - style card header text
     * - style card subheader text
     * - remove extra card horizontal padding
     * - center card action vertically
     * - remove weird negative top-right margins on card actions
     * - add exclusively top padding on card content if sibling of card header
     */
    MuiCardHeader: {
      title: {
        fontSize: '1.5rem',
        fontFamily: 'Mulish, Helvetica, sans-serif',
        fontWeight: 400,
      },
      subheader: {
        fontFamily: 'Mulish, Helvetica, sans-serif',
        color: '#607d8b',
        display: 'flex',
        alignItems: 'center',
      },
      root: {
        padding: 0,
        '& ~ .MuiCardContent-root': {
          padding: '16px 0 0 0',
        },
      },
      action: {
        alignSelf: 'center',
        marginTop: undefined,
        marginRight: undefined,
      },
    },
    /**
     * CUSTOM
     * - 0 padding to card content
     * - stop adding extra bottom 8px padding on card content
     */
    MuiCardContent: {
      root: {
        padding: 0,
        '&:last-child': {
          paddingBottom: null,
        },
      },
    },
    /**
     * CUSTOM
     * - give each card a bottom margin when used in a Container (usually a page)
     */
    MuiContainer: {
      root: {
        '& .MuiCard-root:not(:last-child)': {
          marginBottom: '2rem',
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    /**
     * CUSTOM
     * - always show pointer on link components
     */
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    /**
     * CUSTOM
     * - set icon size to 18
     */
    MuiListItemIcon: {
      root: {
        minWidth: 'auto',
        marginRight: 12,
        width: 18,
        height: 18,
        alignItems: 'center',
      },
    },
    /**
     * CUSTOM
     * - add border radius when focused
     */
    MuiSelect: {
      select: {
        '&:focus': {
          borderRadius: 16,
        },
      },
    },
    /**
     * CUSTOM (for table head, row, and cell)
     * - add smarter bottom borders on tables
     */
    MuiTableHead: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    MuiTableRow: {
      root: {
        '&:not(:last-child)': {
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: undefined,
      },
    },
  },
  props: {
    MuiCardHeader: {
      titleTypographyProps: {
        variant: 'h6',
      },
    },
  },
  typography: {
    /**
     * CUSTOM: increase letter spacing
     */
    button: {
      letterSpacing: '0.83px',
      fontWeight: 600,
    },
    fontFamily:
      'Mulish, Helvetica,-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.DARK]: {
    overrides: {
      MuiTableCell: {
        root: {
          borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
        },
      },
    },
    palette: {
      background: {
        default: '#171c24',
        paper: '#222b36',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      primary: {
        contrastText: '#ffffff',
        main: '#688eff',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
  [THEMES.NATURE]: {
    overrides: {
      MuiTableCell: {
        root: {
          borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
        },
      },
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
};

export const createTheme = (config: ThemeConfig = { theme: 'light' }): Theme => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createMuiTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      },
      {
        direction: config.direction,
      },
    ),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

const customTheme = createTheme({ theme: 'LIGHT', roundedCorners: true, responsiveFontSizes: true });

export default customTheme;
