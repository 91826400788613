import { DateTimePicker, DateTimePickerProps } from '@material-ui/pickers';
import { UseControllerReturn } from 'react-hook-form';

const DATE_FORMAT = 'd MMM y (H:mm O)';

export type DateTimeFieldProps = Omit<DateTimePickerProps, 'value' | 'onChange'> & {
  controller: UseControllerReturn<any, any>;
};

export function DateTimeField(props: DateTimeFieldProps): React.ReactElement {
  const {
    controller: {
      field: { ref, ...defaultControllerProps },
      fieldState: { error },
    },
    ...dateTimePickerProps
  } = props;

  const controllerProps = {
    ...defaultControllerProps,
    inputRef: ref,
    error: !!error,
    helperText: error?.message,
  };

  return (
    <DateTimePicker
      format={DATE_FORMAT}
      {...controllerProps}
      {...dateTimePickerProps}
      value={!controllerProps.value ? null : controllerProps.value}
    />
  );
}
