import { makeStyles, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import Page from 'pages/Page';

export type LoadingPageProps = {
  children?: JSX.Element;
};

/**
 * Loading variant of the `Page` component.
 */
export function LoadingPage(props: LoadingPageProps): React.ReactElement {
  return (
    <Page
      title={<Skeleton style={{ width: 200 }} />}
      pageActions={
        <Skeleton>
          <Button>loading</Button>
        </Skeleton>
      }
    >
      {props.children}
    </Page>
  );
}
