import { useEffect, useRef, useState } from 'react';
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  Paper,
  Tooltip,
} from '@material-ui/core';
import { Menu as MenuIcon, MoreVert } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { PhotoSizeNames } from 'models/Preset';
import { Preset as CompletePreset } from 'codegen/graphql';
import { useMenu } from 'hooks/useMenu';
import { Eye, EyeOff } from 'react-feather';
import BadImage from 'assets/BadImage.png';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

type Preset = Pick<CompletePreset, 'id' | 'name' | 'resize' | 'default' | 'sampleUrl' | 'visibleToContact'>;

type RenderMenuProp = (props: { presetId: string; open: boolean; onClose: () => void }) => JSX.Element;

export type PresetsTableProps = {
  presets: Preset[];
  /**
   * Callback to be executed on row click.
   */
  onRowClick?: (presetId: string) => void;
  renderMenu?: RenderMenuProp;
  handlePresetReorder?: (reorderedPresets: Preset[]) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    tableLayout: 'auto',
    width: '100%',
  },
  tableHeadCell: {
    fontWeight: 500,
    padding: '0 16px 12px',
  },
  previewWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 75,
    height: 75,
    margin: '0 auto',
  },
  previewPaper: {
    borderRadius: 0,
    maxWidth: 75,
    maxHeight: 75,
  },
  previewImg: {
    display: 'block',
    maxWidth: 'inherit',
    maxHeight: 'inherit',
  },
  presetRow: {
    tableLayout: 'fixed',
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  dragHandleColumn: {
    width: 64,
  },
  dragHandleCell: {
    padding: '0 8px',
  },
  nameColumn: {
    width: 288,
  },
  sampleColumn: {
    width: 144,
    textAlign: 'center',
  },
  sizeColumn: {
    width: 160,
    textAlign: 'center',
  },
  visibilityColumn: {
    width: 128,
    textAlign: 'center',
  },
  menuColumn: {
    width: 64,
  },
  menuCell: {
    padding: '0 8px',
  },
}));

export function PresetsTable(props: PresetsTableProps): React.ReactElement {
  const { presets, renderMenu, handlePresetReorder } = props;
  const [movablePresets, setMovablePresets] = useState(presets);
  const { t } = useTranslation();
  const classes = useStyles();

  // needed for when presets are added or removed
  useEffect(() => {
    setMovablePresets([...presets]);
  }, [presets]);

  const tableHeading = (
    <TableHead>
      <TableCell className={`${classes.dragHandleColumn} ${classes.tableHeadCell}`} />
      <TableCell className={`${classes.nameColumn} ${classes.tableHeadCell}`}>Name</TableCell>
      <TableCell className={`${classes.sampleColumn} ${classes.tableHeadCell}`}>Sample</TableCell>
      <TableCell className={`${classes.sizeColumn} ${classes.tableHeadCell}`}>Size</TableCell>
      <TableCell className={`${classes.visibilityColumn} ${classes.tableHeadCell}`}>Visibility</TableCell>
      {renderMenu && <TableCell className={`${classes.menuColumn} ${classes.tableHeadCell}`} />}
    </TableHead>
  );

  const tableRows = movablePresets.map((preset, index) => (
    <PresetsTableRow key={index} index={index} preset={preset} renderMenu={renderMenu} />
  ));

  const handleDragEnd = (result: DropResult) => {
    if (result.source.index === result.destination?.index || !result.destination) return;
    const newPresets = [...movablePresets];
    const [movingPreset] = newPresets.splice(result.source.index, 1);
    newPresets.splice(result.destination.index, 0, movingPreset);
    setMovablePresets(newPresets);
    if (handlePresetReorder) handlePresetReorder(newPresets);
  };

  return (
    <Table className={classes.root}>
      {tableHeading}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="presets-table">
          {(provided) => (
            <TableBody {...provided.droppableProps} ref={provided.innerRef}>
              {tableRows}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    </Table>
  );
}

type PresetsTableRowProps = {
  index: number;
  preset: Preset;
  renderMenu?: RenderMenuProp;
};

function PresetsTableRow(props: PresetsTableRowProps): React.ReactElement {
  const { index, preset, renderMenu } = props;
  const classes = useStyles(props);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const { menuProps, openMenu } = useMenu({
    anchorEl: () => menuButtonRef.current as Element,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  });

  return (
    <Draggable draggableId={`preset-table-draggable-${index}`} index={index}>
      {(provided) => (
        <>
          <TableRow {...provided.draggableProps} ref={provided.innerRef} className={classes.presetRow}>
            <TableCell className={`${classes.dragHandleColumn} ${classes.dragHandleCell}`}>
              <IconButton {...provided.dragHandleProps}>
                <MenuIcon />
              </IconButton>
            </TableCell>
            <TableCell className={classes.nameColumn}>
              <Typography>{preset.name + (preset.default ? ' (default)' : '')}</Typography>
            </TableCell>
            <TableCell className={classes.sampleColumn}>
              {preset.sampleUrl && (
                <div className={classes.previewWrapper}>
                  <Paper className={classes.previewPaper}>
                    <img className={classes.previewImg} src={preset.sampleUrl || BadImage} alt="sample" />
                  </Paper>
                </div>
              )}
            </TableCell>
            <TableCell className={classes.sizeColumn}>
              <Typography>{PhotoSizeNames[preset.resize]}</Typography>
            </TableCell>
            <TableCell className={classes.visibilityColumn}>
              <Tooltip title={`${preset.visibleToContact ? 'Visible' : 'Hidden'} in studio`}>
                {preset.visibleToContact ? <Eye size={18} /> : <EyeOff opacity={0.5} size={18} />}
              </Tooltip>
            </TableCell>
            {renderMenu && (
              <TableCell align="right" className={`${classes.menuColumn} ${classes.menuCell}`}>
                <IconButton
                  onClick={(e) => {
                    openMenu();
                    e.stopPropagation();
                  }}
                  ref={menuButtonRef}
                >
                  <MoreVert />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
          {renderMenu && renderMenu({ presetId: preset.id, ...menuProps })}
        </>
      )}
    </Draggable>
  );
}
