import { FetchResult } from '@apollo/client';
import { UserError } from 'codegen/graphql';
import { get } from 'lodash-es';

type PayloadWithUserErrors = {
  errors: UserError[];
};

type HandleMutationParams<Data, PayloadFieldPath extends string> = Data extends NestedObject<
  PayloadFieldPath,
  PayloadWithUserErrors
>
  ? {
      mutate: () => Promise<
        FetchResult<
          Data & NestedObject<PayloadFieldPath, PayloadWithUserErrors>,
          Record<string, any>,
          Record<string, any>
        >
      >;
      payloadFieldPath: PayloadFieldPath;
      successCondition: (data: Data) => boolean;
      onSuccess: (data: Data) => void;
      onError: (errors: UserError[]) => void;
      /**
       * If specified, a confirmation dialog with this text will pop up to
       * confirm execution of the mutation.
       */
      confirm?: string;
    }
  : never;

/**
 * A helper function that handles mutations easily for you. To use this, a
 * mutation callback with no parameters and a payload field path must be
 * specified. For example, if your mutation document is of the form
 *
 * ```
 * mutation {
 *   acceptSubmissions {
 *     acceptedIds
 *     errors {
 *       path
 *       message
 *     }
 *   }
 * }
 * ```
 *
 * Then the payload field path would be `acceptSubmissions` or
 * `acceptSubmissions?`, depending on whether the payload is nullish. Keep in
 * mind that there must be an `errors` field in the payload.
 *
 * @example
 * const [mutate] = useMutation(MUTATION_DOCUMENT, { variables: { ... } })
 * handleMutation({
 *   mutate: () => mutate(),
 *   payloadFieldPath: 'path.of.payload',
 *   confirm: 'Are you sure you wanna do that?',
 *   successCondition: (data) => data?.path?.of?.payload === expectedPayload
 *   onSuccess: () => console.log('yay!')
 *   onError: () => console.error('boo!')
 * })
 */
export async function handleMutation<Data, PayloadFieldPath extends string>(
  params: HandleMutationParams<Data, PayloadFieldPath>,
): Promise<void> {
  if (params?.confirm && !confirm(params.confirm)) return;

  let returnedUserErrors: UserError[] = [];

  try {
    const result = await params.mutate();
    const payloadFieldPath = params.payloadFieldPath.replace('?', '');
    const payload = get(result.data, payloadFieldPath);

    const gqlErrors = result.errors;
    const userErrors = payload?.errors;

    if (result.data && params.successCondition(result.data)) {
      params.onSuccess(result.data);
      return;
    }

    if (userErrors?.length) {
      returnedUserErrors = userErrors;
      console.error('User errors', userErrors);
    } else if (gqlErrors?.length) {
      console.error('GraphQL errors', gqlErrors);
    }
  } catch (networkError) {
    console.error('Network error', networkError);
  }

  params.onError(returnedUserErrors);
}
