import { Grid } from '@material-ui/core';

export type TwoCardGridProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * The two card components to render in a responsive grid.
   */
  children: [JSX.Element, JSX.Element];
};

/**
 * Component that accepts exactly two components as children and renders them
 * side-by-side responsively. The second child is a bit smaller than the first
 * child on wide screen layouts.
 */
export function TwoCardGrid(props: TwoCardGridProps): React.ReactElement {
  return (
    <Grid className={props.className} container spacing={2}>
      <Grid item lg={8} md={7} sm={6} xs={12}>
        {props.children[0]}
      </Grid>
      <Grid item lg={4} md={5} sm={6} xs={12}>
        {props.children[1]}
      </Grid>
    </Grid>
  );
}
