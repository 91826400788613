import { IframeHTMLAttributes, useEffect, useRef, useState } from 'react';
import { StudioDetailsFragment } from 'codegen/graphql';

type IframeProps = IframeHTMLAttributes<HTMLIFrameElement> & {
  studio: StudioDetailsFragment;
};

const STUDIO_URL = process.env.VITE_STUDIO_URL;

export function StudioEmbed(props: IframeProps) {
  if (!STUDIO_URL) {
    throw new Error('Cannot render embedded Studio when VITE_STUDIO_URL env var is null');
  }

  const { studio, ...iframeProps } = props;

  const $iframe = useRef<HTMLIFrameElement>(null);
  const [studioSource, setStudioSource] = useState<WindowProxy | null>(null);

  // Obtain the Studio embed window by receiving a message from it.
  // Thank Djave on StackOverflow: https://stackoverflow.com/a/68954178
  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (e.origin != STUDIO_URL) {
        console.warn('Received message from unauthorized origin:', e.origin);
        return;
      }

      if (e.data === 'CONNECT') {
        if (!e.source || !messageEventSourceIsWindow(e.source)) {
          throw new Error('Received CONNECT message from a non-Window source');
        }

        setStudioSource(e.source);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    if (!studioSource) return;

    studioSource.postMessage(
      {
        type: 'SET_STUDIO',
        payload: studio,
      },
      STUDIO_URL,
    );
  }, [studio, studioSource]);

  return <iframe title="Studio embed" src={`${STUDIO_URL}/${studio.handle}#preview`} {...iframeProps} ref={$iframe} />;
}

// Blegh: https://github.com/Microsoft/TypeScript/issues/26403
export function messageEventSourceIsWindow(source: MessageEventSource): source is Window {
  return (
    (!('MessagePort' in window) || !(source instanceof MessagePort)) &&
    (!('ServiceWorker' in window) || !(source instanceof ServiceWorker)) &&
    !!source.postMessage
  );
}
