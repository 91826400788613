import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { useDeleteAccessTokenMutation } from 'src/codegen/graphql';
import { Box, Button, Paper, makeStyles, Typography } from '@material-ui/core';
import Dialog from 'src/components/Dialog';
import { useState } from 'react';
import { Trash2 } from 'react-feather';
import { toast } from 'src/components/GlobalSnackbar';
import { UsableAccessToken } from './types';

type TokensDataGridProps = {
  tokens: UsableAccessToken[];
  onTokenDelete: (token: UsableAccessToken) => void;
  loading: boolean;
};

const useStyles = makeStyles(() => ({
  paper: {
    border: 0,
    height: 'auto',
  },
  root: {
    border: 0,

    '& .MuiDataGrid-windowContainer': {
      height: 'auto !important',
    },

    '& .MuiDataGrid-cell:focus-within': {
      outline: 0,
    },
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
      '&:first-child': {
        paddingLeft: '1rem',
      },
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-window': {
      position: 'static !important',
    },
    '& .MuiDataGrid-columnsContainer': {
      position: 'static !important ',
    },
    '& .MuiDataGrid-viewport': {
      maxHeight: 'none !important',
    },
  },
  toolbar: {
    fontSize: 16,
    padding: '1rem',
  },
  scopesCell: {
    width: '100%',
    lineHeight: 1.5,
    overflow: 'auto',
  },
  tokenCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    textTransform: 'uppercase',
  },
  deleteButton: {
    color: 'white',
    backgroundColor: '#F44336',
    paddingRight: '2.5rem',
    paddingLeft: '2.5rem',
    marginRight: '1em',
  },
}));

export function TokensDataGrid({ tokens, onTokenDelete, loading }: TokensDataGridProps): React.ReactElement {
  const [deleteAccessToken, { loading: deleting, error }] = useDeleteAccessTokenMutation();
  const [modalOpen, setModalOpen] = useState(false);
  const [tokenToDelete, setTokenToDelete] = useState<UsableAccessToken | null>(null);

  const classes = useStyles();

  const formattedData =
    tokens.map((accToken) => ({
      id: accToken.id,
      name: accToken.name,
      accountName: accToken?.account?.name,
      scopes: accToken.scopes,
      createdAt: new Date(accToken.createdAt).toLocaleDateString(),
      token: `****${accToken.token.substring(accToken.token.length - 4)}`,
    })) || [];

  const TOKENS_DATA_ROWS = formattedData;

  const TOKENS_DATA_COLUMNS: GridColDef[] = [
    { field: 'name', headerName: 'Token name', sortable: false, disableColumnMenu: true, flex: 1 },
    { field: 'accountName', headerName: 'Account', sortable: true, flex: 1 },
    {
      field: 'scopes',
      headerName: 'Scopes',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className={classes.scopesCell}>
            {params.row.scopes.map((scope: string, index: number) => (
              <li key={index}>{scope}</li>
            ))}
          </Box>
        );
      },
    },
    { field: 'createdAt', headerName: 'Created date', sortable: true, flex: 1 },
    {
      field: 'token',
      headerName: 'Token',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className={classes.tokenCell}>
            <span>{params.formattedValue}</span>
            <Button
              onClick={() => {
                const token = tokens.find((x) => x.id == params.row.id);
                if (!token) return;
                setTokenToDelete(token);
                setModalOpen(true);
              }}
            >
              <Trash2 />
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Paper className={classes.paper}>
      <DataGrid
        rows={TOKENS_DATA_ROWS}
        columns={TOKENS_DATA_COLUMNS}
        autoHeight
        loading={loading}
        components={{
          Toolbar: () => (
            <Box className={classes.toolbar}>
              <Typography>Manage current tokens</Typography>
            </Box>
          ),
        }}
        className={classes.root}
      />
      <Dialog
        title="Are you sure you want to revoke this token?"
        width={600}
        open={modalOpen}
        onClose={clearIdToDelete}
        actions={
          <>
            <Button className={classes.button} onClick={clearIdToDelete}>
              Cancel
            </Button>
            <Button
              className={`${classes.button} ${classes.deleteButton}`}
              onClick={() => {
                if (!tokenToDelete) return;
                handleDelete(tokenToDelete);
              }}
            >
              {deleting ? 'Revoking' : 'Revoke'}
            </Button>
          </>
        }
      >
        <Typography>
          If you revoke this token, it will be deleted and you will no longer be able to view or access it. Once
          revoked, this token cannot be recovered.
        </Typography>
      </Dialog>
    </Paper>
  );

  function clearIdToDelete() {
    setTokenToDelete(null);
    setModalOpen(false);
  }

  async function handleDelete(token: UsableAccessToken) {
    const response = await deleteAccessToken({ variables: { input: { id: token.id } } });
    if (response.data?.deleteAccessToken?.errors.length) {
      toast('Token revocation failed', 'error');
      return;
    }
    onTokenDelete(token);
    toast('Token revoked', 'success');

    setModalOpen(false);
  }
}
