import { makeStyles, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router';

import { Contact, useGetContactAttributesQuery } from 'codegen/graphql';
import { SettingsCard } from 'components/SettingsCard';
import ColumnValue from 'components/ColumnValue';
import { id } from 'lib/gid';
import { usePermissions } from 'hooks/usePermissions';

export type ContactDetailsCardProps = {
  contact: Pick<Contact, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'customAttributes'>;
};

const useStyles = makeStyles((theme) => ({
  ContactAttributes: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '-1em',
    '& > div': {
      flexBasis: '25%',
    },
  },
  CustomContactAttributes: {
    borderTop: '1px solid #e0e0e0',
    margin: '1em -1em -1em',
    padding: '1em 1em 0',
  },
}));

export function ContactDetailsCard(props: ContactDetailsCardProps): React.ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const perms = usePermissions();

  const { loading, error, data: attributesQuery } = useGetContactAttributesQuery();
  const { contactAttributes = [] } = { ...attributesQuery }; // trick to handle destructuring undefined

  const customAttributes = contactAttributes.filter((a) => a.custom);

  return (
    <SettingsCard
      title="Contact information"
      cardActions={
        !!perms.auth?.canChangeContact && (
          <Button onClick={() => history.push(`/contacts/${id(props.contact.id)}/edit`)}>EDIT</Button>
        )
      }
    >
      <div className={classes.ContactAttributes}>
        <ColumnValue label="First name" value={props.contact.firstName} />
        <ColumnValue label="Last name" value={props.contact.lastName} />
        <ColumnValue label="Email address" value={props.contact.email} dataType="email" />
        <ColumnValue label="Phone number" value={props.contact.phone} dataType="phone" />
      </div>

      {customAttributes.length > 0 && (
        <div className={[classes.CustomContactAttributes, classes.ContactAttributes].join(' ')}>
          {customAttributes.map((attribute) => (
            <ColumnValue
              key={attribute.key}
              label={attribute.key}
              value={props.contact.customAttributes?.[attribute.key]}
            />
          ))}
        </div>
      )}
    </SettingsCard>
  );
}

ContactDetailsCard.Skeleton = ContactDetailsCardSkeleton;

function ContactDetailsCardSkeleton(): React.ReactElement {
  const classes = useStyles();

  return (
    <SettingsCard title="Contact information">
      <div className={classes.ContactAttributes}>
        <ColumnValue label="First name" value={<Skeleton variant="text" width={75} />} />
        <ColumnValue label="Last name" value={<Skeleton variant="text" width={120} />} />
        <ColumnValue label="Email address" value={<Skeleton variant="text" width={200} />} dataType="email" />
        <ColumnValue label="Phone number" value={<Skeleton variant="text" width={110} />} dataType="phone" />
        <ColumnValue label="Company" value={<Skeleton variant="text" width={200} />} />
      </div>
    </SettingsCard>
  );
}
