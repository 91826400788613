import { makeStyles, colors, Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { StudioOverviewFragment, useResetStudioPhotosCountMutation } from 'codegen/graphql';
import { toast } from '../GlobalSnackbar';
import { Replay } from '@material-ui/icons';
import { usePermissions } from 'src/hooks/usePermissions';

type StudioPhotosInfoGridProps = {
  /**
   * Studio to display photo info about.
   */
  studio: StudioOverviewFragment;
};

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'left',
    '& .MuiGrid-item': {
      borderRight: '1px solid rgb(232, 234, 246)',
      marginLeft: '3.5rem',
    },
    '& .MuiGrid-item:first-child': {
      marginLeft: '0',
    },
    '& .MuiGrid-item:last-child': {
      borderRight: '0',
    },
  },
  value: {
    fontSize: '1.75rem',
  },
  desc: {
    fontSize: '1rem',
    color: '#546E7A',
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'row',
  },
  green: {
    color: colors.green[500],
  },
  flexIcon: {
    padding: 10,
    marginLeft: '.75rem',
    height: '.75rem',
    width: '.75rem',
  },
}));

/**
 * Component that takes a studio and presents meaningful data about photos
 * and submissions.
 */
export function StudioPhotosInfoGrid({ studio }: StudioPhotosInfoGridProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation('studiosRoute');
  const [resetPhotosCount] = useResetStudioPhotosCountMutation({ variables: { input: { id: studio.id } } });
  const perms = usePermissions();

  const { photosCount: count, photosQuotaLimit: limit } = studio;

  const data = [
    {
      desc: t('photosTaken'),
      value: count,
      action: (
        <Tooltip title="Reset credit balance">
          <IconButton className={classes.flexIcon} onClick={handleResetPhotosCount}>
            <Replay />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      desc: t('photoLimit'),
      value: limit || 'N/A',
    },
    {
      desc: t('photosRemaining'),
      value: limit ? limit - count : 'N/A',
      valueClass: classes.green,
    },
  ];

  return (
    <Grid container className={classes.root}>
      {data.map((data, index) => (
        <Grid item xs key={index}>
          <Typography className={classes.desc}>
            {data.desc}
            {perms.staff && data.action}
          </Typography>
          <Typography className={clsx(classes.value, data.valueClass)}>{`${data.value.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`}</Typography>
        </Grid>
      ))}
    </Grid>
  );

  async function handleResetPhotosCount() {
    try {
      if (confirm('Are you sure you would like to reset credit count? This action cannot be undone.')) {
        await resetPhotosCount();

        toast('Studio credits reset', 'success');
      }
    } catch (e) {
      toast('Could not reset studio credits', 'error');
    }

    // usageMoreMenuProps.onClose();
  }
}
