import { useEffect, useMemo, useState } from 'react';
import {
  makeStyles,
  Button,
  CircularProgress,
  Link,
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  TextField as MuiTextField,
} from '@material-ui/core';
import { useForm, useController, Control, Controller } from 'react-hook-form';

import { SubmissionTabValue } from 'pages/Submissions';
import {
  useExportSubmissionsMutation,
  ExportableSubmissionState,
  useGetStudioOverviewsLazyQuery,
  ImageFormat,
  ExportSubmissionsInput,
  useGetExportFilenamePatternsQuery,
  useClearExportFilenameHistoryMutation,
  useGetContactAttributesQuery,
} from 'codegen/graphql';
import { handleMutation } from 'lib/handleMutation';
import { Dialog } from 'components/Dialog';
import { ErrorCard } from 'components/ErrorCard';
import { SelectField, SelectItem } from 'components/SelectField';
import { toast } from 'components/GlobalSnackbar';
import { PhotoExportAwaitDialog } from 'components/PhotoExportSuccessDialog';
import { useSubmissionsExport } from 'hooks/useSubmissionsExport';
import useDialog from 'hooks/useDialog';
import { TextField } from 'components/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isPropertySignature } from 'typescript';
import { ptPT } from '@material-ui/core/locale';
import { custom } from 'zod';
import { SwitchField } from 'components/SwitchField';

type BaseExportDialogProps = {
  open: boolean;
  onClose: () => void;
  /**
   * Callback to be executed if photos are successfully exported. The first and
   * only parameter is the export URL.
   */
  onSuccess?: (url: string) => void;
  /**
   * Callback to be executed if photo export fails.
   */
  onFail?: () => void;
  enhanced?: boolean;
};

// props passed when export is done via providing multiple filters, like
// submission state and studio ID
type FilterExportDialogProps = BaseExportDialogProps & {
  /**
   * State of submissions to export. Setting this to "REVIEW" would
   * cause the primary action to only export submissions in review.
   * Requires `studioId`. Mutually exclusive with `submissionIds`.
   */
  submissionState: SubmissionTabValue;
  /**
   * ID of the current studio to export from.  Requires `submissionState`.
   * Mutually exclusive with `submissionIds`.
   */
  studioId: string | null;
};

// props passed when export is done via manually specifying submission IDs
type ManualExportDialogProps = BaseExportDialogProps & {
  /**
   * IDs of submissions to export. Mutually exclusive with `submissionState` and
   * `studioId`.
   */
  submissionIds: string[];
};

export type PhotoExportDialogProps = FilterExportDialogProps | ManualExportDialogProps;

const useStyles = makeStyles(() => ({
  loadingWrapper: {
    width: '100%',
    height: 100,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  formWrapper: {
    '& > *:not(:last-child)': {
      marginBottom: 16,
    },
  },
  customFilenamePattern: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiFormControlLabel-label': {
      fontSize: '0.75rem',
    },
  },
}));

const mapToExportableState: Record<SubmissionTabValue, ExportableSubmissionState | null> = {
  ALL: null,
  ACCEPTED: ExportableSubmissionState.Accepted,
  REJECTED: ExportableSubmissionState.Rejected,
  REVIEW: ExportableSubmissionState.Review,
  INVITED: null,
};

const mapToExportableFormat: Record<string, ImageFormat | 'auto'> = {
  auto: 'auto',
  jpeg: ImageFormat.Jpeg,
  png: ImageFormat.Png,
  gif: ImageFormat.Gif,
};

type ExportFormValue = {
  studioId: string | 'ALL';
  submissionState: `${ExportableSubmissionState}` | 'ALL';
  submissionIds: string[];
  format?: `${ImageFormat}` | 'auto';
  pattern: string;
  customPattern: string;
  enhanced: boolean;
};

export function PhotoExportDialog(props: PhotoExportDialogProps): React.ReactElement {
  const { open, onClose } = props;
  const enhanced = props.enhanced ?? true;

  const [exportSubmissions, exportSubmissionsMutation] = useExportSubmissionsMutation();
  const { openDialog: openExportAwaitDialog, dialogProps: exportAwaitDialogProps } = useDialog();
  const { streamExport } = useSubmissionsExport();
  const { control, reset, getValues, setValue, trigger } = useForm<ExportFormValue>({
    defaultValues: {
      studioId: 'ALL',
      submissionState: 'ALL',
      submissionIds: [],
      pattern: '',
      format: 'auto',
      enhanced: enhanced,
    },
  });

  const {
    loading: filenamePatternsLoading,
    data: filenamePatternsData,
    refetch: filenamePatternsRefetch,
  } = useGetExportFilenamePatternsQuery();

  const filenamePatterns = filenamePatternsData?.exportFilenamePatterns || [];

  // sync form with specified state in props only when the user opens the dialog
  useEffect(() => {
    if (props.open) {
      resetForm();
      filenamePatternsRefetch();
    }
    // eslint-disable-next-line
  }, [props.open, reset]);

  // When new filename patterns become available, set to the first pattern. This is really helpful in the case where
  // a user enters a custom pattern
  useEffect(() => {
    setValue('pattern', filenamePatterns[0]);
  }, [setValue, filenamePatterns]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        onExited={resetForm}
        title="Export photos"
        actions={
          <>
            <Button onClick={props.onClose}>CLOSE</Button>
            <Button
              color="primary"
              variant="contained"
              disabled={exportSubmissionsMutation.loading}
              onClick={exportPhotos}
            >
              {exportSubmissionsMutation.loading ? 'EXPORTING...' : 'EXPORT'}
            </Button>
          </>
        }
        width={800}
      >
        {filenamePatternsLoading ? (
          <CircularProgress />
        ) : (
          <ExportForm
            {...props}
            control={control}
            onFilenamePatternsReset={async () => {
              await filenamePatternsRefetch();
              setValue('pattern', filenamePatterns[0]);
            }}
            filenamePatterns={filenamePatterns}
          />
        )}
      </Dialog>
      <PhotoExportAwaitDialog {...exportAwaitDialogProps} />
    </>
  );

  function resetForm() {
    if ('submissionIds' in props) {
      reset({
        studioId: 'ALL',
        submissionState: 'ALL',
        submissionIds: props.submissionIds,
        pattern: filenamePatterns[0],
        customPattern: undefined,
        format: 'auto',
        enhanced: enhanced,
      });
    } else {
      reset({
        studioId: props.studioId ?? 'ALL',
        submissionState: mapToExportableState[props.submissionState] ?? 'ALL',
        submissionIds: [],
        pattern: filenamePatterns[0],
        customPattern: undefined,
        format: 'auto',
        enhanced: enhanced,
      });
    }
  }

  async function exportPhotos() {
    if (!(await trigger())) return;

    onClose();

    const form = getValues();

    let filenamePattern = form.pattern;

    if (filenamePattern == 'custom') {
      filenamePattern = form.customPattern.split('*').join(' ');
    }

    const input = {
      studioId: form.studioId === 'ALL' ? null : form.studioId,
      state: mapToExportableState[form.submissionState],
      filenamePattern,
      ids: form.submissionIds,
      format: form.format == 'auto' ? null : mapToExportableFormat[form.format || 'jpeg'],
      originalPhotos: !form.enhanced,
    } as ExportSubmissionsInput;

    if (form.submissionIds.length == 1) {
      streamExport({
        ids: input.ids || [],
        format: input.format,
        filenamePattern: filenamePattern,
        originalPhotos: input.originalPhotos,
      });

      // Auto close the dialog after download has begun
      setTimeout(() => {
        props.onClose();
      }, 1000);
    } else {
      toast(
        {
          message: 'Export starting...',
          snackbarProps: {
            autoHideDuration: 1000,
          },
        },
        'info',
      );

      handleMutation({
        mutate: () =>
          exportSubmissions({
            variables: {
              input: input,
            },
          }),
        payloadFieldPath: 'exportSubmissions?',
        successCondition: (data) => !!data.exportSubmissions?.exportId,
        onSuccess: () => {
          setTimeout(() => {
            props.onClose();
            openExportAwaitDialog();
          }, 1000);
        }, // type cast because successCondition ensures non-null string
        onError: () => {
          props.onFail && props.onFail();
        },
      });
    }
  }
}

type ExportFormProps = PhotoExportDialogProps & {
  control: Control<ExportFormValue>;
  filenamePatterns: string[];
  onFilenamePatternsReset: () => void;
};

function ExportForm(props: ExportFormProps): JSX.Element {
  const classes = useStyles();
  const { control } = props;

  const manualExport = 'submissionIds' in props;
  const filterExport = !manualExport;

  const studioController = useController({ name: 'studioId', control });
  const statusController = useController({ name: 'submissionState', control });
  const patternController = useController({ name: 'pattern', control });
  const formatController = useController({ name: 'format', control });
  const enhancedController = useController({ name: 'enhanced', control });

  const [runStudioQuery, studioQuery] = useGetStudioOverviewsLazyQuery();
  const [clearPatternHistoryMutation] = useClearExportFilenameHistoryMutation();
  const { data: contactAttributesQuery } = useGetContactAttributesQuery();
  const filenameAttributes = (contactAttributesQuery?.contactAttributes || [])
    .map((attr) => `{{contact.${attr.key}}}`)
    .concat(['{{submittedAt}}', '{{pose.name}}', '{{output.name}}', '/', '-', '_']);

  const [advancedPatternEnabled, setAdvancedPatternEnabled] = useState(false);

  // Clears the Advanced Mode toggle when you select a different filename pattern
  useEffect(() => {
    if (patternController.field.value != 'custom') {
      setAdvancedPatternEnabled(false);
    }
  }, [patternController, setAdvancedPatternEnabled]);

  useEffect(() => {
    // only fetch studios if in filter export that shows a studios select field
    if (filterExport && !studioQuery.called) {
      runStudioQuery();
    }
  }, [filterExport, runStudioQuery, studioQuery.called]);

  const studios = useMemo(
    () => (studioQuery.data?.studios.edges?.length ? studioQuery.data.studios.edges : []),
    [studioQuery.data?.studios.edges],
  );

  const studioItems = useMemo<SelectItem[]>(
    () => [
      {
        label: 'All studios',
        value: 'ALL',
      },
      ...studios.map((studio) => ({
        label: studio.node.name,
        value: studio.node.id,
      })),
    ],
    [studios],
  );

  const statusItems = useMemo<SelectItem[]>(
    () => [
      {
        label: 'All',
        value: 'ALL',
      },
      {
        label: 'Review',
        value: 'REVIEW',
      },
      {
        label: 'Accepted',
        value: 'ACCEPTED',
      },
      {
        label: 'Rejected',
        value: 'REJECTED',
      },
    ],
    [],
  );

  const formatItems = useMemo<SelectItem[]>(
    () => [
      {
        label: 'Auto',
        value: 'auto',
      },
      {
        label: 'JPEG',
        value: 'jpeg',
      },
      {
        label: 'PNG',
        value: 'png',
      },
    ],
    [],
  );

  if (filterExport && studioQuery.loading) {
    return (
      <div className={classes.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }

  if (filterExport && (studioQuery.error || !studioQuery.data)) {
    return <ErrorCard body="Could not load studios." />;
  }

  return (
    <div className={classes.formWrapper}>
      {filterExport && (
        <SelectField label="Studio" variant="outlined" controller={studioController} items={studioItems} />
      )}
      {filterExport && (
        <SelectField label="Submission status" variant="outlined" controller={statusController} items={statusItems} />
      )}
      <SelectField label="Image format" variant="outlined" controller={formatController} items={formatItems} />
      <SelectField
        label="Filename pattern"
        variant="outlined"
        controller={patternController}
        items={props.filenamePatterns
          .map((pattern) => {
            return { value: pattern, label: pattern };
          })
          .concat({ value: 'custom', label: 'Use a custom pattern...' })}
      />
      <FormControlLabel
        control={<SwitchField controller={enhancedController} />}
        label="AI Enhancement (if available)"
      />

      {props.filenamePatterns.some((pattern) => pattern.includes('preset.')) && (
        <Typography>Note: presets have been renamed to outputs, but old filename patterns will still work.</Typography>
      )}

      {patternController.field.value == 'custom' && (
        <Card variant="outlined">
          <Controller
            name="customPattern"
            control={control}
            rules={{
              validate: (value) => {
                if (patternController.field.value == 'custom') {
                  if (!value?.trim()) {
                    return 'Must have a value';
                  }
                }
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  {advancedPatternEnabled ? (
                    <TextField
                      fullWidth
                      multiline
                      variant="outlined"
                      label="Advanced custom filename pattern"
                      value={value}
                      onChange={(value) => onChange(value)}
                    />
                  ) : (
                    <Autocomplete
                      getOptionSelected={() => false}
                      multiple
                      value={value ? value.split('*') : []}
                      onChange={(e, value) => onChange(value.join('*'))}
                      renderInput={(params) => {
                        return <MuiTextField {...params} value="" label="Custom filename pattern" />;
                      }}
                      options={filenameAttributes}
                    />
                  )}

                  <Box className={classes.customFilenamePattern}>
                    <Link onClick={handleClearPatternHistory}>
                      <Typography variant="caption">Clear filename pattern history</Typography>
                    </Link>
                    <FormGroup>
                      <FormControlLabel
                        onChange={() => {
                          onChange(advancedPatternEnabled ? '' : value.replaceAll('*', ' '));
                        }}
                        control={
                          <Switch size="small" onChange={() => setAdvancedPatternEnabled(!advancedPatternEnabled)} />
                        }
                        label="Input custom Liquid (Advanced mode)"
                      />
                    </FormGroup>
                  </Box>
                </>
              );
            }}
          />
        </Card>
      )}
    </div>
  );

  async function handleClearPatternHistory() {
    if (!confirm('Are you sure you would like to clear all filename pattern history?')) return;

    const response = await clearPatternHistoryMutation();

    if (response.errors) {
      toast('Failed to clear filename pattern history', 'error');
    } else {
      toast('Export filename pattern history cleared');
      props.onFilenamePatternsReset();
    }
  }
}
