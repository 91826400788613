import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Download } from 'react-feather';
import { Paper, Tabs, Tab, makeStyles, Button } from '@material-ui/core';

import Page from 'pages/Page';
import { SubmissionsDataGrid } from 'components/SubmissionsDataGrid';
import { ReviewChip } from 'components/ReviewChip';
import { toast } from 'components/GlobalSnackbar';
import useDialog from 'hooks/useDialog';
import { PhotoExportDialog } from 'components/PhotoExportDialog';
import { usePermissions } from 'hooks/usePermissions';
import { StudioSessionInvitations } from 'src/StudioSessionInvitations';

export type SubmissionsProps = {
  // add props here
  selectedTab?: SubmissionTabValue;
};

export type SubmissionTabValue = 'ALL' | 'REVIEW' | 'ACCEPTED' | 'REJECTED' | 'INVITED';
const SUBMISSIONS_TABS: readonly SubmissionTabValue[] = ['ALL', 'REVIEW', 'ACCEPTED', 'REJECTED', 'INVITED'];

const useStyles = makeStyles((theme) => ({
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    borderBottom: '1px solid lightgrey',
    '& .MuiTab-root': {
      [theme.breakpoints.up('sm')]: {
        minWidth: 128,
      },
    },
  },
  tabWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export function Submissions(props: SubmissionsProps): React.ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const perms = usePermissions();

  const [tab, setTab] = useState<SubmissionTabValue>(props.selectedTab || 'ALL');

  // GID of the studio currently selected in the SubmissionsDataGridToolbar
  const [studioId, setStudioId] = useState<string | null>(null);

  // do not directly call setTab, just push onto the history stack
  const handleTabChange = useCallback(
    (_, newTab: SubmissionTabValue) => {
      history.push(`/submissions/${newTab.toLowerCase()}`);
    },
    [history],
  );

  // when props.selectedTab changes, call setTab()
  useEffect(() => {
    props.selectedTab && setTab(props.selectedTab);
  }, [props.selectedTab]);

  return (
    <Page
      title="Submissions"
      maxWidth="xl"
      pageActions={
        // TODO
        // perms.auth?.canExportSubmission &&
        // tab !== 'INVITED' &&
        <ExportSubmissionsPageAction currentTab={tab} currentStudioId={studioId} />
      }
    >
      <Paper className={classes.paper}>
        <Tabs value={tab} onChange={handleTabChange} className={classes.tabs}>
          {SUBMISSIONS_TABS.map((submissionTab, index) => (
            <Tab
              label={
                <div className={classes.tabWrapper}>
                  {submissionTab}
                  {submissionTab === 'REVIEW' && <ReviewChip />}
                </div>
              }
              key={index}
              value={submissionTab}
            />
          ))}
        </Tabs>
        {tab == 'INVITED' ? (
          <StudioSessionInvitations />
        ) : (
          <SubmissionsDataGrid tab={tab} onStudioSelect={(newStudioId) => setStudioId(newStudioId)} />
        )}
      </Paper>
    </Page>
  );
}

type ExportSubmissionsPageActionProps = {
  currentTab: SubmissionTabValue;
  currentStudioId: string | null;
};

function ExportSubmissionsPageAction(props: ExportSubmissionsPageActionProps) {
  const { openDialog: openExportDialog, dialogProps: exportDialogProps, closeDialog: closeExportDialog } = useDialog();

  return (
    <>
      <Button startIcon={<Download />} color="primary" onClick={openExportDialog}>
        Export photos
      </Button>
      <PhotoExportDialog
        {...exportDialogProps}
        submissionState={props.currentTab}
        studioId={props.currentStudioId}
        onFail={handleExportFail}
      />
    </>
  );

  function handleExportFail() {
    closeExportDialog();
    toast('Could not export submissions', 'error');
  }
}
