import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
type JSON = Record<string, string>;
type CustomAttributesInput = Record<string, string>;
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: string;
  /** A set of key -> value pairs, corresponding to contact attributes */
  CustomAttributesInput: CustomAttributesInput;
  DateTime: string;
  /**
   * Must be provided in ISO 8601 duration format.
   *
   * Example: "P1D" for one day, "P1M" for one month.
   */
  Duration: string;
  /** A valid global ID */
  GID: string;
  /** Represents untyped JSON */
  JSON: JSON;
  /** A unique ID generated by Snapbar */
  SnapbarID: string;
  /** A valid URL, transported as a string */
  URL: string;
};

/** Autogenerated input type of AcceptSubmissions */
export type AcceptSubmissionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['GID']>;
};

/** Autogenerated return type of AcceptSubmissions. */
export type AcceptSubmissionsPayload = {
  __typename: 'AcceptSubmissionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  submissions?: Maybe<Array<Submission>>;
};

/** An access token for API resources */
export type AccessToken = {
  __typename: 'AccessToken';
  account?: Maybe<Account>;
  createdAt: Scalars['DateTime'];
  expiresIn?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  scopes: Array<Scalars['String']>;
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum AccessTypeEnum {
  Authenticated = 'authenticated',
  Private = 'private',
  Upload = 'upload'
}

/** An organization using the service */
export type Account = {
  __typename: 'Account';
  createdAt: Scalars['DateTime'];
  /** A global ID unique to the system */
  id: Scalars['GID'];
  name: Scalars['String'];
  organization?: Maybe<Scalars['String']>;
  snapbarId: Scalars['SnapbarID'];
  updatedAt: Scalars['DateTime'];
};

/** Autogenerated input type of AddPose */
export type AddPoseInput = {
  allowPhotoUpload?: Maybe<Scalars['Boolean']>;
  backdrops?: Maybe<Array<BackgroundInput>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customProfile?: Maybe<CustomProfileInput>;
  guidelines?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  presetIds?: Maybe<Array<Scalars['GID']>>;
  profileId?: Maybe<Scalars['GID']>;
  removeBackground?: Maybe<Scalars['Boolean']>;
  studioId: Scalars['GID'];
};

/** Autogenerated return type of AddPose. */
export type AddPosePayload = {
  __typename: 'AddPosePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  pose?: Maybe<Pose>;
};

export type AppliedPreset = {
  __typename: 'AppliedPreset';
  downloadUrl?: Maybe<Scalars['URL']>;
  preset: Preset;
  publicUrl: Scalars['URL'];
};

export type Background = CloudFile | Color;

export type BackgroundInput = {
  color?: Maybe<Scalars['String']>;
  image?: Maybe<CloudFileInput>;
};


export type Branding = {
  __typename: 'Branding';
  background?: Maybe<Background>;
  logo?: Maybe<CloudFile>;
  primaryColor: Scalars['String'];
  secondaryColor: Scalars['String'];
  tertiaryColor: Scalars['String'];
};

export type BrandingInput = {
  background?: Maybe<BackgroundInput>;
  logo?: Maybe<CloudFileInput>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  tertiaryColor?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ClearExportFilenameHistory. */
export type ClearExportFilenameHistoryPayload = {
  __typename: 'ClearExportFilenameHistoryPayload';
  message?: Maybe<Scalars['String']>;
};

export type CloudFile = {
  __typename: 'CloudFile';
  bytes: Scalars['BigInt'];
  provider: CloudProvider;
  providerAccessType?: Maybe<Scalars['String']>;
  providerId: Scalars['String'];
  publicUrl: Scalars['String'];
  type: Scalars['String'];
};

export type CloudFileInput = {
  bytes: Scalars['BigInt'];
  provider: CloudProvider;
  providerAccessType?: Maybe<Scalars['String']>;
  providerId: Scalars['String'];
  publicUrl: Scalars['URL'];
  type: Scalars['String'];
};

export enum CloudProvider {
  Cloudinary = 'CLOUDINARY',
  GcpStorage = 'GCP_STORAGE'
}

export type Color = {
  __typename: 'Color';
  hex: Scalars['String'];
};

export type Contact = {
  __typename: 'Contact';
  contactLists: Array<ContactList>;
  createdAt: Scalars['DateTime'];
  customAttributes?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** A global ID unique to the system */
  id: Scalars['GID'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  submissions: Array<Submission>;
  submissionsCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type ContactAttribute = {
  __typename: 'ContactAttribute';
  createdAt?: Maybe<Scalars['DateTime']>;
  custom: Scalars['Boolean'];
  key: Scalars['String'];
  type: ContactAttributeField;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validation?: Maybe<ContactAttributeValidation>;
};

export enum ContactAttributeField {
  SingleLineText = 'SINGLE_LINE_TEXT'
}

export type ContactAttributeValidation = {
  __typename: 'ContactAttributeValidation';
  createdAt: Scalars['DateTime'];
  errorMessage: Scalars['String'];
  /** A global ID unique to the system */
  id: Scalars['GID'];
  regex: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ContactAttributeValidationInput = {
  errorMessage: Scalars['String'];
  regex: Scalars['String'];
};

/** The connection type for Contact. */
export type ContactConnection = {
  __typename: 'ContactConnection';
  /** A list of edges. */
  edges: Array<ContactEdge>;
  /** A list of nodes. */
  nodes: Array<Contact>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ContactEdge = {
  __typename: 'ContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Contact;
};

export type ContactInput = {
  customAttributes?: Maybe<Scalars['CustomAttributesInput']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ContactList = {
  __typename: 'ContactList';
  contactsCount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** A global ID unique to the system */
  id: Scalars['GID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum ContactSortKey {
  CreatedAt = 'CREATED_AT',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  SubmissionsCount = 'SUBMISSIONS_COUNT',
  UpdatedAt = 'UPDATED_AT'
}

/** Autogenerated input type of CreateAccessToken */
export type CreateAccessTokenInput = {
  applicationName?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  scopes: Scalars['String'];
  snapbarId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAccessToken. */
export type CreateAccessTokenPayload = {
  __typename: 'CreateAccessTokenPayload';
  accessToken?: Maybe<AccessToken>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of CreateContactAccessURL */
export type CreateContactAccessUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactId: Scalars['GID'];
  id: Scalars['GID'];
};

/** Autogenerated return type of CreateContactAccessURL. */
export type CreateContactAccessUrlPayload = {
  __typename: 'CreateContactAccessURLPayload';
  accessUrl?: Maybe<Scalars['URL']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of CreateContactAttribute */
export type CreateContactAttributeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  type?: Maybe<ContactAttributeField>;
  validation?: Maybe<ContactAttributeValidationInput>;
};

/** Autogenerated return type of CreateContactAttribute. */
export type CreateContactAttributePayload = {
  __typename: 'CreateContactAttributePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactAttribute?: Maybe<ContactAttribute>;
  errors: Array<UserError>;
};

/** Autogenerated input type of CreateContact */
export type CreateContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactListIds?: Maybe<Array<Scalars['GID']>>;
  customAttributes?: Maybe<Scalars['CustomAttributesInput']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateContactList */
export type CreateContactListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateContactList. */
export type CreateContactListPayload = {
  __typename: 'CreateContactListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactList?: Maybe<ContactList>;
  errors: Array<UserError>;
};

/** Autogenerated return type of CreateContact. */
export type CreateContactPayload = {
  __typename: 'CreateContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  errors: Array<UserError>;
};

/** Autogenerated input type of CreatePhotoFilter */
export type CreatePhotoFilterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cloudinaryArtisticFilter?: Maybe<Scalars['String']>;
  cloudinaryTransform?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Autogenerated return type of CreatePhotoFilter. */
export type CreatePhotoFilterPayload = {
  __typename: 'CreatePhotoFilterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  photoFilter?: Maybe<PhotoFilter>;
};

/** Autogenerated input type of CreatePoseProfile */
export type CreatePoseProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  exampleImages?: Maybe<Array<CloudFileInput>>;
  name: Scalars['String'];
  outlineImage?: Maybe<CloudFileInput>;
};

/** Autogenerated return type of CreatePoseProfile. */
export type CreatePoseProfilePayload = {
  __typename: 'CreatePoseProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  poseProfile?: Maybe<PoseProfile>;
};

/** Autogenerated input type of CreatePreset */
export type CreatePresetInput = {
  autoEnhance?: Maybe<Scalars['Boolean']>;
  backdrop?: Maybe<BackgroundInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cloudinaryTransform?: Maybe<Scalars['String']>;
  crop?: Maybe<CropInput>;
  imageFormat?: Maybe<ImageFormat>;
  name: Scalars['String'];
  overlayImage?: Maybe<CloudFileInput>;
  photoFilterId?: Maybe<Scalars['GID']>;
  resize?: Maybe<PhotoSize>;
  visibleToContact?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreatePreset. */
export type CreatePresetPayload = {
  __typename: 'CreatePresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  preset?: Maybe<Preset>;
};

/** Autogenerated input type of CreateStudio */
export type CreateStudioInput = {
  aiEnhancementEnabled?: Maybe<Scalars['Boolean']>;
  branding?: Maybe<BrandingInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['GID']>;
  customAccessUrl?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<DisclaimerInput>;
  endDate?: Maybe<Scalars['String']>;
  formFields?: Maybe<Array<FormFieldInput>>;
  galleryEnabled?: Maybe<Scalars['Boolean']>;
  handle: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  maxSubmissionsPerContact?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  photosQuotaLimit?: Maybe<Scalars['Int']>;
  requireSubmissionReview?: Maybe<Scalars['Boolean']>;
  sharingPassword?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<SharingStatus>;
  startDate: Scalars['String'];
};

/** Autogenerated return type of CreateStudio. */
export type CreateStudioPayload = {
  __typename: 'CreateStudioPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  studio?: Maybe<Studio>;
};

/** Autogenerated input type of CreateStudioSession */
export type CreateStudioSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  studioId: Scalars['GID'];
  /** The URL of the studio to be accessed. Default is the global studio URL. */
  studioUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateStudioSession. */
export type CreateStudioSessionPayload = {
  __typename: 'CreateStudioSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  studioSession?: Maybe<StudioSession>;
};

/** Autogenerated input type of CreateSubmissionNotification */
export type CreateSubmissionNotificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  frequency: FrequencyEnum;
  studioId: Scalars['GID'];
};

/** Autogenerated return type of CreateSubmissionNotification. */
export type CreateSubmissionNotificationPayload = {
  __typename: 'CreateSubmissionNotificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  submissionNotification?: Maybe<SubmissionNotification>;
};

export type Crop = {
  __typename: 'Crop';
  factor: CropFactor;
  gravity?: Maybe<CropGravity>;
};

export enum CropFactor {
  /** Circle crop (1x1) */
  Circle = 'CIRCLE',
  /** 3x4 */
  Landscape = 'LANDSCAPE',
  /** 4x3 */
  Portrait = 'PORTRAIT',
  /** 1x1 */
  Square = 'SQUARE'
}

export enum CropGravity {
  Center = 'CENTER',
  Face = 'FACE'
}

export type CropInput = {
  factor: CropFactor;
  gravity: CropGravity;
};


export type CustomProfileInput = {
  exampleImages?: Maybe<Array<CloudFileInput>>;
  outlineImage?: Maybe<CloudFileInput>;
};


/** Autogenerated input type of DeleteAccessToken */
export type DeleteAccessTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** Autogenerated return type of DeleteAccessToken. */
export type DeleteAccessTokenPayload = {
  __typename: 'DeleteAccessTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTokenId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeleteContactAttribute */
export type DeleteContactAttributeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  key: Scalars['String'];
};

/** Autogenerated return type of DeleteContactAttribute. */
export type DeleteContactAttributePayload = {
  __typename: 'DeleteContactAttributePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedKey?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeleteContactList */
export type DeleteContactListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
};

/** Autogenerated return type of DeleteContactList. */
export type DeleteContactListPayload = {
  __typename: 'DeleteContactListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['GID']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeleteContacts */
export type DeleteContactsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['GID']>;
};

/** Autogenerated return type of DeleteContacts. */
export type DeleteContactsPayload = {
  __typename: 'DeleteContactsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedIds: Array<Scalars['GID']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeletePhotoFilter */
export type DeletePhotoFilterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
};

/** Autogenerated return type of DeletePhotoFilter. */
export type DeletePhotoFilterPayload = {
  __typename: 'DeletePhotoFilterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['GID']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeletePose */
export type DeletePoseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
  studioId: Scalars['GID'];
};

/** Autogenerated return type of DeletePose. */
export type DeletePosePayload = {
  __typename: 'DeletePosePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['GID']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeletePoseProfile */
export type DeletePoseProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
};

/** Autogenerated return type of DeletePoseProfile. */
export type DeletePoseProfilePayload = {
  __typename: 'DeletePoseProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['GID']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeletePreset */
export type DeletePresetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
};

/** Autogenerated return type of DeletePreset. */
export type DeletePresetPayload = {
  __typename: 'DeletePresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['GID']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeleteStudio */
export type DeleteStudioInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
};

/** Autogenerated return type of DeleteStudio. */
export type DeleteStudioPayload = {
  __typename: 'DeleteStudioPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['GID']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeleteStudioSessions */
export type DeleteStudioSessionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['GID']>;
};

/** Autogenerated return type of DeleteStudioSessions. */
export type DeleteStudioSessionsPayload = {
  __typename: 'DeleteStudioSessionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedIds?: Maybe<Array<Scalars['GID']>>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeleteSubmissionNotifications */
export type DeleteSubmissionNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['GID']>;
};

/** Autogenerated return type of DeleteSubmissionNotifications. */
export type DeleteSubmissionNotificationsPayload = {
  __typename: 'DeleteSubmissionNotificationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedIds: Array<Scalars['GID']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeleteSubmissions */
export type DeleteSubmissionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['GID']>;
};

/** Autogenerated return type of DeleteSubmissions. */
export type DeleteSubmissionsPayload = {
  __typename: 'DeleteSubmissionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedIds?: Maybe<Array<Scalars['GID']>>;
  errors: Array<UserError>;
};

/** Autogenerated return type of DeselectAccount. */
export type DeselectAccountPayload = {
  __typename: 'DeselectAccountPayload';
  account?: Maybe<Account>;
  errors: Array<UserError>;
};

export type Disclaimer = {
  __typename: 'Disclaimer';
  content: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type DisclaimerInput = {
  content?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};


/** Autogenerated input type of EnhanceSubmissions */
export type EnhanceSubmissionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['GID']>;
  /** Force process each photo, regardless if it's been enhanced before */
  reprocess?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of EnhanceSubmissions. */
export type EnhanceSubmissionsPayload = {
  __typename: 'EnhanceSubmissionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  submissions?: Maybe<Array<Submission>>;
};

export enum EnhancementStatus {
  Enhanced = 'ENHANCED',
  Failed = 'FAILED',
  Original = 'ORIGINAL',
  Processing = 'PROCESSING'
}

export type Export = {
  __typename: 'Export';
  failedCount: Scalars['Int'];
  file?: Maybe<CloudFile>;
  filesCount: Scalars['Int'];
  /** A global ID unique to the system */
  id: Scalars['GID'];
  processedCount: Scalars['Int'];
  status: ExportStatus;
  totalCount: Scalars['Int'];
};

/** Autogenerated input type of ExportContacts */
export type ExportContactsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['GID']>>;
  listId?: Maybe<Scalars['GID']>;
  photoPoseId?: Maybe<Scalars['GID']>;
  presetId?: Maybe<Scalars['GID']>;
};

/** Autogenerated return type of ExportContacts. */
export type ExportContactsPayload = {
  __typename: 'ExportContactsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  exportId?: Maybe<Scalars['GID']>;
};

export enum ExportStatus {
  Created = 'CREATED',
  Done = 'DONE',
  Error = 'ERROR',
  Processing = 'PROCESSING'
}

/** Autogenerated input type of ExportSubmissions */
export type ExportSubmissionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  filenamePattern?: Maybe<Scalars['String']>;
  format?: Maybe<ImageFormat>;
  ids?: Maybe<Array<Scalars['GID']>>;
  originalPhotos?: Maybe<Scalars['Boolean']>;
  state?: Maybe<ExportableSubmissionState>;
  studioId?: Maybe<Scalars['GID']>;
};

/** Autogenerated return type of ExportSubmissions. */
export type ExportSubmissionsPayload = {
  __typename: 'ExportSubmissionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  exportId?: Maybe<Scalars['GID']>;
};

export enum ExportableSubmissionState {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Review = 'REVIEW'
}

export type FormField = {
  __typename: 'FormField';
  choices?: Maybe<Array<Scalars['String']>>;
  contactAttribute: ContactAttribute;
  createdAt: Scalars['DateTime'];
  /** A global ID unique to the system */
  id: Scalars['GID'];
  kind: FormFieldKind;
  label: Scalars['String'];
  required: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type FormFieldInput = {
  choices?: Maybe<Array<Scalars['String']>>;
  contactAttributeKey: Scalars['String'];
  kind?: Maybe<FormFieldKind>;
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
};

export enum FormFieldKind {
  Radio = 'radio',
  Select = 'select',
  Text = 'text'
}

export enum FrequencyEnum {
  Daily = 'DAILY',
  Immediate = 'IMMEDIATE',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}


/** Autogenerated return type of GenerateMetricsToken. */
export type GenerateMetricsTokenPayload = {
  __typename: 'GenerateMetricsTokenPayload';
  errors: Array<UserError>;
  token?: Maybe<Scalars['String']>;
};

export enum ImageFormat {
  Gif = 'gif',
  Jpeg = 'jpeg',
  Png = 'png'
}

/** Autogenerated input type of InviteContactList */
export type InviteContactListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactListId: Scalars['GID'];
  studioId: Scalars['GID'];
};

/** Autogenerated return type of InviteContactList. */
export type InviteContactListPayload = {
  __typename: 'InviteContactListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactList?: Maybe<ContactList>;
  errors: Array<UserError>;
};

/** Autogenerated input type of InviteContacts */
export type InviteContactsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emails: Array<Scalars['String']>;
  studioId: Scalars['GID'];
};

/** Autogenerated return type of InviteContacts. */
export type InviteContactsPayload = {
  __typename: 'InviteContactsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Contact>>;
  errors: Array<UserError>;
};


/** A user's membership to an account */
export type Membership = {
  __typename: 'Membership';
  /** A global ID unique to the system */
  id: Scalars['GID'];
  permissions: Array<Scalars['String']>;
  snapbarId: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  acceptSubmissions?: Maybe<AcceptSubmissionsPayload>;
  addPose?: Maybe<AddPosePayload>;
  clearExportFilenameHistory?: Maybe<ClearExportFilenameHistoryPayload>;
  createAccessToken?: Maybe<CreateAccessTokenPayload>;
  createContact?: Maybe<CreateContactPayload>;
  createContactAccessUrl?: Maybe<CreateContactAccessUrlPayload>;
  createContactAttribute?: Maybe<CreateContactAttributePayload>;
  createContactList?: Maybe<CreateContactListPayload>;
  createPhotoFilter?: Maybe<CreatePhotoFilterPayload>;
  createPoseProfile?: Maybe<CreatePoseProfilePayload>;
  createPreset?: Maybe<CreatePresetPayload>;
  createStudio?: Maybe<CreateStudioPayload>;
  createStudioSession?: Maybe<CreateStudioSessionPayload>;
  createSubmissionNotification?: Maybe<CreateSubmissionNotificationPayload>;
  deleteAccessToken?: Maybe<DeleteAccessTokenPayload>;
  deleteContactAttribute?: Maybe<DeleteContactAttributePayload>;
  deleteContactList?: Maybe<DeleteContactListPayload>;
  deleteContacts?: Maybe<DeleteContactsPayload>;
  deletePhotoFilter?: Maybe<DeletePhotoFilterPayload>;
  deletePose?: Maybe<DeletePosePayload>;
  deletePoseProfile?: Maybe<DeletePoseProfilePayload>;
  deletePreset?: Maybe<DeletePresetPayload>;
  deleteStudio?: Maybe<DeleteStudioPayload>;
  deleteStudioSessions?: Maybe<DeleteStudioSessionsPayload>;
  deleteSubmissionNotifications?: Maybe<DeleteSubmissionNotificationsPayload>;
  deleteSubmissions?: Maybe<DeleteSubmissionsPayload>;
  /** Deselect current account. */
  deselectAccount?: Maybe<DeselectAccountPayload>;
  enhanceSubmissions?: Maybe<EnhanceSubmissionsPayload>;
  exportContacts?: Maybe<ExportContactsPayload>;
  exportSubmissions?: Maybe<ExportSubmissionsPayload>;
  generateMetricsToken?: Maybe<GenerateMetricsTokenPayload>;
  inviteContactList?: Maybe<InviteContactListPayload>;
  inviteContacts?: Maybe<InviteContactsPayload>;
  presignCloudinaryFile?: Maybe<PresignCloudinaryFilePayload>;
  previewPreset?: Maybe<PreviewPresetPayload>;
  rejectSubmissions?: Maybe<RejectSubmissionsPayload>;
  resetStudioPhotosCount?: Maybe<ResetStudioPhotosCountPayload>;
  /** Select an account based on a Snapbar ID */
  selectAccount?: Maybe<SelectAccountPayload>;
  sendReminders?: Maybe<SendRemindersPayload>;
  setDefaultPreset?: Maybe<SetDefaultPresetPayload>;
  signExport?: Maybe<SignExportPayload>;
  submitPhotos?: Maybe<SubmitPhotosPayload>;
  updateContact?: Maybe<UpdateContactPayload>;
  updateContactAttribute?: Maybe<UpdateContactAttributePayload>;
  updateContactList?: Maybe<UpdateContactListPayload>;
  updatePhotoFilter?: Maybe<UpdatePhotoFilterPayload>;
  updatePose?: Maybe<UpdatePosePayload>;
  updatePoseProfile?: Maybe<UpdatePoseProfilePayload>;
  updatePreset?: Maybe<UpdatePresetPayload>;
  updateStudio?: Maybe<UpdateStudioPayload>;
};


export type MutationAcceptSubmissionsArgs = {
  input: AcceptSubmissionsInput;
};


export type MutationAddPoseArgs = {
  input: AddPoseInput;
};


export type MutationCreateAccessTokenArgs = {
  input: CreateAccessTokenInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateContactAccessUrlArgs = {
  input: CreateContactAccessUrlInput;
};


export type MutationCreateContactAttributeArgs = {
  input: CreateContactAttributeInput;
};


export type MutationCreateContactListArgs = {
  input: CreateContactListInput;
};


export type MutationCreatePhotoFilterArgs = {
  input: CreatePhotoFilterInput;
};


export type MutationCreatePoseProfileArgs = {
  input: CreatePoseProfileInput;
};


export type MutationCreatePresetArgs = {
  input: CreatePresetInput;
};


export type MutationCreateStudioArgs = {
  input: CreateStudioInput;
};


export type MutationCreateStudioSessionArgs = {
  input: CreateStudioSessionInput;
};


export type MutationCreateSubmissionNotificationArgs = {
  input: CreateSubmissionNotificationInput;
};


export type MutationDeleteAccessTokenArgs = {
  input: DeleteAccessTokenInput;
};


export type MutationDeleteContactAttributeArgs = {
  input: DeleteContactAttributeInput;
};


export type MutationDeleteContactListArgs = {
  input: DeleteContactListInput;
};


export type MutationDeleteContactsArgs = {
  input: DeleteContactsInput;
};


export type MutationDeletePhotoFilterArgs = {
  input: DeletePhotoFilterInput;
};


export type MutationDeletePoseArgs = {
  input: DeletePoseInput;
};


export type MutationDeletePoseProfileArgs = {
  input: DeletePoseProfileInput;
};


export type MutationDeletePresetArgs = {
  input: DeletePresetInput;
};


export type MutationDeleteStudioArgs = {
  input: DeleteStudioInput;
};


export type MutationDeleteStudioSessionsArgs = {
  input: DeleteStudioSessionsInput;
};


export type MutationDeleteSubmissionNotificationsArgs = {
  input: DeleteSubmissionNotificationsInput;
};


export type MutationDeleteSubmissionsArgs = {
  input: DeleteSubmissionsInput;
};


export type MutationEnhanceSubmissionsArgs = {
  input: EnhanceSubmissionsInput;
};


export type MutationExportContactsArgs = {
  input: ExportContactsInput;
};


export type MutationExportSubmissionsArgs = {
  input: ExportSubmissionsInput;
};


export type MutationInviteContactListArgs = {
  input: InviteContactListInput;
};


export type MutationInviteContactsArgs = {
  input: InviteContactsInput;
};


export type MutationPresignCloudinaryFileArgs = {
  accessType?: Maybe<AccessTypeEnum>;
  accountId: Scalars['String'];
  filename: Scalars['String'];
};


export type MutationPreviewPresetArgs = {
  input: PreviewPresetInput;
};


export type MutationRejectSubmissionsArgs = {
  input: RejectSubmissionsInput;
};


export type MutationResetStudioPhotosCountArgs = {
  input: ResetStudioPhotosCountInput;
};


export type MutationSelectAccountArgs = {
  snapbarId: Scalars['SnapbarID'];
};


export type MutationSendRemindersArgs = {
  input: SendRemindersInput;
};


export type MutationSetDefaultPresetArgs = {
  input: SetDefaultPresetInput;
};


export type MutationSignExportArgs = {
  input: SignExportInput;
};


export type MutationSubmitPhotosArgs = {
  input: SubmitPhotosInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateContactAttributeArgs = {
  input: UpdateContactAttributeInput;
};


export type MutationUpdateContactListArgs = {
  input: UpdateContactListInput;
};


export type MutationUpdatePhotoFilterArgs = {
  input: UpdatePhotoFilterInput;
};


export type MutationUpdatePoseArgs = {
  input: UpdatePoseInput;
};


export type MutationUpdatePoseProfileArgs = {
  input: UpdatePoseProfileInput;
};


export type MutationUpdatePresetArgs = {
  input: UpdatePresetInput;
};


export type MutationUpdateStudioArgs = {
  input: UpdateStudioInput;
};

export type NullableStudioSession = {
  __typename: 'NullableStudioSession';
  closedAt?: Maybe<Scalars['DateTime']>;
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['GID']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  studio: Studio;
  submission?: Maybe<Submission>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Photo = {
  __typename: 'Photo';
  appliedPresets: Array<AppliedPreset>;
  backdrop?: Maybe<Background>;
  enhancedFile?: Maybe<CloudFile>;
  enhancementStatus: EnhancementStatus;
  file: CloudFile;
  /** A global ID unique to the system */
  id: Scalars['GID'];
  name: Scalars['String'];
  pose?: Maybe<Pose>;
};


export type PhotoAppliedPresetsArgs = {
  original?: Maybe<Scalars['Boolean']>;
};

export type PhotoFilter = {
  __typename: 'PhotoFilter';
  cloudinaryArtisticFilter?: Maybe<Scalars['String']>;
  cloudinaryTransform?: Maybe<Scalars['String']>;
  /** A global ID unique to the system */
  id: Scalars['GID'];
  name: Scalars['String'];
};

export type PhotoInput = {
  backdrop?: Maybe<BackgroundInput>;
  file: CloudFileInput;
  poseId: Scalars['GID'];
};

export enum PhotoSize {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  Thumb = 'THUMB'
}

export type Pose = {
  __typename: 'Pose';
  allowPhotoUpload: Scalars['Boolean'];
  backdrops?: Maybe<Array<Background>>;
  guidelines: Scalars['String'];
  /** A global ID unique to the system */
  id: Scalars['GID'];
  name: Scalars['String'];
  presets: Array<Preset>;
  profile: PoseProfile;
  removeBackground: Scalars['Boolean'];
};

export type PoseProfile = {
  __typename: 'PoseProfile';
  exampleImages: Array<CloudFile>;
  id?: Maybe<Scalars['GID']>;
  name?: Maybe<Scalars['String']>;
  outlineImage?: Maybe<CloudFile>;
};

export type Preset = {
  __typename: 'Preset';
  autoEnhance?: Maybe<Scalars['Boolean']>;
  backdrop?: Maybe<Background>;
  cloudinaryTransform?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  crop?: Maybe<Crop>;
  default: Scalars['Boolean'];
  /** A global ID unique to the system */
  id: Scalars['GID'];
  imageFormat: ImageFormat;
  name: Scalars['String'];
  overlayImage?: Maybe<CloudFile>;
  photoFilter?: Maybe<PhotoFilter>;
  resize: PhotoSize;
  sampleUrl?: Maybe<Scalars['URL']>;
  updatedAt: Scalars['DateTime'];
  visibleToContact?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of PresignCloudinaryFile. */
export type PresignCloudinaryFilePayload = {
  __typename: 'PresignCloudinaryFilePayload';
  apiKey?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  params?: Maybe<Scalars['JSON']>;
  publicId?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['URL']>;
};

/** Autogenerated input type of PreviewPreset */
export type PreviewPresetInput = {
  autoEnhance?: Maybe<Scalars['Boolean']>;
  backdrop?: Maybe<BackgroundInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cloudinaryTransform?: Maybe<Scalars['String']>;
  crop?: Maybe<CropInput>;
  imageFormat?: Maybe<ImageFormat>;
  overlayImage?: Maybe<CloudFileInput>;
  photoFilterId?: Maybe<Scalars['GID']>;
  resize?: Maybe<PhotoSize>;
  visibleToContact?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of PreviewPreset. */
export type PreviewPresetPayload = {
  __typename: 'PreviewPresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  originalUrl?: Maybe<Scalars['URL']>;
  sampleUrl?: Maybe<Scalars['URL']>;
};

/** The root object */
export type Query = {
  __typename: 'Query';
  /** A list of available scopes for access tokens */
  accessTokenScopes?: Maybe<Array<Scalars['String']>>;
  /** A list of current access tokens */
  accessTokens?: Maybe<Array<AccessToken>>;
  /** Find a contact by ID */
  contact?: Maybe<Contact>;
  contactAttributes: Array<ContactAttribute>;
  /** Retrieve a contact list by its GID. */
  contactList?: Maybe<ContactList>;
  /** Show contact lists for the account */
  contactLists: Array<ContactList>;
  /** Show contacts for the account */
  contacts: ContactConnection;
  /** Show the selected account */
  currentAccount?: Maybe<Account>;
  /** Show the user's membership with the selected account */
  currentMembership?: Maybe<Membership>;
  /** The current logged in user */
  currentUser?: Maybe<User>;
  /** Find a specific export by ID */
  export?: Maybe<Export>;
  /** Get a list of filename patterns used for exports */
  exportFilenamePatterns?: Maybe<Array<Scalars['String']>>;
  /** Obtain a photo filter by its ID */
  photoFilter?: Maybe<PhotoFilter>;
  /** Show a list of globally available photo filters */
  photoFilters: Array<PhotoFilter>;
  /** Find a global pose profile by its ID */
  poseProfile?: Maybe<PoseProfile>;
  /** Get the global pose profiles available */
  poseProfiles: Array<PoseProfile>;
  /** Get a single preset for the account */
  preset?: Maybe<Preset>;
  /** Show a list of presets for the account */
  presets: Array<Preset>;
  /** Search contacts within an account */
  searchContacts: ContactConnection;
  /** Filter through a list of Snapbar accounts */
  searchSnapbarAccounts?: Maybe<Array<SnapbarAccount>>;
  /** Get a studio by its ID */
  studio?: Maybe<Studio>;
  /** Get any studio by handle */
  studioByHandle?: Maybe<Studio>;
  /** Check if a studio exists */
  studioExists: Scalars['Boolean'];
  /** Get a studio session from a signature */
  studioSession?: Maybe<NullableStudioSession>;
  /** List invited studio sessions */
  studioSessionInvitations: StudioSessionConnection;
  /** Show a list of studios on the account */
  studios: StudioConnection;
  /** Show a list of submissions */
  submissions: SubmissionConnection;
  /** Get user submission notifications */
  userSubmissionNotifications: Array<SubmissionNotification>;
};


/** The root object */
export type QueryAccessTokenScopesArgs = {
  applicationName: Scalars['String'];
};


/** The root object */
export type QueryAccessTokensArgs = {
  onlyApiTokens: Scalars['Boolean'];
};


/** The root object */
export type QueryContactArgs = {
  id: Scalars['GID'];
};


/** The root object */
export type QueryContactListArgs = {
  id: Scalars['GID'];
};


/** The root object */
export type QueryContactsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['GID']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sortBy?: Maybe<ContactSortKey>;
  sortDirection?: Maybe<SortDirection>;
};


/** The root object */
export type QueryExportArgs = {
  id: Scalars['GID'];
};


/** The root object */
export type QueryPhotoFilterArgs = {
  id: Scalars['GID'];
};


/** The root object */
export type QueryPoseProfileArgs = {
  id: Scalars['GID'];
};


/** The root object */
export type QueryPresetArgs = {
  id: Scalars['GID'];
};


/** The root object */
export type QuerySearchContactsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query: Scalars['String'];
};


/** The root object */
export type QuerySearchSnapbarAccountsArgs = {
  query: Scalars['String'];
};


/** The root object */
export type QueryStudioArgs = {
  id: Scalars['GID'];
};


/** The root object */
export type QueryStudioByHandleArgs = {
  handle: Scalars['String'];
};


/** The root object */
export type QueryStudioExistsArgs = {
  handle: Scalars['String'];
};


/** The root object */
export type QueryStudioSessionArgs = {
  accessSignature: Scalars['String'];
  omitPrivatePresets?: Maybe<Scalars['Boolean']>;
  studioHandle: Scalars['String'];
};


/** The root object */
export type QueryStudioSessionInvitationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<StudioSessionSortKey>;
  sortDirection?: Maybe<SortDirection>;
  studioId?: Maybe<Scalars['GID']>;
};


/** The root object */
export type QueryStudiosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};


/** The root object */
export type QuerySubmissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SubmissionSortKey>;
  sortDirection?: Maybe<SortDirection>;
  state?: Maybe<SubmissionState>;
  studioIds?: Maybe<Array<Scalars['GID']>>;
  submittedAfter?: Maybe<Scalars['DateTime']>;
};

/** Autogenerated input type of RejectSubmissions */
export type RejectSubmissionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['GID']>;
  retake?: Maybe<RetakeInput>;
};

/** Autogenerated return type of RejectSubmissions. */
export type RejectSubmissionsPayload = {
  __typename: 'RejectSubmissionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  submissions?: Maybe<Array<Submission>>;
};

/** Autogenerated input type of ResetStudioPhotosCount */
export type ResetStudioPhotosCountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
};

/** Autogenerated return type of ResetStudioPhotosCount. */
export type ResetStudioPhotosCountPayload = {
  __typename: 'ResetStudioPhotosCountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  studio?: Maybe<Studio>;
};

export type RetakeInput = {
  message: Scalars['String'];
};

/** Autogenerated return type of SelectAccount. */
export type SelectAccountPayload = {
  __typename: 'SelectAccountPayload';
  account?: Maybe<Account>;
  errors: Array<UserError>;
};

/** Autogenerated input type of SendReminders */
export type SendRemindersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['GID']>;
};

/** Autogenerated return type of SendReminders. */
export type SendRemindersPayload = {
  __typename: 'SendRemindersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SetDefaultPreset */
export type SetDefaultPresetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
};

/** Autogenerated return type of SetDefaultPreset. */
export type SetDefaultPresetPayload = {
  __typename: 'SetDefaultPresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  preset?: Maybe<Preset>;
};

export enum SharingStatus {
  /** Only available to contacts with a unique link */
  Private = 'PRIVATE',
  /** Available to the public for submission with a single URL */
  Public = 'PUBLIC'
}

/** Autogenerated input type of SignExport */
export type SignExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
};

/** Autogenerated return type of SignExport. */
export type SignExportPayload = {
  __typename: 'SignExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  url?: Maybe<Scalars['URL']>;
};

/** An account from Snapbar */
export type SnapbarAccount = {
  __typename: 'SnapbarAccount';
  name: Scalars['String'];
  snapbarId: Scalars['SnapbarID'];
};


export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** A method to capture and receive photos from contacts */
export type Studio = {
  __typename: 'Studio';
  account: Account;
  /** A URL from which an admin can view the studio */
  adminAccessUrl: Scalars['URL'];
  aiEnhancementEnabled: Scalars['Boolean'];
  branding: Branding;
  contactList?: Maybe<ContactList>;
  /** The coordinator(client) in charge of the studio */
  coordinator?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  /** Override the default access url location */
  customAccessUrl?: Maybe<Scalars['String']>;
  disclaimer: Disclaimer;
  /** When the studio ends */
  endDate?: Maybe<Scalars['DateTime']>;
  formFields: Array<FormField>;
  /** Show gallery of contact's photos in Studio */
  galleryEnabled: Scalars['Boolean'];
  handle: Scalars['String'];
  /** A global ID unique to the system */
  id: Scalars['GID'];
  locale: Scalars['String'];
  maxSubmissionsPerContact?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /** The user who initially created the studio */
  originator?: Maybe<User>;
  /** The current number of submissions the studio has received */
  photosCount: Scalars['Int'];
  /** The maximum number of submissions available under the studio */
  photosQuotaLimit?: Maybe<Scalars['Int']>;
  /** The total size of files being stored (in bytes) */
  photosStoredSize?: Maybe<Scalars['Int']>;
  poses: Array<Pose>;
  publicUrl: Scalars['URL'];
  /**
   * An expression of time that determines the intervals at which reminders will be sent.
   * Must be provided in ISO 8601 duration format. If not provided, reminders will not be sent.
   *
   * Example: "P1D" for one day, "P1M" for one month.
   */
  reminderDuration?: Maybe<Scalars['Duration']>;
  /** Toggle for reminders on or off */
  remindersEnabled?: Maybe<Scalars['Boolean']>;
  requireSubmissionReview: Scalars['Boolean'];
  sharingPassword?: Maybe<Scalars['String']>;
  sharingStatus: SharingStatus;
  /** When the studio begins */
  startDate: Scalars['DateTime'];
  status: StudioStatus;
  suggestMobileDevice: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

/** The connection type for Studio. */
export type StudioConnection = {
  __typename: 'StudioConnection';
  /** A list of edges. */
  edges: Array<StudioEdge>;
  /** A list of nodes. */
  nodes: Array<Studio>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type StudioEdge = {
  __typename: 'StudioEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Studio;
};

export type StudioSession = {
  __typename: 'StudioSession';
  closedAt?: Maybe<Scalars['DateTime']>;
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  /** A global ID unique to the system */
  id: Scalars['GID'];
  invitedAt?: Maybe<Scalars['DateTime']>;
  studio: Studio;
  submission?: Maybe<Submission>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** The connection type for StudioSession. */
export type StudioSessionConnection = {
  __typename: 'StudioSessionConnection';
  /** A list of edges. */
  edges: Array<StudioSessionEdge>;
  /** A list of nodes. */
  nodes: Array<StudioSession>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type StudioSessionEdge = {
  __typename: 'StudioSessionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: StudioSession;
};

export enum StudioSessionSortKey {
  ContactFirstName = 'CONTACT_FIRST_NAME',
  ContactLastName = 'CONTACT_LAST_NAME',
  Email = 'EMAIL',
  InvitedAt = 'INVITED_AT'
}

export enum StudioStatus {
  /** The studio has ended */
  Ended = 'ENDED',
  /** Currently accepting submissions */
  Open = 'OPEN',
  /** Max submissions has been reached */
  QuotaReached = 'QUOTA_REACHED',
  /** The studio hasn't started yet */
  Scheduled = 'SCHEDULED'
}

export type Submission = {
  __typename: 'Submission';
  contact: Contact;
  createdAt: Scalars['DateTime'];
  /** A global ID unique to the system */
  id: Scalars['GID'];
  photos: Array<Photo>;
  state: SubmissionState;
  studio?: Maybe<Studio>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

/** The connection type for Submission. */
export type SubmissionConnection = {
  __typename: 'SubmissionConnection';
  /** A list of edges. */
  edges: Array<SubmissionEdge>;
  /** A list of nodes. */
  nodes: Array<Submission>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SubmissionEdge = {
  __typename: 'SubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Submission;
};

export type SubmissionNotification = {
  __typename: 'SubmissionNotification';
  endAt: Scalars['DateTime'];
  frequency: FrequencyEnum;
  /** A global ID unique to the system */
  id: Scalars['GID'];
  lastSentAt?: Maybe<Scalars['DateTime']>;
  startAt: Scalars['DateTime'];
  studio?: Maybe<Studio>;
  user: User;
};

export enum SubmissionSortKey {
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  SubmittedAt = 'SUBMITTED_AT'
}

export enum SubmissionState {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Review = 'REVIEW'
}

/** Autogenerated input type of SubmitPhotos */
export type SubmitPhotosInput = {
  /**
   * A unique token generated by a contact invite or email verification that
   * ensures the contact is genuinely who they say they are.
   */
  accessSignature: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact: ContactInput;
  photos: Array<PhotoInput>;
  /** When true, submission will not be saved. Used for previewing photos prior to submission. */
  preview?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of SubmitPhotos. */
export type SubmitPhotosPayload = {
  __typename: 'SubmitPhotosPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  submission?: Maybe<Submission>;
};


/** Autogenerated input type of UpdateContactAttribute */
export type UpdateContactAttributeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  validation?: Maybe<ContactAttributeValidationInput>;
};

/** Autogenerated return type of UpdateContactAttribute. */
export type UpdateContactAttributePayload = {
  __typename: 'UpdateContactAttributePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactAttribute?: Maybe<ContactAttribute>;
  errors: Array<UserError>;
};

/** Autogenerated input type of UpdateContact */
export type UpdateContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactListIds?: Maybe<Array<Scalars['GID']>>;
  customAttributes?: Maybe<Scalars['CustomAttributesInput']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateContactList */
export type UpdateContactListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateContactList. */
export type UpdateContactListPayload = {
  __typename: 'UpdateContactListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactList?: Maybe<ContactList>;
  errors: Array<UserError>;
};

/** Autogenerated return type of UpdateContact. */
export type UpdateContactPayload = {
  __typename: 'UpdateContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  errors: Array<UserError>;
};

/** Autogenerated input type of UpdatePhotoFilter */
export type UpdatePhotoFilterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cloudinaryArtisticFilter?: Maybe<Scalars['String']>;
  cloudinaryTransform?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePhotoFilter. */
export type UpdatePhotoFilterPayload = {
  __typename: 'UpdatePhotoFilterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  photoFilter?: Maybe<PhotoFilter>;
};

/** Autogenerated input type of UpdatePose */
export type UpdatePoseInput = {
  allowPhotoUpload?: Maybe<Scalars['Boolean']>;
  backdrops?: Maybe<Array<BackgroundInput>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customProfile?: Maybe<CustomProfileInput>;
  guidelines?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
  name?: Maybe<Scalars['String']>;
  presetIds?: Maybe<Array<Scalars['GID']>>;
  profileId?: Maybe<Scalars['GID']>;
  removeBackground?: Maybe<Scalars['Boolean']>;
  studioId: Scalars['GID'];
};

/** Autogenerated return type of UpdatePose. */
export type UpdatePosePayload = {
  __typename: 'UpdatePosePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  pose?: Maybe<Pose>;
};

/** Autogenerated input type of UpdatePoseProfile */
export type UpdatePoseProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  exampleImages?: Maybe<Array<CloudFileInput>>;
  id: Scalars['GID'];
  name?: Maybe<Scalars['String']>;
  outlineImage?: Maybe<CloudFileInput>;
};

/** Autogenerated return type of UpdatePoseProfile. */
export type UpdatePoseProfilePayload = {
  __typename: 'UpdatePoseProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  poseProfile?: Maybe<PoseProfile>;
};

/** Autogenerated input type of UpdatePreset */
export type UpdatePresetInput = {
  autoEnhance?: Maybe<Scalars['Boolean']>;
  backdrop?: Maybe<BackgroundInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cloudinaryTransform?: Maybe<Scalars['String']>;
  crop?: Maybe<CropInput>;
  id: Scalars['GID'];
  imageFormat?: Maybe<ImageFormat>;
  name?: Maybe<Scalars['String']>;
  overlayImage?: Maybe<CloudFileInput>;
  photoFilterId?: Maybe<Scalars['GID']>;
  resize?: Maybe<PhotoSize>;
  visibleToContact?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdatePreset. */
export type UpdatePresetPayload = {
  __typename: 'UpdatePresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  preset?: Maybe<Preset>;
};

/** Autogenerated input type of UpdateStudio */
export type UpdateStudioInput = {
  aiEnhancementEnabled?: Maybe<Scalars['Boolean']>;
  branding?: Maybe<BrandingInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['GID']>;
  customAccessUrl?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<DisclaimerInput>;
  endDate?: Maybe<Scalars['String']>;
  formFields?: Maybe<Array<FormFieldInput>>;
  galleryEnabled?: Maybe<Scalars['Boolean']>;
  handle?: Maybe<Scalars['String']>;
  id: Scalars['GID'];
  locale?: Maybe<Scalars['String']>;
  maxSubmissionsPerContact?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  photosQuotaLimit?: Maybe<Scalars['Int']>;
  reminderDuration?: Maybe<Scalars['Duration']>;
  remindersEnabled?: Maybe<Scalars['Boolean']>;
  requireSubmissionReview?: Maybe<Scalars['Boolean']>;
  sharingPassword?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<SharingStatus>;
  startDate?: Maybe<Scalars['String']>;
  suggestMobileDevice?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateStudio. */
export type UpdateStudioPayload = {
  __typename: 'UpdateStudioPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  studio?: Maybe<Studio>;
};

/** A user who has access to the admin */
export type User = {
  __typename: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  /** A global ID unique to the system */
  id: Scalars['GID'];
  lastActiveAt: Scalars['DateTime'];
  lastName: Scalars['String'];
  snapbarId: Scalars['String'];
  /** If the user has full permissions */
  staff: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

/** A user-readable error */
export type UserError = {
  __typename: 'UserError';
  /** A description of the error */
  message: Scalars['String'];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars['String']>>;
};

export type AccountMenuQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type AccountMenuQuery = { currentAccount?: Maybe<{ __typename: 'Account', id: string, name: string, organization?: Maybe<string> }>, searchSnapbarAccounts?: Maybe<Array<{ __typename: 'SnapbarAccount', snapbarId: string, name: string }>> };

export type ContactsDataGridQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  contactListId?: Maybe<Scalars['GID']>;
  sortBy?: Maybe<ContactSortKey>;
  sortDirection?: Maybe<SortDirection>;
}>;


export type ContactsDataGridQuery = { contacts: { __typename: 'ContactConnection', totalCount: number, edges: Array<{ __typename: 'ContactEdge', cursor: string, node: (
        { __typename: 'Contact' }
        & ContactRowFragment
      ) }>, pageInfo: { __typename: 'PageInfo', startCursor?: Maybe<string>, endCursor?: Maybe<string>, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type SearchContactsQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchContactsQuery = { searchContacts: { __typename: 'ContactConnection', nodes: Array<{ __typename: 'Contact', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, email?: Maybe<string> }> } };

export type FiveContactsQueryVariables = Exact<{
  id: Scalars['GID'];
}>;


export type FiveContactsQuery = { contacts: { __typename: 'ContactConnection', edges: Array<{ __typename: 'ContactEdge', node: { __typename: 'Contact', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, email?: Maybe<string> } }> } };

export type UpdateDisclaimerMutationVariables = Exact<{
  id: Scalars['GID'];
  disclaimer: DisclaimerInput;
}>;


export type UpdateDisclaimerMutation = { updateStudio?: Maybe<{ __typename: 'UpdateStudioPayload', studio?: Maybe<{ __typename: 'Studio', id: string, disclaimer: { __typename: 'Disclaimer', enabled: boolean, content: string } }>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type InviteContactListDialogQueryVariables = Exact<{ [key: string]: never; }>;


export type InviteContactListDialogQuery = { contactLists: Array<{ __typename: 'ContactList', id: string, name: string, contactsCount: number }> };

export type ProfilesCardQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfilesCardQuery = { poseProfiles: Array<{ __typename: 'PoseProfile', id?: Maybe<string>, name?: Maybe<string>, exampleImages: Array<{ __typename: 'CloudFile', publicUrl: string }>, outlineImage?: Maybe<{ __typename: 'CloudFile', publicUrl: string }> }> };

export type ReviewChipQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewChipQuery = { submissions: { __typename: 'SubmissionConnection', totalCount: number } };

export type StudiosDataGridQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
}>;


export type StudiosDataGridQuery = { studios: { __typename: 'StudioConnection', pageInfo: { __typename: 'PageInfo', startCursor?: Maybe<string>, endCursor?: Maybe<string>, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename: 'StudioEdge', cursor: string, node: (
        { __typename: 'Studio', account: { __typename: 'Account', id: string, snapbarId: string, name: string } }
        & StudioOverviewFragment
      ) }> } };

export type CloudFileDetailsFragment = { __typename: 'CloudFile', bytes: string, provider: CloudProvider, providerId: string, publicUrl: string, type: string };

export type CloudFileOverviewFragment = { __typename: 'CloudFile', publicUrl: string };

export type ContactRowFragment = { __typename: 'Contact', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, email?: Maybe<string>, createdAt: string, submissionsCount: number };

export type ContactSubmissionFragment = { __typename: 'Submission', id: string, createdAt: string, state: SubmissionState, contact: { __typename: 'Contact', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, phone?: Maybe<string>, email?: Maybe<string> }, photos: Array<(
    { __typename: 'Photo' }
    & PhotoPreviewFragment
  )>, studio?: Maybe<{ __typename: 'Studio', name: string }> };

export type PhotoPreviewFragment = { __typename: 'Photo', id: string, name: string, enhancementStatus: EnhancementStatus, file: { __typename: 'CloudFile', publicUrl: string }, appliedPresets: Array<{ __typename: 'AppliedPreset', publicUrl: string }>, appliedPresetsEnhanced: Array<{ __typename: 'AppliedPreset', publicUrl: string }> };

export type PoseDetailsFragment = { __typename: 'Pose', id: string, name: string, guidelines: string, removeBackground: boolean, allowPhotoUpload: boolean, presets: Array<(
    { __typename: 'Preset' }
    & PresetOverviewFragment
  )>, profile: (
    { __typename: 'PoseProfile' }
    & PoseProfileDetailsFragment
  ), backdrops?: Maybe<Array<(
    { __typename: 'CloudFile' }
    & CloudFileDetailsFragment
  ) | { __typename: 'Color', hex: string }>> };

export type PoseProfileDetailsFragment = { __typename: 'PoseProfile', id?: Maybe<string>, name?: Maybe<string>, outlineImage?: Maybe<(
    { __typename: 'CloudFile' }
    & CloudFileDetailsFragment
  )>, exampleImages: Array<(
    { __typename: 'CloudFile' }
    & CloudFileDetailsFragment
  )> };

export type PresetDetailsFragment = { __typename: 'Preset', id: string, name: string, resize: PhotoSize, cloudinaryTransform?: Maybe<string>, imageFormat: ImageFormat, visibleToContact?: Maybe<boolean>, autoEnhance?: Maybe<boolean>, photoFilter?: Maybe<{ __typename: 'PhotoFilter', id: string }>, backdrop?: Maybe<(
    { __typename: 'CloudFile' }
    & CloudFileDetailsFragment
  ) | { __typename: 'Color', hex: string }>, crop?: Maybe<{ __typename: 'Crop', factor: CropFactor, gravity?: Maybe<CropGravity> }>, overlayImage?: Maybe<(
    { __typename: 'CloudFile' }
    & CloudFileDetailsFragment
  )> };

export type PresetOverviewFragment = { __typename: 'Preset', id: string, name: string, sampleUrl?: Maybe<string>, resize: PhotoSize, default: boolean, visibleToContact?: Maybe<boolean>, createdAt: string, imageFormat: ImageFormat, updatedAt: string };

export type StudioDetailsFragment = { __typename: 'Studio', name: string, id: string, startDate: string, endDate?: Maybe<string>, photosCount: number, photosQuotaLimit?: Maybe<number>, handle: string, requireSubmissionReview: boolean, galleryEnabled: boolean, maxSubmissionsPerContact?: Maybe<number>, sharingStatus: SharingStatus, sharingPassword?: Maybe<string>, publicUrl: string, adminAccessUrl: string, locale: string, remindersEnabled?: Maybe<boolean>, reminderDuration?: Maybe<string>, aiEnhancementEnabled: boolean, suggestMobileDevice: boolean, poses: Array<(
    { __typename: 'Pose' }
    & PoseDetailsFragment
  )>, branding: { __typename: 'Branding', primaryColor: string, secondaryColor: string, tertiaryColor: string, background?: Maybe<(
      { __typename: 'CloudFile' }
      & CloudFileDetailsFragment
    ) | { __typename: 'Color', hex: string }>, logo?: Maybe<(
      { __typename: 'CloudFile' }
      & CloudFileDetailsFragment
    )> }, disclaimer: { __typename: 'Disclaimer', enabled: boolean, content: string }, formFields: Array<{ __typename: 'FormField', label: string, required: boolean, kind: FormFieldKind, choices?: Maybe<Array<string>>, contactAttribute: { __typename: 'ContactAttribute', key: string, custom: boolean } }> };

export type StudioOverviewFragment = { __typename: 'Studio', id: string, name: string, startDate: string, endDate?: Maybe<string>, photosCount: number, photosQuotaLimit?: Maybe<number>, locale: string, sharingStatus: SharingStatus, sharingPassword?: Maybe<string>, publicUrl: string, adminAccessUrl: string };

export type StudioSessionRowFragment = { __typename: 'StudioSession', id: string, createdAt?: Maybe<string>, invitedAt?: Maybe<string>, email?: Maybe<string>, studio: { __typename: 'Studio', id: string, name: string }, contact?: Maybe<{ __typename: 'Contact', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, phone?: Maybe<string>, email?: Maybe<string>, customAttributes?: Maybe<JSON> }> };

export type SubmissionRowFragment = { __typename: 'Submission', id: string, createdAt: string, submittedAt?: Maybe<string>, state: SubmissionState, studio?: Maybe<{ __typename: 'Studio', id: string, name: string }>, contact: { __typename: 'Contact', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, phone?: Maybe<string>, email?: Maybe<string>, customAttributes?: Maybe<JSON> }, photos: Array<(
    { __typename: 'Photo' }
    & PhotoPreviewFragment
  )> };

export type AcceptSubmissionsMutationVariables = Exact<{
  ids: Array<Scalars['GID']> | Scalars['GID'];
}>;


export type AcceptSubmissionsMutation = { acceptSubmissions?: Maybe<{ __typename: 'AcceptSubmissionsPayload', submissions?: Maybe<Array<{ __typename: 'Submission', id: string, state: SubmissionState }>>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type ClearExportFilenameHistoryMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearExportFilenameHistoryMutation = { clearExportFilenameHistory?: Maybe<{ __typename: 'ClearExportFilenameHistoryPayload', message?: Maybe<string> }> };

export type CreateAccessTokenMutationVariables = Exact<{
  input: CreateAccessTokenInput;
}>;


export type CreateAccessTokenMutation = { createAccessToken?: Maybe<{ __typename: 'CreateAccessTokenPayload', accessToken?: Maybe<{ __typename: 'AccessToken', createdAt: string, expiresIn?: Maybe<string>, id: string, name?: Maybe<string>, scopes: Array<string>, token: string, updatedAt: string, account?: Maybe<{ __typename: 'Account', snapbarId: string, name: string }> }>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;


export type CreateContactMutation = { createContact?: Maybe<{ __typename: 'CreateContactPayload', contact?: Maybe<{ __typename: 'Contact', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, email?: Maybe<string>, phone?: Maybe<string>, customAttributes?: Maybe<JSON>, contactLists: Array<{ __typename: 'ContactList', id: string, name: string }> }>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type CreateContactAttributeMutationVariables = Exact<{
  input: CreateContactAttributeInput;
}>;


export type CreateContactAttributeMutation = { createContactAttribute?: Maybe<{ __typename: 'CreateContactAttributePayload', contactAttribute?: Maybe<{ __typename: 'ContactAttribute', key: string }>, errors: Array<{ __typename: 'UserError', message: string, path?: Maybe<Array<string>> }> }> };

export type CreateContactListMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateContactListMutation = { createContactList?: Maybe<{ __typename: 'CreateContactListPayload', contactList?: Maybe<{ __typename: 'ContactList', id: string, name: string }>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type CreatePhotoFilterMutationVariables = Exact<{
  input: CreatePhotoFilterInput;
}>;


export type CreatePhotoFilterMutation = { createPhotoFilter?: Maybe<{ __typename: 'CreatePhotoFilterPayload', errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }>, photoFilter?: Maybe<{ __typename: 'PhotoFilter', id: string, name: string, cloudinaryArtisticFilter?: Maybe<string>, cloudinaryTransform?: Maybe<string> }> }> };

export type CreatePoseMutationVariables = Exact<{
  input: AddPoseInput;
}>;


export type CreatePoseMutation = { addPose?: Maybe<{ __typename: 'AddPosePayload', pose?: Maybe<(
      { __typename: 'Pose' }
      & PoseDetailsFragment
    )>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type CreatePoseProfileMutationVariables = Exact<{
  input: CreatePoseProfileInput;
}>;


export type CreatePoseProfileMutation = { createPoseProfile?: Maybe<{ __typename: 'CreatePoseProfilePayload', poseProfile?: Maybe<{ __typename: 'PoseProfile', id?: Maybe<string> }>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type CreatePresetMutationVariables = Exact<{
  input: CreatePresetInput;
}>;


export type CreatePresetMutation = { createPreset?: Maybe<{ __typename: 'CreatePresetPayload', preset?: Maybe<(
      { __typename: 'Preset' }
      & PresetDetailsFragment
    )>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type CreateStudioMutationVariables = Exact<{
  input: CreateStudioInput;
}>;


export type CreateStudioMutation = { createStudio?: Maybe<{ __typename: 'CreateStudioPayload', studio?: Maybe<(
      { __typename: 'Studio' }
      & StudioDetailsFragment
    )>, errors: Array<{ __typename: 'UserError', message: string, path?: Maybe<Array<string>> }> }> };

export type CreateSubmissionNotificationMutationVariables = Exact<{
  input: CreateSubmissionNotificationInput;
}>;


export type CreateSubmissionNotificationMutation = { createSubmissionNotification?: Maybe<{ __typename: 'CreateSubmissionNotificationPayload', submissionNotification?: Maybe<{ __typename: 'SubmissionNotification', frequency: FrequencyEnum, id: string, lastSentAt?: Maybe<string>, studio?: Maybe<{ __typename: 'Studio', id: string, name: string }>, user: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } }>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type DeleteAccessTokenMutationVariables = Exact<{
  input: DeleteAccessTokenInput;
}>;


export type DeleteAccessTokenMutation = { deleteAccessToken?: Maybe<{ __typename: 'DeleteAccessTokenPayload', deletedTokenId?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type DeleteContactAttributeMutationVariables = Exact<{
  input: DeleteContactAttributeInput;
}>;


export type DeleteContactAttributeMutation = { deleteContactAttribute?: Maybe<{ __typename: 'DeleteContactAttributePayload', deletedKey?: Maybe<string>, errors: Array<{ __typename: 'UserError', message: string }> }> };

export type DeleteContactListMutationVariables = Exact<{
  id: Scalars['GID'];
}>;


export type DeleteContactListMutation = { deleteContactList?: Maybe<{ __typename: 'DeleteContactListPayload', deletedId?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type DeleteContactsMutationVariables = Exact<{
  ids: Array<Scalars['GID']> | Scalars['GID'];
}>;


export type DeleteContactsMutation = { deleteContacts?: Maybe<{ __typename: 'DeleteContactsPayload', deletedIds: Array<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type DeletePhotoFilterMutationVariables = Exact<{
  input: DeletePhotoFilterInput;
}>;


export type DeletePhotoFilterMutation = { deletePhotoFilter?: Maybe<{ __typename: 'DeletePhotoFilterPayload', deletedId?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type DeletePoseMutationVariables = Exact<{
  input: DeletePoseInput;
}>;


export type DeletePoseMutation = { deletePose?: Maybe<{ __typename: 'DeletePosePayload', deletedId?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type DeletePoseProfileMutationVariables = Exact<{
  id: Scalars['GID'];
}>;


export type DeletePoseProfileMutation = { deletePoseProfile?: Maybe<{ __typename: 'DeletePoseProfilePayload', deletedId?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type DeletePresetMutationVariables = Exact<{
  input: DeletePresetInput;
}>;


export type DeletePresetMutation = { deletePreset?: Maybe<{ __typename: 'DeletePresetPayload', deletedId?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type DeleteStudioMutationVariables = Exact<{
  input: DeleteStudioInput;
}>;


export type DeleteStudioMutation = { deleteStudio?: Maybe<{ __typename: 'DeleteStudioPayload', deletedId?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type DeleteStudioSessionsMutationVariables = Exact<{
  input: DeleteStudioSessionsInput;
}>;


export type DeleteStudioSessionsMutation = { deleteStudioSessions?: Maybe<{ __typename: 'DeleteStudioSessionsPayload', deletedIds?: Maybe<Array<string>>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type DeleteSubmissionNotificationsMutationVariables = Exact<{
  input: DeleteSubmissionNotificationsInput;
}>;


export type DeleteSubmissionNotificationsMutation = { deleteSubmissionNotifications?: Maybe<{ __typename: 'DeleteSubmissionNotificationsPayload', deletedIds: Array<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type DeleteSubmissionsMutationVariables = Exact<{
  ids: Array<Scalars['GID']> | Scalars['GID'];
}>;


export type DeleteSubmissionsMutation = { deleteSubmissions?: Maybe<{ __typename: 'DeleteSubmissionsPayload', deletedIds?: Maybe<Array<string>>, errors: Array<{ __typename: 'UserError', message: string, path?: Maybe<Array<string>> }> }> };

export type DeselectAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeselectAccountMutation = { deselectAccount?: Maybe<{ __typename: 'DeselectAccountPayload', errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type EnhanceSubmissionsMutationVariables = Exact<{
  ids: Array<Scalars['GID']> | Scalars['GID'];
}>;


export type EnhanceSubmissionsMutation = { enhanceSubmissions?: Maybe<{ __typename: 'EnhanceSubmissionsPayload', submissions?: Maybe<Array<{ __typename: 'Submission', id: string }>>, errors: Array<{ __typename: 'UserError', message: string, path?: Maybe<Array<string>> }> }> };

export type ExportContactsMutationVariables = Exact<{
  input: ExportContactsInput;
}>;


export type ExportContactsMutation = { exportContacts?: Maybe<{ __typename: 'ExportContactsPayload', exportId?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type ExportSubmissionsMutationVariables = Exact<{
  input: ExportSubmissionsInput;
}>;


export type ExportSubmissionsMutation = { exportSubmissions?: Maybe<{ __typename: 'ExportSubmissionsPayload', exportId?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type GenerateMetricsTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateMetricsTokenMutation = { generateMetricsToken?: Maybe<{ __typename: 'GenerateMetricsTokenPayload', token?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type InviteContactListMutationVariables = Exact<{
  studioId: Scalars['GID'];
  contactListId: Scalars['GID'];
}>;


export type InviteContactListMutation = { inviteContactList?: Maybe<{ __typename: 'InviteContactListPayload', contactList?: Maybe<{ __typename: 'ContactList', id: string }>, errors: Array<{ __typename: 'UserError', message: string, path?: Maybe<Array<string>> }> }> };

export type InviteContactsMutationVariables = Exact<{
  studioId: Scalars['GID'];
  emails: Array<Scalars['String']> | Scalars['String'];
}>;


export type InviteContactsMutation = { inviteContacts?: Maybe<{ __typename: 'InviteContactsPayload', contacts?: Maybe<Array<{ __typename: 'Contact', id: string }>>, errors: Array<{ __typename: 'UserError', message: string, path?: Maybe<Array<string>> }> }> };

export type PresignCloudinaryFileMutationVariables = Exact<{
  filename: Scalars['String'];
  accountId: Scalars['String'];
  accessType?: Maybe<AccessTypeEnum>;
}>;


export type PresignCloudinaryFileMutation = { presignCloudinaryFile?: Maybe<{ __typename: 'PresignCloudinaryFilePayload', apiKey?: Maybe<string>, publicId?: Maybe<string>, signature?: Maybe<string>, params?: Maybe<JSON>, uploadUrl?: Maybe<string> }> };

export type PreviewPresetMutationVariables = Exact<{
  input: PreviewPresetInput;
}>;


export type PreviewPresetMutation = { previewPreset?: Maybe<{ __typename: 'PreviewPresetPayload', originalUrl?: Maybe<string>, sampleUrl?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type RejectSubmissionsMutationVariables = Exact<{
  ids: Array<Scalars['GID']> | Scalars['GID'];
  retake?: Maybe<RetakeInput>;
}>;


export type RejectSubmissionsMutation = { rejectSubmissions?: Maybe<{ __typename: 'RejectSubmissionsPayload', submissions?: Maybe<Array<{ __typename: 'Submission', id: string, state: SubmissionState }>>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type ResetStudioPhotosCountMutationVariables = Exact<{
  input: ResetStudioPhotosCountInput;
}>;


export type ResetStudioPhotosCountMutation = { resetStudioPhotosCount?: Maybe<{ __typename: 'ResetStudioPhotosCountPayload', studio?: Maybe<{ __typename: 'Studio', id: string, photosCount: number }> }> };

export type SelectAccountMutationVariables = Exact<{
  snapbarId: Scalars['SnapbarID'];
}>;


export type SelectAccountMutation = { selectAccount?: Maybe<{ __typename: 'SelectAccountPayload', account?: Maybe<{ __typename: 'Account', id: string, snapbarId: string, name: string }>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type SendRemindersMutationVariables = Exact<{
  input: SendRemindersInput;
}>;


export type SendRemindersMutation = { sendReminders?: Maybe<{ __typename: 'SendRemindersPayload', success: boolean, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type SignExportMutationVariables = Exact<{
  input: SignExportInput;
}>;


export type SignExportMutation = { signExport?: Maybe<{ __typename: 'SignExportPayload', url?: Maybe<string>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type UpdateBrandingMutationVariables = Exact<{
  input: UpdateStudioInput;
}>;


export type UpdateBrandingMutation = { updateStudio?: Maybe<{ __typename: 'UpdateStudioPayload', studio?: Maybe<{ __typename: 'Studio', id: string, branding: { __typename: 'Branding', primaryColor: string, secondaryColor: string, tertiaryColor: string, background?: Maybe<(
          { __typename: 'CloudFile' }
          & CloudFileDetailsFragment
        ) | { __typename: 'Color', hex: string }>, logo?: Maybe<(
          { __typename: 'CloudFile' }
          & CloudFileDetailsFragment
        )> } }>, errors: Array<{ __typename: 'UserError', message: string, path?: Maybe<Array<string>> }> }> };

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactInput;
}>;


export type UpdateContactMutation = { updateContact?: Maybe<{ __typename: 'UpdateContactPayload', contact?: Maybe<{ __typename: 'Contact', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, email?: Maybe<string>, phone?: Maybe<string>, customAttributes?: Maybe<JSON>, contactLists: Array<{ __typename: 'ContactList', id: string, name: string }> }>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type UpdateContactAttributeMutationVariables = Exact<{
  input: UpdateContactAttributeInput;
}>;


export type UpdateContactAttributeMutation = { updateContactAttribute?: Maybe<{ __typename: 'UpdateContactAttributePayload', contactAttribute?: Maybe<{ __typename: 'ContactAttribute', key: string }>, errors: Array<{ __typename: 'UserError', message: string, path?: Maybe<Array<string>> }> }> };

export type UpdateContactListMutationVariables = Exact<{
  input: UpdateContactListInput;
}>;


export type UpdateContactListMutation = { updateContactList?: Maybe<{ __typename: 'UpdateContactListPayload', contactList?: Maybe<{ __typename: 'ContactList', id: string, name: string }>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type UpdatePhotoFilterMutationVariables = Exact<{
  input: UpdatePhotoFilterInput;
}>;


export type UpdatePhotoFilterMutation = { updatePhotoFilter?: Maybe<{ __typename: 'UpdatePhotoFilterPayload', errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }>, photoFilter?: Maybe<{ __typename: 'PhotoFilter', id: string, name: string, cloudinaryArtisticFilter?: Maybe<string>, cloudinaryTransform?: Maybe<string> }> }> };

export type UpdatePoseMutationVariables = Exact<{
  input: UpdatePoseInput;
}>;


export type UpdatePoseMutation = { updatePose?: Maybe<{ __typename: 'UpdatePosePayload', pose?: Maybe<(
      { __typename: 'Pose' }
      & PoseDetailsFragment
    )>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type UpdatePoseProfileMutationVariables = Exact<{
  input: UpdatePoseProfileInput;
}>;


export type UpdatePoseProfileMutation = { updatePoseProfile?: Maybe<{ __typename: 'UpdatePoseProfilePayload', poseProfile?: Maybe<(
      { __typename: 'PoseProfile' }
      & PoseProfileDetailsFragment
    )>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type UpdatePresetMutationVariables = Exact<{
  input: UpdatePresetInput;
}>;


export type UpdatePresetMutation = { updatePreset?: Maybe<{ __typename: 'UpdatePresetPayload', preset?: Maybe<(
      { __typename: 'Preset' }
      & PresetDetailsFragment
    )>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type UpdateReviewSettingMutationVariables = Exact<{
  id: Scalars['GID'];
  requireSubmissionReview: Scalars['Boolean'];
}>;


export type UpdateReviewSettingMutation = { updateStudio?: Maybe<{ __typename: 'UpdateStudioPayload', studio?: Maybe<{ __typename: 'Studio', id: string, requireSubmissionReview: boolean }> }> };

export type UpdateStudioMutationVariables = Exact<{
  input: UpdateStudioInput;
}>;


export type UpdateStudioMutation = { updateStudio?: Maybe<{ __typename: 'UpdateStudioPayload', studio?: Maybe<(
      { __typename: 'Studio' }
      & StudioDetailsFragment
    )>, errors: Array<{ __typename: 'UserError', path?: Maybe<Array<string>>, message: string }> }> };

export type ContactPageQueryVariables = Exact<{
  id: Scalars['GID'];
}>;


export type ContactPageQuery = { contact?: Maybe<{ __typename: 'Contact', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, email?: Maybe<string>, phone?: Maybe<string>, createdAt: string, customAttributes?: Maybe<JSON>, submissions: Array<(
      { __typename: 'Submission' }
      & ContactSubmissionFragment
    )> }> };

export type ContactFormPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactFormPageQuery = { contactLists: Array<{ __typename: 'ContactList', id: string, name: string }> };

export type ContactsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactsPageQuery = { contactLists: Array<{ __typename: 'ContactList', id: string, name: string }> };

export type DashboardPageQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardPageQuery = { currentUser?: Maybe<{ __typename: 'User', id: string, firstName: string, lastName: string }>, currentAccount?: Maybe<{ __typename: 'Account', id: string }> };

export type PosesPageQueryVariables = Exact<{
  studioId: Scalars['GID'];
}>;


export type PosesPageQuery = { studio?: Maybe<{ __typename: 'Studio', id: string, name: string, poses: Array<(
      { __typename: 'Pose' }
      & PoseDetailsFragment
    )> }>, presets: Array<(
    { __typename: 'Preset' }
    & PresetOverviewFragment
  )>, poseProfiles: Array<(
    { __typename: 'PoseProfile' }
    & PoseProfileDetailsFragment
  )> };

export type PresetFormPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PresetFormPageQuery = { photoFilters: Array<{ __typename: 'PhotoFilter', id: string, name: string }> };

export type ProfileFormPageQueryVariables = Exact<{
  id: Scalars['GID'];
}>;


export type ProfileFormPageQuery = { poseProfile?: Maybe<(
    { __typename: 'PoseProfile' }
    & PoseProfileDetailsFragment
  )> };

export type GetAccessTokenScopesQueryVariables = Exact<{
  applicationName: Scalars['String'];
}>;


export type GetAccessTokenScopesQuery = { accessTokenScopes?: Maybe<Array<string>> };

export type GetAccessTokensQueryVariables = Exact<{
  onlyApiTokens: Scalars['Boolean'];
}>;


export type GetAccessTokensQuery = { accessTokens?: Maybe<Array<{ __typename: 'AccessToken', id: string, name?: Maybe<string>, scopes: Array<string>, createdAt: string, token: string, account?: Maybe<{ __typename: 'Account', name: string }> }>> };

export type GetContactQueryVariables = Exact<{
  id: Scalars['GID'];
}>;


export type GetContactQuery = { contact?: Maybe<{ __typename: 'Contact', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, email?: Maybe<string>, phone?: Maybe<string>, customAttributes?: Maybe<JSON>, contactLists: Array<{ __typename: 'ContactList', id: string, name: string }> }> };

export type GetContactAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactAttributesQuery = { contactAttributes: Array<{ __typename: 'ContactAttribute', key: string, type: ContactAttributeField, custom: boolean, validation?: Maybe<{ __typename: 'ContactAttributeValidation', regex: string, errorMessage: string }> }> };

export type GetContactListQueryVariables = Exact<{
  id: Scalars['GID'];
}>;


export type GetContactListQuery = { contactList?: Maybe<{ __typename: 'ContactList', id: string, name: string }> };

export type GetCurrentAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentAccountQuery = { currentAccount?: Maybe<{ __typename: 'Account', id: string, name: string, organization?: Maybe<string> }> };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { currentUser?: Maybe<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string }> };

export type GetExportQueryVariables = Exact<{
  id: Scalars['GID'];
}>;


export type GetExportQuery = { export?: Maybe<{ __typename: 'Export', id: string, status: ExportStatus, filesCount: number, processedCount: number, failedCount: number, file?: Maybe<{ __typename: 'CloudFile', providerId: string, bytes: string, type: string }> }> };

export type GetExportFilenamePatternsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExportFilenamePatternsQuery = { exportFilenamePatterns?: Maybe<Array<string>> };

export type GetPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPermissionsQuery = { currentUser?: Maybe<{ __typename: 'User', id: string, staff: boolean }>, currentMembership?: Maybe<{ __typename: 'Membership', permissions: Array<string> }> };

export type GetPhotoFilterQueryVariables = Exact<{
  id: Scalars['GID'];
}>;


export type GetPhotoFilterQuery = { photoFilter?: Maybe<{ __typename: 'PhotoFilter', name: string, cloudinaryTransform?: Maybe<string>, cloudinaryArtisticFilter?: Maybe<string> }> };

export type GetPhotoFilterRowsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPhotoFilterRowsQuery = { photoFilters: Array<{ __typename: 'PhotoFilter', id: string, name: string, cloudinaryTransform?: Maybe<string> }> };

export type GetPresetQueryVariables = Exact<{
  id: Scalars['GID'];
}>;


export type GetPresetQuery = { preset?: Maybe<{ __typename: 'Preset', id: string, name: string, default: boolean, resize: PhotoSize, cloudinaryTransform?: Maybe<string>, visibleToContact?: Maybe<boolean>, autoEnhance?: Maybe<boolean>, imageFormat: ImageFormat, crop?: Maybe<{ __typename: 'Crop', factor: CropFactor, gravity?: Maybe<CropGravity> }>, photoFilter?: Maybe<{ __typename: 'PhotoFilter', id: string, name: string }>, backdrop?: Maybe<(
      { __typename: 'CloudFile' }
      & CloudFileDetailsFragment
    ) | { __typename: 'Color', hex: string }>, overlayImage?: Maybe<{ __typename: 'CloudFile', provider: CloudProvider, providerId: string, publicUrl: string, type: string, bytes: string }> }> };

export type GetPresetOverviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPresetOverviewsQuery = { presets: Array<(
    { __typename: 'Preset' }
    & PresetOverviewFragment
  )> };

export type GetStudioDetailsQueryVariables = Exact<{
  id: Scalars['GID'];
}>;


export type GetStudioDetailsQuery = { studio?: Maybe<(
    { __typename: 'Studio' }
    & StudioDetailsFragment
  )> };

export type GetStudioOverviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStudioOverviewsQuery = { studios: { __typename: 'StudioConnection', edges: Array<{ __typename: 'StudioEdge', cursor: string, node: (
        { __typename: 'Studio' }
        & StudioOverviewFragment
      ) }> } };

export type GetStudioSessionInvitationsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  studioId?: Maybe<Scalars['GID']>;
  sortBy?: Maybe<StudioSessionSortKey>;
  sortDirection?: Maybe<SortDirection>;
}>;


export type GetStudioSessionInvitationsQuery = { studioSessionInvitations: { __typename: 'StudioSessionConnection', edges: Array<{ __typename: 'StudioSessionEdge', cursor: string, node: (
        { __typename: 'StudioSession' }
        & StudioSessionRowFragment
      ) }>, pageInfo: { __typename: 'PageInfo', startCursor?: Maybe<string>, endCursor?: Maybe<string>, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetSubmissionNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubmissionNotificationsQuery = { userSubmissionNotifications: Array<{ __typename: 'SubmissionNotification', id: string, frequency: FrequencyEnum, studio?: Maybe<{ __typename: 'Studio', id: string, name: string }> }> };

export type GetSubmissionRowsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  state?: Maybe<SubmissionState>;
  sortBy?: Maybe<SubmissionSortKey>;
  sortDirection?: Maybe<SortDirection>;
  studioIds?: Maybe<Array<Scalars['GID']> | Scalars['GID']>;
  submittedAfter?: Maybe<Scalars['DateTime']>;
}>;


export type GetSubmissionRowsQuery = { submissions: { __typename: 'SubmissionConnection', totalCount: number, edges: Array<{ __typename: 'SubmissionEdge', cursor: string, node: (
        { __typename: 'Submission' }
        & SubmissionRowFragment
      ) }>, pageInfo: { __typename: 'PageInfo', startCursor?: Maybe<string>, endCursor?: Maybe<string>, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type StudioExistsQueryVariables = Exact<{
  handle: Scalars['String'];
}>;


export type StudioExistsQuery = { studioExists: boolean };

export const CloudFileOverviewFragmentDoc = gql`
    fragment CloudFileOverview on CloudFile {
  publicUrl
}
    `;
export const ContactRowFragmentDoc = gql`
    fragment ContactRow on Contact {
  id
  firstName
  lastName
  email
  createdAt
  submissionsCount
}
    `;
export const PhotoPreviewFragmentDoc = gql`
    fragment PhotoPreview on Photo {
  id
  name
  enhancementStatus
  file {
    publicUrl
  }
  appliedPresets(original: true) {
    publicUrl
  }
  appliedPresetsEnhanced: appliedPresets(original: false) {
    publicUrl
  }
}
    `;
export const ContactSubmissionFragmentDoc = gql`
    fragment ContactSubmission on Submission {
  id
  createdAt
  state
  contact {
    id
    firstName
    lastName
    phone
    email
  }
  photos {
    ...PhotoPreview
  }
  studio {
    name
  }
}
    ${PhotoPreviewFragmentDoc}`;
export const CloudFileDetailsFragmentDoc = gql`
    fragment CloudFileDetails on CloudFile {
  bytes
  provider
  providerId
  publicUrl
  type
}
    `;
export const PresetDetailsFragmentDoc = gql`
    fragment PresetDetails on Preset {
  id
  name
  resize
  photoFilter {
    id
  }
  backdrop {
    ... on CloudFile {
      ...CloudFileDetails
    }
    ... on Color {
      hex
    }
  }
  crop {
    factor
    gravity
  }
  overlayImage {
    ...CloudFileDetails
  }
  cloudinaryTransform
  imageFormat
  visibleToContact
  autoEnhance
}
    ${CloudFileDetailsFragmentDoc}`;
export const PresetOverviewFragmentDoc = gql`
    fragment PresetOverview on Preset {
  id
  name
  sampleUrl
  resize
  default
  visibleToContact
  createdAt
  imageFormat
  updatedAt
}
    `;
export const PoseProfileDetailsFragmentDoc = gql`
    fragment PoseProfileDetails on PoseProfile {
  id
  name
  outlineImage {
    ...CloudFileDetails
  }
  exampleImages {
    ...CloudFileDetails
  }
}
    ${CloudFileDetailsFragmentDoc}`;
export const PoseDetailsFragmentDoc = gql`
    fragment PoseDetails on Pose {
  id
  name
  guidelines
  presets {
    ...PresetOverview
  }
  profile {
    ...PoseProfileDetails
  }
  removeBackground
  backdrops {
    ... on Color {
      hex
    }
    ... on CloudFile {
      ...CloudFileDetails
    }
  }
  allowPhotoUpload
}
    ${PresetOverviewFragmentDoc}
${PoseProfileDetailsFragmentDoc}
${CloudFileDetailsFragmentDoc}`;
export const StudioDetailsFragmentDoc = gql`
    fragment StudioDetails on Studio {
  name
  id
  startDate
  endDate
  photosCount
  photosQuotaLimit
  handle
  poses {
    ...PoseDetails
  }
  branding {
    primaryColor
    secondaryColor
    tertiaryColor
    background {
      ... on Color {
        hex
      }
      ... on CloudFile {
        ...CloudFileDetails
      }
    }
    logo {
      ...CloudFileDetails
    }
  }
  disclaimer {
    enabled
    content
  }
  requireSubmissionReview
  galleryEnabled
  maxSubmissionsPerContact
  sharingStatus
  sharingPassword
  publicUrl
  adminAccessUrl
  locale
  remindersEnabled
  reminderDuration
  formFields {
    label
    required
    contactAttribute {
      key
      custom
    }
    kind
    choices
  }
  aiEnhancementEnabled
  suggestMobileDevice
}
    ${PoseDetailsFragmentDoc}
${CloudFileDetailsFragmentDoc}`;
export const StudioOverviewFragmentDoc = gql`
    fragment StudioOverview on Studio {
  id
  name
  startDate
  endDate
  photosCount
  photosQuotaLimit
  locale
  sharingStatus
  sharingPassword
  publicUrl
  adminAccessUrl
}
    `;
export const StudioSessionRowFragmentDoc = gql`
    fragment StudioSessionRow on StudioSession {
  id
  createdAt
  invitedAt
  studio {
    id
    name
  }
  email
  contact {
    id
    firstName
    lastName
    phone
    email
    customAttributes
  }
}
    `;
export const SubmissionRowFragmentDoc = gql`
    fragment SubmissionRow on Submission {
  id
  createdAt
  submittedAt
  state
  studio {
    id
    name
  }
  contact {
    id
    firstName
    lastName
    phone
    email
    customAttributes
  }
  photos {
    ...PhotoPreview
  }
}
    ${PhotoPreviewFragmentDoc}`;
export const AccountMenuDocument = gql`
    query AccountMenu($query: String!) {
  currentAccount {
    id
    name
    organization
  }
  searchSnapbarAccounts(query: $query) {
    snapbarId
    name
  }
}
    `;

/**
 * __useAccountMenuQuery__
 *
 * To run a query within a React component, call `useAccountMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMenuQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAccountMenuQuery(baseOptions: Apollo.QueryHookOptions<AccountMenuQuery, AccountMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountMenuQuery, AccountMenuQueryVariables>(AccountMenuDocument, options);
      }
export function useAccountMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountMenuQuery, AccountMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountMenuQuery, AccountMenuQueryVariables>(AccountMenuDocument, options);
        }
export type AccountMenuQueryHookResult = ReturnType<typeof useAccountMenuQuery>;
export type AccountMenuLazyQueryHookResult = ReturnType<typeof useAccountMenuLazyQuery>;
export type AccountMenuQueryResult = Apollo.QueryResult<AccountMenuQuery, AccountMenuQueryVariables>;
export const ContactsDataGridDocument = gql`
    query ContactsDataGrid($after: String, $before: String, $first: Int, $last: Int, $contactListId: GID, $sortBy: ContactSortKey, $sortDirection: SortDirection) {
  contacts(
    after: $after
    before: $before
    first: $first
    last: $last
    contactListId: $contactListId
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    edges {
      cursor
      node {
        ...ContactRow
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${ContactRowFragmentDoc}`;

/**
 * __useContactsDataGridQuery__
 *
 * To run a query within a React component, call `useContactsDataGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsDataGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsDataGridQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      contactListId: // value for 'contactListId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useContactsDataGridQuery(baseOptions?: Apollo.QueryHookOptions<ContactsDataGridQuery, ContactsDataGridQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsDataGridQuery, ContactsDataGridQueryVariables>(ContactsDataGridDocument, options);
      }
export function useContactsDataGridLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsDataGridQuery, ContactsDataGridQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsDataGridQuery, ContactsDataGridQueryVariables>(ContactsDataGridDocument, options);
        }
export type ContactsDataGridQueryHookResult = ReturnType<typeof useContactsDataGridQuery>;
export type ContactsDataGridLazyQueryHookResult = ReturnType<typeof useContactsDataGridLazyQuery>;
export type ContactsDataGridQueryResult = Apollo.QueryResult<ContactsDataGridQuery, ContactsDataGridQueryVariables>;
export const SearchContactsDocument = gql`
    query searchContacts($query: String!) {
  searchContacts(query: $query, first: 25) {
    nodes {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useSearchContactsQuery__
 *
 * To run a query within a React component, call `useSearchContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContactsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchContactsQuery(baseOptions: Apollo.QueryHookOptions<SearchContactsQuery, SearchContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchContactsQuery, SearchContactsQueryVariables>(SearchContactsDocument, options);
      }
export function useSearchContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchContactsQuery, SearchContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchContactsQuery, SearchContactsQueryVariables>(SearchContactsDocument, options);
        }
export type SearchContactsQueryHookResult = ReturnType<typeof useSearchContactsQuery>;
export type SearchContactsLazyQueryHookResult = ReturnType<typeof useSearchContactsLazyQuery>;
export type SearchContactsQueryResult = Apollo.QueryResult<SearchContactsQuery, SearchContactsQueryVariables>;
export const FiveContactsDocument = gql`
    query fiveContacts($id: GID!) {
  contacts(first: 5, contactListId: $id) {
    edges {
      node {
        id
        firstName
        lastName
        email
      }
    }
  }
}
    `;

/**
 * __useFiveContactsQuery__
 *
 * To run a query within a React component, call `useFiveContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFiveContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFiveContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFiveContactsQuery(baseOptions: Apollo.QueryHookOptions<FiveContactsQuery, FiveContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FiveContactsQuery, FiveContactsQueryVariables>(FiveContactsDocument, options);
      }
export function useFiveContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FiveContactsQuery, FiveContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FiveContactsQuery, FiveContactsQueryVariables>(FiveContactsDocument, options);
        }
export type FiveContactsQueryHookResult = ReturnType<typeof useFiveContactsQuery>;
export type FiveContactsLazyQueryHookResult = ReturnType<typeof useFiveContactsLazyQuery>;
export type FiveContactsQueryResult = Apollo.QueryResult<FiveContactsQuery, FiveContactsQueryVariables>;
export const UpdateDisclaimerDocument = gql`
    mutation updateDisclaimer($id: GID!, $disclaimer: DisclaimerInput!) {
  updateStudio(input: {id: $id, disclaimer: $disclaimer}) {
    studio {
      id
      disclaimer {
        enabled
        content
      }
    }
    errors {
      path
      message
    }
  }
}
    `;
export type UpdateDisclaimerMutationFn = Apollo.MutationFunction<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>;

/**
 * __useUpdateDisclaimerMutation__
 *
 * To run a mutation, you first call `useUpdateDisclaimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisclaimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisclaimerMutation, { data, loading, error }] = useUpdateDisclaimerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      disclaimer: // value for 'disclaimer'
 *   },
 * });
 */
export function useUpdateDisclaimerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>(UpdateDisclaimerDocument, options);
      }
export type UpdateDisclaimerMutationHookResult = ReturnType<typeof useUpdateDisclaimerMutation>;
export type UpdateDisclaimerMutationResult = Apollo.MutationResult<UpdateDisclaimerMutation>;
export type UpdateDisclaimerMutationOptions = Apollo.BaseMutationOptions<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>;
export const InviteContactListDialogDocument = gql`
    query InviteContactListDialog {
  contactLists {
    id
    name
    contactsCount
  }
}
    `;

/**
 * __useInviteContactListDialogQuery__
 *
 * To run a query within a React component, call `useInviteContactListDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteContactListDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteContactListDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useInviteContactListDialogQuery(baseOptions?: Apollo.QueryHookOptions<InviteContactListDialogQuery, InviteContactListDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InviteContactListDialogQuery, InviteContactListDialogQueryVariables>(InviteContactListDialogDocument, options);
      }
export function useInviteContactListDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteContactListDialogQuery, InviteContactListDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InviteContactListDialogQuery, InviteContactListDialogQueryVariables>(InviteContactListDialogDocument, options);
        }
export type InviteContactListDialogQueryHookResult = ReturnType<typeof useInviteContactListDialogQuery>;
export type InviteContactListDialogLazyQueryHookResult = ReturnType<typeof useInviteContactListDialogLazyQuery>;
export type InviteContactListDialogQueryResult = Apollo.QueryResult<InviteContactListDialogQuery, InviteContactListDialogQueryVariables>;
export const ProfilesCardDocument = gql`
    query profilesCard {
  poseProfiles {
    id
    name
    exampleImages {
      publicUrl
    }
    outlineImage {
      publicUrl
    }
  }
}
    `;

/**
 * __useProfilesCardQuery__
 *
 * To run a query within a React component, call `useProfilesCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilesCardQuery(baseOptions?: Apollo.QueryHookOptions<ProfilesCardQuery, ProfilesCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilesCardQuery, ProfilesCardQueryVariables>(ProfilesCardDocument, options);
      }
export function useProfilesCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilesCardQuery, ProfilesCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilesCardQuery, ProfilesCardQueryVariables>(ProfilesCardDocument, options);
        }
export type ProfilesCardQueryHookResult = ReturnType<typeof useProfilesCardQuery>;
export type ProfilesCardLazyQueryHookResult = ReturnType<typeof useProfilesCardLazyQuery>;
export type ProfilesCardQueryResult = Apollo.QueryResult<ProfilesCardQuery, ProfilesCardQueryVariables>;
export const ReviewChipDocument = gql`
    query ReviewChip {
  submissions(state: REVIEW) {
    totalCount
  }
}
    `;

/**
 * __useReviewChipQuery__
 *
 * To run a query within a React component, call `useReviewChipQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewChipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewChipQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewChipQuery(baseOptions?: Apollo.QueryHookOptions<ReviewChipQuery, ReviewChipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewChipQuery, ReviewChipQueryVariables>(ReviewChipDocument, options);
      }
export function useReviewChipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewChipQuery, ReviewChipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewChipQuery, ReviewChipQueryVariables>(ReviewChipDocument, options);
        }
export type ReviewChipQueryHookResult = ReturnType<typeof useReviewChipQuery>;
export type ReviewChipLazyQueryHookResult = ReturnType<typeof useReviewChipLazyQuery>;
export type ReviewChipQueryResult = Apollo.QueryResult<ReviewChipQuery, ReviewChipQueryVariables>;
export const StudiosDataGridDocument = gql`
    query StudiosDataGrid($after: String, $before: String, $first: Int, $last: Int, $query: String) {
  studios(
    after: $after
    before: $before
    first: $first
    last: $last
    query: $query
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ...StudioOverview
        account {
          id
          snapbarId
          name
        }
      }
    }
  }
}
    ${StudioOverviewFragmentDoc}`;

/**
 * __useStudiosDataGridQuery__
 *
 * To run a query within a React component, call `useStudiosDataGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudiosDataGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudiosDataGridQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useStudiosDataGridQuery(baseOptions?: Apollo.QueryHookOptions<StudiosDataGridQuery, StudiosDataGridQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudiosDataGridQuery, StudiosDataGridQueryVariables>(StudiosDataGridDocument, options);
      }
export function useStudiosDataGridLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudiosDataGridQuery, StudiosDataGridQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudiosDataGridQuery, StudiosDataGridQueryVariables>(StudiosDataGridDocument, options);
        }
export type StudiosDataGridQueryHookResult = ReturnType<typeof useStudiosDataGridQuery>;
export type StudiosDataGridLazyQueryHookResult = ReturnType<typeof useStudiosDataGridLazyQuery>;
export type StudiosDataGridQueryResult = Apollo.QueryResult<StudiosDataGridQuery, StudiosDataGridQueryVariables>;
export const AcceptSubmissionsDocument = gql`
    mutation acceptSubmissions($ids: [GID!]!) {
  acceptSubmissions(input: {ids: $ids}) {
    submissions {
      id
      state
    }
    errors {
      path
      message
    }
  }
}
    `;
export type AcceptSubmissionsMutationFn = Apollo.MutationFunction<AcceptSubmissionsMutation, AcceptSubmissionsMutationVariables>;

/**
 * __useAcceptSubmissionsMutation__
 *
 * To run a mutation, you first call `useAcceptSubmissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSubmissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSubmissionsMutation, { data, loading, error }] = useAcceptSubmissionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAcceptSubmissionsMutation(baseOptions?: Apollo.MutationHookOptions<AcceptSubmissionsMutation, AcceptSubmissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptSubmissionsMutation, AcceptSubmissionsMutationVariables>(AcceptSubmissionsDocument, options);
      }
export type AcceptSubmissionsMutationHookResult = ReturnType<typeof useAcceptSubmissionsMutation>;
export type AcceptSubmissionsMutationResult = Apollo.MutationResult<AcceptSubmissionsMutation>;
export type AcceptSubmissionsMutationOptions = Apollo.BaseMutationOptions<AcceptSubmissionsMutation, AcceptSubmissionsMutationVariables>;
export const ClearExportFilenameHistoryDocument = gql`
    mutation clearExportFilenameHistory {
  clearExportFilenameHistory {
    message
  }
}
    `;
export type ClearExportFilenameHistoryMutationFn = Apollo.MutationFunction<ClearExportFilenameHistoryMutation, ClearExportFilenameHistoryMutationVariables>;

/**
 * __useClearExportFilenameHistoryMutation__
 *
 * To run a mutation, you first call `useClearExportFilenameHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearExportFilenameHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearExportFilenameHistoryMutation, { data, loading, error }] = useClearExportFilenameHistoryMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearExportFilenameHistoryMutation(baseOptions?: Apollo.MutationHookOptions<ClearExportFilenameHistoryMutation, ClearExportFilenameHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearExportFilenameHistoryMutation, ClearExportFilenameHistoryMutationVariables>(ClearExportFilenameHistoryDocument, options);
      }
export type ClearExportFilenameHistoryMutationHookResult = ReturnType<typeof useClearExportFilenameHistoryMutation>;
export type ClearExportFilenameHistoryMutationResult = Apollo.MutationResult<ClearExportFilenameHistoryMutation>;
export type ClearExportFilenameHistoryMutationOptions = Apollo.BaseMutationOptions<ClearExportFilenameHistoryMutation, ClearExportFilenameHistoryMutationVariables>;
export const CreateAccessTokenDocument = gql`
    mutation createAccessToken($input: CreateAccessTokenInput!) {
  createAccessToken(input: $input) {
    accessToken {
      account {
        snapbarId
        name
      }
      createdAt
      expiresIn
      id
      name
      scopes
      token
      updatedAt
    }
    errors {
      path
      message
    }
  }
}
    `;
export type CreateAccessTokenMutationFn = Apollo.MutationFunction<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>;

/**
 * __useCreateAccessTokenMutation__
 *
 * To run a mutation, you first call `useCreateAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessTokenMutation, { data, loading, error }] = useCreateAccessTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>(CreateAccessTokenDocument, options);
      }
export type CreateAccessTokenMutationHookResult = ReturnType<typeof useCreateAccessTokenMutation>;
export type CreateAccessTokenMutationResult = Apollo.MutationResult<CreateAccessTokenMutation>;
export type CreateAccessTokenMutationOptions = Apollo.BaseMutationOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($input: CreateContactInput!) {
  createContact(input: $input) {
    contact {
      id
      firstName
      lastName
      email
      phone
      customAttributes
      contactLists {
        id
        name
      }
    }
    errors {
      path
      message
    }
  }
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const CreateContactAttributeDocument = gql`
    mutation CreateContactAttribute($input: CreateContactAttributeInput!) {
  createContactAttribute(input: $input) {
    contactAttribute {
      key
    }
    errors {
      message
      path
    }
  }
}
    `;
export type CreateContactAttributeMutationFn = Apollo.MutationFunction<CreateContactAttributeMutation, CreateContactAttributeMutationVariables>;

/**
 * __useCreateContactAttributeMutation__
 *
 * To run a mutation, you first call `useCreateContactAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactAttributeMutation, { data, loading, error }] = useCreateContactAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactAttributeMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactAttributeMutation, CreateContactAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactAttributeMutation, CreateContactAttributeMutationVariables>(CreateContactAttributeDocument, options);
      }
export type CreateContactAttributeMutationHookResult = ReturnType<typeof useCreateContactAttributeMutation>;
export type CreateContactAttributeMutationResult = Apollo.MutationResult<CreateContactAttributeMutation>;
export type CreateContactAttributeMutationOptions = Apollo.BaseMutationOptions<CreateContactAttributeMutation, CreateContactAttributeMutationVariables>;
export const CreateContactListDocument = gql`
    mutation createContactList($name: String!) {
  createContactList(input: {name: $name}) {
    contactList {
      id
      name
    }
    errors {
      path
      message
    }
  }
}
    `;
export type CreateContactListMutationFn = Apollo.MutationFunction<CreateContactListMutation, CreateContactListMutationVariables>;

/**
 * __useCreateContactListMutation__
 *
 * To run a mutation, you first call `useCreateContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactListMutation, { data, loading, error }] = useCreateContactListMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateContactListMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactListMutation, CreateContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactListMutation, CreateContactListMutationVariables>(CreateContactListDocument, options);
      }
export type CreateContactListMutationHookResult = ReturnType<typeof useCreateContactListMutation>;
export type CreateContactListMutationResult = Apollo.MutationResult<CreateContactListMutation>;
export type CreateContactListMutationOptions = Apollo.BaseMutationOptions<CreateContactListMutation, CreateContactListMutationVariables>;
export const CreatePhotoFilterDocument = gql`
    mutation createPhotoFilter($input: CreatePhotoFilterInput!) {
  createPhotoFilter(input: $input) {
    errors {
      path
      message
    }
    photoFilter {
      id
      name
      cloudinaryArtisticFilter
      cloudinaryTransform
    }
  }
}
    `;
export type CreatePhotoFilterMutationFn = Apollo.MutationFunction<CreatePhotoFilterMutation, CreatePhotoFilterMutationVariables>;

/**
 * __useCreatePhotoFilterMutation__
 *
 * To run a mutation, you first call `useCreatePhotoFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhotoFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhotoFilterMutation, { data, loading, error }] = useCreatePhotoFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePhotoFilterMutation(baseOptions?: Apollo.MutationHookOptions<CreatePhotoFilterMutation, CreatePhotoFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePhotoFilterMutation, CreatePhotoFilterMutationVariables>(CreatePhotoFilterDocument, options);
      }
export type CreatePhotoFilterMutationHookResult = ReturnType<typeof useCreatePhotoFilterMutation>;
export type CreatePhotoFilterMutationResult = Apollo.MutationResult<CreatePhotoFilterMutation>;
export type CreatePhotoFilterMutationOptions = Apollo.BaseMutationOptions<CreatePhotoFilterMutation, CreatePhotoFilterMutationVariables>;
export const CreatePoseDocument = gql`
    mutation createPose($input: AddPoseInput!) {
  addPose(input: $input) {
    pose {
      ...PoseDetails
    }
    errors {
      path
      message
    }
  }
}
    ${PoseDetailsFragmentDoc}`;
export type CreatePoseMutationFn = Apollo.MutationFunction<CreatePoseMutation, CreatePoseMutationVariables>;

/**
 * __useCreatePoseMutation__
 *
 * To run a mutation, you first call `useCreatePoseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePoseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPoseMutation, { data, loading, error }] = useCreatePoseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePoseMutation(baseOptions?: Apollo.MutationHookOptions<CreatePoseMutation, CreatePoseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePoseMutation, CreatePoseMutationVariables>(CreatePoseDocument, options);
      }
export type CreatePoseMutationHookResult = ReturnType<typeof useCreatePoseMutation>;
export type CreatePoseMutationResult = Apollo.MutationResult<CreatePoseMutation>;
export type CreatePoseMutationOptions = Apollo.BaseMutationOptions<CreatePoseMutation, CreatePoseMutationVariables>;
export const CreatePoseProfileDocument = gql`
    mutation createPoseProfile($input: CreatePoseProfileInput!) {
  createPoseProfile(input: $input) {
    poseProfile {
      id
    }
    errors {
      path
      message
    }
  }
}
    `;
export type CreatePoseProfileMutationFn = Apollo.MutationFunction<CreatePoseProfileMutation, CreatePoseProfileMutationVariables>;

/**
 * __useCreatePoseProfileMutation__
 *
 * To run a mutation, you first call `useCreatePoseProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePoseProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPoseProfileMutation, { data, loading, error }] = useCreatePoseProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePoseProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreatePoseProfileMutation, CreatePoseProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePoseProfileMutation, CreatePoseProfileMutationVariables>(CreatePoseProfileDocument, options);
      }
export type CreatePoseProfileMutationHookResult = ReturnType<typeof useCreatePoseProfileMutation>;
export type CreatePoseProfileMutationResult = Apollo.MutationResult<CreatePoseProfileMutation>;
export type CreatePoseProfileMutationOptions = Apollo.BaseMutationOptions<CreatePoseProfileMutation, CreatePoseProfileMutationVariables>;
export const CreatePresetDocument = gql`
    mutation createPreset($input: CreatePresetInput!) {
  createPreset(input: $input) {
    preset {
      ...PresetDetails
    }
    errors {
      path
      message
    }
  }
}
    ${PresetDetailsFragmentDoc}`;
export type CreatePresetMutationFn = Apollo.MutationFunction<CreatePresetMutation, CreatePresetMutationVariables>;

/**
 * __useCreatePresetMutation__
 *
 * To run a mutation, you first call `useCreatePresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresetMutation, { data, loading, error }] = useCreatePresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresetMutation(baseOptions?: Apollo.MutationHookOptions<CreatePresetMutation, CreatePresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePresetMutation, CreatePresetMutationVariables>(CreatePresetDocument, options);
      }
export type CreatePresetMutationHookResult = ReturnType<typeof useCreatePresetMutation>;
export type CreatePresetMutationResult = Apollo.MutationResult<CreatePresetMutation>;
export type CreatePresetMutationOptions = Apollo.BaseMutationOptions<CreatePresetMutation, CreatePresetMutationVariables>;
export const CreateStudioDocument = gql`
    mutation createStudio($input: CreateStudioInput!) {
  createStudio(input: $input) {
    studio {
      ...StudioDetails
    }
    errors {
      message
      path
    }
  }
}
    ${StudioDetailsFragmentDoc}`;
export type CreateStudioMutationFn = Apollo.MutationFunction<CreateStudioMutation, CreateStudioMutationVariables>;

/**
 * __useCreateStudioMutation__
 *
 * To run a mutation, you first call `useCreateStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudioMutation, { data, loading, error }] = useCreateStudioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStudioMutation(baseOptions?: Apollo.MutationHookOptions<CreateStudioMutation, CreateStudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStudioMutation, CreateStudioMutationVariables>(CreateStudioDocument, options);
      }
export type CreateStudioMutationHookResult = ReturnType<typeof useCreateStudioMutation>;
export type CreateStudioMutationResult = Apollo.MutationResult<CreateStudioMutation>;
export type CreateStudioMutationOptions = Apollo.BaseMutationOptions<CreateStudioMutation, CreateStudioMutationVariables>;
export const CreateSubmissionNotificationDocument = gql`
    mutation createSubmissionNotification($input: CreateSubmissionNotificationInput!) {
  createSubmissionNotification(input: $input) {
    submissionNotification {
      __typename
      frequency
      id
      lastSentAt
      studio {
        __typename
        id
        name
      }
      user {
        __typename
        id
        firstName
        lastName
        email
      }
    }
    errors {
      path
      message
    }
  }
}
    `;
export type CreateSubmissionNotificationMutationFn = Apollo.MutationFunction<CreateSubmissionNotificationMutation, CreateSubmissionNotificationMutationVariables>;

/**
 * __useCreateSubmissionNotificationMutation__
 *
 * To run a mutation, you first call `useCreateSubmissionNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubmissionNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubmissionNotificationMutation, { data, loading, error }] = useCreateSubmissionNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubmissionNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubmissionNotificationMutation, CreateSubmissionNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubmissionNotificationMutation, CreateSubmissionNotificationMutationVariables>(CreateSubmissionNotificationDocument, options);
      }
export type CreateSubmissionNotificationMutationHookResult = ReturnType<typeof useCreateSubmissionNotificationMutation>;
export type CreateSubmissionNotificationMutationResult = Apollo.MutationResult<CreateSubmissionNotificationMutation>;
export type CreateSubmissionNotificationMutationOptions = Apollo.BaseMutationOptions<CreateSubmissionNotificationMutation, CreateSubmissionNotificationMutationVariables>;
export const DeleteAccessTokenDocument = gql`
    mutation deleteAccessToken($input: DeleteAccessTokenInput!) {
  deleteAccessToken(input: $input) {
    deletedTokenId
    errors {
      path
      message
    }
  }
}
    `;
export type DeleteAccessTokenMutationFn = Apollo.MutationFunction<DeleteAccessTokenMutation, DeleteAccessTokenMutationVariables>;

/**
 * __useDeleteAccessTokenMutation__
 *
 * To run a mutation, you first call `useDeleteAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccessTokenMutation, { data, loading, error }] = useDeleteAccessTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccessTokenMutation, DeleteAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccessTokenMutation, DeleteAccessTokenMutationVariables>(DeleteAccessTokenDocument, options);
      }
export type DeleteAccessTokenMutationHookResult = ReturnType<typeof useDeleteAccessTokenMutation>;
export type DeleteAccessTokenMutationResult = Apollo.MutationResult<DeleteAccessTokenMutation>;
export type DeleteAccessTokenMutationOptions = Apollo.BaseMutationOptions<DeleteAccessTokenMutation, DeleteAccessTokenMutationVariables>;
export const DeleteContactAttributeDocument = gql`
    mutation deleteContactAttribute($input: DeleteContactAttributeInput!) {
  deleteContactAttribute(input: $input) {
    deletedKey
    errors {
      message
    }
  }
}
    `;
export type DeleteContactAttributeMutationFn = Apollo.MutationFunction<DeleteContactAttributeMutation, DeleteContactAttributeMutationVariables>;

/**
 * __useDeleteContactAttributeMutation__
 *
 * To run a mutation, you first call `useDeleteContactAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactAttributeMutation, { data, loading, error }] = useDeleteContactAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContactAttributeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactAttributeMutation, DeleteContactAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactAttributeMutation, DeleteContactAttributeMutationVariables>(DeleteContactAttributeDocument, options);
      }
export type DeleteContactAttributeMutationHookResult = ReturnType<typeof useDeleteContactAttributeMutation>;
export type DeleteContactAttributeMutationResult = Apollo.MutationResult<DeleteContactAttributeMutation>;
export type DeleteContactAttributeMutationOptions = Apollo.BaseMutationOptions<DeleteContactAttributeMutation, DeleteContactAttributeMutationVariables>;
export const DeleteContactListDocument = gql`
    mutation deleteContactList($id: GID!) {
  deleteContactList(input: {id: $id}) {
    deletedId
    errors {
      path
      message
    }
  }
}
    `;
export type DeleteContactListMutationFn = Apollo.MutationFunction<DeleteContactListMutation, DeleteContactListMutationVariables>;

/**
 * __useDeleteContactListMutation__
 *
 * To run a mutation, you first call `useDeleteContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactListMutation, { data, loading, error }] = useDeleteContactListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactListMutation, DeleteContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactListMutation, DeleteContactListMutationVariables>(DeleteContactListDocument, options);
      }
export type DeleteContactListMutationHookResult = ReturnType<typeof useDeleteContactListMutation>;
export type DeleteContactListMutationResult = Apollo.MutationResult<DeleteContactListMutation>;
export type DeleteContactListMutationOptions = Apollo.BaseMutationOptions<DeleteContactListMutation, DeleteContactListMutationVariables>;
export const DeleteContactsDocument = gql`
    mutation deleteContacts($ids: [GID!]!) {
  deleteContacts(input: {ids: $ids}) {
    deletedIds
    errors {
      path
      message
    }
  }
}
    `;
export type DeleteContactsMutationFn = Apollo.MutationFunction<DeleteContactsMutation, DeleteContactsMutationVariables>;

/**
 * __useDeleteContactsMutation__
 *
 * To run a mutation, you first call `useDeleteContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactsMutation, { data, loading, error }] = useDeleteContactsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteContactsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactsMutation, DeleteContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactsMutation, DeleteContactsMutationVariables>(DeleteContactsDocument, options);
      }
export type DeleteContactsMutationHookResult = ReturnType<typeof useDeleteContactsMutation>;
export type DeleteContactsMutationResult = Apollo.MutationResult<DeleteContactsMutation>;
export type DeleteContactsMutationOptions = Apollo.BaseMutationOptions<DeleteContactsMutation, DeleteContactsMutationVariables>;
export const DeletePhotoFilterDocument = gql`
    mutation deletePhotoFilter($input: DeletePhotoFilterInput!) {
  deletePhotoFilter(input: $input) {
    deletedId
    errors {
      path
      message
    }
  }
}
    `;
export type DeletePhotoFilterMutationFn = Apollo.MutationFunction<DeletePhotoFilterMutation, DeletePhotoFilterMutationVariables>;

/**
 * __useDeletePhotoFilterMutation__
 *
 * To run a mutation, you first call `useDeletePhotoFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhotoFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhotoFilterMutation, { data, loading, error }] = useDeletePhotoFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePhotoFilterMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhotoFilterMutation, DeletePhotoFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePhotoFilterMutation, DeletePhotoFilterMutationVariables>(DeletePhotoFilterDocument, options);
      }
export type DeletePhotoFilterMutationHookResult = ReturnType<typeof useDeletePhotoFilterMutation>;
export type DeletePhotoFilterMutationResult = Apollo.MutationResult<DeletePhotoFilterMutation>;
export type DeletePhotoFilterMutationOptions = Apollo.BaseMutationOptions<DeletePhotoFilterMutation, DeletePhotoFilterMutationVariables>;
export const DeletePoseDocument = gql`
    mutation deletePose($input: DeletePoseInput!) {
  deletePose(input: $input) {
    deletedId
    errors {
      path
      message
    }
  }
}
    `;
export type DeletePoseMutationFn = Apollo.MutationFunction<DeletePoseMutation, DeletePoseMutationVariables>;

/**
 * __useDeletePoseMutation__
 *
 * To run a mutation, you first call `useDeletePoseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePoseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePoseMutation, { data, loading, error }] = useDeletePoseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePoseMutation(baseOptions?: Apollo.MutationHookOptions<DeletePoseMutation, DeletePoseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePoseMutation, DeletePoseMutationVariables>(DeletePoseDocument, options);
      }
export type DeletePoseMutationHookResult = ReturnType<typeof useDeletePoseMutation>;
export type DeletePoseMutationResult = Apollo.MutationResult<DeletePoseMutation>;
export type DeletePoseMutationOptions = Apollo.BaseMutationOptions<DeletePoseMutation, DeletePoseMutationVariables>;
export const DeletePoseProfileDocument = gql`
    mutation deletePoseProfile($id: GID!) {
  deletePoseProfile(input: {id: $id}) {
    deletedId
    errors {
      path
      message
    }
  }
}
    `;
export type DeletePoseProfileMutationFn = Apollo.MutationFunction<DeletePoseProfileMutation, DeletePoseProfileMutationVariables>;

/**
 * __useDeletePoseProfileMutation__
 *
 * To run a mutation, you first call `useDeletePoseProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePoseProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePoseProfileMutation, { data, loading, error }] = useDeletePoseProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePoseProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeletePoseProfileMutation, DeletePoseProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePoseProfileMutation, DeletePoseProfileMutationVariables>(DeletePoseProfileDocument, options);
      }
export type DeletePoseProfileMutationHookResult = ReturnType<typeof useDeletePoseProfileMutation>;
export type DeletePoseProfileMutationResult = Apollo.MutationResult<DeletePoseProfileMutation>;
export type DeletePoseProfileMutationOptions = Apollo.BaseMutationOptions<DeletePoseProfileMutation, DeletePoseProfileMutationVariables>;
export const DeletePresetDocument = gql`
    mutation deletePreset($input: DeletePresetInput!) {
  deletePreset(input: $input) {
    deletedId
    errors {
      path
      message
    }
  }
}
    `;
export type DeletePresetMutationFn = Apollo.MutationFunction<DeletePresetMutation, DeletePresetMutationVariables>;

/**
 * __useDeletePresetMutation__
 *
 * To run a mutation, you first call `useDeletePresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePresetMutation, { data, loading, error }] = useDeletePresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePresetMutation(baseOptions?: Apollo.MutationHookOptions<DeletePresetMutation, DeletePresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePresetMutation, DeletePresetMutationVariables>(DeletePresetDocument, options);
      }
export type DeletePresetMutationHookResult = ReturnType<typeof useDeletePresetMutation>;
export type DeletePresetMutationResult = Apollo.MutationResult<DeletePresetMutation>;
export type DeletePresetMutationOptions = Apollo.BaseMutationOptions<DeletePresetMutation, DeletePresetMutationVariables>;
export const DeleteStudioDocument = gql`
    mutation deleteStudio($input: DeleteStudioInput!) {
  deleteStudio(input: $input) {
    deletedId
    errors {
      path
      message
    }
  }
}
    `;
export type DeleteStudioMutationFn = Apollo.MutationFunction<DeleteStudioMutation, DeleteStudioMutationVariables>;

/**
 * __useDeleteStudioMutation__
 *
 * To run a mutation, you first call `useDeleteStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudioMutation, { data, loading, error }] = useDeleteStudioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStudioMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStudioMutation, DeleteStudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStudioMutation, DeleteStudioMutationVariables>(DeleteStudioDocument, options);
      }
export type DeleteStudioMutationHookResult = ReturnType<typeof useDeleteStudioMutation>;
export type DeleteStudioMutationResult = Apollo.MutationResult<DeleteStudioMutation>;
export type DeleteStudioMutationOptions = Apollo.BaseMutationOptions<DeleteStudioMutation, DeleteStudioMutationVariables>;
export const DeleteStudioSessionsDocument = gql`
    mutation deleteStudioSessions($input: DeleteStudioSessionsInput!) {
  deleteStudioSessions(input: $input) {
    deletedIds
    errors {
      path
      message
    }
  }
}
    `;
export type DeleteStudioSessionsMutationFn = Apollo.MutationFunction<DeleteStudioSessionsMutation, DeleteStudioSessionsMutationVariables>;

/**
 * __useDeleteStudioSessionsMutation__
 *
 * To run a mutation, you first call `useDeleteStudioSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudioSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudioSessionsMutation, { data, loading, error }] = useDeleteStudioSessionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStudioSessionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStudioSessionsMutation, DeleteStudioSessionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStudioSessionsMutation, DeleteStudioSessionsMutationVariables>(DeleteStudioSessionsDocument, options);
      }
export type DeleteStudioSessionsMutationHookResult = ReturnType<typeof useDeleteStudioSessionsMutation>;
export type DeleteStudioSessionsMutationResult = Apollo.MutationResult<DeleteStudioSessionsMutation>;
export type DeleteStudioSessionsMutationOptions = Apollo.BaseMutationOptions<DeleteStudioSessionsMutation, DeleteStudioSessionsMutationVariables>;
export const DeleteSubmissionNotificationsDocument = gql`
    mutation deleteSubmissionNotifications($input: DeleteSubmissionNotificationsInput!) {
  deleteSubmissionNotifications(input: $input) {
    deletedIds
    errors {
      path
      message
    }
  }
}
    `;
export type DeleteSubmissionNotificationsMutationFn = Apollo.MutationFunction<DeleteSubmissionNotificationsMutation, DeleteSubmissionNotificationsMutationVariables>;

/**
 * __useDeleteSubmissionNotificationsMutation__
 *
 * To run a mutation, you first call `useDeleteSubmissionNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubmissionNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubmissionNotificationsMutation, { data, loading, error }] = useDeleteSubmissionNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSubmissionNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubmissionNotificationsMutation, DeleteSubmissionNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubmissionNotificationsMutation, DeleteSubmissionNotificationsMutationVariables>(DeleteSubmissionNotificationsDocument, options);
      }
export type DeleteSubmissionNotificationsMutationHookResult = ReturnType<typeof useDeleteSubmissionNotificationsMutation>;
export type DeleteSubmissionNotificationsMutationResult = Apollo.MutationResult<DeleteSubmissionNotificationsMutation>;
export type DeleteSubmissionNotificationsMutationOptions = Apollo.BaseMutationOptions<DeleteSubmissionNotificationsMutation, DeleteSubmissionNotificationsMutationVariables>;
export const DeleteSubmissionsDocument = gql`
    mutation deleteSubmissions($ids: [GID!]!) {
  deleteSubmissions(input: {ids: $ids}) {
    deletedIds
    errors {
      message
      path
    }
  }
}
    `;
export type DeleteSubmissionsMutationFn = Apollo.MutationFunction<DeleteSubmissionsMutation, DeleteSubmissionsMutationVariables>;

/**
 * __useDeleteSubmissionsMutation__
 *
 * To run a mutation, you first call `useDeleteSubmissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubmissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubmissionsMutation, { data, loading, error }] = useDeleteSubmissionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteSubmissionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubmissionsMutation, DeleteSubmissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubmissionsMutation, DeleteSubmissionsMutationVariables>(DeleteSubmissionsDocument, options);
      }
export type DeleteSubmissionsMutationHookResult = ReturnType<typeof useDeleteSubmissionsMutation>;
export type DeleteSubmissionsMutationResult = Apollo.MutationResult<DeleteSubmissionsMutation>;
export type DeleteSubmissionsMutationOptions = Apollo.BaseMutationOptions<DeleteSubmissionsMutation, DeleteSubmissionsMutationVariables>;
export const DeselectAccountDocument = gql`
    mutation deselectAccount {
  deselectAccount {
    errors {
      path
      message
    }
  }
}
    `;
export type DeselectAccountMutationFn = Apollo.MutationFunction<DeselectAccountMutation, DeselectAccountMutationVariables>;

/**
 * __useDeselectAccountMutation__
 *
 * To run a mutation, you first call `useDeselectAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeselectAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deselectAccountMutation, { data, loading, error }] = useDeselectAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeselectAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeselectAccountMutation, DeselectAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeselectAccountMutation, DeselectAccountMutationVariables>(DeselectAccountDocument, options);
      }
export type DeselectAccountMutationHookResult = ReturnType<typeof useDeselectAccountMutation>;
export type DeselectAccountMutationResult = Apollo.MutationResult<DeselectAccountMutation>;
export type DeselectAccountMutationOptions = Apollo.BaseMutationOptions<DeselectAccountMutation, DeselectAccountMutationVariables>;
export const EnhanceSubmissionsDocument = gql`
    mutation enhanceSubmissions($ids: [GID!]!) {
  enhanceSubmissions(input: {ids: $ids}) {
    submissions {
      id
    }
    errors {
      message
      path
    }
  }
}
    `;
export type EnhanceSubmissionsMutationFn = Apollo.MutationFunction<EnhanceSubmissionsMutation, EnhanceSubmissionsMutationVariables>;

/**
 * __useEnhanceSubmissionsMutation__
 *
 * To run a mutation, you first call `useEnhanceSubmissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnhanceSubmissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enhanceSubmissionsMutation, { data, loading, error }] = useEnhanceSubmissionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useEnhanceSubmissionsMutation(baseOptions?: Apollo.MutationHookOptions<EnhanceSubmissionsMutation, EnhanceSubmissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnhanceSubmissionsMutation, EnhanceSubmissionsMutationVariables>(EnhanceSubmissionsDocument, options);
      }
export type EnhanceSubmissionsMutationHookResult = ReturnType<typeof useEnhanceSubmissionsMutation>;
export type EnhanceSubmissionsMutationResult = Apollo.MutationResult<EnhanceSubmissionsMutation>;
export type EnhanceSubmissionsMutationOptions = Apollo.BaseMutationOptions<EnhanceSubmissionsMutation, EnhanceSubmissionsMutationVariables>;
export const ExportContactsDocument = gql`
    mutation exportContacts($input: ExportContactsInput!) {
  exportContacts(input: $input) {
    exportId
    errors {
      path
      message
    }
  }
}
    `;
export type ExportContactsMutationFn = Apollo.MutationFunction<ExportContactsMutation, ExportContactsMutationVariables>;

/**
 * __useExportContactsMutation__
 *
 * To run a mutation, you first call `useExportContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportContactsMutation, { data, loading, error }] = useExportContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportContactsMutation(baseOptions?: Apollo.MutationHookOptions<ExportContactsMutation, ExportContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportContactsMutation, ExportContactsMutationVariables>(ExportContactsDocument, options);
      }
export type ExportContactsMutationHookResult = ReturnType<typeof useExportContactsMutation>;
export type ExportContactsMutationResult = Apollo.MutationResult<ExportContactsMutation>;
export type ExportContactsMutationOptions = Apollo.BaseMutationOptions<ExportContactsMutation, ExportContactsMutationVariables>;
export const ExportSubmissionsDocument = gql`
    mutation exportSubmissions($input: ExportSubmissionsInput!) {
  exportSubmissions(input: $input) {
    exportId
    errors {
      path
      message
    }
  }
}
    `;
export type ExportSubmissionsMutationFn = Apollo.MutationFunction<ExportSubmissionsMutation, ExportSubmissionsMutationVariables>;

/**
 * __useExportSubmissionsMutation__
 *
 * To run a mutation, you first call `useExportSubmissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportSubmissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportSubmissionsMutation, { data, loading, error }] = useExportSubmissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportSubmissionsMutation(baseOptions?: Apollo.MutationHookOptions<ExportSubmissionsMutation, ExportSubmissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportSubmissionsMutation, ExportSubmissionsMutationVariables>(ExportSubmissionsDocument, options);
      }
export type ExportSubmissionsMutationHookResult = ReturnType<typeof useExportSubmissionsMutation>;
export type ExportSubmissionsMutationResult = Apollo.MutationResult<ExportSubmissionsMutation>;
export type ExportSubmissionsMutationOptions = Apollo.BaseMutationOptions<ExportSubmissionsMutation, ExportSubmissionsMutationVariables>;
export const GenerateMetricsTokenDocument = gql`
    mutation generateMetricsToken {
  generateMetricsToken {
    token
    errors {
      path
      message
    }
  }
}
    `;
export type GenerateMetricsTokenMutationFn = Apollo.MutationFunction<GenerateMetricsTokenMutation, GenerateMetricsTokenMutationVariables>;

/**
 * __useGenerateMetricsTokenMutation__
 *
 * To run a mutation, you first call `useGenerateMetricsTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMetricsTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMetricsTokenMutation, { data, loading, error }] = useGenerateMetricsTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateMetricsTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateMetricsTokenMutation, GenerateMetricsTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateMetricsTokenMutation, GenerateMetricsTokenMutationVariables>(GenerateMetricsTokenDocument, options);
      }
export type GenerateMetricsTokenMutationHookResult = ReturnType<typeof useGenerateMetricsTokenMutation>;
export type GenerateMetricsTokenMutationResult = Apollo.MutationResult<GenerateMetricsTokenMutation>;
export type GenerateMetricsTokenMutationOptions = Apollo.BaseMutationOptions<GenerateMetricsTokenMutation, GenerateMetricsTokenMutationVariables>;
export const InviteContactListDocument = gql`
    mutation inviteContactList($studioId: GID!, $contactListId: GID!) {
  inviteContactList(input: {studioId: $studioId, contactListId: $contactListId}) {
    contactList {
      id
    }
    errors {
      message
      path
    }
  }
}
    `;
export type InviteContactListMutationFn = Apollo.MutationFunction<InviteContactListMutation, InviteContactListMutationVariables>;

/**
 * __useInviteContactListMutation__
 *
 * To run a mutation, you first call `useInviteContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteContactListMutation, { data, loading, error }] = useInviteContactListMutation({
 *   variables: {
 *      studioId: // value for 'studioId'
 *      contactListId: // value for 'contactListId'
 *   },
 * });
 */
export function useInviteContactListMutation(baseOptions?: Apollo.MutationHookOptions<InviteContactListMutation, InviteContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteContactListMutation, InviteContactListMutationVariables>(InviteContactListDocument, options);
      }
export type InviteContactListMutationHookResult = ReturnType<typeof useInviteContactListMutation>;
export type InviteContactListMutationResult = Apollo.MutationResult<InviteContactListMutation>;
export type InviteContactListMutationOptions = Apollo.BaseMutationOptions<InviteContactListMutation, InviteContactListMutationVariables>;
export const InviteContactsDocument = gql`
    mutation inviteContacts($studioId: GID!, $emails: [String!]!) {
  inviteContacts(input: {studioId: $studioId, emails: $emails}) {
    contacts {
      id
    }
    errors {
      message
      path
    }
  }
}
    `;
export type InviteContactsMutationFn = Apollo.MutationFunction<InviteContactsMutation, InviteContactsMutationVariables>;

/**
 * __useInviteContactsMutation__
 *
 * To run a mutation, you first call `useInviteContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteContactsMutation, { data, loading, error }] = useInviteContactsMutation({
 *   variables: {
 *      studioId: // value for 'studioId'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useInviteContactsMutation(baseOptions?: Apollo.MutationHookOptions<InviteContactsMutation, InviteContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteContactsMutation, InviteContactsMutationVariables>(InviteContactsDocument, options);
      }
export type InviteContactsMutationHookResult = ReturnType<typeof useInviteContactsMutation>;
export type InviteContactsMutationResult = Apollo.MutationResult<InviteContactsMutation>;
export type InviteContactsMutationOptions = Apollo.BaseMutationOptions<InviteContactsMutation, InviteContactsMutationVariables>;
export const PresignCloudinaryFileDocument = gql`
    mutation presignCloudinaryFile($filename: String!, $accountId: String!, $accessType: AccessTypeEnum) {
  presignCloudinaryFile(
    filename: $filename
    accountId: $accountId
    accessType: $accessType
  ) {
    apiKey
    publicId
    signature
    params
    uploadUrl
  }
}
    `;
export type PresignCloudinaryFileMutationFn = Apollo.MutationFunction<PresignCloudinaryFileMutation, PresignCloudinaryFileMutationVariables>;

/**
 * __usePresignCloudinaryFileMutation__
 *
 * To run a mutation, you first call `usePresignCloudinaryFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePresignCloudinaryFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [presignCloudinaryFileMutation, { data, loading, error }] = usePresignCloudinaryFileMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      accountId: // value for 'accountId'
 *      accessType: // value for 'accessType'
 *   },
 * });
 */
export function usePresignCloudinaryFileMutation(baseOptions?: Apollo.MutationHookOptions<PresignCloudinaryFileMutation, PresignCloudinaryFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PresignCloudinaryFileMutation, PresignCloudinaryFileMutationVariables>(PresignCloudinaryFileDocument, options);
      }
export type PresignCloudinaryFileMutationHookResult = ReturnType<typeof usePresignCloudinaryFileMutation>;
export type PresignCloudinaryFileMutationResult = Apollo.MutationResult<PresignCloudinaryFileMutation>;
export type PresignCloudinaryFileMutationOptions = Apollo.BaseMutationOptions<PresignCloudinaryFileMutation, PresignCloudinaryFileMutationVariables>;
export const PreviewPresetDocument = gql`
    mutation previewPreset($input: PreviewPresetInput!) {
  previewPreset(input: $input) {
    originalUrl
    sampleUrl
    errors {
      path
      message
    }
  }
}
    `;
export type PreviewPresetMutationFn = Apollo.MutationFunction<PreviewPresetMutation, PreviewPresetMutationVariables>;

/**
 * __usePreviewPresetMutation__
 *
 * To run a mutation, you first call `usePreviewPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewPresetMutation, { data, loading, error }] = usePreviewPresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreviewPresetMutation(baseOptions?: Apollo.MutationHookOptions<PreviewPresetMutation, PreviewPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreviewPresetMutation, PreviewPresetMutationVariables>(PreviewPresetDocument, options);
      }
export type PreviewPresetMutationHookResult = ReturnType<typeof usePreviewPresetMutation>;
export type PreviewPresetMutationResult = Apollo.MutationResult<PreviewPresetMutation>;
export type PreviewPresetMutationOptions = Apollo.BaseMutationOptions<PreviewPresetMutation, PreviewPresetMutationVariables>;
export const RejectSubmissionsDocument = gql`
    mutation rejectSubmissions($ids: [GID!]!, $retake: RetakeInput) {
  rejectSubmissions(input: {ids: $ids, retake: $retake}) {
    submissions {
      id
      state
    }
    errors {
      path
      message
    }
  }
}
    `;
export type RejectSubmissionsMutationFn = Apollo.MutationFunction<RejectSubmissionsMutation, RejectSubmissionsMutationVariables>;

/**
 * __useRejectSubmissionsMutation__
 *
 * To run a mutation, you first call `useRejectSubmissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectSubmissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectSubmissionsMutation, { data, loading, error }] = useRejectSubmissionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      retake: // value for 'retake'
 *   },
 * });
 */
export function useRejectSubmissionsMutation(baseOptions?: Apollo.MutationHookOptions<RejectSubmissionsMutation, RejectSubmissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectSubmissionsMutation, RejectSubmissionsMutationVariables>(RejectSubmissionsDocument, options);
      }
export type RejectSubmissionsMutationHookResult = ReturnType<typeof useRejectSubmissionsMutation>;
export type RejectSubmissionsMutationResult = Apollo.MutationResult<RejectSubmissionsMutation>;
export type RejectSubmissionsMutationOptions = Apollo.BaseMutationOptions<RejectSubmissionsMutation, RejectSubmissionsMutationVariables>;
export const ResetStudioPhotosCountDocument = gql`
    mutation resetStudioPhotosCount($input: ResetStudioPhotosCountInput!) {
  resetStudioPhotosCount(input: $input) {
    studio {
      id
      photosCount
    }
  }
}
    `;
export type ResetStudioPhotosCountMutationFn = Apollo.MutationFunction<ResetStudioPhotosCountMutation, ResetStudioPhotosCountMutationVariables>;

/**
 * __useResetStudioPhotosCountMutation__
 *
 * To run a mutation, you first call `useResetStudioPhotosCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetStudioPhotosCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetStudioPhotosCountMutation, { data, loading, error }] = useResetStudioPhotosCountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetStudioPhotosCountMutation(baseOptions?: Apollo.MutationHookOptions<ResetStudioPhotosCountMutation, ResetStudioPhotosCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetStudioPhotosCountMutation, ResetStudioPhotosCountMutationVariables>(ResetStudioPhotosCountDocument, options);
      }
export type ResetStudioPhotosCountMutationHookResult = ReturnType<typeof useResetStudioPhotosCountMutation>;
export type ResetStudioPhotosCountMutationResult = Apollo.MutationResult<ResetStudioPhotosCountMutation>;
export type ResetStudioPhotosCountMutationOptions = Apollo.BaseMutationOptions<ResetStudioPhotosCountMutation, ResetStudioPhotosCountMutationVariables>;
export const SelectAccountDocument = gql`
    mutation SelectAccount($snapbarId: SnapbarID!) {
  selectAccount(snapbarId: $snapbarId) {
    account {
      id
      snapbarId
      name
    }
    errors {
      path
      message
    }
  }
}
    `;
export type SelectAccountMutationFn = Apollo.MutationFunction<SelectAccountMutation, SelectAccountMutationVariables>;

/**
 * __useSelectAccountMutation__
 *
 * To run a mutation, you first call `useSelectAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectAccountMutation, { data, loading, error }] = useSelectAccountMutation({
 *   variables: {
 *      snapbarId: // value for 'snapbarId'
 *   },
 * });
 */
export function useSelectAccountMutation(baseOptions?: Apollo.MutationHookOptions<SelectAccountMutation, SelectAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelectAccountMutation, SelectAccountMutationVariables>(SelectAccountDocument, options);
      }
export type SelectAccountMutationHookResult = ReturnType<typeof useSelectAccountMutation>;
export type SelectAccountMutationResult = Apollo.MutationResult<SelectAccountMutation>;
export type SelectAccountMutationOptions = Apollo.BaseMutationOptions<SelectAccountMutation, SelectAccountMutationVariables>;
export const SendRemindersDocument = gql`
    mutation SendReminders($input: SendRemindersInput!) {
  sendReminders(input: $input) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type SendRemindersMutationFn = Apollo.MutationFunction<SendRemindersMutation, SendRemindersMutationVariables>;

/**
 * __useSendRemindersMutation__
 *
 * To run a mutation, you first call `useSendRemindersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRemindersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRemindersMutation, { data, loading, error }] = useSendRemindersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendRemindersMutation(baseOptions?: Apollo.MutationHookOptions<SendRemindersMutation, SendRemindersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendRemindersMutation, SendRemindersMutationVariables>(SendRemindersDocument, options);
      }
export type SendRemindersMutationHookResult = ReturnType<typeof useSendRemindersMutation>;
export type SendRemindersMutationResult = Apollo.MutationResult<SendRemindersMutation>;
export type SendRemindersMutationOptions = Apollo.BaseMutationOptions<SendRemindersMutation, SendRemindersMutationVariables>;
export const SignExportDocument = gql`
    mutation SignExport($input: SignExportInput!) {
  signExport(input: $input) {
    url
    errors {
      path
      message
    }
  }
}
    `;
export type SignExportMutationFn = Apollo.MutationFunction<SignExportMutation, SignExportMutationVariables>;

/**
 * __useSignExportMutation__
 *
 * To run a mutation, you first call `useSignExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signExportMutation, { data, loading, error }] = useSignExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignExportMutation(baseOptions?: Apollo.MutationHookOptions<SignExportMutation, SignExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignExportMutation, SignExportMutationVariables>(SignExportDocument, options);
      }
export type SignExportMutationHookResult = ReturnType<typeof useSignExportMutation>;
export type SignExportMutationResult = Apollo.MutationResult<SignExportMutation>;
export type SignExportMutationOptions = Apollo.BaseMutationOptions<SignExportMutation, SignExportMutationVariables>;
export const UpdateBrandingDocument = gql`
    mutation updateBranding($input: UpdateStudioInput!) {
  updateStudio(input: $input) {
    studio {
      id
      branding {
        background {
          ... on Color {
            hex
          }
          ... on CloudFile {
            ...CloudFileDetails
          }
        }
        primaryColor
        secondaryColor
        tertiaryColor
        logo {
          ...CloudFileDetails
        }
      }
    }
    errors {
      message
      path
    }
  }
}
    ${CloudFileDetailsFragmentDoc}`;
export type UpdateBrandingMutationFn = Apollo.MutationFunction<UpdateBrandingMutation, UpdateBrandingMutationVariables>;

/**
 * __useUpdateBrandingMutation__
 *
 * To run a mutation, you first call `useUpdateBrandingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandingMutation, { data, loading, error }] = useUpdateBrandingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandingMutation, UpdateBrandingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrandingMutation, UpdateBrandingMutationVariables>(UpdateBrandingDocument, options);
      }
export type UpdateBrandingMutationHookResult = ReturnType<typeof useUpdateBrandingMutation>;
export type UpdateBrandingMutationResult = Apollo.MutationResult<UpdateBrandingMutation>;
export type UpdateBrandingMutationOptions = Apollo.BaseMutationOptions<UpdateBrandingMutation, UpdateBrandingMutationVariables>;
export const UpdateContactDocument = gql`
    mutation updateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    contact {
      id
      firstName
      lastName
      email
      phone
      customAttributes
      contactLists {
        id
        name
      }
    }
    errors {
      path
      message
    }
  }
}
    `;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const UpdateContactAttributeDocument = gql`
    mutation UpdateContactAttribute($input: UpdateContactAttributeInput!) {
  updateContactAttribute(input: $input) {
    contactAttribute {
      key
    }
    errors {
      message
      path
    }
  }
}
    `;
export type UpdateContactAttributeMutationFn = Apollo.MutationFunction<UpdateContactAttributeMutation, UpdateContactAttributeMutationVariables>;

/**
 * __useUpdateContactAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateContactAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactAttributeMutation, { data, loading, error }] = useUpdateContactAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactAttributeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactAttributeMutation, UpdateContactAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactAttributeMutation, UpdateContactAttributeMutationVariables>(UpdateContactAttributeDocument, options);
      }
export type UpdateContactAttributeMutationHookResult = ReturnType<typeof useUpdateContactAttributeMutation>;
export type UpdateContactAttributeMutationResult = Apollo.MutationResult<UpdateContactAttributeMutation>;
export type UpdateContactAttributeMutationOptions = Apollo.BaseMutationOptions<UpdateContactAttributeMutation, UpdateContactAttributeMutationVariables>;
export const UpdateContactListDocument = gql`
    mutation updateContactList($input: UpdateContactListInput!) {
  updateContactList(input: $input) {
    contactList {
      id
      name
    }
    errors {
      path
      message
    }
  }
}
    `;
export type UpdateContactListMutationFn = Apollo.MutationFunction<UpdateContactListMutation, UpdateContactListMutationVariables>;

/**
 * __useUpdateContactListMutation__
 *
 * To run a mutation, you first call `useUpdateContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactListMutation, { data, loading, error }] = useUpdateContactListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactListMutation, UpdateContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactListMutation, UpdateContactListMutationVariables>(UpdateContactListDocument, options);
      }
export type UpdateContactListMutationHookResult = ReturnType<typeof useUpdateContactListMutation>;
export type UpdateContactListMutationResult = Apollo.MutationResult<UpdateContactListMutation>;
export type UpdateContactListMutationOptions = Apollo.BaseMutationOptions<UpdateContactListMutation, UpdateContactListMutationVariables>;
export const UpdatePhotoFilterDocument = gql`
    mutation updatePhotoFilter($input: UpdatePhotoFilterInput!) {
  updatePhotoFilter(input: $input) {
    errors {
      path
      message
    }
    photoFilter {
      id
      name
      cloudinaryArtisticFilter
      cloudinaryTransform
    }
  }
}
    `;
export type UpdatePhotoFilterMutationFn = Apollo.MutationFunction<UpdatePhotoFilterMutation, UpdatePhotoFilterMutationVariables>;

/**
 * __useUpdatePhotoFilterMutation__
 *
 * To run a mutation, you first call `useUpdatePhotoFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhotoFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhotoFilterMutation, { data, loading, error }] = useUpdatePhotoFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePhotoFilterMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhotoFilterMutation, UpdatePhotoFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhotoFilterMutation, UpdatePhotoFilterMutationVariables>(UpdatePhotoFilterDocument, options);
      }
export type UpdatePhotoFilterMutationHookResult = ReturnType<typeof useUpdatePhotoFilterMutation>;
export type UpdatePhotoFilterMutationResult = Apollo.MutationResult<UpdatePhotoFilterMutation>;
export type UpdatePhotoFilterMutationOptions = Apollo.BaseMutationOptions<UpdatePhotoFilterMutation, UpdatePhotoFilterMutationVariables>;
export const UpdatePoseDocument = gql`
    mutation updatePose($input: UpdatePoseInput!) {
  updatePose(input: $input) {
    pose {
      ...PoseDetails
    }
    errors {
      path
      message
    }
  }
}
    ${PoseDetailsFragmentDoc}`;
export type UpdatePoseMutationFn = Apollo.MutationFunction<UpdatePoseMutation, UpdatePoseMutationVariables>;

/**
 * __useUpdatePoseMutation__
 *
 * To run a mutation, you first call `useUpdatePoseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePoseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePoseMutation, { data, loading, error }] = useUpdatePoseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePoseMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePoseMutation, UpdatePoseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePoseMutation, UpdatePoseMutationVariables>(UpdatePoseDocument, options);
      }
export type UpdatePoseMutationHookResult = ReturnType<typeof useUpdatePoseMutation>;
export type UpdatePoseMutationResult = Apollo.MutationResult<UpdatePoseMutation>;
export type UpdatePoseMutationOptions = Apollo.BaseMutationOptions<UpdatePoseMutation, UpdatePoseMutationVariables>;
export const UpdatePoseProfileDocument = gql`
    mutation updatePoseProfile($input: UpdatePoseProfileInput!) {
  updatePoseProfile(input: $input) {
    poseProfile {
      ...PoseProfileDetails
    }
    errors {
      path
      message
    }
  }
}
    ${PoseProfileDetailsFragmentDoc}`;
export type UpdatePoseProfileMutationFn = Apollo.MutationFunction<UpdatePoseProfileMutation, UpdatePoseProfileMutationVariables>;

/**
 * __useUpdatePoseProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePoseProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePoseProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePoseProfileMutation, { data, loading, error }] = useUpdatePoseProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePoseProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePoseProfileMutation, UpdatePoseProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePoseProfileMutation, UpdatePoseProfileMutationVariables>(UpdatePoseProfileDocument, options);
      }
export type UpdatePoseProfileMutationHookResult = ReturnType<typeof useUpdatePoseProfileMutation>;
export type UpdatePoseProfileMutationResult = Apollo.MutationResult<UpdatePoseProfileMutation>;
export type UpdatePoseProfileMutationOptions = Apollo.BaseMutationOptions<UpdatePoseProfileMutation, UpdatePoseProfileMutationVariables>;
export const UpdatePresetDocument = gql`
    mutation updatePreset($input: UpdatePresetInput!) {
  updatePreset(input: $input) {
    preset {
      ...PresetDetails
    }
    errors {
      path
      message
    }
  }
}
    ${PresetDetailsFragmentDoc}`;
export type UpdatePresetMutationFn = Apollo.MutationFunction<UpdatePresetMutation, UpdatePresetMutationVariables>;

/**
 * __useUpdatePresetMutation__
 *
 * To run a mutation, you first call `useUpdatePresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePresetMutation, { data, loading, error }] = useUpdatePresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePresetMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePresetMutation, UpdatePresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePresetMutation, UpdatePresetMutationVariables>(UpdatePresetDocument, options);
      }
export type UpdatePresetMutationHookResult = ReturnType<typeof useUpdatePresetMutation>;
export type UpdatePresetMutationResult = Apollo.MutationResult<UpdatePresetMutation>;
export type UpdatePresetMutationOptions = Apollo.BaseMutationOptions<UpdatePresetMutation, UpdatePresetMutationVariables>;
export const UpdateReviewSettingDocument = gql`
    mutation updateReviewSetting($id: GID!, $requireSubmissionReview: Boolean!) {
  updateStudio(
    input: {id: $id, requireSubmissionReview: $requireSubmissionReview}
  ) {
    studio {
      id
      requireSubmissionReview
    }
  }
}
    `;
export type UpdateReviewSettingMutationFn = Apollo.MutationFunction<UpdateReviewSettingMutation, UpdateReviewSettingMutationVariables>;

/**
 * __useUpdateReviewSettingMutation__
 *
 * To run a mutation, you first call `useUpdateReviewSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewSettingMutation, { data, loading, error }] = useUpdateReviewSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      requireSubmissionReview: // value for 'requireSubmissionReview'
 *   },
 * });
 */
export function useUpdateReviewSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReviewSettingMutation, UpdateReviewSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReviewSettingMutation, UpdateReviewSettingMutationVariables>(UpdateReviewSettingDocument, options);
      }
export type UpdateReviewSettingMutationHookResult = ReturnType<typeof useUpdateReviewSettingMutation>;
export type UpdateReviewSettingMutationResult = Apollo.MutationResult<UpdateReviewSettingMutation>;
export type UpdateReviewSettingMutationOptions = Apollo.BaseMutationOptions<UpdateReviewSettingMutation, UpdateReviewSettingMutationVariables>;
export const UpdateStudioDocument = gql`
    mutation updateStudio($input: UpdateStudioInput!) {
  updateStudio(input: $input) {
    studio {
      ...StudioDetails
    }
    errors {
      path
      message
    }
  }
}
    ${StudioDetailsFragmentDoc}`;
export type UpdateStudioMutationFn = Apollo.MutationFunction<UpdateStudioMutation, UpdateStudioMutationVariables>;

/**
 * __useUpdateStudioMutation__
 *
 * To run a mutation, you first call `useUpdateStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudioMutation, { data, loading, error }] = useUpdateStudioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStudioMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStudioMutation, UpdateStudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStudioMutation, UpdateStudioMutationVariables>(UpdateStudioDocument, options);
      }
export type UpdateStudioMutationHookResult = ReturnType<typeof useUpdateStudioMutation>;
export type UpdateStudioMutationResult = Apollo.MutationResult<UpdateStudioMutation>;
export type UpdateStudioMutationOptions = Apollo.BaseMutationOptions<UpdateStudioMutation, UpdateStudioMutationVariables>;
export const ContactPageDocument = gql`
    query ContactPage($id: GID!) {
  contact(id: $id) {
    id
    firstName
    lastName
    email
    phone
    createdAt
    customAttributes
    submissions {
      ...ContactSubmission
    }
  }
}
    ${ContactSubmissionFragmentDoc}`;

/**
 * __useContactPageQuery__
 *
 * To run a query within a React component, call `useContactPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactPageQuery(baseOptions: Apollo.QueryHookOptions<ContactPageQuery, ContactPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactPageQuery, ContactPageQueryVariables>(ContactPageDocument, options);
      }
export function useContactPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactPageQuery, ContactPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactPageQuery, ContactPageQueryVariables>(ContactPageDocument, options);
        }
export type ContactPageQueryHookResult = ReturnType<typeof useContactPageQuery>;
export type ContactPageLazyQueryHookResult = ReturnType<typeof useContactPageLazyQuery>;
export type ContactPageQueryResult = Apollo.QueryResult<ContactPageQuery, ContactPageQueryVariables>;
export const ContactFormPageDocument = gql`
    query ContactFormPage {
  contactLists {
    id
    name
  }
}
    `;

/**
 * __useContactFormPageQuery__
 *
 * To run a query within a React component, call `useContactFormPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactFormPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactFormPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactFormPageQuery(baseOptions?: Apollo.QueryHookOptions<ContactFormPageQuery, ContactFormPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactFormPageQuery, ContactFormPageQueryVariables>(ContactFormPageDocument, options);
      }
export function useContactFormPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactFormPageQuery, ContactFormPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactFormPageQuery, ContactFormPageQueryVariables>(ContactFormPageDocument, options);
        }
export type ContactFormPageQueryHookResult = ReturnType<typeof useContactFormPageQuery>;
export type ContactFormPageLazyQueryHookResult = ReturnType<typeof useContactFormPageLazyQuery>;
export type ContactFormPageQueryResult = Apollo.QueryResult<ContactFormPageQuery, ContactFormPageQueryVariables>;
export const ContactsPageDocument = gql`
    query ContactsPage {
  contactLists {
    id
    name
  }
}
    `;

/**
 * __useContactsPageQuery__
 *
 * To run a query within a React component, call `useContactsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactsPageQuery(baseOptions?: Apollo.QueryHookOptions<ContactsPageQuery, ContactsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsPageQuery, ContactsPageQueryVariables>(ContactsPageDocument, options);
      }
export function useContactsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsPageQuery, ContactsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsPageQuery, ContactsPageQueryVariables>(ContactsPageDocument, options);
        }
export type ContactsPageQueryHookResult = ReturnType<typeof useContactsPageQuery>;
export type ContactsPageLazyQueryHookResult = ReturnType<typeof useContactsPageLazyQuery>;
export type ContactsPageQueryResult = Apollo.QueryResult<ContactsPageQuery, ContactsPageQueryVariables>;
export const DashboardPageDocument = gql`
    query DashboardPage {
  currentUser {
    id
    firstName
    lastName
  }
  currentAccount {
    id
  }
}
    `;

/**
 * __useDashboardPageQuery__
 *
 * To run a query within a React component, call `useDashboardPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardPageQuery(baseOptions?: Apollo.QueryHookOptions<DashboardPageQuery, DashboardPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardPageQuery, DashboardPageQueryVariables>(DashboardPageDocument, options);
      }
export function useDashboardPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardPageQuery, DashboardPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardPageQuery, DashboardPageQueryVariables>(DashboardPageDocument, options);
        }
export type DashboardPageQueryHookResult = ReturnType<typeof useDashboardPageQuery>;
export type DashboardPageLazyQueryHookResult = ReturnType<typeof useDashboardPageLazyQuery>;
export type DashboardPageQueryResult = Apollo.QueryResult<DashboardPageQuery, DashboardPageQueryVariables>;
export const PosesPageDocument = gql`
    query PosesPage($studioId: GID!) {
  studio(id: $studioId) {
    id
    name
    poses {
      ...PoseDetails
    }
  }
  presets {
    ...PresetOverview
  }
  poseProfiles {
    ...PoseProfileDetails
  }
}
    ${PoseDetailsFragmentDoc}
${PresetOverviewFragmentDoc}
${PoseProfileDetailsFragmentDoc}`;

/**
 * __usePosesPageQuery__
 *
 * To run a query within a React component, call `usePosesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePosesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePosesPageQuery({
 *   variables: {
 *      studioId: // value for 'studioId'
 *   },
 * });
 */
export function usePosesPageQuery(baseOptions: Apollo.QueryHookOptions<PosesPageQuery, PosesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PosesPageQuery, PosesPageQueryVariables>(PosesPageDocument, options);
      }
export function usePosesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PosesPageQuery, PosesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PosesPageQuery, PosesPageQueryVariables>(PosesPageDocument, options);
        }
export type PosesPageQueryHookResult = ReturnType<typeof usePosesPageQuery>;
export type PosesPageLazyQueryHookResult = ReturnType<typeof usePosesPageLazyQuery>;
export type PosesPageQueryResult = Apollo.QueryResult<PosesPageQuery, PosesPageQueryVariables>;
export const PresetFormPageDocument = gql`
    query PresetFormPage {
  photoFilters {
    id
    name
  }
}
    `;

/**
 * __usePresetFormPageQuery__
 *
 * To run a query within a React component, call `usePresetFormPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresetFormPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresetFormPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePresetFormPageQuery(baseOptions?: Apollo.QueryHookOptions<PresetFormPageQuery, PresetFormPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PresetFormPageQuery, PresetFormPageQueryVariables>(PresetFormPageDocument, options);
      }
export function usePresetFormPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PresetFormPageQuery, PresetFormPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PresetFormPageQuery, PresetFormPageQueryVariables>(PresetFormPageDocument, options);
        }
export type PresetFormPageQueryHookResult = ReturnType<typeof usePresetFormPageQuery>;
export type PresetFormPageLazyQueryHookResult = ReturnType<typeof usePresetFormPageLazyQuery>;
export type PresetFormPageQueryResult = Apollo.QueryResult<PresetFormPageQuery, PresetFormPageQueryVariables>;
export const ProfileFormPageDocument = gql`
    query ProfileFormPage($id: GID!) {
  poseProfile(id: $id) {
    ...PoseProfileDetails
  }
}
    ${PoseProfileDetailsFragmentDoc}`;

/**
 * __useProfileFormPageQuery__
 *
 * To run a query within a React component, call `useProfileFormPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileFormPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileFormPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileFormPageQuery(baseOptions: Apollo.QueryHookOptions<ProfileFormPageQuery, ProfileFormPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileFormPageQuery, ProfileFormPageQueryVariables>(ProfileFormPageDocument, options);
      }
export function useProfileFormPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileFormPageQuery, ProfileFormPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileFormPageQuery, ProfileFormPageQueryVariables>(ProfileFormPageDocument, options);
        }
export type ProfileFormPageQueryHookResult = ReturnType<typeof useProfileFormPageQuery>;
export type ProfileFormPageLazyQueryHookResult = ReturnType<typeof useProfileFormPageLazyQuery>;
export type ProfileFormPageQueryResult = Apollo.QueryResult<ProfileFormPageQuery, ProfileFormPageQueryVariables>;
export const GetAccessTokenScopesDocument = gql`
    query getAccessTokenScopes($applicationName: String!) {
  accessTokenScopes(applicationName: $applicationName)
}
    `;

/**
 * __useGetAccessTokenScopesQuery__
 *
 * To run a query within a React component, call `useGetAccessTokenScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessTokenScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessTokenScopesQuery({
 *   variables: {
 *      applicationName: // value for 'applicationName'
 *   },
 * });
 */
export function useGetAccessTokenScopesQuery(baseOptions: Apollo.QueryHookOptions<GetAccessTokenScopesQuery, GetAccessTokenScopesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessTokenScopesQuery, GetAccessTokenScopesQueryVariables>(GetAccessTokenScopesDocument, options);
      }
export function useGetAccessTokenScopesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessTokenScopesQuery, GetAccessTokenScopesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessTokenScopesQuery, GetAccessTokenScopesQueryVariables>(GetAccessTokenScopesDocument, options);
        }
export type GetAccessTokenScopesQueryHookResult = ReturnType<typeof useGetAccessTokenScopesQuery>;
export type GetAccessTokenScopesLazyQueryHookResult = ReturnType<typeof useGetAccessTokenScopesLazyQuery>;
export type GetAccessTokenScopesQueryResult = Apollo.QueryResult<GetAccessTokenScopesQuery, GetAccessTokenScopesQueryVariables>;
export const GetAccessTokensDocument = gql`
    query getAccessTokens($onlyApiTokens: Boolean!) {
  accessTokens(onlyApiTokens: $onlyApiTokens) {
    id
    name
    account {
      name
    }
    scopes
    createdAt
    token
  }
}
    `;

/**
 * __useGetAccessTokensQuery__
 *
 * To run a query within a React component, call `useGetAccessTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessTokensQuery({
 *   variables: {
 *      onlyApiTokens: // value for 'onlyApiTokens'
 *   },
 * });
 */
export function useGetAccessTokensQuery(baseOptions: Apollo.QueryHookOptions<GetAccessTokensQuery, GetAccessTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessTokensQuery, GetAccessTokensQueryVariables>(GetAccessTokensDocument, options);
      }
export function useGetAccessTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessTokensQuery, GetAccessTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessTokensQuery, GetAccessTokensQueryVariables>(GetAccessTokensDocument, options);
        }
export type GetAccessTokensQueryHookResult = ReturnType<typeof useGetAccessTokensQuery>;
export type GetAccessTokensLazyQueryHookResult = ReturnType<typeof useGetAccessTokensLazyQuery>;
export type GetAccessTokensQueryResult = Apollo.QueryResult<GetAccessTokensQuery, GetAccessTokensQueryVariables>;
export const GetContactDocument = gql`
    query getContact($id: GID!) {
  contact(id: $id) {
    id
    firstName
    lastName
    email
    phone
    customAttributes
    contactLists {
      id
      name
    }
  }
}
    `;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions: Apollo.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
      }
export function useGetContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const GetContactAttributesDocument = gql`
    query getContactAttributes {
  contactAttributes {
    key
    type
    custom
    validation {
      regex
      errorMessage
    }
  }
}
    `;

/**
 * __useGetContactAttributesQuery__
 *
 * To run a query within a React component, call `useGetContactAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactAttributesQuery(baseOptions?: Apollo.QueryHookOptions<GetContactAttributesQuery, GetContactAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactAttributesQuery, GetContactAttributesQueryVariables>(GetContactAttributesDocument, options);
      }
export function useGetContactAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactAttributesQuery, GetContactAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactAttributesQuery, GetContactAttributesQueryVariables>(GetContactAttributesDocument, options);
        }
export type GetContactAttributesQueryHookResult = ReturnType<typeof useGetContactAttributesQuery>;
export type GetContactAttributesLazyQueryHookResult = ReturnType<typeof useGetContactAttributesLazyQuery>;
export type GetContactAttributesQueryResult = Apollo.QueryResult<GetContactAttributesQuery, GetContactAttributesQueryVariables>;
export const GetContactListDocument = gql`
    query getContactList($id: GID!) {
  contactList(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetContactListQuery__
 *
 * To run a query within a React component, call `useGetContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactListQuery(baseOptions: Apollo.QueryHookOptions<GetContactListQuery, GetContactListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactListQuery, GetContactListQueryVariables>(GetContactListDocument, options);
      }
export function useGetContactListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactListQuery, GetContactListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactListQuery, GetContactListQueryVariables>(GetContactListDocument, options);
        }
export type GetContactListQueryHookResult = ReturnType<typeof useGetContactListQuery>;
export type GetContactListLazyQueryHookResult = ReturnType<typeof useGetContactListLazyQuery>;
export type GetContactListQueryResult = Apollo.QueryResult<GetContactListQuery, GetContactListQueryVariables>;
export const GetCurrentAccountDocument = gql`
    query getCurrentAccount {
  currentAccount {
    id
    name
    organization
  }
}
    `;

/**
 * __useGetCurrentAccountQuery__
 *
 * To run a query within a React component, call `useGetCurrentAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentAccountQuery, GetCurrentAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentAccountQuery, GetCurrentAccountQueryVariables>(GetCurrentAccountDocument, options);
      }
export function useGetCurrentAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentAccountQuery, GetCurrentAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentAccountQuery, GetCurrentAccountQueryVariables>(GetCurrentAccountDocument, options);
        }
export type GetCurrentAccountQueryHookResult = ReturnType<typeof useGetCurrentAccountQuery>;
export type GetCurrentAccountLazyQueryHookResult = ReturnType<typeof useGetCurrentAccountLazyQuery>;
export type GetCurrentAccountQueryResult = Apollo.QueryResult<GetCurrentAccountQuery, GetCurrentAccountQueryVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  currentUser {
    id
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetExportDocument = gql`
    query getExport($id: GID!) {
  export(id: $id) {
    id
    status
    filesCount
    processedCount
    failedCount
    file {
      providerId
      bytes
      type
    }
  }
}
    `;

/**
 * __useGetExportQuery__
 *
 * To run a query within a React component, call `useGetExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExportQuery(baseOptions: Apollo.QueryHookOptions<GetExportQuery, GetExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExportQuery, GetExportQueryVariables>(GetExportDocument, options);
      }
export function useGetExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExportQuery, GetExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExportQuery, GetExportQueryVariables>(GetExportDocument, options);
        }
export type GetExportQueryHookResult = ReturnType<typeof useGetExportQuery>;
export type GetExportLazyQueryHookResult = ReturnType<typeof useGetExportLazyQuery>;
export type GetExportQueryResult = Apollo.QueryResult<GetExportQuery, GetExportQueryVariables>;
export const GetExportFilenamePatternsDocument = gql`
    query getExportFilenamePatterns {
  exportFilenamePatterns
}
    `;

/**
 * __useGetExportFilenamePatternsQuery__
 *
 * To run a query within a React component, call `useGetExportFilenamePatternsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportFilenamePatternsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportFilenamePatternsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExportFilenamePatternsQuery(baseOptions?: Apollo.QueryHookOptions<GetExportFilenamePatternsQuery, GetExportFilenamePatternsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExportFilenamePatternsQuery, GetExportFilenamePatternsQueryVariables>(GetExportFilenamePatternsDocument, options);
      }
export function useGetExportFilenamePatternsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExportFilenamePatternsQuery, GetExportFilenamePatternsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExportFilenamePatternsQuery, GetExportFilenamePatternsQueryVariables>(GetExportFilenamePatternsDocument, options);
        }
export type GetExportFilenamePatternsQueryHookResult = ReturnType<typeof useGetExportFilenamePatternsQuery>;
export type GetExportFilenamePatternsLazyQueryHookResult = ReturnType<typeof useGetExportFilenamePatternsLazyQuery>;
export type GetExportFilenamePatternsQueryResult = Apollo.QueryResult<GetExportFilenamePatternsQuery, GetExportFilenamePatternsQueryVariables>;
export const GetPermissionsDocument = gql`
    query getPermissions {
  currentUser {
    id
    staff
  }
  currentMembership {
    permissions
  }
}
    `;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
      }
export function useGetPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<GetPermissionsQuery, GetPermissionsQueryVariables>;
export const GetPhotoFilterDocument = gql`
    query getPhotoFilter($id: GID!) {
  photoFilter(id: $id) {
    name
    cloudinaryTransform
    cloudinaryArtisticFilter
  }
}
    `;

/**
 * __useGetPhotoFilterQuery__
 *
 * To run a query within a React component, call `useGetPhotoFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhotoFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhotoFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPhotoFilterQuery(baseOptions: Apollo.QueryHookOptions<GetPhotoFilterQuery, GetPhotoFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhotoFilterQuery, GetPhotoFilterQueryVariables>(GetPhotoFilterDocument, options);
      }
export function useGetPhotoFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhotoFilterQuery, GetPhotoFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhotoFilterQuery, GetPhotoFilterQueryVariables>(GetPhotoFilterDocument, options);
        }
export type GetPhotoFilterQueryHookResult = ReturnType<typeof useGetPhotoFilterQuery>;
export type GetPhotoFilterLazyQueryHookResult = ReturnType<typeof useGetPhotoFilterLazyQuery>;
export type GetPhotoFilterQueryResult = Apollo.QueryResult<GetPhotoFilterQuery, GetPhotoFilterQueryVariables>;
export const GetPhotoFilterRowsDocument = gql`
    query getPhotoFilterRows {
  photoFilters {
    id
    name
    cloudinaryTransform
  }
}
    `;

/**
 * __useGetPhotoFilterRowsQuery__
 *
 * To run a query within a React component, call `useGetPhotoFilterRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhotoFilterRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhotoFilterRowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPhotoFilterRowsQuery(baseOptions?: Apollo.QueryHookOptions<GetPhotoFilterRowsQuery, GetPhotoFilterRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhotoFilterRowsQuery, GetPhotoFilterRowsQueryVariables>(GetPhotoFilterRowsDocument, options);
      }
export function useGetPhotoFilterRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhotoFilterRowsQuery, GetPhotoFilterRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhotoFilterRowsQuery, GetPhotoFilterRowsQueryVariables>(GetPhotoFilterRowsDocument, options);
        }
export type GetPhotoFilterRowsQueryHookResult = ReturnType<typeof useGetPhotoFilterRowsQuery>;
export type GetPhotoFilterRowsLazyQueryHookResult = ReturnType<typeof useGetPhotoFilterRowsLazyQuery>;
export type GetPhotoFilterRowsQueryResult = Apollo.QueryResult<GetPhotoFilterRowsQuery, GetPhotoFilterRowsQueryVariables>;
export const GetPresetDocument = gql`
    query getPreset($id: GID!) {
  preset(id: $id) {
    id
    name
    default
    crop {
      factor
      gravity
    }
    photoFilter {
      id
      name
    }
    resize
    cloudinaryTransform
    backdrop {
      ... on CloudFile {
        ...CloudFileDetails
      }
      ... on Color {
        hex
      }
    }
    overlayImage {
      provider
      providerId
      publicUrl
      type
      bytes
    }
    visibleToContact
    autoEnhance
    imageFormat
  }
}
    ${CloudFileDetailsFragmentDoc}`;

/**
 * __useGetPresetQuery__
 *
 * To run a query within a React component, call `useGetPresetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPresetQuery(baseOptions: Apollo.QueryHookOptions<GetPresetQuery, GetPresetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPresetQuery, GetPresetQueryVariables>(GetPresetDocument, options);
      }
export function useGetPresetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPresetQuery, GetPresetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPresetQuery, GetPresetQueryVariables>(GetPresetDocument, options);
        }
export type GetPresetQueryHookResult = ReturnType<typeof useGetPresetQuery>;
export type GetPresetLazyQueryHookResult = ReturnType<typeof useGetPresetLazyQuery>;
export type GetPresetQueryResult = Apollo.QueryResult<GetPresetQuery, GetPresetQueryVariables>;
export const GetPresetOverviewsDocument = gql`
    query getPresetOverviews {
  presets {
    ...PresetOverview
  }
}
    ${PresetOverviewFragmentDoc}`;

/**
 * __useGetPresetOverviewsQuery__
 *
 * To run a query within a React component, call `useGetPresetOverviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresetOverviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresetOverviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPresetOverviewsQuery(baseOptions?: Apollo.QueryHookOptions<GetPresetOverviewsQuery, GetPresetOverviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPresetOverviewsQuery, GetPresetOverviewsQueryVariables>(GetPresetOverviewsDocument, options);
      }
export function useGetPresetOverviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPresetOverviewsQuery, GetPresetOverviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPresetOverviewsQuery, GetPresetOverviewsQueryVariables>(GetPresetOverviewsDocument, options);
        }
export type GetPresetOverviewsQueryHookResult = ReturnType<typeof useGetPresetOverviewsQuery>;
export type GetPresetOverviewsLazyQueryHookResult = ReturnType<typeof useGetPresetOverviewsLazyQuery>;
export type GetPresetOverviewsQueryResult = Apollo.QueryResult<GetPresetOverviewsQuery, GetPresetOverviewsQueryVariables>;
export const GetStudioDetailsDocument = gql`
    query getStudioDetails($id: GID!) {
  studio(id: $id) {
    ...StudioDetails
  }
}
    ${StudioDetailsFragmentDoc}`;

/**
 * __useGetStudioDetailsQuery__
 *
 * To run a query within a React component, call `useGetStudioDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudioDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudioDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStudioDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetStudioDetailsQuery, GetStudioDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudioDetailsQuery, GetStudioDetailsQueryVariables>(GetStudioDetailsDocument, options);
      }
export function useGetStudioDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudioDetailsQuery, GetStudioDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudioDetailsQuery, GetStudioDetailsQueryVariables>(GetStudioDetailsDocument, options);
        }
export type GetStudioDetailsQueryHookResult = ReturnType<typeof useGetStudioDetailsQuery>;
export type GetStudioDetailsLazyQueryHookResult = ReturnType<typeof useGetStudioDetailsLazyQuery>;
export type GetStudioDetailsQueryResult = Apollo.QueryResult<GetStudioDetailsQuery, GetStudioDetailsQueryVariables>;
export const GetStudioOverviewsDocument = gql`
    query getStudioOverviews {
  studios(first: 250) {
    edges {
      cursor
      node {
        ...StudioOverview
      }
    }
  }
}
    ${StudioOverviewFragmentDoc}`;

/**
 * __useGetStudioOverviewsQuery__
 *
 * To run a query within a React component, call `useGetStudioOverviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudioOverviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudioOverviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudioOverviewsQuery(baseOptions?: Apollo.QueryHookOptions<GetStudioOverviewsQuery, GetStudioOverviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudioOverviewsQuery, GetStudioOverviewsQueryVariables>(GetStudioOverviewsDocument, options);
      }
export function useGetStudioOverviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudioOverviewsQuery, GetStudioOverviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudioOverviewsQuery, GetStudioOverviewsQueryVariables>(GetStudioOverviewsDocument, options);
        }
export type GetStudioOverviewsQueryHookResult = ReturnType<typeof useGetStudioOverviewsQuery>;
export type GetStudioOverviewsLazyQueryHookResult = ReturnType<typeof useGetStudioOverviewsLazyQuery>;
export type GetStudioOverviewsQueryResult = Apollo.QueryResult<GetStudioOverviewsQuery, GetStudioOverviewsQueryVariables>;
export const GetStudioSessionInvitationsDocument = gql`
    query getStudioSessionInvitations($after: String, $before: String, $first: Int, $last: Int, $studioId: GID, $sortBy: StudioSessionSortKey, $sortDirection: SortDirection) {
  studioSessionInvitations(
    after: $after
    before: $before
    first: $first
    last: $last
    studioId: $studioId
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    edges {
      cursor
      node {
        ...StudioSessionRow
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${StudioSessionRowFragmentDoc}`;

/**
 * __useGetStudioSessionInvitationsQuery__
 *
 * To run a query within a React component, call `useGetStudioSessionInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudioSessionInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudioSessionInvitationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      studioId: // value for 'studioId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetStudioSessionInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<GetStudioSessionInvitationsQuery, GetStudioSessionInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudioSessionInvitationsQuery, GetStudioSessionInvitationsQueryVariables>(GetStudioSessionInvitationsDocument, options);
      }
export function useGetStudioSessionInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudioSessionInvitationsQuery, GetStudioSessionInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudioSessionInvitationsQuery, GetStudioSessionInvitationsQueryVariables>(GetStudioSessionInvitationsDocument, options);
        }
export type GetStudioSessionInvitationsQueryHookResult = ReturnType<typeof useGetStudioSessionInvitationsQuery>;
export type GetStudioSessionInvitationsLazyQueryHookResult = ReturnType<typeof useGetStudioSessionInvitationsLazyQuery>;
export type GetStudioSessionInvitationsQueryResult = Apollo.QueryResult<GetStudioSessionInvitationsQuery, GetStudioSessionInvitationsQueryVariables>;
export const GetSubmissionNotificationsDocument = gql`
    query getSubmissionNotifications {
  userSubmissionNotifications {
    __typename
    id
    frequency
    studio {
      __typename
      id
      name
    }
  }
}
    `;

/**
 * __useGetSubmissionNotificationsQuery__
 *
 * To run a query within a React component, call `useGetSubmissionNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmissionNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmissionNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubmissionNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubmissionNotificationsQuery, GetSubmissionNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubmissionNotificationsQuery, GetSubmissionNotificationsQueryVariables>(GetSubmissionNotificationsDocument, options);
      }
export function useGetSubmissionNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubmissionNotificationsQuery, GetSubmissionNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubmissionNotificationsQuery, GetSubmissionNotificationsQueryVariables>(GetSubmissionNotificationsDocument, options);
        }
export type GetSubmissionNotificationsQueryHookResult = ReturnType<typeof useGetSubmissionNotificationsQuery>;
export type GetSubmissionNotificationsLazyQueryHookResult = ReturnType<typeof useGetSubmissionNotificationsLazyQuery>;
export type GetSubmissionNotificationsQueryResult = Apollo.QueryResult<GetSubmissionNotificationsQuery, GetSubmissionNotificationsQueryVariables>;
export const GetSubmissionRowsDocument = gql`
    query getSubmissionRows($after: String, $before: String, $first: Int, $last: Int, $state: SubmissionState, $sortBy: SubmissionSortKey, $sortDirection: SortDirection, $studioIds: [GID!], $submittedAfter: DateTime) {
  submissions(
    after: $after
    before: $before
    first: $first
    last: $last
    state: $state
    sortBy: $sortBy
    sortDirection: $sortDirection
    studioIds: $studioIds
    submittedAfter: $submittedAfter
  ) {
    edges {
      cursor
      node {
        ...SubmissionRow
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${SubmissionRowFragmentDoc}`;

/**
 * __useGetSubmissionRowsQuery__
 *
 * To run a query within a React component, call `useGetSubmissionRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmissionRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmissionRowsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      state: // value for 'state'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      studioIds: // value for 'studioIds'
 *      submittedAfter: // value for 'submittedAfter'
 *   },
 * });
 */
export function useGetSubmissionRowsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubmissionRowsQuery, GetSubmissionRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubmissionRowsQuery, GetSubmissionRowsQueryVariables>(GetSubmissionRowsDocument, options);
      }
export function useGetSubmissionRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubmissionRowsQuery, GetSubmissionRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubmissionRowsQuery, GetSubmissionRowsQueryVariables>(GetSubmissionRowsDocument, options);
        }
export type GetSubmissionRowsQueryHookResult = ReturnType<typeof useGetSubmissionRowsQuery>;
export type GetSubmissionRowsLazyQueryHookResult = ReturnType<typeof useGetSubmissionRowsLazyQuery>;
export type GetSubmissionRowsQueryResult = Apollo.QueryResult<GetSubmissionRowsQuery, GetSubmissionRowsQueryVariables>;
export const StudioExistsDocument = gql`
    query studioExists($handle: String!) {
  studioExists(handle: $handle)
}
    `;

/**
 * __useStudioExistsQuery__
 *
 * To run a query within a React component, call `useStudioExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudioExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudioExistsQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useStudioExistsQuery(baseOptions: Apollo.QueryHookOptions<StudioExistsQuery, StudioExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudioExistsQuery, StudioExistsQueryVariables>(StudioExistsDocument, options);
      }
export function useStudioExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudioExistsQuery, StudioExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudioExistsQuery, StudioExistsQueryVariables>(StudioExistsDocument, options);
        }
export type StudioExistsQueryHookResult = ReturnType<typeof useStudioExistsQuery>;
export type StudioExistsLazyQueryHookResult = ReturnType<typeof useStudioExistsLazyQuery>;
export type StudioExistsQueryResult = Apollo.QueryResult<StudioExistsQuery, StudioExistsQueryVariables>;
export type AcceptSubmissionsPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'submissions' | AcceptSubmissionsPayloadKeySpecifier)[];
export type AcceptSubmissionsPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	submissions?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AccessTokenKeySpecifier = ('account' | 'createdAt' | 'expiresIn' | 'id' | 'name' | 'scopes' | 'token' | 'updatedAt' | AccessTokenKeySpecifier)[];
export type AccessTokenFieldPolicy = {
	account?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	expiresIn?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	scopes?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AccountKeySpecifier = ('createdAt' | 'id' | 'name' | 'organization' | 'snapbarId' | 'updatedAt' | AccountKeySpecifier)[];
export type AccountFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	organization?: FieldPolicy<any> | FieldReadFunction<any>,
	snapbarId?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AddPosePayloadKeySpecifier = ('clientMutationId' | 'errors' | 'pose' | AddPosePayloadKeySpecifier)[];
export type AddPosePayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	pose?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AppliedPresetKeySpecifier = ('downloadUrl' | 'preset' | 'publicUrl' | AppliedPresetKeySpecifier)[];
export type AppliedPresetFieldPolicy = {
	downloadUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	preset?: FieldPolicy<any> | FieldReadFunction<any>,
	publicUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BrandingKeySpecifier = ('background' | 'logo' | 'primaryColor' | 'secondaryColor' | 'tertiaryColor' | BrandingKeySpecifier)[];
export type BrandingFieldPolicy = {
	background?: FieldPolicy<any> | FieldReadFunction<any>,
	logo?: FieldPolicy<any> | FieldReadFunction<any>,
	primaryColor?: FieldPolicy<any> | FieldReadFunction<any>,
	secondaryColor?: FieldPolicy<any> | FieldReadFunction<any>,
	tertiaryColor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ClearExportFilenameHistoryPayloadKeySpecifier = ('message' | ClearExportFilenameHistoryPayloadKeySpecifier)[];
export type ClearExportFilenameHistoryPayloadFieldPolicy = {
	message?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CloudFileKeySpecifier = ('bytes' | 'provider' | 'providerAccessType' | 'providerId' | 'publicUrl' | 'type' | CloudFileKeySpecifier)[];
export type CloudFileFieldPolicy = {
	bytes?: FieldPolicy<any> | FieldReadFunction<any>,
	provider?: FieldPolicy<any> | FieldReadFunction<any>,
	providerAccessType?: FieldPolicy<any> | FieldReadFunction<any>,
	providerId?: FieldPolicy<any> | FieldReadFunction<any>,
	publicUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ColorKeySpecifier = ('hex' | ColorKeySpecifier)[];
export type ColorFieldPolicy = {
	hex?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactKeySpecifier = ('contactLists' | 'createdAt' | 'customAttributes' | 'email' | 'firstName' | 'id' | 'lastName' | 'phone' | 'submissions' | 'submissionsCount' | 'updatedAt' | ContactKeySpecifier)[];
export type ContactFieldPolicy = {
	contactLists?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	customAttributes?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	phone?: FieldPolicy<any> | FieldReadFunction<any>,
	submissions?: FieldPolicy<any> | FieldReadFunction<any>,
	submissionsCount?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactAttributeKeySpecifier = ('createdAt' | 'custom' | 'key' | 'type' | 'updatedAt' | 'validation' | ContactAttributeKeySpecifier)[];
export type ContactAttributeFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	custom?: FieldPolicy<any> | FieldReadFunction<any>,
	key?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	validation?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactAttributeValidationKeySpecifier = ('createdAt' | 'errorMessage' | 'id' | 'regex' | 'updatedAt' | ContactAttributeValidationKeySpecifier)[];
export type ContactAttributeValidationFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	errorMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	regex?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactConnectionKeySpecifier = ('edges' | 'nodes' | 'pageInfo' | 'totalCount' | ContactConnectionKeySpecifier)[];
export type ContactConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactEdgeKeySpecifier = ('cursor' | 'node' | ContactEdgeKeySpecifier)[];
export type ContactEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactListKeySpecifier = ('contactsCount' | 'createdAt' | 'id' | 'name' | 'updatedAt' | ContactListKeySpecifier)[];
export type ContactListFieldPolicy = {
	contactsCount?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateAccessTokenPayloadKeySpecifier = ('accessToken' | 'clientMutationId' | 'errors' | CreateAccessTokenPayloadKeySpecifier)[];
export type CreateAccessTokenPayloadFieldPolicy = {
	accessToken?: FieldPolicy<any> | FieldReadFunction<any>,
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateContactAccessURLPayloadKeySpecifier = ('accessUrl' | 'clientMutationId' | 'errors' | CreateContactAccessURLPayloadKeySpecifier)[];
export type CreateContactAccessURLPayloadFieldPolicy = {
	accessUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateContactAttributePayloadKeySpecifier = ('clientMutationId' | 'contactAttribute' | 'errors' | CreateContactAttributePayloadKeySpecifier)[];
export type CreateContactAttributePayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	contactAttribute?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateContactListPayloadKeySpecifier = ('clientMutationId' | 'contactList' | 'errors' | CreateContactListPayloadKeySpecifier)[];
export type CreateContactListPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	contactList?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateContactPayloadKeySpecifier = ('clientMutationId' | 'contact' | 'errors' | CreateContactPayloadKeySpecifier)[];
export type CreateContactPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	contact?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreatePhotoFilterPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'photoFilter' | CreatePhotoFilterPayloadKeySpecifier)[];
export type CreatePhotoFilterPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	photoFilter?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreatePoseProfilePayloadKeySpecifier = ('clientMutationId' | 'errors' | 'poseProfile' | CreatePoseProfilePayloadKeySpecifier)[];
export type CreatePoseProfilePayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	poseProfile?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreatePresetPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'preset' | CreatePresetPayloadKeySpecifier)[];
export type CreatePresetPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	preset?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateStudioPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'studio' | CreateStudioPayloadKeySpecifier)[];
export type CreateStudioPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	studio?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateStudioSessionPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'studioSession' | CreateStudioSessionPayloadKeySpecifier)[];
export type CreateStudioSessionPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	studioSession?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateSubmissionNotificationPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'submissionNotification' | CreateSubmissionNotificationPayloadKeySpecifier)[];
export type CreateSubmissionNotificationPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	submissionNotification?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CropKeySpecifier = ('factor' | 'gravity' | CropKeySpecifier)[];
export type CropFieldPolicy = {
	factor?: FieldPolicy<any> | FieldReadFunction<any>,
	gravity?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteAccessTokenPayloadKeySpecifier = ('clientMutationId' | 'deletedTokenId' | 'errors' | DeleteAccessTokenPayloadKeySpecifier)[];
export type DeleteAccessTokenPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedTokenId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteContactAttributePayloadKeySpecifier = ('clientMutationId' | 'deletedKey' | 'errors' | DeleteContactAttributePayloadKeySpecifier)[];
export type DeleteContactAttributePayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedKey?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteContactListPayloadKeySpecifier = ('clientMutationId' | 'deletedId' | 'errors' | DeleteContactListPayloadKeySpecifier)[];
export type DeleteContactListPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteContactsPayloadKeySpecifier = ('clientMutationId' | 'deletedIds' | 'errors' | DeleteContactsPayloadKeySpecifier)[];
export type DeleteContactsPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedIds?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePhotoFilterPayloadKeySpecifier = ('clientMutationId' | 'deletedId' | 'errors' | DeletePhotoFilterPayloadKeySpecifier)[];
export type DeletePhotoFilterPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePosePayloadKeySpecifier = ('clientMutationId' | 'deletedId' | 'errors' | DeletePosePayloadKeySpecifier)[];
export type DeletePosePayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePoseProfilePayloadKeySpecifier = ('clientMutationId' | 'deletedId' | 'errors' | DeletePoseProfilePayloadKeySpecifier)[];
export type DeletePoseProfilePayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePresetPayloadKeySpecifier = ('clientMutationId' | 'deletedId' | 'errors' | DeletePresetPayloadKeySpecifier)[];
export type DeletePresetPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteStudioPayloadKeySpecifier = ('clientMutationId' | 'deletedId' | 'errors' | DeleteStudioPayloadKeySpecifier)[];
export type DeleteStudioPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteStudioSessionsPayloadKeySpecifier = ('clientMutationId' | 'deletedIds' | 'errors' | DeleteStudioSessionsPayloadKeySpecifier)[];
export type DeleteStudioSessionsPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedIds?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteSubmissionNotificationsPayloadKeySpecifier = ('clientMutationId' | 'deletedIds' | 'errors' | DeleteSubmissionNotificationsPayloadKeySpecifier)[];
export type DeleteSubmissionNotificationsPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedIds?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteSubmissionsPayloadKeySpecifier = ('clientMutationId' | 'deletedIds' | 'errors' | DeleteSubmissionsPayloadKeySpecifier)[];
export type DeleteSubmissionsPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedIds?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeselectAccountPayloadKeySpecifier = ('account' | 'errors' | DeselectAccountPayloadKeySpecifier)[];
export type DeselectAccountPayloadFieldPolicy = {
	account?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DisclaimerKeySpecifier = ('content' | 'enabled' | DisclaimerKeySpecifier)[];
export type DisclaimerFieldPolicy = {
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	enabled?: FieldPolicy<any> | FieldReadFunction<any>
};
export type EnhanceSubmissionsPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'submissions' | EnhanceSubmissionsPayloadKeySpecifier)[];
export type EnhanceSubmissionsPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	submissions?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExportKeySpecifier = ('failedCount' | 'file' | 'filesCount' | 'id' | 'processedCount' | 'status' | 'totalCount' | ExportKeySpecifier)[];
export type ExportFieldPolicy = {
	failedCount?: FieldPolicy<any> | FieldReadFunction<any>,
	file?: FieldPolicy<any> | FieldReadFunction<any>,
	filesCount?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	processedCount?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExportContactsPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'exportId' | ExportContactsPayloadKeySpecifier)[];
export type ExportContactsPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	exportId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExportSubmissionsPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'exportId' | ExportSubmissionsPayloadKeySpecifier)[];
export type ExportSubmissionsPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	exportId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FormFieldKeySpecifier = ('choices' | 'contactAttribute' | 'createdAt' | 'id' | 'kind' | 'label' | 'required' | 'updatedAt' | FormFieldKeySpecifier)[];
export type FormFieldFieldPolicy = {
	choices?: FieldPolicy<any> | FieldReadFunction<any>,
	contactAttribute?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	kind?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	required?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GenerateMetricsTokenPayloadKeySpecifier = ('errors' | 'token' | GenerateMetricsTokenPayloadKeySpecifier)[];
export type GenerateMetricsTokenPayloadFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InviteContactListPayloadKeySpecifier = ('clientMutationId' | 'contactList' | 'errors' | InviteContactListPayloadKeySpecifier)[];
export type InviteContactListPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	contactList?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InviteContactsPayloadKeySpecifier = ('clientMutationId' | 'contacts' | 'errors' | InviteContactsPayloadKeySpecifier)[];
export type InviteContactsPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	contacts?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MembershipKeySpecifier = ('id' | 'permissions' | 'snapbarId' | MembershipKeySpecifier)[];
export type MembershipFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	permissions?: FieldPolicy<any> | FieldReadFunction<any>,
	snapbarId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('acceptSubmissions' | 'addPose' | 'clearExportFilenameHistory' | 'createAccessToken' | 'createContact' | 'createContactAccessUrl' | 'createContactAttribute' | 'createContactList' | 'createPhotoFilter' | 'createPoseProfile' | 'createPreset' | 'createStudio' | 'createStudioSession' | 'createSubmissionNotification' | 'deleteAccessToken' | 'deleteContactAttribute' | 'deleteContactList' | 'deleteContacts' | 'deletePhotoFilter' | 'deletePose' | 'deletePoseProfile' | 'deletePreset' | 'deleteStudio' | 'deleteStudioSessions' | 'deleteSubmissionNotifications' | 'deleteSubmissions' | 'deselectAccount' | 'enhanceSubmissions' | 'exportContacts' | 'exportSubmissions' | 'generateMetricsToken' | 'inviteContactList' | 'inviteContacts' | 'presignCloudinaryFile' | 'previewPreset' | 'rejectSubmissions' | 'resetStudioPhotosCount' | 'selectAccount' | 'sendReminders' | 'setDefaultPreset' | 'signExport' | 'submitPhotos' | 'updateContact' | 'updateContactAttribute' | 'updateContactList' | 'updatePhotoFilter' | 'updatePose' | 'updatePoseProfile' | 'updatePreset' | 'updateStudio' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	acceptSubmissions?: FieldPolicy<any> | FieldReadFunction<any>,
	addPose?: FieldPolicy<any> | FieldReadFunction<any>,
	clearExportFilenameHistory?: FieldPolicy<any> | FieldReadFunction<any>,
	createAccessToken?: FieldPolicy<any> | FieldReadFunction<any>,
	createContact?: FieldPolicy<any> | FieldReadFunction<any>,
	createContactAccessUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	createContactAttribute?: FieldPolicy<any> | FieldReadFunction<any>,
	createContactList?: FieldPolicy<any> | FieldReadFunction<any>,
	createPhotoFilter?: FieldPolicy<any> | FieldReadFunction<any>,
	createPoseProfile?: FieldPolicy<any> | FieldReadFunction<any>,
	createPreset?: FieldPolicy<any> | FieldReadFunction<any>,
	createStudio?: FieldPolicy<any> | FieldReadFunction<any>,
	createStudioSession?: FieldPolicy<any> | FieldReadFunction<any>,
	createSubmissionNotification?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteAccessToken?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteContactAttribute?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteContactList?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteContacts?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePhotoFilter?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePose?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePoseProfile?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePreset?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteStudio?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteStudioSessions?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteSubmissionNotifications?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteSubmissions?: FieldPolicy<any> | FieldReadFunction<any>,
	deselectAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	enhanceSubmissions?: FieldPolicy<any> | FieldReadFunction<any>,
	exportContacts?: FieldPolicy<any> | FieldReadFunction<any>,
	exportSubmissions?: FieldPolicy<any> | FieldReadFunction<any>,
	generateMetricsToken?: FieldPolicy<any> | FieldReadFunction<any>,
	inviteContactList?: FieldPolicy<any> | FieldReadFunction<any>,
	inviteContacts?: FieldPolicy<any> | FieldReadFunction<any>,
	presignCloudinaryFile?: FieldPolicy<any> | FieldReadFunction<any>,
	previewPreset?: FieldPolicy<any> | FieldReadFunction<any>,
	rejectSubmissions?: FieldPolicy<any> | FieldReadFunction<any>,
	resetStudioPhotosCount?: FieldPolicy<any> | FieldReadFunction<any>,
	selectAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	sendReminders?: FieldPolicy<any> | FieldReadFunction<any>,
	setDefaultPreset?: FieldPolicy<any> | FieldReadFunction<any>,
	signExport?: FieldPolicy<any> | FieldReadFunction<any>,
	submitPhotos?: FieldPolicy<any> | FieldReadFunction<any>,
	updateContact?: FieldPolicy<any> | FieldReadFunction<any>,
	updateContactAttribute?: FieldPolicy<any> | FieldReadFunction<any>,
	updateContactList?: FieldPolicy<any> | FieldReadFunction<any>,
	updatePhotoFilter?: FieldPolicy<any> | FieldReadFunction<any>,
	updatePose?: FieldPolicy<any> | FieldReadFunction<any>,
	updatePoseProfile?: FieldPolicy<any> | FieldReadFunction<any>,
	updatePreset?: FieldPolicy<any> | FieldReadFunction<any>,
	updateStudio?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NullableStudioSessionKeySpecifier = ('closedAt' | 'contact' | 'createdAt' | 'email' | 'id' | 'invitedAt' | 'studio' | 'submission' | 'updatedAt' | NullableStudioSessionKeySpecifier)[];
export type NullableStudioSessionFieldPolicy = {
	closedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	contact?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	invitedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	studio?: FieldPolicy<any> | FieldReadFunction<any>,
	submission?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | PageInfoKeySpecifier)[];
export type PageInfoFieldPolicy = {
	endCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>,
	startCursor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PhotoKeySpecifier = ('appliedPresets' | 'backdrop' | 'enhancedFile' | 'enhancementStatus' | 'file' | 'id' | 'name' | 'pose' | PhotoKeySpecifier)[];
export type PhotoFieldPolicy = {
	appliedPresets?: FieldPolicy<any> | FieldReadFunction<any>,
	backdrop?: FieldPolicy<any> | FieldReadFunction<any>,
	enhancedFile?: FieldPolicy<any> | FieldReadFunction<any>,
	enhancementStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	file?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	pose?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PhotoFilterKeySpecifier = ('cloudinaryArtisticFilter' | 'cloudinaryTransform' | 'id' | 'name' | PhotoFilterKeySpecifier)[];
export type PhotoFilterFieldPolicy = {
	cloudinaryArtisticFilter?: FieldPolicy<any> | FieldReadFunction<any>,
	cloudinaryTransform?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PoseKeySpecifier = ('allowPhotoUpload' | 'backdrops' | 'guidelines' | 'id' | 'name' | 'presets' | 'profile' | 'removeBackground' | PoseKeySpecifier)[];
export type PoseFieldPolicy = {
	allowPhotoUpload?: FieldPolicy<any> | FieldReadFunction<any>,
	backdrops?: FieldPolicy<any> | FieldReadFunction<any>,
	guidelines?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	presets?: FieldPolicy<any> | FieldReadFunction<any>,
	profile?: FieldPolicy<any> | FieldReadFunction<any>,
	removeBackground?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PoseProfileKeySpecifier = ('exampleImages' | 'id' | 'name' | 'outlineImage' | PoseProfileKeySpecifier)[];
export type PoseProfileFieldPolicy = {
	exampleImages?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	outlineImage?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PresetKeySpecifier = ('autoEnhance' | 'backdrop' | 'cloudinaryTransform' | 'createdAt' | 'crop' | 'default' | 'id' | 'imageFormat' | 'name' | 'overlayImage' | 'photoFilter' | 'resize' | 'sampleUrl' | 'updatedAt' | 'visibleToContact' | PresetKeySpecifier)[];
export type PresetFieldPolicy = {
	autoEnhance?: FieldPolicy<any> | FieldReadFunction<any>,
	backdrop?: FieldPolicy<any> | FieldReadFunction<any>,
	cloudinaryTransform?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	crop?: FieldPolicy<any> | FieldReadFunction<any>,
	default?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	imageFormat?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	overlayImage?: FieldPolicy<any> | FieldReadFunction<any>,
	photoFilter?: FieldPolicy<any> | FieldReadFunction<any>,
	resize?: FieldPolicy<any> | FieldReadFunction<any>,
	sampleUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	visibleToContact?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PresignCloudinaryFilePayloadKeySpecifier = ('apiKey' | 'errors' | 'params' | 'publicId' | 'signature' | 'uploadUrl' | PresignCloudinaryFilePayloadKeySpecifier)[];
export type PresignCloudinaryFilePayloadFieldPolicy = {
	apiKey?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	params?: FieldPolicy<any> | FieldReadFunction<any>,
	publicId?: FieldPolicy<any> | FieldReadFunction<any>,
	signature?: FieldPolicy<any> | FieldReadFunction<any>,
	uploadUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PreviewPresetPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'originalUrl' | 'sampleUrl' | PreviewPresetPayloadKeySpecifier)[];
export type PreviewPresetPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	originalUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	sampleUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('accessTokenScopes' | 'accessTokens' | 'contact' | 'contactAttributes' | 'contactList' | 'contactLists' | 'contacts' | 'currentAccount' | 'currentMembership' | 'currentUser' | 'export' | 'exportFilenamePatterns' | 'photoFilter' | 'photoFilters' | 'poseProfile' | 'poseProfiles' | 'preset' | 'presets' | 'searchContacts' | 'searchSnapbarAccounts' | 'studio' | 'studioByHandle' | 'studioExists' | 'studioSession' | 'studioSessionInvitations' | 'studios' | 'submissions' | 'userSubmissionNotifications' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	accessTokenScopes?: FieldPolicy<any> | FieldReadFunction<any>,
	accessTokens?: FieldPolicy<any> | FieldReadFunction<any>,
	contact?: FieldPolicy<any> | FieldReadFunction<any>,
	contactAttributes?: FieldPolicy<any> | FieldReadFunction<any>,
	contactList?: FieldPolicy<any> | FieldReadFunction<any>,
	contactLists?: FieldPolicy<any> | FieldReadFunction<any>,
	contacts?: FieldPolicy<any> | FieldReadFunction<any>,
	currentAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	currentMembership?: FieldPolicy<any> | FieldReadFunction<any>,
	currentUser?: FieldPolicy<any> | FieldReadFunction<any>,
	export?: FieldPolicy<any> | FieldReadFunction<any>,
	exportFilenamePatterns?: FieldPolicy<any> | FieldReadFunction<any>,
	photoFilter?: FieldPolicy<any> | FieldReadFunction<any>,
	photoFilters?: FieldPolicy<any> | FieldReadFunction<any>,
	poseProfile?: FieldPolicy<any> | FieldReadFunction<any>,
	poseProfiles?: FieldPolicy<any> | FieldReadFunction<any>,
	preset?: FieldPolicy<any> | FieldReadFunction<any>,
	presets?: FieldPolicy<any> | FieldReadFunction<any>,
	searchContacts?: FieldPolicy<any> | FieldReadFunction<any>,
	searchSnapbarAccounts?: FieldPolicy<any> | FieldReadFunction<any>,
	studio?: FieldPolicy<any> | FieldReadFunction<any>,
	studioByHandle?: FieldPolicy<any> | FieldReadFunction<any>,
	studioExists?: FieldPolicy<any> | FieldReadFunction<any>,
	studioSession?: FieldPolicy<any> | FieldReadFunction<any>,
	studioSessionInvitations?: FieldPolicy<any> | FieldReadFunction<any>,
	studios?: FieldPolicy<any> | FieldReadFunction<any>,
	submissions?: FieldPolicy<any> | FieldReadFunction<any>,
	userSubmissionNotifications?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RejectSubmissionsPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'submissions' | RejectSubmissionsPayloadKeySpecifier)[];
export type RejectSubmissionsPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	submissions?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ResetStudioPhotosCountPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'studio' | ResetStudioPhotosCountPayloadKeySpecifier)[];
export type ResetStudioPhotosCountPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	studio?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SelectAccountPayloadKeySpecifier = ('account' | 'errors' | SelectAccountPayloadKeySpecifier)[];
export type SelectAccountPayloadFieldPolicy = {
	account?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SendRemindersPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'success' | SendRemindersPayloadKeySpecifier)[];
export type SendRemindersPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SetDefaultPresetPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'preset' | SetDefaultPresetPayloadKeySpecifier)[];
export type SetDefaultPresetPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	preset?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SignExportPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'url' | SignExportPayloadKeySpecifier)[];
export type SignExportPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SnapbarAccountKeySpecifier = ('name' | 'snapbarId' | SnapbarAccountKeySpecifier)[];
export type SnapbarAccountFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	snapbarId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StudioKeySpecifier = ('account' | 'adminAccessUrl' | 'aiEnhancementEnabled' | 'branding' | 'contactList' | 'coordinator' | 'createdAt' | 'customAccessUrl' | 'disclaimer' | 'endDate' | 'formFields' | 'galleryEnabled' | 'handle' | 'id' | 'locale' | 'maxSubmissionsPerContact' | 'name' | 'originator' | 'photosCount' | 'photosQuotaLimit' | 'photosStoredSize' | 'poses' | 'publicUrl' | 'reminderDuration' | 'remindersEnabled' | 'requireSubmissionReview' | 'sharingPassword' | 'sharingStatus' | 'startDate' | 'status' | 'suggestMobileDevice' | 'updatedAt' | StudioKeySpecifier)[];
export type StudioFieldPolicy = {
	account?: FieldPolicy<any> | FieldReadFunction<any>,
	adminAccessUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	aiEnhancementEnabled?: FieldPolicy<any> | FieldReadFunction<any>,
	branding?: FieldPolicy<any> | FieldReadFunction<any>,
	contactList?: FieldPolicy<any> | FieldReadFunction<any>,
	coordinator?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	customAccessUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	disclaimer?: FieldPolicy<any> | FieldReadFunction<any>,
	endDate?: FieldPolicy<any> | FieldReadFunction<any>,
	formFields?: FieldPolicy<any> | FieldReadFunction<any>,
	galleryEnabled?: FieldPolicy<any> | FieldReadFunction<any>,
	handle?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	locale?: FieldPolicy<any> | FieldReadFunction<any>,
	maxSubmissionsPerContact?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	originator?: FieldPolicy<any> | FieldReadFunction<any>,
	photosCount?: FieldPolicy<any> | FieldReadFunction<any>,
	photosQuotaLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	photosStoredSize?: FieldPolicy<any> | FieldReadFunction<any>,
	poses?: FieldPolicy<any> | FieldReadFunction<any>,
	publicUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	reminderDuration?: FieldPolicy<any> | FieldReadFunction<any>,
	remindersEnabled?: FieldPolicy<any> | FieldReadFunction<any>,
	requireSubmissionReview?: FieldPolicy<any> | FieldReadFunction<any>,
	sharingPassword?: FieldPolicy<any> | FieldReadFunction<any>,
	sharingStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	startDate?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	suggestMobileDevice?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StudioConnectionKeySpecifier = ('edges' | 'nodes' | 'pageInfo' | StudioConnectionKeySpecifier)[];
export type StudioConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StudioEdgeKeySpecifier = ('cursor' | 'node' | StudioEdgeKeySpecifier)[];
export type StudioEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StudioSessionKeySpecifier = ('closedAt' | 'contact' | 'createdAt' | 'email' | 'id' | 'invitedAt' | 'studio' | 'submission' | 'updatedAt' | StudioSessionKeySpecifier)[];
export type StudioSessionFieldPolicy = {
	closedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	contact?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	invitedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	studio?: FieldPolicy<any> | FieldReadFunction<any>,
	submission?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StudioSessionConnectionKeySpecifier = ('edges' | 'nodes' | 'pageInfo' | StudioSessionConnectionKeySpecifier)[];
export type StudioSessionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StudioSessionEdgeKeySpecifier = ('cursor' | 'node' | StudioSessionEdgeKeySpecifier)[];
export type StudioSessionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubmissionKeySpecifier = ('contact' | 'createdAt' | 'id' | 'photos' | 'state' | 'studio' | 'submittedAt' | 'updatedAt' | SubmissionKeySpecifier)[];
export type SubmissionFieldPolicy = {
	contact?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	photos?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	studio?: FieldPolicy<any> | FieldReadFunction<any>,
	submittedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubmissionConnectionKeySpecifier = ('edges' | 'nodes' | 'pageInfo' | 'totalCount' | SubmissionConnectionKeySpecifier)[];
export type SubmissionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubmissionEdgeKeySpecifier = ('cursor' | 'node' | SubmissionEdgeKeySpecifier)[];
export type SubmissionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubmissionNotificationKeySpecifier = ('endAt' | 'frequency' | 'id' | 'lastSentAt' | 'startAt' | 'studio' | 'user' | SubmissionNotificationKeySpecifier)[];
export type SubmissionNotificationFieldPolicy = {
	endAt?: FieldPolicy<any> | FieldReadFunction<any>,
	frequency?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	lastSentAt?: FieldPolicy<any> | FieldReadFunction<any>,
	startAt?: FieldPolicy<any> | FieldReadFunction<any>,
	studio?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubmitPhotosPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'submission' | SubmitPhotosPayloadKeySpecifier)[];
export type SubmitPhotosPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	submission?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdateContactAttributePayloadKeySpecifier = ('clientMutationId' | 'contactAttribute' | 'errors' | UpdateContactAttributePayloadKeySpecifier)[];
export type UpdateContactAttributePayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	contactAttribute?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdateContactListPayloadKeySpecifier = ('clientMutationId' | 'contactList' | 'errors' | UpdateContactListPayloadKeySpecifier)[];
export type UpdateContactListPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	contactList?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdateContactPayloadKeySpecifier = ('clientMutationId' | 'contact' | 'errors' | UpdateContactPayloadKeySpecifier)[];
export type UpdateContactPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	contact?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdatePhotoFilterPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'photoFilter' | UpdatePhotoFilterPayloadKeySpecifier)[];
export type UpdatePhotoFilterPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	photoFilter?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdatePosePayloadKeySpecifier = ('clientMutationId' | 'errors' | 'pose' | UpdatePosePayloadKeySpecifier)[];
export type UpdatePosePayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	pose?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdatePoseProfilePayloadKeySpecifier = ('clientMutationId' | 'errors' | 'poseProfile' | UpdatePoseProfilePayloadKeySpecifier)[];
export type UpdatePoseProfilePayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	poseProfile?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdatePresetPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'preset' | UpdatePresetPayloadKeySpecifier)[];
export type UpdatePresetPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	preset?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdateStudioPayloadKeySpecifier = ('clientMutationId' | 'errors' | 'studio' | UpdateStudioPayloadKeySpecifier)[];
export type UpdateStudioPayloadFieldPolicy = {
	clientMutationId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	studio?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserKeySpecifier = ('createdAt' | 'email' | 'firstName' | 'id' | 'lastActiveAt' | 'lastName' | 'snapbarId' | 'staff' | 'updatedAt' | UserKeySpecifier)[];
export type UserFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	lastActiveAt?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	snapbarId?: FieldPolicy<any> | FieldReadFunction<any>,
	staff?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserErrorKeySpecifier = ('message' | 'path' | UserErrorKeySpecifier)[];
export type UserErrorFieldPolicy = {
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	path?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TypedTypePolicies = TypePolicies & {
	AcceptSubmissionsPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AcceptSubmissionsPayloadKeySpecifier | (() => undefined | AcceptSubmissionsPayloadKeySpecifier),
		fields?: AcceptSubmissionsPayloadFieldPolicy,
	},
	AccessToken?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AccessTokenKeySpecifier | (() => undefined | AccessTokenKeySpecifier),
		fields?: AccessTokenFieldPolicy,
	},
	Account?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AccountKeySpecifier | (() => undefined | AccountKeySpecifier),
		fields?: AccountFieldPolicy,
	},
	AddPosePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AddPosePayloadKeySpecifier | (() => undefined | AddPosePayloadKeySpecifier),
		fields?: AddPosePayloadFieldPolicy,
	},
	AppliedPreset?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppliedPresetKeySpecifier | (() => undefined | AppliedPresetKeySpecifier),
		fields?: AppliedPresetFieldPolicy,
	},
	Branding?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BrandingKeySpecifier | (() => undefined | BrandingKeySpecifier),
		fields?: BrandingFieldPolicy,
	},
	ClearExportFilenameHistoryPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ClearExportFilenameHistoryPayloadKeySpecifier | (() => undefined | ClearExportFilenameHistoryPayloadKeySpecifier),
		fields?: ClearExportFilenameHistoryPayloadFieldPolicy,
	},
	CloudFile?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CloudFileKeySpecifier | (() => undefined | CloudFileKeySpecifier),
		fields?: CloudFileFieldPolicy,
	},
	Color?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ColorKeySpecifier | (() => undefined | ColorKeySpecifier),
		fields?: ColorFieldPolicy,
	},
	Contact?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactKeySpecifier | (() => undefined | ContactKeySpecifier),
		fields?: ContactFieldPolicy,
	},
	ContactAttribute?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactAttributeKeySpecifier | (() => undefined | ContactAttributeKeySpecifier),
		fields?: ContactAttributeFieldPolicy,
	},
	ContactAttributeValidation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactAttributeValidationKeySpecifier | (() => undefined | ContactAttributeValidationKeySpecifier),
		fields?: ContactAttributeValidationFieldPolicy,
	},
	ContactConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactConnectionKeySpecifier | (() => undefined | ContactConnectionKeySpecifier),
		fields?: ContactConnectionFieldPolicy,
	},
	ContactEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactEdgeKeySpecifier | (() => undefined | ContactEdgeKeySpecifier),
		fields?: ContactEdgeFieldPolicy,
	},
	ContactList?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactListKeySpecifier | (() => undefined | ContactListKeySpecifier),
		fields?: ContactListFieldPolicy,
	},
	CreateAccessTokenPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateAccessTokenPayloadKeySpecifier | (() => undefined | CreateAccessTokenPayloadKeySpecifier),
		fields?: CreateAccessTokenPayloadFieldPolicy,
	},
	CreateContactAccessURLPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateContactAccessURLPayloadKeySpecifier | (() => undefined | CreateContactAccessURLPayloadKeySpecifier),
		fields?: CreateContactAccessURLPayloadFieldPolicy,
	},
	CreateContactAttributePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateContactAttributePayloadKeySpecifier | (() => undefined | CreateContactAttributePayloadKeySpecifier),
		fields?: CreateContactAttributePayloadFieldPolicy,
	},
	CreateContactListPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateContactListPayloadKeySpecifier | (() => undefined | CreateContactListPayloadKeySpecifier),
		fields?: CreateContactListPayloadFieldPolicy,
	},
	CreateContactPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateContactPayloadKeySpecifier | (() => undefined | CreateContactPayloadKeySpecifier),
		fields?: CreateContactPayloadFieldPolicy,
	},
	CreatePhotoFilterPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreatePhotoFilterPayloadKeySpecifier | (() => undefined | CreatePhotoFilterPayloadKeySpecifier),
		fields?: CreatePhotoFilterPayloadFieldPolicy,
	},
	CreatePoseProfilePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreatePoseProfilePayloadKeySpecifier | (() => undefined | CreatePoseProfilePayloadKeySpecifier),
		fields?: CreatePoseProfilePayloadFieldPolicy,
	},
	CreatePresetPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreatePresetPayloadKeySpecifier | (() => undefined | CreatePresetPayloadKeySpecifier),
		fields?: CreatePresetPayloadFieldPolicy,
	},
	CreateStudioPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateStudioPayloadKeySpecifier | (() => undefined | CreateStudioPayloadKeySpecifier),
		fields?: CreateStudioPayloadFieldPolicy,
	},
	CreateStudioSessionPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateStudioSessionPayloadKeySpecifier | (() => undefined | CreateStudioSessionPayloadKeySpecifier),
		fields?: CreateStudioSessionPayloadFieldPolicy,
	},
	CreateSubmissionNotificationPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateSubmissionNotificationPayloadKeySpecifier | (() => undefined | CreateSubmissionNotificationPayloadKeySpecifier),
		fields?: CreateSubmissionNotificationPayloadFieldPolicy,
	},
	Crop?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CropKeySpecifier | (() => undefined | CropKeySpecifier),
		fields?: CropFieldPolicy,
	},
	DeleteAccessTokenPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteAccessTokenPayloadKeySpecifier | (() => undefined | DeleteAccessTokenPayloadKeySpecifier),
		fields?: DeleteAccessTokenPayloadFieldPolicy,
	},
	DeleteContactAttributePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteContactAttributePayloadKeySpecifier | (() => undefined | DeleteContactAttributePayloadKeySpecifier),
		fields?: DeleteContactAttributePayloadFieldPolicy,
	},
	DeleteContactListPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteContactListPayloadKeySpecifier | (() => undefined | DeleteContactListPayloadKeySpecifier),
		fields?: DeleteContactListPayloadFieldPolicy,
	},
	DeleteContactsPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteContactsPayloadKeySpecifier | (() => undefined | DeleteContactsPayloadKeySpecifier),
		fields?: DeleteContactsPayloadFieldPolicy,
	},
	DeletePhotoFilterPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePhotoFilterPayloadKeySpecifier | (() => undefined | DeletePhotoFilterPayloadKeySpecifier),
		fields?: DeletePhotoFilterPayloadFieldPolicy,
	},
	DeletePosePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePosePayloadKeySpecifier | (() => undefined | DeletePosePayloadKeySpecifier),
		fields?: DeletePosePayloadFieldPolicy,
	},
	DeletePoseProfilePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePoseProfilePayloadKeySpecifier | (() => undefined | DeletePoseProfilePayloadKeySpecifier),
		fields?: DeletePoseProfilePayloadFieldPolicy,
	},
	DeletePresetPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePresetPayloadKeySpecifier | (() => undefined | DeletePresetPayloadKeySpecifier),
		fields?: DeletePresetPayloadFieldPolicy,
	},
	DeleteStudioPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteStudioPayloadKeySpecifier | (() => undefined | DeleteStudioPayloadKeySpecifier),
		fields?: DeleteStudioPayloadFieldPolicy,
	},
	DeleteStudioSessionsPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteStudioSessionsPayloadKeySpecifier | (() => undefined | DeleteStudioSessionsPayloadKeySpecifier),
		fields?: DeleteStudioSessionsPayloadFieldPolicy,
	},
	DeleteSubmissionNotificationsPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteSubmissionNotificationsPayloadKeySpecifier | (() => undefined | DeleteSubmissionNotificationsPayloadKeySpecifier),
		fields?: DeleteSubmissionNotificationsPayloadFieldPolicy,
	},
	DeleteSubmissionsPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteSubmissionsPayloadKeySpecifier | (() => undefined | DeleteSubmissionsPayloadKeySpecifier),
		fields?: DeleteSubmissionsPayloadFieldPolicy,
	},
	DeselectAccountPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeselectAccountPayloadKeySpecifier | (() => undefined | DeselectAccountPayloadKeySpecifier),
		fields?: DeselectAccountPayloadFieldPolicy,
	},
	Disclaimer?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DisclaimerKeySpecifier | (() => undefined | DisclaimerKeySpecifier),
		fields?: DisclaimerFieldPolicy,
	},
	EnhanceSubmissionsPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | EnhanceSubmissionsPayloadKeySpecifier | (() => undefined | EnhanceSubmissionsPayloadKeySpecifier),
		fields?: EnhanceSubmissionsPayloadFieldPolicy,
	},
	Export?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExportKeySpecifier | (() => undefined | ExportKeySpecifier),
		fields?: ExportFieldPolicy,
	},
	ExportContactsPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExportContactsPayloadKeySpecifier | (() => undefined | ExportContactsPayloadKeySpecifier),
		fields?: ExportContactsPayloadFieldPolicy,
	},
	ExportSubmissionsPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExportSubmissionsPayloadKeySpecifier | (() => undefined | ExportSubmissionsPayloadKeySpecifier),
		fields?: ExportSubmissionsPayloadFieldPolicy,
	},
	FormField?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FormFieldKeySpecifier | (() => undefined | FormFieldKeySpecifier),
		fields?: FormFieldFieldPolicy,
	},
	GenerateMetricsTokenPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GenerateMetricsTokenPayloadKeySpecifier | (() => undefined | GenerateMetricsTokenPayloadKeySpecifier),
		fields?: GenerateMetricsTokenPayloadFieldPolicy,
	},
	InviteContactListPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InviteContactListPayloadKeySpecifier | (() => undefined | InviteContactListPayloadKeySpecifier),
		fields?: InviteContactListPayloadFieldPolicy,
	},
	InviteContactsPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InviteContactsPayloadKeySpecifier | (() => undefined | InviteContactsPayloadKeySpecifier),
		fields?: InviteContactsPayloadFieldPolicy,
	},
	Membership?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MembershipKeySpecifier | (() => undefined | MembershipKeySpecifier),
		fields?: MembershipFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	NullableStudioSession?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NullableStudioSessionKeySpecifier | (() => undefined | NullableStudioSessionKeySpecifier),
		fields?: NullableStudioSessionFieldPolicy,
	},
	PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier),
		fields?: PageInfoFieldPolicy,
	},
	Photo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PhotoKeySpecifier | (() => undefined | PhotoKeySpecifier),
		fields?: PhotoFieldPolicy,
	},
	PhotoFilter?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PhotoFilterKeySpecifier | (() => undefined | PhotoFilterKeySpecifier),
		fields?: PhotoFilterFieldPolicy,
	},
	Pose?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PoseKeySpecifier | (() => undefined | PoseKeySpecifier),
		fields?: PoseFieldPolicy,
	},
	PoseProfile?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PoseProfileKeySpecifier | (() => undefined | PoseProfileKeySpecifier),
		fields?: PoseProfileFieldPolicy,
	},
	Preset?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PresetKeySpecifier | (() => undefined | PresetKeySpecifier),
		fields?: PresetFieldPolicy,
	},
	PresignCloudinaryFilePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PresignCloudinaryFilePayloadKeySpecifier | (() => undefined | PresignCloudinaryFilePayloadKeySpecifier),
		fields?: PresignCloudinaryFilePayloadFieldPolicy,
	},
	PreviewPresetPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PreviewPresetPayloadKeySpecifier | (() => undefined | PreviewPresetPayloadKeySpecifier),
		fields?: PreviewPresetPayloadFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	RejectSubmissionsPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RejectSubmissionsPayloadKeySpecifier | (() => undefined | RejectSubmissionsPayloadKeySpecifier),
		fields?: RejectSubmissionsPayloadFieldPolicy,
	},
	ResetStudioPhotosCountPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ResetStudioPhotosCountPayloadKeySpecifier | (() => undefined | ResetStudioPhotosCountPayloadKeySpecifier),
		fields?: ResetStudioPhotosCountPayloadFieldPolicy,
	},
	SelectAccountPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SelectAccountPayloadKeySpecifier | (() => undefined | SelectAccountPayloadKeySpecifier),
		fields?: SelectAccountPayloadFieldPolicy,
	},
	SendRemindersPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SendRemindersPayloadKeySpecifier | (() => undefined | SendRemindersPayloadKeySpecifier),
		fields?: SendRemindersPayloadFieldPolicy,
	},
	SetDefaultPresetPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SetDefaultPresetPayloadKeySpecifier | (() => undefined | SetDefaultPresetPayloadKeySpecifier),
		fields?: SetDefaultPresetPayloadFieldPolicy,
	},
	SignExportPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SignExportPayloadKeySpecifier | (() => undefined | SignExportPayloadKeySpecifier),
		fields?: SignExportPayloadFieldPolicy,
	},
	SnapbarAccount?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SnapbarAccountKeySpecifier | (() => undefined | SnapbarAccountKeySpecifier),
		fields?: SnapbarAccountFieldPolicy,
	},
	Studio?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StudioKeySpecifier | (() => undefined | StudioKeySpecifier),
		fields?: StudioFieldPolicy,
	},
	StudioConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StudioConnectionKeySpecifier | (() => undefined | StudioConnectionKeySpecifier),
		fields?: StudioConnectionFieldPolicy,
	},
	StudioEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StudioEdgeKeySpecifier | (() => undefined | StudioEdgeKeySpecifier),
		fields?: StudioEdgeFieldPolicy,
	},
	StudioSession?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StudioSessionKeySpecifier | (() => undefined | StudioSessionKeySpecifier),
		fields?: StudioSessionFieldPolicy,
	},
	StudioSessionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StudioSessionConnectionKeySpecifier | (() => undefined | StudioSessionConnectionKeySpecifier),
		fields?: StudioSessionConnectionFieldPolicy,
	},
	StudioSessionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StudioSessionEdgeKeySpecifier | (() => undefined | StudioSessionEdgeKeySpecifier),
		fields?: StudioSessionEdgeFieldPolicy,
	},
	Submission?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubmissionKeySpecifier | (() => undefined | SubmissionKeySpecifier),
		fields?: SubmissionFieldPolicy,
	},
	SubmissionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubmissionConnectionKeySpecifier | (() => undefined | SubmissionConnectionKeySpecifier),
		fields?: SubmissionConnectionFieldPolicy,
	},
	SubmissionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubmissionEdgeKeySpecifier | (() => undefined | SubmissionEdgeKeySpecifier),
		fields?: SubmissionEdgeFieldPolicy,
	},
	SubmissionNotification?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubmissionNotificationKeySpecifier | (() => undefined | SubmissionNotificationKeySpecifier),
		fields?: SubmissionNotificationFieldPolicy,
	},
	SubmitPhotosPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubmitPhotosPayloadKeySpecifier | (() => undefined | SubmitPhotosPayloadKeySpecifier),
		fields?: SubmitPhotosPayloadFieldPolicy,
	},
	UpdateContactAttributePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdateContactAttributePayloadKeySpecifier | (() => undefined | UpdateContactAttributePayloadKeySpecifier),
		fields?: UpdateContactAttributePayloadFieldPolicy,
	},
	UpdateContactListPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdateContactListPayloadKeySpecifier | (() => undefined | UpdateContactListPayloadKeySpecifier),
		fields?: UpdateContactListPayloadFieldPolicy,
	},
	UpdateContactPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdateContactPayloadKeySpecifier | (() => undefined | UpdateContactPayloadKeySpecifier),
		fields?: UpdateContactPayloadFieldPolicy,
	},
	UpdatePhotoFilterPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdatePhotoFilterPayloadKeySpecifier | (() => undefined | UpdatePhotoFilterPayloadKeySpecifier),
		fields?: UpdatePhotoFilterPayloadFieldPolicy,
	},
	UpdatePosePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdatePosePayloadKeySpecifier | (() => undefined | UpdatePosePayloadKeySpecifier),
		fields?: UpdatePosePayloadFieldPolicy,
	},
	UpdatePoseProfilePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdatePoseProfilePayloadKeySpecifier | (() => undefined | UpdatePoseProfilePayloadKeySpecifier),
		fields?: UpdatePoseProfilePayloadFieldPolicy,
	},
	UpdatePresetPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdatePresetPayloadKeySpecifier | (() => undefined | UpdatePresetPayloadKeySpecifier),
		fields?: UpdatePresetPayloadFieldPolicy,
	},
	UpdateStudioPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdateStudioPayloadKeySpecifier | (() => undefined | UpdateStudioPayloadKeySpecifier),
		fields?: UpdateStudioPayloadFieldPolicy,
	},
	User?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier),
		fields?: UserFieldPolicy,
	},
	UserError?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserErrorKeySpecifier | (() => undefined | UserErrorKeySpecifier),
		fields?: UserErrorFieldPolicy,
	}
};