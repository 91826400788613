import {
  Typography,
  makeStyles,
  ButtonBase,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ChevronDown, Download, Edit2, MoreVertical, Trash, Trash2, Upload } from 'react-feather';
import { useRef } from 'react';
import clsx from 'clsx';

import { toast } from 'components/GlobalSnackbar';
import { ContactListImportButton } from 'components/ContactListImportButton';
import { handleMutation } from 'lib/handleMutation';
import { ContactList, useDeleteContactListMutation, useDeleteContactsMutation } from 'codegen/graphql';
import { useMenu } from 'src/hooks/useMenu';
import useDialog from 'hooks/useDialog';
import { usePermissions } from 'hooks/usePermissions';
import ContactListFormDialog from 'components/ContactListFormDialog';
import { ContactExportDialog } from 'components/ContactExportDialog';
import Dialog from 'components/Dialog';

export type ContactsDataGridToolbarProps = {
  rowCount: number;
  selectedIds: string[];
  total: string;
  /**
   * Callback that refreshes the current page of the data grid.
   */
  refreshCurrentPage: () => void;
  /**
   * Callback that reloads the entire parent data grid.
   */
  reloadDataGrid: () => void;
  /**
   * Model ID of the current tab.
   */
  contactList?: Pick<ContactList, 'id' | 'name'>;
};

type BulkAction = {
  label: string;
  icon: JSX.Element;
  loading?: boolean;
  onClick: () => unknown;
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '16px 16px 0 16px',
  },
  // of course MUI doesn't support children in the `Chip` component so I have to
  // style my own.
  chip: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    background: '#F1F3FB',
    borderRadius: 25,
    marginLeft: -8,
    padding: '8px 16px',
  },
  chipActions: {
    marginLeft: 12,
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 16,
  },
  listActionItem: {
    '& .MuiTypography-root': {
      fontWeight: 600,
      fontSize: '0.875rem',
    },
    '& svg': {
      color: 'black',
    },
  },
  delete: {
    color: theme.palette.error.main,
    '& svg': {
      color: theme.palette.error.main,
    },
  },
  text: {
    display: 'inline-block',
    fontSize: 15,
    fontWeight: 600,
  },
  firstRow: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  filtersGroup: {
    '& > *:not(:last-child)': {
      marginRight: 8,
    },
  },
  select: {
    minWidth: 180,
    '& .MuiOutlinedInput-input': {
      padding: '8px 32px 8px 14px',
    },
    '& .MuiInputLabel-outlined[data-shrink="false"]': {
      transform: 'translate(14px, 10px) scale(1)',
    },
  },
}));

export function ContactsDataGridToolbar(props: ContactsDataGridToolbarProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const perms = usePermissions();

  const listId = props.contactList?.id;

  const bulkActionsButtonRef = useRef<HTMLButtonElement>(null);
  const listActionsButtonRef = useRef<HTMLButtonElement>(null);

  const { openDialog: openExportDialog, dialogProps: exportDialogProps } = useDialog();
  const { openDialog: openExportDelayedDialog, dialogProps: exportDelayedDialogProps } = useDialog();
  const { openDialog: openImportStartedDialog, dialogProps: importStartedDialogProps } = useDialog();

  const {
    menuProps: bulkActionsMenuProps,
    openMenu: openBulkActionsMenu,
    closeMenu: closeBulkActionsMenu,
  } = useMenu({
    anchorEl: () => bulkActionsButtonRef.current as Element,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  });

  const { menuProps: listActionsMenuProps, openMenu: openListActionsMenu } = useMenu({
    anchorEl: () => listActionsButtonRef.current as Element,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  });

  const { openDialog: openUpdateListDialog, dialogProps: updateListDialogProps } = useDialog();

  const [deleteContacts, deleteContactsState] = useDeleteContactsMutation({ variables: { ids: props.selectedIds } });
  const [deleteContactList, deleteContactListState] = useDeleteContactListMutation();

  const pluralized = props.selectedIds.length > 1 ? 'contact_plural' : 'contact';
  const bulkActions: BulkAction[] = perms.auth?.canDeleteContact
    ? [
        {
          icon: <Trash2 />,
          label: deleteContactsState.loading ? 'Deleting...' : 'Delete',
          loading: deleteContactsState.loading,
          onClick: handleBulkDelete,
        },
      ]
    : [];

  return (
    <div className={classes.root}>
      <div className={classes.firstRow}>
        {props.selectedIds.length ? (
          <div className={classes.chip}>
            <Typography className={classes.text}>
              {props.selectedIds.length} of {props.rowCount} selected
            </Typography>
            {!!bulkActions.length && (
              <>
                <ButtonBase className={classes.chipActions} onClick={openBulkActionsMenu} ref={bulkActionsButtonRef}>
                  <Typography variant="body2">Actions</Typography>
                  <ChevronDown size={12} style={{ display: 'inline-block', marginLeft: 8 }} />
                </ButtonBase>
                <Menu {...bulkActionsMenuProps}>
                  {bulkActions.map((action, index) => (
                    <MenuItem key={index} onClick={action.onClick} disabled={action.loading}>
                      <ListItemIcon>{action.icon}</ListItemIcon>
                      <Typography variant="body2">{action.label}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </div>
        ) : (
          <div className={classes.chip} style={{ backgroundColor: 'white' }}>
            <Typography className={classes.text}>
              Showing {props.rowCount} of {props.total}
            </Typography>
          </div>
        )}
        <div>
          {perms.auth?.canAddContact && (
            <ContactListImportButton listId={listId} onImportStarted={openImportStartedDialog} />
          )}
          <Button onClick={openExportDialog} startIcon={<Download />} component="span">
            Export
          </Button>
          {listId && (
            <>
              <IconButton ref={listActionsButtonRef} onClick={openListActionsMenu}>
                <MoreVertical size={16} />
              </IconButton>
              <Menu {...listActionsMenuProps}>
                <MenuItem className={classes.listActionItem} onClick={openUpdateListDialog}>
                  <ListItemIcon>
                    <Edit2 />
                  </ListItemIcon>
                  <Typography>Rename list</Typography>
                </MenuItem>
                <MenuItem
                  className={clsx(classes.listActionItem, classes.delete)}
                  onClick={() => handleDeleteList(listId)}
                  disabled={deleteContactListState.loading}
                >
                  <ListItemIcon>
                    <Trash />
                  </ListItemIcon>
                  <Typography>{deleteContactListState.loading ? 'Deleting list...' : 'Delete list'}</Typography>
                </MenuItem>
              </Menu>
              <ContactListFormDialog {...updateListDialogProps} id={listId} />
            </>
          )}
        </div>
      </div>
      <ContactExportDialog
        selectedIds={props.selectedIds}
        contactList={props.contactList}
        onDelayed={openExportDelayedDialog}
        {...exportDialogProps}
      />
      <Dialog
        {...exportDelayedDialogProps}
        title="Export in progress..."
        width={500}
        actions={
          <>
            <Button onClick={exportDelayedDialogProps.onClose} variant="contained" color="primary">
              Okay
            </Button>
          </>
        }
      >
        <Typography>
          {"Your contacts are currently being exported. We'll send you an email once the export is completed."}
        </Typography>
      </Dialog>

      <Dialog
        {...importStartedDialogProps}
        title="Import in progress..."
        width={500}
        onExited={props.reloadDataGrid}
        actions={
          <>
            <Button onClick={importStartedDialogProps.onClose} variant="contained" color="primary">
              Okay
            </Button>
          </>
        }
      >
        <Typography>
          {"Your contacts are currently being imported. We'll send you an email once the import is completed."}
        </Typography>
      </Dialog>
    </div>
  );

  async function handleBulkDelete() {
    await handleMutation({
      mutate: deleteContacts,
      payloadFieldPath: 'deleteContacts?',
      confirm:
        // eslint-disable-next-line
        "Are you sure you'd like to delete the selected " +
        t(pluralized).toLowerCase() +
        '? Submissions for the selected ' +
        t(pluralized).toLowerCase() +
        ' will also be deleted.',
      successCondition: (data) => (data.deleteContacts?.deletedIds?.length || 0) > 0,
      onSuccess: () => {
        toast(t(pluralized) + ' deleted', 'success');
        props.refreshCurrentPage();
      },
      onError: () => {
        toast('Could not delete ' + t(pluralized).toLowerCase(), 'error');
      },
    });
    closeBulkActionsMenu();
  }

  async function handleDeleteList(id: string) {
    await handleMutation({
      mutate: () => deleteContactList({ variables: { id } }),
      payloadFieldPath: 'deleteContactList?',
      successCondition: (data) => data.deleteContactList?.deletedId === id,
      confirm: 'Are you sure you want to delete this contact list? The associated contacts will not be deleted.',
      onSuccess: () => {
        toast('Contact list deleted', 'success');
        history.push('/contacts');
      },
      onError: () => {
        toast('Could not delete contact list', 'error');
      },
    });
  }
}
