import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  ApolloLink,
  FieldMergeFunction,
  NormalizedCacheObject,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import omitDeep from 'omit-deep-lodash';

import {
  ContactAttribute,
  DeleteContactAttributePayload,
  FormField,
  Mutation,
  PageInfo,
  Studio,
  TypedTypePolicies,
} from 'codegen/graphql';

type CachedObject = {
  __ref: string;
};

type CachedConnection = {
  edges: {
    node: CachedObject;
  }[];
  pageInfo: PageInfo;
};

/**
 * Initializes a new, pre-configured Apollo client.
 */
export function initializeApolloClient(csrfToken: string | null | undefined): ApolloClient<NormalizedCacheObject> {
  // append operation name to request for easier debugging
  const httpLink = createHttpLink({
    uri: '/api/v1/graphql',
    fetch: (uri, options) => {
      const { operationName } = JSON.parse((options?.body || '') as string);
      return fetch(`${uri}?op=${operationName}`, options);
    },
  });

  // add CSRF token header
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'X-CSRF-Token': csrfToken,
      },
    };
  });

  // never include `__typename` in an argument variable. this is because
  // `graphql-ruby` will throw when it receives an argument with a field not
  // defined in the corresponding argument type.
  const stripTypenameFromVariablesLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = omitDeep(operation.variables, '__typename');
    }
    return forward(operation).map((data) => data);
  });

  function deleteFromRootArray<ItemType, Payload extends Record<string, unknown>>(
    rootArrayField: string,
    deletedIdField: keyof Payload = 'deletedId',
    refKey: keyof (ItemType & CachedObject) = '__ref',
  ): FieldMergeFunction {
    return (_, payload) => {
      const deletedId = payload?.[deletedIdField];
      if (!deletedId) return;
      cache.modify({
        fields: {
          [rootArrayField](existingNodes: (ItemType & CachedObject)[]) {
            return existingNodes.filter((filter) => filter[refKey] !== deletedId);
          },
        },
      });
    };
  }

  function mergeIntoRootArray(payloadField: string, rootArrayField: string): FieldMergeFunction {
    return (_, payload) => {
      const newNode = payload?.[payloadField];
      if (!newNode) return;
      cache.modify({
        fields: {
          [rootArrayField](existingNodes) {
            return [...existingNodes, newNode];
          },
        },
      });
    };
  }

  function mergeIntoRootConnection(payloadField: string, rootConnectionField: string): FieldMergeFunction {
    return (_, payload) => {
      const newNode = payload?.[payloadField];
      if (!newNode) return;
      cache.modify({
        fields: {
          [rootConnectionField]({ edges: existingEdges }) {
            const newEdge = { node: newNode };
            return { edges: [...existingEdges, newEdge] };
          },
        },
      });
    };
  }

  function refreshReviewCount() {
    return () => {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          ['submissions:{"state":"REVIEW"}'](_, { DELETE }) {
            // prefer DELETE over INVALIDATE.
            // https://github.com/apollographql/apollo-client/issues/7060
            return DELETE;
          },
        },
      });
    };
  }

  function invalidateCache() {
    cache.evict({ id: 'ROOT_QUERY' });
  }

  const typePolicies: TypedTypePolicies = {
    Query: {
      fields: {
        studioSessionInvitations: relayStylePagination(),
        contacts: relayStylePagination(['contactListId']),
        submissions: relayStylePagination(
          // separate caches based on state; this prevents
          // submissions.totalCount shown in the "REVIEW" tab from being
          // overwritten on tab change.
          // see https://www.apollographql.com/docs/react/pagination/key-args
          ['state'],
        ),
        studios: relayStylePagination(['query']),
      },
    },
    Mutation: {
      fields: {
        createStudio: {
          merge: mergeIntoRootConnection('studio', 'studios'),
        },
        createContactList: {
          merge: mergeIntoRootArray('contactList', 'contactLists'),
        },
        createPreset: {
          merge: mergeIntoRootArray('preset', 'presets'),
        },
        createPhotoFilter: {
          merge: mergeIntoRootArray('photoFilter', 'photoFilters'),
        },
        deletePhotoFilter: {
          merge: deleteFromRootArray('photoFilters'),
        },
        deletePreset: {
          merge: deleteFromRootArray('presets'),
        },
        addPose: {
          merge: (_, payload, { variables }) => {
            const studioId = variables?.input?.studioId;
            const newPose = payload.pose;
            if (!studioId || !newPose) return;
            cache.modify({
              id: studioId,
              fields: {
                poses(existingPoses) {
                  return [...existingPoses, newPose];
                },
              },
            });
          },
        },
        deleteStudio: {
          merge: (_, payload: Mutation['deleteStudio']) => {
            const deletedId = payload?.deletedId;
            if (!deletedId) return;
            // really should use `cache.evict()`, but it keeps triggering extra
            // network requests even when `broadcast: false`.
            cache.modify({
              fields: {
                studios({ edges }: CachedConnection) {
                  return { edges: edges.filter(({ node }) => node.__ref !== deletedId) };
                },
              },
            });
          },
        },
        deletePose: {
          merge: (_, payload, { variables }) => {
            const studioId = variables?.input.studioId;
            const deletedId = payload?.deletedId;
            if (!deletedId || !studioId) return;
            cache.modify({
              id: studioId,
              fields: {
                poses(existingPoses: CachedObject[]) {
                  return existingPoses.filter((pose) => pose.__ref !== deletedId);
                },
              },
            });
          },
        },
        selectAccount: {
          merge: (_, payload, { variables }) => {
            const newAccount = payload?.account;
            if (!newAccount) return;
            invalidateCache();
            cache.modify({
              fields: {
                currentAccount() {
                  return newAccount;
                },
              },
            });
          },
        },
        deselectAccount: {
          merge: () => {
            invalidateCache();
            cache.modify({
              fields: {
                currentAccount() {
                  return null;
                },
              },
            });
          },
        },
        createPoseProfile: {
          merge: mergeIntoRootArray('poseProfile', 'poseProfiles'),
        },
        deletePoseProfile: {
          merge: deleteFromRootArray('poseProfiles'),
        },
        deleteContactList: {
          merge: deleteFromRootArray('contactLists'),
        },
        acceptSubmissions: {
          merge: refreshReviewCount(),
        },
        rejectSubmissions: {
          merge: refreshReviewCount(),
        },
        deleteSubmissions: {
          merge: refreshReviewCount(),
        },
        deleteContactAttribute: {
          merge: (...args) => {
            deleteFromRootArray<ContactAttribute, DeleteContactAttributePayload>(
              'contactAttributes',
              'deletedKey',
              'key',
            )(...args);

            const payload = args[1] as DeleteContactAttributePayload;

            const result = cache.extract();
            const rootKeys = Object.keys(result);

            rootKeys.forEach((key) => {
              if (!key.includes('/Studio/')) return;

              const studio = result[key] as Partial<Studio>;
              if (!studio.formFields) return;

              const containsRemovedField = studio.formFields.some(
                (field) => field.contactAttribute.key === payload.deletedKey,
              );
              if (!containsRemovedField) return;

              cache.evict({ id: key, fieldName: 'formFields' });
            });
          },
        },
      },
    },
  };

  const cache = new InMemoryCache({
    typePolicies,
    // just use GID for cache ID, no need to prepend `__typename` as it is
    // already included in GID returned from backend.
    dataIdFromObject(object) {
      return (object.id as string) || false;
    },
  });

  return new ApolloClient({
    link: from([authLink, stripTypenameFromVariablesLink, httpLink]),
    connectToDevTools: true,
    cache,
  });
}
