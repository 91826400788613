import { useTranslation } from 'react-i18next';
import { Button, Paper, Typography, makeStyles } from '@material-ui/core';
import { useCallback } from 'react';
import Page from 'pages/Page';

import SnapbarStudioLogoDark from 'assets/SnapbarStudioLogoDark.svg';

export type LoginProps = {
  // add props here
};

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '100%',
    maxWidth: 200,
    paddingBottom: 50,
    zIndex: 1,
  },
  paper: {
    padding: '48px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 450,
    textAlign: 'center',
  },
  title: {
    marginBottom: 16,
    color: '#253858',
  },
  button: {
    width: '100%',
  },
  noPadding: {
    padding: '0',
  },
}));

export function Login(props: LoginProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    // use Window API to directly handle redirect to server-side route
    window.location.replace('/auth/snapbar');
  }, []);

  return (
    <Page title="Login" hideTitleBar className={classes.noPadding}>
      <div className={classes.root}>
        <img src={SnapbarStudioLogoDark} alt="logo" className={classes.logo} />
        <Paper className={classes.paper}>
          <Typography variant="h3" className={classes.title}>
            Welcome to Snapbar Studio
          </Typography>
          <Typography style={{ marginBottom: 24 }}>
            Access the admin dashboard by logging in with your Snapbar account
          </Typography>
          <Button color="primary" variant="contained" onClick={handleClick} className={classes.button}>
            Log in
          </Button>
        </Paper>
      </div>
    </Page>
  );
}
