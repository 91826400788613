import { Button, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useForm, useController } from 'react-hook-form';
import { useEffect, useMemo, useRef, useState } from 'react';
import Dialog from 'components/Dialog';
import {
  ContactList,
  useExportContactsMutation,
  useGetExportLazyQuery,
  useGetPresetOverviewsQuery,
  useGetStudioDetailsLazyQuery,
  useGetStudioOverviewsQuery,
} from 'codegen/graphql';
import { SelectField, SelectItem } from 'components/SelectField';
import { CheckboxField } from 'components/CheckboxField';
import { handleMutation } from 'lib/handleMutation';
import { toast } from 'components/GlobalSnackbar';
import { useHistory } from 'react-router';
import { id } from 'lib/gid';

export type ContactExportDialogProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Toggles opening of the dialog.
   */
  open: boolean;
  /**
   * Callback executed on dialog close.
   */
  onClose: () => void;
  /**
   * When an export took longer than 5 seconds, show them a message saying to wait for an email
   */
  onDelayed: () => void;
  /**
   * The contact list ID to filter export by
   */
  contactList?: Pick<ContactList, 'id' | 'name'>;
  /**
   * A list of contact IDs to export
   */
  selectedIds?: string[];
};

export function ContactExportDialog(props: ContactExportDialogProps): React.ReactElement {
  const history = useHistory();

  const { data: studiosData, loading: loadingStudios, error: errorStudios } = useGetStudioOverviewsQuery();
  const [getStudioDetails, { data: studioDetailsData }] = useGetStudioDetailsLazyQuery({});
  const { data: presetsData, loading: loadingPresets, error: errorPresets } = useGetPresetOverviewsQuery();
  const [getExport, { data: exportData, refetch: refetchExportData }] = useGetExportLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [exportContacts, exportContactsState] = useExportContactsMutation();
  const [pollExportAttempts, setPollExportAttempts] = useState<number>();
  const pollTimeoutRef = useRef<number>();

  const { control, reset, trigger, getValues, setValue } = useForm({
    defaultValues: {
      showStudioSelect: false,
      studio: '',
      poseId: '',
      presetId: '',
    },
  });

  const showStudioSelectController = useController({ control, name: 'showStudioSelect' });
  const studioController = useController({ control, name: 'studio' });
  const poseIdController = useController({ control, name: 'poseId' });
  const presetIdController = useController({ control, name: 'presetId' });

  const studioOptions = useMemo((): SelectItem[] => {
    return (
      studiosData?.studios.edges.map((edge) => {
        return {
          label: edge.node.name,
          value: edge.node.id,
        };
      }) || []
    );
  }, [studiosData]);

  const poseOptions = useMemo((): SelectItem[] => {
    setValue('poseId', '');

    if (!studioController.field.value || !studioDetailsData) {
      return [];
    } else {
      return (
        studioDetailsData.studio?.poses.map((pose) => {
          return {
            label: pose.name,
            value: pose.id,
          };
        }) || []
      );
    }
  }, [setValue, studioDetailsData, studioController.field.value]);

  const presetOptions = useMemo((): SelectItem[] => {
    return (
      presetsData?.presets?.map((preset) => {
        return {
          label: preset.name,
          value: preset.id,
        };
      }) || []
    );
  }, [presetsData]);

  useEffect(() => {
    reset();
    setPollExportAttempts(undefined);
    clearTimeout(pollTimeoutRef.current);
  }, [setPollExportAttempts, reset, props.open]);

  useEffect(() => {
    setValue('studio', showStudioSelectController.field.value ? studioOptions[0]?.value : '');
  }, [setValue, studioOptions, showStudioSelectController.field.value]);

  useEffect(() => {
    if (!studioController.field.value) return;

    getStudioDetails({
      variables: {
        id: studioController.field.value,
      },
    });
  }, [getStudioDetails, studioController.field.value]);

  useEffect(() => {
    const value = poseOptions[0]?.value;
    if (!value) return;

    setValue('poseId', value);
  }, [setValue, poseOptions, studioController.field.value]);

  useEffect(() => {
    if (!presetsData) return;

    setValue('presetId', presetsData.presets.find((preset) => preset.default)?.id || '');
  }, [setValue, showStudioSelectController.field.value, presetsData]);

  useEffect(() => {
    if (!pollExportAttempts || !refetchExportData) return;

    if (pollExportAttempts >= 5) {
      props.onDelayed();
      props.onClose();
      return;
    }

    pollTimeoutRef.current = window.setTimeout(() => {
      refetchExportData();
      setPollExportAttempts((current) => (current || 0) + 1);
    }, 1000);
  }, [refetchExportData, pollExportAttempts, setPollExportAttempts]);

  useEffect(() => {
    if (exportData?.export?.status == 'DONE') {
      clearTimeout(pollTimeoutRef.current);
      setPollExportAttempts(undefined);

      history.push(`/contacts/exports/${id(exportData.export.id)}`);
    }
  }, [history, exportData]);

  const loading = exportContactsState.loading || !!pollExportAttempts;
  const selectedContacts = props.selectedIds?.length || 0;
  const studiosExist = Boolean(studiosData?.studios.edges.length);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      onExited={() => reset()}
      title="Export contacts"
      width={600}
      actions={
        <>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleExport} disabled={loading}>
            {loading ? 'Exporting...' : 'Export'}
          </Button>
        </>
      }
    >
      {loadingStudios || loadingPresets ? (
        <>
          <Skeleton height={57} />
          <Skeleton height={57} />
        </>
      ) : (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {selectedContacts ? (
              <Typography>
                <strong>{selectedContacts} selected</strong> contact{selectedContacts > 1 && 's'} will be exported
              </Typography>
            ) : (
              <Typography>
                All contacts{' '}
                {props.contactList && (
                  <>
                    from <strong>{props.contactList?.name}</strong>
                  </>
                )}{' '}
                will be exported
              </Typography>
            )}
          </Grid>
          {studiosExist && (
            <Grid item>
              <CheckboxField label="Include photo URL in export" controller={showStudioSelectController} />
            </Grid>
          )}
          {showStudioSelectController.field.value && (
            <>
              <Grid item>
                <SelectField
                  items={studioOptions}
                  label="Use photo submitted to studio"
                  controller={studioController}
                />
              </Grid>
              <Grid item>
                <SelectField items={poseOptions} label="From pose" controller={poseIdController} />
              </Grid>
              <Grid item>
                <SelectField items={presetOptions} label="With output" controller={presetIdController} />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Dialog>
  );

  async function handleExport() {
    if (!(await trigger())) {
      console.warn(control._formState.errors);
      return;
    }

    const form = getValues();
    await handleMutation({
      mutate: () =>
        exportContacts({
          variables: {
            input: {
              ids: props.selectedIds,
              listId: props.contactList?.id,
              photoPoseId: form.poseId || null,
              presetId: form.presetId || null,
            },
          },
        }),
      payloadFieldPath: 'exportContacts?',
      successCondition: (data) => !!data.exportContacts?.exportId,
      onSuccess: async (data) => {
        const exportId = data.exportContacts!.exportId!;

        await getExport({ variables: { id: exportId } });
        setPollExportAttempts(1);
      },
      onError: () => {
        toast('Failed to export contacts', 'error');
      },
    });
  }
}
