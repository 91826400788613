import { Fragment } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import type { UseControllerReturn } from 'react-hook-form';

export type RadioItem = {
  /**
   * Label of this radio item, rendered to the right of the radio button.
   */
  label: React.ReactNode;
  /**
   * Internal value of the item.
   */
  value: string;
  /**
   * Text caption that goes under the label
   */
  description?: string;
  /**
   * Any content (i.e. a sub-field) that should be displayed below the option's label
   */
  contentBelowLabel?: React.ReactNode;
  /**
   * Allows for the disabling of individual items
   */
  disabled?: boolean;
};

export type RadioFieldProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Radio group controller returned from the `useRadioGroupController` hook.
   */
  controller: UseControllerReturn<any, any>;
  /**
   * Items to render within this radio field.
   */
  items: RadioItem[];
  /**
   * The label of this radio group.
   */
  label?: string;
  /**
   * If `true`, place each radio field item on a new line.
   */
  fullWidthItems?: boolean;
  /**
   * Callback executed on change. First argument is the new value of the field.
   */
  onChange?: (value: string) => unknown;
  /**
   * Whether to disable this radio field.
   */
  disabled?: boolean;
  helpText?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    // do not inline by default
    display: 'flex',
  },
  fieldGroup: {
    display: 'block',
    '& .MuiFormLabel-root': {
      margin: 0,
    },
  },
  fullWidth: {
    width: '100%',
    marginRight: 0,
  },
  labelTypography: {
    marginTop: theme.spacing(2.25),
    marginBottom: theme.spacing(-0.75),
  },
}));

/**
 * Component that wraps the MUI `RadioGroup` component and provides sensible
 * defaults for usage with `react-hook-form`. Requires radio fields as children
 * and a `react-hook-form` controller as a prop.
 */
export function RadioField(props: RadioFieldProps): React.ReactElement {
  const classes = useStyles(props);

  const {
    field: { onChange: controllerOnChange, ...controllerProps },
    fieldState: { error },
  } = props.controller;

  return (
    <FormControl error={!!error} className={clsx(props.className, classes.root)}>
      <RadioGroup row className={classes.fieldGroup} {...controllerProps} onChange={handleChange}>
        <FormLabel component="p">{props.label}</FormLabel>
        {props.items.map((item, index) => (
          <Fragment key={index}>
            <FormControlLabel
              value={item.value}
              label={
                <>
                  <Typography variant="body2" className={clsx(item.description && classes.labelTypography)}>
                    {item.label}
                  </Typography>
                  {item.description && (
                    <Typography variant="caption" color={'textSecondary'}>
                      {item.description}
                    </Typography>
                  )}
                </>
              }
              className={clsx(props.fullWidthItems && classes.fullWidth)}
              control={<Radio checked={item.value === props.controller.field.value} />}
              disabled={props.disabled || item.disabled}
            />
            {item.contentBelowLabel}
          </Fragment>
        ))}

        {props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </RadioGroup>
    </FormControl>
  );

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    controllerOnChange(e);
    props.onChange?.(e.target.value);
  }
}
