import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'holar-admin',
  env: import.meta.env.VITE_HOST,
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  allowFallbackToLocalStorage: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [`https://${import.meta.env.VITE_HOST}`],
});
