import {
  makeStyles,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { X } from 'react-feather';

export type DialogProps = MuiDialogProps & {
  /**
   * Title of the MUI dialog.
   */
  title: string;
  /**
   * Actions to render on the bottom of the dialog.
   */
  actions?: JSX.Element;
  /**
   * Desired width of the dialog in CSS units.
   */
  width?: number;
  /**
   * Element to render to the left of the close button.
   */
  titleActions?: JSX.Element;
};

const useStyles = makeStyles((theme) => ({
  dialogPaper: (props: DialogProps) => ({
    flexBasis: props.width,
    maxWidth: 'unset',
  }),
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleBox: {
    maxWidth: '60%',
  },
  titleActions: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 32,
  },
  content: {
    padding: 24,
  },
  closeButton: {
    padding: 4,
    marginRight: -16,
    marginLeft: 16,
  },
}));

/**
 * Simple abstraction over the MUI dialog component that allows for fewer import
 * statements and more visual consistency across dialogs. Also adds a close
 * button on the top right.
 */
export function Dialog(props: DialogProps): React.ReactElement {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const { title, children, actions, classes: muiDialogClasses, ...muiDialogProps } = props;

  return (
    <MuiDialog
      {...muiDialogProps}
      classes={{ ...muiDialogClasses, paper: clsx(muiDialogClasses?.paper, classes.dialogPaper) }}
    >
      <DialogTitle style={{ borderBottom: '1px solid #eee' }}>
        <div className={classes.titleWrapper}>
          <div className={classes.titleBox}>{title}</div>
          <div className={classes.titleActions}>
            {props.titleActions}
            <IconButton onClick={(e) => props.onClose?.(e, 'escapeKeyDown')} className={classes.closeButton}>
              <X />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
      {actions && <DialogActions style={{ borderTop: '1px solid #eeeeee' }}>{actions}</DialogActions>}
    </MuiDialog>
  );
}
