import { Link } from '@material-ui/core';
import { DataGrid, GridColDef, GridOverlay } from '@material-ui/data-grid';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import { useState, useCallback, useMemo } from 'react';
import { debounce } from 'lodash-es';
import { StudiosDataGridQuery, useSelectAccountMutation, useStudiosDataGridLazyQuery } from 'codegen/graphql';
import { useDataGridController } from 'hooks/useDataGridController';
import { id } from 'lib/gid';
import { toast } from 'components/GlobalSnackbar';
import ErrorCard from 'components/ErrorCard';
import { StudiosDataGridToolbar } from 'components/StudiosDataGridToolbar';

type Studio = StudiosDataGridQuery['studios']['edges'][number]['node'];

export function StudiosDataGrid(): React.ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState('');
  const query = useStudiosDataGridLazyQuery({
    variables: {
      query: searchQuery,
    },
  });
  const [selectAccount] = useSelectAccountMutation();

  const STUDIOS_COLUMNS: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 130,
      renderCell: ({ value, row }) => {
        const name = value as string;
        const studioRow = row as Studio;
        return <Link onClick={() => handleStudioNameClick(studioRow)}>{name}</Link>;
      },
    },
    {
      field: 'account',
      headerName: 'Account',
      width: 200,
      renderCell: ({ value, row }) => {
        const account = value as Studio['account'];
        const studioRow = row as Studio;
        return <Link onClick={() => handleAccountNameClick(studioRow)}>{account.name}</Link>;
      },
    },
    {
      field: 'startDate',
      headerName: 'Start date',
      valueFormatter: ({ value }) => {
        const startDate = value as string;
        return startDate ? format(new Date(startDate), 'MM/dd/yyyy') : '--';
      },
    },
    {
      field: 'endDate',
      headerName: 'End date',
      valueFormatter: ({ value }) => {
        const endDate = value as string;
        return endDate ? format(new Date(endDate), 'MM/dd/yyyy') : '--';
      },
    },
    {
      field: 'photosCount',
      headerName: 'Total photos',
      width: 130,
    },
    {
      field: 'photosQuotaLimit',
      headerName: 'Photo limit',
      width: 130,
    },
  ];

  const handleSearch = useMemo(() => debounce((query: string) => setSearchQuery(query), 500), []);

  const Toolbar = useCallback(() => <StudiosDataGridToolbar onSearch={handleSearch} />, [handleSearch]);

  const dataGridController = useDataGridController({
    query,
    connectionField: 'studios',
    columns: STUDIOS_COLUMNS,
  });

  if ('error' in dataGridController) {
    return <ErrorCard body="Could not load studios. Please try refreshing the page." />;
  }

  return (
    <DataGrid
      {...dataGridController.dataGridProps}
      autoHeight
      components={{
        Toolbar,
        NoRowsOverlay: () => <GridOverlay>No studios found. Please search by name or handle.</GridOverlay>,
      }}
    />
  );

  async function handleStudioNameClick(studio: Studio) {
    try {
      await selectAccount({ variables: { snapbarId: studio.account.snapbarId } });
      history.push(`/studios/${id(studio.id)}/settings`, { preventNoAccountRedirect: true });
    } catch (e) {
      console.error(e);
      toast('Could not select new account. Please try again later.', 'error');
    }
  }

  async function handleAccountNameClick(studio: Studio) {
    try {
      await selectAccount({ variables: { snapbarId: studio.account.snapbarId } });
      history.push('/dashboard', { preventNoAccountRedirect: true });
    } catch (e) {
      console.error(e);
      toast('Could not select new account. Please try again later.', 'error');
    }
  }
}
