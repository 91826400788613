import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Scrollable } from 'components/Scrollable';
import { useRichTextStyles } from 'components/RichTextEditor';

export type RichTextPreviewProps = {
  /**
   * Class name attached to the root element.
   */
  classes?: {
    container?: string;
    body?: string;
  };
  /**
   *
   */
  content: string;
};

export function RichTextPreview(props: RichTextPreviewProps): React.ReactElement {
  const classes = useRichTextStyles();
  const { t } = useTranslation();

  return (
    <Scrollable className={props.classes?.container} maxHeight="200px">
      <div className={clsx(classes.rte, props.classes?.body)} dangerouslySetInnerHTML={{ __html: props.content }} />
    </Scrollable>
  );
}
