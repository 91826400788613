import Page from '../Page';
import { CreateTokenCard } from './CreateTokenCard';
import { TokensDataGrid } from './TokensDataGrid';
import { useGetAccessTokensQuery } from 'src/codegen/graphql';
import { UsableAccessToken } from './types';
import { useEffect, useState } from 'react';
import { toast } from 'components/GlobalSnackbar';
import { without } from 'lodash-es';

export function TokensPage(): React.ReactElement {
  const { loading: tokensLoading, data } = useGetAccessTokensQuery({
    variables: { onlyApiTokens: true },
    onError: () => toast('Error loading tokens', 'error'),
  });

  const [tokens, setTokens] = useState<UsableAccessToken[]>([]);

  const handleTokenCreate = (token: UsableAccessToken) => {
    setTokens([...tokens, token]);
  };

  const handleTokenDelete = (token: UsableAccessToken) => {
    setTokens(without(tokens, token));
  };

  useEffect(() => {
    if (!data?.accessTokens) return;
    setTokens(data.accessTokens);
  }, [data?.accessTokens]);

  return (
    <Page
      title="API Tokens"
      maxWidth="xl"
      breadcrumbs={[
        {
          body: 'Admin settings',
          to: '/admin',
        },
        {
          body: 'API Tokens',
          to: '/admin/tokens',
        },
      ]}
    >
      <CreateTokenCard onTokenCreate={handleTokenCreate} />
      <TokensDataGrid tokens={tokens} onTokenDelete={handleTokenDelete} loading={tokensLoading} />
    </Page>
  );
}
