import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export type ErrorCardProps = {
  /**
   * Body of the error card.
   */
  body: string;
};

export function ErrorCard(props: ErrorCardProps): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t('Oh no! An error has occurred.')} />
      <CardContent>
        <Typography>{props.body}</Typography>
      </CardContent>
    </Card>
  );
}
