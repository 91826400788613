import { Button, Card, CardHeader, CardContent, Chip, Typography, makeStyles } from '@material-ui/core';
import { ContactSubmissionFragment } from 'codegen/graphql';
import { Download, Eye, EyeOff } from 'react-feather';
import { SubmissionPhotos } from 'components/SubmissionPhotos';
import { format } from 'date-fns';
import { Skeleton } from '@material-ui/lab';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { PhotoExportDialog } from 'components/PhotoExportDialog';
import { usePermissions } from 'hooks/usePermissions';

export type ContactSubmissionCardProps = {
  submission: ContactSubmissionFragment;
};

const useSharedStyles = makeStyles(() => ({
  submissionHeader: {
    '& .MuiCardHeader-title': {
      fontSize: '1.25em',
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  chip: (props: ContactSubmissionCardProps) => ({
    marginLeft: '1em',
    color:
      props.submission.state === 'ACCEPTED'
        ? theme.palette.success.contrastText
        : props.submission.state === 'REJECTED'
        ? theme.palette.warning.contrastText
        : theme.palette.text.primary,
    backgroundColor:
      props.submission.state === 'ACCEPTED'
        ? theme.palette.success.main
        : props.submission.state === 'REJECTED'
        ? theme.palette.warning.main
        : theme.palette.grey[300],
  }),
  downloadButton: {
    float: 'right',
  },
  cardContent: {
    paddingTop: '0 !important',
  },
  submittedCaption: {
    color: '#546E7A',
  },
}));

export function ContactSubmissionCard(props: ContactSubmissionCardProps): React.ReactElement {
  const { submission } = props;
  const classes = useStyles(props);
  const sharedClasses = useSharedStyles();
  const perms = usePermissions();
  const [showExport, setShowExport] = useState(false);
  const submittedAt = format(new Date(submission.createdAt), 'MMMM d, yyyy');

  const hasEnhanced = !!submission.photos.find((p) => p.appliedPresetsEnhanced.length);
  const [showEnhanced, setShowEnhanced] = useState(hasEnhanced);

  return (
    <Card>
      <CardHeader
        className={sharedClasses.submissionHeader}
        title={sentenceCase(submission.studio?.name || '')}
        subheader={
          <>
            <Typography className={classes.submittedCaption} variant="caption">
              Submitted {submittedAt}
            </Typography>
            <Chip className={classes.chip} label={sentenceCase(submission.state)} />
          </>
        }
        action={
          perms.auth?.canExportSubmission ? (
            <Button startIcon={<Download />} variant="outlined" onClick={() => setShowExport(true)}>
              Export
            </Button>
          ) : (
            <></>
          )
        }
      />

      <CardContent>
        {hasEnhanced && (
          <Button
            startIcon={showEnhanced ? <Eye /> : <EyeOff />}
            onClick={() => setShowEnhanced(!showEnhanced)}
            size="small"
          >
            AI enhanced
          </Button>
        )}
        {submission && (
          <>
            <SubmissionPhotos
              photos={submission.photos}
              submissionId={submission.id}
              previewSize={250}
              poseHeadingTypographyProps={{ variant: 'overline' }}
              poseRowStyle={{ marginTop: 10 }}
              enhanced={showEnhanced}
            />
          </>
        )}
      </CardContent>
      <PhotoExportDialog
        open={showExport}
        onClose={() => setShowExport(false)}
        submissionIds={[submission.id]}
        enhanced={showEnhanced}
      />
    </Card>
  );
}

ContactSubmissionCard.Skeleton = ContactSubmissionCardSkeleton;

function ContactSubmissionCardSkeleton() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useSharedStyles();

  return (
    <Card>
      <CardHeader
        className={classes.submissionHeader}
        title={<Skeleton variant="text" width={300} />}
        subheader={<Skeleton variant="text" width={150} />}
        action={<Skeleton variant="text" height={40} width={100} />}
      />
      <CardContent>
        <SubmissionPhotos.Skeleton />
      </CardContent>
    </Card>
  );
}
