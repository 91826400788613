import { useState } from 'react';
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Send } from 'react-feather';

import { Dialog } from 'components/Dialog';
import { validateEmail } from 'lib/validateEmail';
import { useInviteContactsMutation } from 'src/codegen/graphql';
import { toast } from '../GlobalSnackbar';

export type InviteContactsDialogProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  open: boolean;
  onClose: () => void;
  /**
   * GID of the studio to invite contacts to.
   */
  studioId: string;
};

export function InviteContactsDialog(props: InviteContactsDialogProps): React.ReactElement {
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const [error, setError] = useState<null | { message: string }>(null);
  const [inviteContacts, inviteContactsState] = useInviteContactsMutation();
  const { ...menuProps } = props;

  return (
    <Dialog {...menuProps} title="Invite contacts" actions={<DialogActions />} width={600} onExited={handleExit}>
      <TextField
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        label="Contact emails (comma-separated)"
        variant="outlined"
        style={{ width: '100%' }}
        error={!!error}
        helperText={error?.message}
      />
    </Dialog>
  );

  function handleChange(newValue: string) {
    setValue(newValue);
    setError(null);
  }

  function handleExit() {
    setValue('');
    setError(null);
  }

  function DialogActions() {
    return (
      <>
        <Button onClick={props.onClose}>Close</Button>
        {value && (
          <Button
            startIcon={<Send />}
            variant="contained"
            color="primary"
            onClick={handleInvite}
            disabled={inviteContactsState.loading}
          >
            SEND INVITATION
          </Button>
        )}
      </>
    );

    async function handleInvite() {
      const emails = value.split(',').map((email) => email.trim());
      const emailsAreInvalid = emails.some((email) => !validateEmail(email));
      if (emailsAreInvalid) {
        setError({ message: 'Please enter a valid list of emails' });
        return;
      }

      let success = false;
      try {
        const { data, errors: gqlErrors } = await inviteContacts({
          variables: {
            studioId: props.studioId,
            emails,
          },
        });
        const userErrors = data?.inviteContacts?.errors;
        const invitedContacts = data?.inviteContacts?.contacts;

        if (gqlErrors?.length) {
          console.error('GraphQL errors', gqlErrors);
        } else if (userErrors?.length) {
          console.error('User errors', userErrors);
        } else if (invitedContacts?.length) {
          success = true;
        }
      } catch (networkError) {
        console.error('Network error', networkError);
      }
      props.onClose();
      success ? toast('Contacts invited', 'success') : toast('Could not invite contacts', 'error');
    }
  }
}
