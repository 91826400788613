import {
  Drawer,
  makeStyles,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
  Typography,
} from '@material-ui/core';
import { Camera, Users, Image, Layers, ChevronDown, ChevronUp } from 'react-feather';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useBreakpoint from 'hooks/useBreakpoint';
import CurrentUserAvatar from 'components/CurrentUserAvatar';
import { ReviewChip } from 'components/ReviewChip';
import { usePermissions } from 'hooks/usePermissions';
import { BookOutlined, HelpOutline } from '@material-ui/icons';

export type AppWrapperDrawerProps = {
  /**
   * Classes applied to the Paper child of the drawer.
   */
  className?: string;
  /**
   * Toggles opening of the drawer on screen widths below the `md` breakpoint.
   * On desktop, the drawer is permanently open.
   */
  open: boolean;
  /**
   * Callback executed when drawer closes (should set open to `false`).
   */
  onClose: () => void;
};

const GREY = '#546E7A';

const listStyles = {
  paddingBottom: 0,
  marginBottom: 16,
  '&:last-of-type': {
    paddingBottom: 0,
    marginBottom: 0,
  },
  '& .MuiListSubheader-root': {
    lineHeight: 'unset',
    fontSize: '0.75rem',
    letterSpacing: '0.06rem',
  },
  '& .MuiListItemText-root > .MuiTypography-root': {
    fontWeight: 500,
  },
};

const collapsedListStyles = {
  '& .MuiListItem-root': {
    paddingLeft: 57,
  },
  '& .MuiListItemText-root > .MuiTypography-root': {
    fontWeight: 400,
  },
};

const useStyles = makeStyles(() => ({
  drawerPaper: {
    zIndex: 1099,
    color: GREY,
    justifyContent: 'space-between',
    '& .MuiList-root': {
      ...listStyles,
    },
    '& .MuiCollapse-container .MuiList-root': {
      ...collapsedListStyles,
    },
    '& .MuiTypography-root': {
      fontSize: '0.875rem',
    },
  },
  drawerBottomHalf: {
    justifySelf: 'end',
    '& .MuiDivider-root': {
      marginBottom: 5,
    },
    '& #copyright-notice': {
      padding: '6px 6px 6px 20px',
      fontSize: '0.75rem',
      letterSpacing: '0.83px',
    },
  },
}));

/**
 * The side drawer in the AppWrapper.
 */
export function AppWrapperDrawer(props: AppWrapperDrawerProps): JSX.Element | null {
  const classes = useStyles();
  const hidden = useBreakpoint();
  const [contactsDropdownOpen, setContactsDropdownOpen] = useState(false);
  const { t } = useTranslation();

  const perms = usePermissions();

  if (perms.loading) {
    return null;
  }

  if (perms.error) {
    return null;
  }

  const { auth } = perms;

  return (
    <Drawer
      variant={hidden ? 'temporary' : 'permanent'}
      anchor="left"
      open={props.open}
      onClose={props.onClose}
      className="AppWrapperDrawer"
      classes={{ paper: clsx(props.className, classes.drawerPaper) }}
    >
      <div>
        <CurrentUserAvatar />
        <Divider style={{ marginBottom: 16 }} />
        <List subheader={<ListSubheader>MANAGE</ListSubheader>}>
          <ListItem button component={Link} to="/studios" disabled={!auth.canViewStudio}>
            <ListItemIcon>
              <Camera />
            </ListItemIcon>
            <ListItemText>Studios</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/presets" disabled={!auth.canViewPreset}>
            <ListItemIcon>
              <Layers />
            </ListItemIcon>
            <ListItemText>Outputs</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setContactsDropdownOpen(!contactsDropdownOpen);
            }}
          >
            <ListItemIcon>
              <Users />
            </ListItemIcon>
            <ListItemText>Contacts</ListItemText>
            {contactsDropdownOpen ? <ChevronUp /> : <ChevronDown />}
          </ListItem>
          <Collapse in={contactsDropdownOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/contacts" disabled={!auth.canViewContact}>
                <ListItemText>Lists</ListItemText>
              </ListItem>
              {perms.staff && (
                <ListItem button component={Link} to="/contacts/attributes">
                  <ListItemText>Attributes</ListItemText>
                </ListItem>
              )}
            </List>
          </Collapse>
          <ListItem button component={Link} to="/submissions/all" disabled={!auth.canViewSubmission}>
            <ListItemIcon>
              <Image />
            </ListItemIcon>
            <ListItemText>
              Submissions
              {auth.canViewSubmission && <ReviewChip />}
            </ListItemText>
          </ListItem>
        </List>
      </div>

      <div className={classes.drawerBottomHalf}>
        <ListItem button component={Link} to="/resources">
          <ListItemIcon>
            <BookOutlined />
          </ListItemIcon>
          <ListItemText>Resources</ListItemText>
        </ListItem>
        <ListItem button component="a" href="https://help.snapbar.com/en/collections/1062589-studio" target="_blank">
          <ListItemIcon>
            <HelpOutline />
          </ListItemIcon>
          <ListItemText>Help desk</ListItemText>
        </ListItem>
        <Divider />
        <Typography variant="body2" id="copyright-notice">
          Copyright {new Date().getFullYear()} The SnapBar, LLC
        </Typography>
      </div>
    </Drawer>
  );
}
