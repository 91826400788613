import Page from '../Page';
import { useTranslation } from 'react-i18next';
import { makeStyles, Paper } from '@material-ui/core';
import { useHistory } from 'react-router';
import PageActionButton from 'components/PageActionButton';
import { PresetsDataGrid } from 'src/components/PresetsDataGrid/PresetsDataGrid';
import { useGetPresetOverviewsQuery } from 'codegen/graphql';
import LoadingCard from 'components/LoadingCard';
import LoadingPage from 'pages/LoadingPage';
import ErrorCard from 'components/ErrorCard';
import { usePermissions } from 'hooks/usePermissions';
import { id } from 'lib/gid';

export function Presets(): React.ReactElement {
  const { t } = useTranslation();
  const { loading, error, data } = useGetPresetOverviewsQuery();
  const history = useHistory();
  const perms = usePermissions();

  if (loading || perms.loading) {
    return (
      <LoadingPage>
        <LoadingCard />
      </LoadingPage>
    );
  }

  if (error || !data?.presets || perms.error) {
    return (
      <Page title={t('preset_plural_capital')}>
        <ErrorCard body={`Could not load ${t('preset_plural')}.`} />
      </Page>
    );
  }

  return (
    <Page
      title={t('preset_plural_capital')}
      pageActions={
        perms.auth.canAddPreset && <PageActionButton to="/presets/new">{t('presetsRoute:addPreset')}</PageActionButton>
      }
    >
      <Paper>
        <PresetsDataGrid
          presets={data.presets}
          onRowClick={
            perms.auth.canChangePreset ? (presetId) => history.push(`/presets/${id(presetId)}/edit`) : undefined
          }
        />
      </Paper>
    </Page>
  );
}
