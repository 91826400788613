import { Card, CardHeader, CardContent, Typography, makeStyles, Icon, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export type SettingsCardProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * Icon to render in the top left corner.
   */
  icon?: JSX.Element;
  /**
   * Title of the settings card.
   */
  title: string;
  /**
   * Action buttons to render in the top right corner.
   */
  cardActions?: React.ReactNode;
  contentPadding?: boolean;
  /**
   * Content of the card to render.
   */
  children: React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  header: {
    '& span': {
      display: 'flex',
      '& > *:first-child': {
        marginRight: '0.8rem',
      },
    },
    '& span.MuiIconButton-label': {
      '& > svg': {
        marginRight: 0,
      },
    },
    padding: '0 1rem !important',
    height: '3.6rem',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[300],
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    '& p': {
      height: '1em',
    },
  },
  content: (props: SettingsCardProps) => ({
    padding: props.contentPadding !== false ? '1.2rem 1.2rem !important' : '0 !important',
    '& > .MuiTypography-overline': {
      display: 'block',
      lineHeight: 1,
      marginBottom: '0.5rem',
    },
  }),
}));

export const tableStyles = {
  labelColumn: {
    color: 'rgb(69, 90, 100)',
    fontSize: '14px',
    letterSpacing: '0.35px',
  },
  valueColumn: {
    fontSize: '14px',
    letterSpacing: '0.09px',
  },
};

export function SettingsCard(props: SettingsCardProps): React.ReactElement {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <>
            {props.icon}
            <Typography className={classes.title}>{props.title}</Typography>
          </>
        }
        action={props.cardActions}
        className={classes.header}
      />
      <CardContent className={classes.content}>{props.children}</CardContent>
    </Card>
  );
}
