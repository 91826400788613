import { useState, useCallback, useRef } from 'react';
import { makeStyles, ButtonBase, Menu, MenuItem, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { useGetCurrentUserQuery } from 'codegen/graphql';
import { usePermissions } from 'hooks/usePermissions';

export type UserButtonProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  accountSelected: boolean;
};

const useStyles = makeStyles(() => ({
  button: {
    padding: 8,
    marginRight: 8,
    borderRadius: 16,
  },
  name: {
    marginLeft: 8,
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

export function UserButton({ accountSelected }: UserButtonProps): React.ReactElement {
  const perms = usePermissions();
  const { auth } = perms;
  const classes = useStyles();
  const { t } = useTranslation();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const openMenu = useCallback(() => setUserMenuOpen(true), []);
  const closeMenu = useCallback(() => setUserMenuOpen(false), []);

  const handleLogout = useCallback(() => window.location.replace('/logout'), []);

  const { data } = useGetCurrentUserQuery();
  const name = data?.currentUser ? `${data.currentUser.firstName} ${data.currentUser.lastName}`.trim() : '';

  return (
    <>
      <ButtonBase ref={anchorRef} onClick={openMenu} className={classes.button}>
        <AccountCircle />
        {name && <Typography className={classes.name}>{name}</Typography>}
      </ButtonBase>
      <Menu
        open={userMenuOpen}
        onClose={closeMenu}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {accountSelected && auth?.canViewSubmission && auth.canViewStudio && (
          <MenuItem component="a" href="/user/settings/notifications">
            Notification settings
          </MenuItem>
        )}

        <MenuItem component="a" href="https://help.snapbar.com/en/collections/1062589-studio" target="_blank">
          Help desk
        </MenuItem>

        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}
