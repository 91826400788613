import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles, Paper, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useForm, useController } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Save, Trash } from 'react-feather';
import { useApolloClient } from '@apollo/client';
import { get } from 'lodash-es';

import Page from 'pages/Page';
import {
  UpdateContactInput,
  GetContactDocument,
  ContactFormPageDocument,
  useDeleteContactsMutation,
  useUpdateContactMutation,
  ContactFormPageQuery,
  GetContactQuery,
  useCreateContactMutation,
  useGetContactAttributesQuery,
  UserError,
  GetContactAttributesQuery,
  GetContactAttributesQueryResult,
} from 'codegen/graphql';
import TextField from 'components/TextField';
import { gid } from 'lib/gid';
import { handleMutation } from 'lib/handleMutation';
import TwoCardGrid from 'components/TwoCardGrid';
import { toast } from 'components/GlobalSnackbar';
import { validateEmail } from 'lib/validateEmail';
import CheckboxField, { CheckboxItem } from 'components/CheckboxField';
import { usePermissions } from 'hooks/usePermissions';
import { FormErrors } from 'components/FormErrors/FormErrors';

type ContactFormPageProps = {
  /**
   * Model ID of the contact to edit. If not provided, it is assumed we are
   * creating a new contact.
   */
  id?: string;
  /**
   * Model ID of the contact list a newly created contact should belong to by
   * default.
   */
  selectedListId?: string;
};
const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
    marginTop: '1em',
  },
  deleteButton: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  contactListsField: {
    '& .MuiFormControlLabel-root': {
      width: '100%',
    },
  },
}));

type ContactFormType = Pick<UpdateContactInput, 'firstName' | 'lastName' | 'email' | 'phone' | 'customAttributes'> & {
  contactListIds: Record<string, boolean>;
  serverError?: string;
};

export function ContactFormPage(props: ContactFormPageProps): React.ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const perms = usePermissions();

  const id = props.id ? gid(props.id, 'Contact') : null;
  const selectedListId =
    !props.selectedListId || props.selectedListId === 'all' ? null : gid(props.selectedListId, 'ContactList');
  const [loading, setLoading] = useState(false);
  const [contactListCheckboxItems, setContactListCheckboxItems] = useState<CheckboxItem[]>([]);
  const [showErrors, setShowErrors] = useState(false);
  const client = useApolloClient();

  const { data: attributesQuery } = useGetContactAttributesQuery();
  const [createContact, createContactState] = useCreateContactMutation();
  const [updateContact, updateContactState] = useUpdateContactMutation();
  const [deleteContact, deleteContactState] = useDeleteContactsMutation();

  const cached = client.readQuery<GetContactQuery>({ query: GetContactDocument, variables: { id } });
  const name = [cached?.contact?.firstName, cached?.contact?.lastName].join(' ').trim();
  const email = cached?.contact?.email?.trim();

  const { reset, control, trigger, getValues, clearErrors, setError, formState } = useForm<ContactFormType>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      customAttributes: {},
      contactListIds: {},
    },
  });
  const firstNameController = useController({ control, name: 'firstName' });
  const lastNameController = useController({ control, name: 'lastName' });
  const emailController = useController({
    control,
    name: 'email',
    rules: { validate: (email) => (email && validateEmail(email)) || 'Must provide a valid email' },
  });
  const phoneController = useController({ control, name: 'phone' });
  const contactListIdsController = useController({ control, name: 'contactListIds' });
  const customAttributesController = useController({ control, name: 'customAttributes' });

  // when we receive existing contact data, fill out the form
  useEffect(() => {
    async function hydrateForm() {
      setLoading(true);

      // first, always fetch existing contact lists
      const { data: contactFormPageData } = await client.query<ContactFormPageQuery>({
        query: ContactFormPageDocument,
      });

      if (!contactFormPageData?.contactLists) {
        toast('Could not load contact lists', 'error');
        setLoading(false);
        return;
      }
      const { contactLists } = contactFormPageData;

      const newForm: ContactFormType = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        customAttributes: {},
        contactListIds: {},
      };

      // if id was provided, query for existing contact and hydrate contact fields
      if (id) {
        const { data: existingContactData } = await client.query<GetContactQuery>({
          query: GetContactDocument,
          variables: { id },
        });
        if (!existingContactData.contact) {
          toast('Could not load existing contact data', 'error');
          setLoading(false);
          return;
        }
        const { contact } = existingContactData;
        newForm.firstName = contact.firstName;
        newForm.lastName = contact.lastName;
        newForm.email = contact.email;
        newForm.phone = contact.phone;
        newForm.customAttributes = contact.customAttributes;

        for (const list of contactLists) {
          newForm.contactListIds[list.id] = contact.contactLists.map((list) => list.id).includes(list.id);
        }
      }

      // if specified in props, check the selected list id
      for (const list of contactLists) {
        newForm.contactListIds[list.id] ||= list.id === selectedListId;
      }

      reset(newForm); // important: run this before setContactListCheckboxItems to prevent flash of uncontrolled inputs
      setContactListCheckboxItems(contactLists.map((list) => ({ key: list.id, label: list.name })));
      setLoading(false);
    }
    hydrateForm();
    // only run on initial render
    // eslint-disable-next-line
  }, []);

  const customContactAttributes = attributesQuery?.contactAttributes.filter((attr) => attr.custom) || [];

  return (
    <Page
      title={props.id ? 'Edit contact' : 'New contact'}
      breadcrumbs={[
        {
          to: '/contacts/lists/all',
          body: 'Contacts',
        },
        ...(props.id
          ? [
              {
                to: `/contacts/${props.id}/view`,
                body: name || email || 'Unnamed contact',
              },
              {
                to: `/contacts/${props.id}/edit`,
                body: 'Edit',
              },
            ]
          : [
              {
                to: '/contacts/new',
                body: 'New',
              },
            ]),
      ]}
      pageActions={
        <>
          {id && perms.auth?.canDeleteContact && (
            <Button
              className={classes.deleteButton}
              variant="contained"
              onClick={() => handleDelete(id)}
              disabled={deleteContactState.loading}
              startIcon={<Trash />}
            >
              {deleteContactState.loading ? 'DELETING...' : 'DELETE'}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => (id ? handleUpdate(id) : handleCreate())}
            disabled={loading || updateContactState.loading || createContactState.loading}
            startIcon={<Save />}
          >
            {updateContactState.loading ? 'SAVING...' : 'SAVE'}
          </Button>
        </>
      }
    >
      <TwoCardGrid>
        <div>
          <Paper className={classes.paper}>
            {showErrors && <FormErrors errors={formState.errors} />}
            {loading || perms.loading ? (
              <ContactAttributesSkeleton />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField variant="outlined" fullWidth label="First name" controller={firstNameController} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField variant="outlined" fullWidth label="Last name" controller={lastNameController} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField variant="outlined" fullWidth label="Email" controller={emailController} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField variant="outlined" fullWidth label="Phone" controller={phoneController} />
                </Grid>
              </Grid>
            )}
          </Paper>

          {customContactAttributes?.length > 0 ? (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                {customContactAttributes.map((attribute) => {
                  const error = get(formState.errors, `customAttributes.${attribute.key}`);

                  return (
                    <Grid key={attribute.key} item xs={12} lg={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label={attribute.key}
                        controller={
                          // This is gross, wish I could make field controllers on the fly
                          {
                            fieldState: showErrors ? { error } : {},
                            field: {
                              // eslint-disable-next-line @typescript-eslint/no-empty-function
                              ref: () => {},
                              name: attribute.key,
                              value: customAttributesController.field.value?.[attribute.key],
                              // eslint-disable-next-line @typescript-eslint/no-empty-function
                              onBlur: () => {},
                              onChange: makeChangeHandler(attribute),
                            },
                          } as any
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>
          ) : (
            <></>
          )}
        </div>

        <Paper className={classes.paper}>
          <CheckboxField
            className={classes.contactListsField}
            label="Contact lists"
            multiple
            controller={contactListIdsController}
            items={contactListCheckboxItems}
          />
        </Paper>
      </TwoCardGrid>
    </Page>
  );

  function makeChangeHandler(attribute: GetContactAttributesQuery['contactAttributes'][number]) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      customAttributesController.field.onChange({
        ...customAttributesController.field.value,
        [attribute.key]: value,
      });

      if (attribute.validation) {
        const { regex, errorMessage } = attribute.validation;
        const fieldPath = `customAttributes.${attribute.key}` as const;

        const isValid = value === '' || new RegExp(regex).test(value);

        if (isValid) {
          clearErrors(fieldPath);
          return;
        }

        setError(fieldPath, { message: errorMessage || 'Is invalid' });
      }
    };
  }

  async function handleCreate() {
    setShowErrors(true);
    if (!(await trigger())) return;

    handleMutation({
      mutate: () => {
        const form = getValues();
        return createContact({
          variables: {
            input: {
              firstName: form.firstName?.trim(),
              lastName: form.lastName?.trim(),
              email: form.email?.trim(),
              phone: form.phone?.trim(),
              customAttributes: form.customAttributes,
              contactListIds: Object.entries(form.contactListIds)
                .filter(([, value]) => value)
                .map(([contactListId]) => contactListId),
            },
          },
        });
      },
      payloadFieldPath: 'createContact?',
      successCondition: (data) => !!data.createContact?.contact?.id,
      onSuccess: () => {
        toast('Contact created', 'success');
        history.push('/contacts');
        setShowErrors(false);
      },
      onError: (errors) => {
        handleUserErrors(errors);
      },
    });
  }

  async function handleUpdate(id: string) {
    setShowErrors(true);
    if (!(await trigger())) return;

    handleMutation({
      mutate: () => {
        const form = getValues();
        return updateContact({
          variables: {
            input: {
              id,
              firstName: form.firstName?.trim(),
              lastName: form.lastName?.trim(),
              email: form.email?.trim(),
              phone: form.phone?.trim(),
              customAttributes: form.customAttributes,
              contactListIds: Object.entries(form.contactListIds)
                .filter(([, value]) => value)
                .map(([contactListId]) => contactListId),
            },
          },
        });
      },
      payloadFieldPath: 'updateContact?',
      successCondition: (data) => !!data.updateContact?.contact?.id,
      onSuccess: () => {
        toast('Contact updated', 'success');
        setShowErrors(false);
      },
      onError: (errors) => {
        handleUserErrors(errors);
      },
    });
  }

  async function handleDelete(id: string) {
    handleMutation({
      mutate: () => deleteContact({ variables: { ids: [id] } }),
      payloadFieldPath: 'deleteContacts?',
      successCondition: (data) => data.deleteContacts?.deletedIds?.[0] === id,
      // eslint-disable-next-line
      confirm: "Are you sure you'd like to delete this contact? Submissions for this contact will also be deleted.",
      onSuccess: () => {
        history.push('/contacts/lists');
        toast('Contact deleted', 'success');
      },
      onError: () => {
        toast('Could not delete contact', 'error');
      },
    });
  }

  function handleUserErrors(errors: UserError[]) {
    errors.forEach((error) => {
      const path = error.path && error.path[0];

      // Probably unnecessary?
      if (path?.startsWith('customAttributes.')) {
        const key = path.replace('customAttributes.', '');
        const attribute = attributesQuery?.contactAttributes.find((attribute) => attribute.key === key);

        setError(path as `customAttributes.${string}`, { message: attribute?.validation?.errorMessage || '' });
      } else {
        switch (path) {
          case 'firstName':
          case 'lastName':
          case 'email':
            setError(path, { message: error.message });
            break;
          default:
            setError('serverError', { message: error.message });
        }
      }
    });
  }
}

function ContactAttributesSkeleton() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Skeleton height={56} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Skeleton height={56} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Skeleton height={56} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Skeleton height={56} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Skeleton height={56} />
      </Grid>
    </Grid>
  );
}
