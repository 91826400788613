import { Card, CardContent, Typography, Link, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Control, useController } from 'react-hook-form';
import { TextField } from 'components/TextField';
import { FilterFormType } from 'pages/FilterFormPage/FilterFormPage';

const useStyles = makeStyles(() => ({
  content: {
    '& > *:not(:last-child)': {
      marginBottom: 16,
    },
  },
}));

export type FilterFormCardProps = {
  formControl: Control<FilterFormType>;
};

export function FilterFormCard(props: FilterFormCardProps): JSX.Element {
  const { formControl } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const nameController = useController({ name: 'name', control: formControl });
  const artisticFilterController = useController({ name: 'cloudinaryArtisticFilter', control: formControl });
  const transformController = useController({ name: 'cloudinaryTransform', control: formControl });

  return (
    <Card>
      <CardContent className={classes.content}>
        <TextField label={t('$t(filter_capital) $t(name)')} variant="outlined" fullWidth controller={nameController} />
        <Typography variant="button">Cloudinary {t('transformation_plural')}</Typography>
        <Typography variant="body2">
          {t('filtersRoute:transformationsDesc')}{' '}
          <Link href="https://cloudinary.com/documentation/transformation_reference" target="_blank">
            See reference
          </Link>
        </Typography>
        <TextField
          label={t('Cloudinary $t(artistic) $t(filter)')}
          variant="outlined"
          fullWidth
          controller={artisticFilterController}
        />
        <TextField
          label={t('Cloudinary $t(transform)')}
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          controller={transformController}
        />
      </CardContent>
    </Card>
  );
}
