import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Tooltip } from '@material-ui/core';
import { DataGrid, GridColDef, GridValueFormatterParams, GridRowParams, GridCellParams } from '@material-ui/data-grid';
import { Eye, EyeOff } from 'react-feather';
import LoadingCard from 'components/LoadingCard';
import LoadingPage from 'pages/LoadingPage';
import { usePermissions } from 'hooks/usePermissions';
import { Preset as CompletePreset } from 'codegen/graphql';
import { PhotoSizeNames } from 'src/models/Preset';
import BadImage from 'assets/BadImage.png';

type Preset = Pick<CompletePreset, 'id' | 'name' | 'resize' | 'default' | 'sampleUrl' | 'visibleToContact'>;

type PresetsDataGridProps = {
  presets: Preset[];
  onRowClick?: (presetId: string) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    border: 0,
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    '& .MuiDataGrid-row:last-child': {
      borderRadius: '0 0 16px 16px',
    },
    '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {
      borderBottom: 0,
    },
    '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
  },
  presetName: {
    marginLeft: 8,
  },
  previewImg: {
    height: 75,
  },
}));

export function PresetsDataGrid(props: PresetsDataGridProps): React.ReactElement {
  const { t } = useTranslation();
  const perms = usePermissions();
  const classes = useStyles();

  if (perms.loading) {
    return (
      <LoadingPage>
        <LoadingCard />
      </LoadingPage>
    );
  }

  const PresetName = (params: GridCellParams) => (
    <Typography noWrap className={classes.presetName}>{`${params.row.name}${
      params.row.default ? ' (default)' : ''
    }`}</Typography>
  );

  const SampleImage = (params: GridCellParams) => {
    return <img className={classes.previewImg} src={params.row.sampleUrl || BadImage} alt="sample" />;
  };

  const ImageSize = (params: GridValueFormatterParams) => {
    const getPhotoSize = (): 'THUMB' | 'SMALL' | 'MEDIUM' | 'LARGE' => {
      return params.row.resize;
    };
    return <Typography>{`${PhotoSizeNames[getPhotoSize()]}`}</Typography>;
  };

  const VisibilityIcon = (params: GridCellParams) => {
    return (
      <Tooltip title={`${params.row.visibleToContact ? 'Visible' : 'Hidden'} in studio`}>
        {params.row.visibleToContact ? <Eye size={18} /> : <EyeOff opacity={0.5} size={18} />}
      </Tooltip>
    );
  };

  const rows = props.presets;

  const handleRowClick = (params: GridRowParams) => {
    if (props.onRowClick) props.onRowClick(params.row.id);
  };

  const PRESETS_DATA_COLUMNS: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      renderCell: PresetName,
    },
    {
      field: 'sampleUrl',
      headerName: 'Sample',
      sortable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: SampleImage,
    },
    {
      field: 'resize',
      headerName: 'Size',
      sortable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: ImageSize,
    },
    {
      field: 'visibleToContact',
      headerName: 'Visibility',
      sortable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: VisibilityIcon,
    },
  ];

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      hideFooter
      rows={rows}
      columns={PRESETS_DATA_COLUMNS}
      rowHeight={108}
      onRowClick={handleRowClick}
      className={classes.root}
    />
  );
}
