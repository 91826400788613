import { makeStyles, Card, CardHeader, CardContent, Divider, Grid, Button, Typography } from '@material-ui/core';

export type DashboardSubmissionsCardProps = {
  // add props here
};

const useStyles = makeStyles((theme) => ({
  cardColumnGrid: {
    textAlign: 'center',
    '& > .MuiGrid-item:not(:last-child)': {
      borderRight: '1px solid rgb(232, 234, 246)',
    },
  },
  cardColumnValue: {
    fontSize: 54,
  },
  cardColumnDesc: {
    fontSize: 14,
    color: '#546E7A',
    textTransform: 'uppercase',
  },
}));

export function DashboardSubmissionsCard(props: DashboardSubmissionsCardProps): React.ReactElement {
  const classes = useStyles();

  const ViewAllButton = (
    <Button color="primary">
      <Typography variant="body2">VIEW ALL</Typography>
    </Button>
  );

  return (
    <Card>
      <CardHeader title="Recent submissions" action={ViewAllButton} />
      <Divider />
      <CardContent>
        <Grid container className={classes.cardColumnGrid}>
          <Grid item xs={4}>
            <Typography className={classes.cardColumnValue}>3</Typography>
            <Typography className={classes.cardColumnDesc}>Today</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.cardColumnValue}>45</Typography>
            <Typography className={classes.cardColumnDesc}>Last 7 days</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.cardColumnValue}>231</Typography>
            <Typography className={classes.cardColumnDesc}>Last 30 days</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
