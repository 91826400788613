import { useHistory, useLocation } from 'react-router';
import { useEffect, useMemo, useState } from 'react';

type UseQueryStringOpts = {
  clear?: boolean;
};

export function useQueryString<Key extends string>(keys: Key[], opts: UseQueryStringOpts = {}): Record<Key, string> {
  const location = useLocation();
  const history = useHistory();

  const [queryStringUpdate, setQueryStringUpdate] = useState<string | null>(null);

  const params = new URLSearchParams(location.search);

  const dict = keys.reduce((queryStringDict: Record<string, string>, key) => {
    queryStringDict[key] = params.get(key) || '';

    if (opts.clear) {
      params.delete(key);
    }

    return queryStringDict;
  }, {});

  const newQueryString = params.toString();

  useEffect(() => {
    if (newQueryString !== location.search) {
      history.replace({
        search: params.toString(),
      });
    }
  }, [newQueryString, location]);

  return dict;
}
