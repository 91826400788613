import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import Page, { Breadcrumb } from 'pages/Page';
import PageActionButton from 'components/PageActionButton';
import LoadingPage from 'pages/LoadingPage';
import LoadingCard from 'components/LoadingCard';
import PoseFormDialog from 'components/PoseFormDialog';
import { PoseCard } from 'components/PoseCard';
import { PosePresetsCard } from 'components/PosePresetsCard';
import useDialog from 'hooks/useDialog';
import { handleMutation } from 'lib/handleMutation';
import { toast } from 'components/GlobalSnackbar';
import { useGetStudioDetailsQuery } from 'codegen/graphql';

import {
  useCreatePoseMutation,
  PoseDetailsFragment,
  useUpdatePoseMutation,
  CloudFileInput,
  usePosesPageQuery,
  BackgroundInput,
  AddPoseInput,
} from 'codegen/graphql';
import { id, gid } from 'lib/gid';
import { usePermissions } from 'hooks/usePermissions';

export type PosesPageProps = {
  /**
   * Model ID of the studio these poses belong to.
   */
  studioId: string;
};

const MIN_BACKDROP_COUNT = 2;

export type PoseFormState = {
  name: string;
  guidelines: string;
  removeBackground: 'enabled' | 'enabled-with-backdrops' | 'disabled';
  allowPhotoUpload: boolean;
  backdrops: BackgroundInput[];
} & (
  | {
      profileId: string;
      // cannot define as `null` because fields that are properties of a
      // parent field require parent to be initialized as an object.
      customProfile: {
        exampleImages: null;
        outlineImage: null;
      };
    }
  | {
      profileId: null;
      customProfile: {
        exampleImages: CloudFileInput[];
        outlineImage: CloudFileInput | null;
      };
    }
);

const useStyles = makeStyles({
  tabs: {
    marginBottom: 12,
    borderBottom: '1px solid #e8e8e8',
  },
});

/**
 * The poses page component. This directly controls the `PoseFormDialog`
 * component, which is used to create and update poses.
 */
export function PosesPage(props: PosesPageProps): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();
  const studioId = gid(props.studioId, 'Studio');
  const perms = usePermissions();

  const [formIntent, setFormIntent] = useState<'EDIT' | 'CREATE'>('CREATE');

  const queryState = usePosesPageQuery({ variables: { studioId } });
  const studio = queryState.data?.studio;

  const [createPose, createPoseState] = useCreatePoseMutation();
  const [updatePose, updatePoseState] = useUpdatePoseMutation();
  const defaultPoseForm: PoseFormState = useMemo(() => {
    return {
      name: '',
      guidelines: '<p></p>',
      // default to selecting first pose profile
      profileId: queryState?.data?.poseProfiles?.[0]?.id || '',
      custom: false,
      customProfile: {
        exampleImages: null,
        outlineImage: null,
      },
      message: '',
      removeBackground: 'enabled',
      backdrops: [],
      allowPhotoUpload: true,
    };
  }, [queryState?.data?.poseProfiles]);

  const { control, reset, getValues, trigger, formState } = useForm<PoseFormState>({
    defaultValues: defaultPoseForm,
  });

  // reset to default values when default values are updated. sadly, this seems
  // to be the only way to get async default values, since default values get
  // deep-copied by value into form state on initial render. see
  // https://github.com/react-hook-form/react-hook-form/issues/2492.
  //
  // tl;dr don't update default values while the user is in the form. this is OK
  // here because we're not showing any action buttons until the query resolves.
  useEffect(() => {
    reset(defaultPoseForm);
  }, [defaultPoseForm, reset]);

  const [tabIndex, setTabIndex] = useState(0);
  const { openDialog, closeDialog, dialogProps } = useDialog();
  const { loading, error, data } = useGetStudioDetailsQuery({ variables: { id: studioId } });

  // update tab index every time pose gets deleted
  useEffect(() => {
    if (queryState.data?.studio && queryState.data.studio.poses.length <= tabIndex) {
      setTabIndex(0);
    }
  }, [queryState, tabIndex]);

  if (queryState.loading) {
    return (
      <LoadingPage>
        <LoadingCard />
      </LoadingPage>
    );
  }

  if (queryState.error || !queryState.data || !studio) {
    console.error(queryState.error);
    return <></>;
  }

  const pageAction = <PageActionButton onClick={openAddPoseDialog}>Add pose</PageActionButton>;

  const breadcrumbs: Breadcrumb[] = [
    {
      to: '/studios',
      body: t('studio_plural_capital'),
    },
    {
      to: `/studios/${id(studioId)}/settings`,
      body: `${data?.studio?.name}`,
    },
    {
      to: `/studios/${id(studioId)}/poses`,
      body: 'Poses',
    },
  ];

  const poses = studio.poses;
  const currentPose = studio.poses[tabIndex];

  return (
    <>
      <Page title="Poses" pageActions={perms.staff && pageAction} breadcrumbs={breadcrumbs}>
        <Tabs className={classes.tabs} value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
          {poses.map((pose, index) => (
            <Tab label={pose.name} key={index} />
          ))}
        </Tabs>
        {currentPose && (
          <>
            <PoseCard
              pose={currentPose}
              studioId={studioId}
              onEdit={openEditPoseDialog}
              disableDelete={poses.length === 1}
            />
            <PosePresetsCard
              poseId={currentPose.id}
              studioId={studioId}
              appliedPresets={currentPose.presets}
              presets={queryState.data.presets}
            />
          </>
        )}
      </Page>
      <PoseFormDialog
        {...dialogProps}
        onExited={handleExited}
        title={formIntent === 'CREATE' ? 'New pose' : 'Edit pose'}
        poseProfiles={queryState.data.poseProfiles}
        presets={queryState.data.presets}
        formState={formState}
        control={control}
        onSubmit={formIntent === 'CREATE' ? handleAddPose : handleUpdatePose}
        loading={createPoseState.loading || updatePoseState.loading}
        savedGuidelines={
          // need to check for nullish currentPose since it can be briefly
          // `undefined` when deleting last pose in a studio
          formIntent === 'CREATE' || !currentPose?.guidelines ? defaultPoseForm.guidelines : currentPose.guidelines
        }
      />
    </>
  );

  function handleExited() {
    // when dialog exits, reset to blank default form
    reset(defaultPoseForm);
  }

  function openAddPoseDialog() {
    setFormIntent('CREATE');
    reset(defaultPoseForm);
    openDialog();
  }

  function openEditPoseDialog() {
    setFormIntent('EDIT');
    reset(toPoseFormState(currentPose));
    openDialog();
  }

  async function handleAddPose() {
    const form = getValues();
    if (!studio) return;
    if (!(await trigger())) return;

    const variables = {
      input: {
        studioId: studio.id,
        ...toPoseInput(form),
      },
    };

    await handleMutation({
      mutate: () => createPose({ variables }),
      payloadFieldPath: 'addPose?',
      successCondition: (data) => !!data.addPose?.pose?.id && data.addPose.errors.length === 0,
      onSuccess: () => toast('Pose successfully added', 'success'),
      onError: () => toast('Could not add pose', 'error'),
    });
    closeDialog();
  }

  async function handleUpdatePose() {
    const form = getValues();
    if (!studio) return;
    if (!(await trigger())) return;

    const variables = {
      input: {
        id: currentPose.id,
        studioId: studio.id,
        ...toPoseInput(form),
      },
    };

    await handleMutation({
      mutate: () => updatePose({ variables }),
      payloadFieldPath: 'updatePose?',
      successCondition: (data) => !!data.updatePose?.pose?.id && data.updatePose.errors.length === 0,
      onSuccess: () => toast('Pose successfully updated', 'success'),
      onError: () => toast('Could not update pose', 'error'),
    });
    closeDialog();
  }
}

/**
 * Function that maps pose data received from the backend to the internal form
 * state expected by `PoseFormDialog`.
 */
function toPoseFormState(pose: PoseDetailsFragment): PoseFormState {
  let removeBackground: PoseFormState['removeBackground'] = pose.removeBackground ? 'enabled' : 'disabled';
  if (removeBackground === 'enabled' && pose.backdrops?.length) {
    removeBackground = 'enabled-with-backdrops';
  }

  const backdrops: PoseFormState['backdrops'] =
    pose.backdrops?.map((backdrop) => ({
      image: backdrop.__typename == 'CloudFile' ? backdrop : null,
      color: backdrop.__typename == 'Color' ? backdrop.hex : null,
    })) ?? [];

  const profileProps = pose.profile.id
    ? {
        profileId: pose.profile.id,
        customProfile: {
          exampleImages: null,
          outlineImage: null,
        },
      }
    : {
        profileId: null,
        customProfile: {
          exampleImages: pose.profile.exampleImages,
          outlineImage: pose.profile.outlineImage ?? null,
        },
      };

  return {
    ...pose,
    removeBackground,
    backdrops,
    ...profileProps,
  };
}

/**
 * Maps pose form state to pose input expected from the backend on pose create
 * and update. Does not include `studioId` or `poseId`.
 */
function toPoseInput(form: PoseFormState): Omit<AddPoseInput, 'studioId'> {
  const profileProps = form.profileId
    ? {
        customProfile: null,
        profileId: form.profileId,
      }
    : {
        customProfile: form.customProfile,
        profileId: null,
      };

  return {
    name: form.name,
    guidelines: form.guidelines,
    removeBackground: form.removeBackground === 'enabled' || form.removeBackground === 'enabled-with-backdrops',
    backdrops: form.removeBackground === 'enabled-with-backdrops' ? form.backdrops : [],
    allowPhotoUpload: form.allowPhotoUpload,
    ...profileProps,
  };
}
