import { useRef, useCallback } from 'react';
import { ButtonBase, CircularProgress, Paper, makeStyles } from '@material-ui/core';
import { Upload } from 'react-feather';
import clsx from 'clsx';

import { useState } from 'react';
import { toast } from 'components/GlobalSnackbar';

export type FileUploadButtonProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  /**
   * MIME types to accept in file input field. Defaults to `image/*`.
   */
  accept?: string;
  /**
   * The largest acceptable file size, in megabytes
   */
  maxFileSizeMb?: number;
  /**
   * Callback to execute on file upload. If this callback is asynchronous, this
   * component renders a loading spinner until the callback resolves.
   */
  onChange?: (file: File) => unknown;
  /**
   * Render prop that overrides what is rendered as a child of the root
   * `ButtonBase` component. If you pass a `Button` component, be sure to set
   * the `component` prop to `span` to prevent click event capturing.
   */
  render?: (props: { loading: boolean }) => JSX.Element;
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    '& .MuiTouchRipple-root': {
      borderRadius: 16,
    },
  },
  paper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& > img': {
      width: 100,
      height: 100,
      borderRadius: 16,
    },
    width: 100,
    height: 100,
  },
}));

/**
 * A button, which shockingly, allows users to upload files. You can access the
 * uploaded file via the `onUpload` callback.
 */
export function FileUploadButton(props: FileUploadButtonProps): React.ReactElement {
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const { onChange: onUpload } = props;

  const handleFileUpload = useCallback(async () => {
    const input = fileInputRef.current;
    if (!input) return;

    const newUploadedFile = input.files?.[0];
    if (!newUploadedFile) return;

    const maxFileSize = props.maxFileSizeMb || Infinity;
    if (newUploadedFile.size > maxFileSize * 1000000) {
      toast(`Max file size for imports is ${props.maxFileSizeMb}MB`, 'error');
      return;
    }

    setLoading(true);
    await onUpload?.(newUploadedFile);
    setLoading(false);
  }, [onUpload]);

  return (
    <ButtonBase component="label" className={clsx(classes.root, props.className)} disabled={loading}>
      {props.render ? (
        props.render({ loading })
      ) : (
        <Paper className={classes.paper}>{loading ? <CircularProgress /> : <Upload />}</Paper>
      )}
      <input
        type="file"
        accept={props.accept || 'image/*'}
        hidden
        ref={fileInputRef}
        onChange={async () => {
          await handleFileUpload();

          // Always clear out the value to reset the field so that the same file can be uploaded again.
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }}
      />
    </ButtonBase>
  );
}
