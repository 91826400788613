import { Container, Typography, Grid, Paper, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';

import DashboardBackground from 'assets/DashboardBackground.svg';
import DashboardSubmissionsCard from 'components/DashboardSubmissionsCard';
import DashboardReviewCard from 'components/DashboardReviewCard';
import DashboardConnectCard from 'components/DashboardConnectCard';
import { useDashboardPageQuery } from 'codegen/graphql';
import Page from 'pages/Page';
import { StudiosDataGrid } from 'components/StudiosDataGrid';

export type DashboardPageProps = {
  // add props here
};

const useStyles = makeStyles((theme) => ({
  page: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  headerContainer: {
    marginTop: -30,
    marginBottom: 30,
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column-reverse',
      '& img': {
        marginBottom: 16,
      },
    },
  },
  headerText: {
    color: 'rgb(38, 50, 56)',
    fontSize: 35,
    letterSpacing: -0.24,
    lineHeight: '40px',
  },
  background: {
    width: '100%',
  },
  studiosDataGridWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

export function DashboardPage(props: DashboardPageProps): React.ReactElement {
  const { loading, error, data } = useDashboardPageQuery();
  const history = useHistory();
  const classes = useStyles();

  if (loading) {
    // todo: handle loading state
    return <></>;
  }

  if (error || !data) {
    // todo: handle error state
    return <></>;
  }

  if (data.currentAccount) {
    history.push('/studios');
  }

  return data.currentAccount ? (
    <Container maxWidth="md" className={classes.page}>
      <Grid container spacing={2} className={classes.headerContainer}>
        <Grid item md={6}>
          {data?.currentUser && (
            <Typography className={classes.headerText}>
              Your headshots are happy to see you, {data.currentUser.firstName}.
            </Typography>
          )}
        </Grid>
        <Grid item md={6}>
          <img src={DashboardBackground} alt="dashboard-decoration" className={classes.background} />
        </Grid>
      </Grid>
      <DashboardSubmissionsCard />
      <DashboardReviewCard />
      <DashboardConnectCard />
    </Container>
  ) : (
    <Page title="All studios" htmlTitle="Dashboard" capHeight>
      <Paper className={classes.studiosDataGridWrapper}>
        <StudiosDataGrid />
      </Paper>
    </Page>
  );
}
