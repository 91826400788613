import { useEffect, useRef, useState } from 'react';
import { Color, ColorBox, ColorBoxProps } from 'material-ui-color';
import { createColor } from 'material-ui-color';

export interface DebounceColorBoxProps {
  delay?: number;
  onChange: (color: Color) => void;
  value: string | null;
  colorBoxProps?: Omit<ColorBoxProps, 'onChange' | 'value'>;
}

export function DebouncedColorBox(props: DebounceColorBoxProps): JSX.Element {
  const [color, setColor] = useState<Color>();
  const debounceRef = useRef<number>();

  useEffect(() => {
    if (props.value) setColor(createColor(props.value));
  }, []);

  return (
    <ColorBox
      {...props.colorBoxProps}
      value={color}
      onChange={(color) => {
        setColor(color);

        if (debounceRef.current) clearTimeout(debounceRef.current);

        debounceRef.current = window.setTimeout(() => {
          props.onChange(color);
        }, props.delay || 250);
      }}
    />
  );
}
