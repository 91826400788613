import {
  Card,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useGetPhotoFilterRowsQuery } from 'codegen/graphql';
import { useHistory } from 'react-router-dom';
import { id } from 'src/lib/gid';

export type FiltersCardProps = {
  // add props here
};

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 16,
  },
  filterRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
}));

export function FiltersCard(props: FiltersCardProps): React.ReactElement {
  const { t } = useTranslation('glossary');
  const classes = useStyles();
  const history = useHistory();

  const { loading, error, data } = useGetPhotoFilterRowsQuery();

  const filterHeading = (
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography variant="button">{t('name')}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="button">Transformation</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const filterRows = data?.photoFilters.map((filter, index) => (
    <TableRow
      className={classes.filterRow}
      key={index}
      onClick={() => history.push(`/admin/filters/${id(filter.id)}/edit`)}
    >
      <TableCell>
        <Typography>{filter.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{filter.cloudinaryTransform || 'None'}</Typography>
      </TableCell>
    </TableRow>
  ));

  return (
    <Card className={classes.card}>
      <Table>
        {filterHeading}
        <TableBody>{filterRows}</TableBody>
      </Table>
    </Card>
  );
}
