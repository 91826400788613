/**
 * Our own implementation of kebab-case that permits trailing whitespaces,
 * which is preferable for editing a value that is forced to be kebab-case.
 * @param string
 */
export function kebabCase(string = ''): string {
  return string
    .toLowerCase()
    .replace(/ /g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Replace multiple hyphens with only one
    .replace(/[^a-z0-9-]/g, ''); // Get rid of special chars and whatnot
}
