import { makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export type DisabledProps = {
  children: React.ReactNode | React.ReactNode[];
  hideTooltip?: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    opacity: 0.5,
  },
  content: {
    pointerEvents: 'none',
  },
}));

export function Disabled(props: DisabledProps): React.ReactElement {
  const { hideTooltip = false } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const content = (
    <div className={classes.root}>
      <div className={classes.content}>{props.children}</div>
    </div>
  );

  if (hideTooltip) return content;

  return <Tooltip title="Work in progress">{content}</Tooltip>;
}
