import { useCallback, useEffect, useRef, useState } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { DataGrid, GridColDef, GridSortItem } from '@material-ui/data-grid';

import {
  GetStudioSessionInvitationsQueryVariables,
  SortDirection,
  StudioSessionRowFragment,
  StudioSessionSortKey,
  useDeleteStudioSessionsMutation,
  useGetStudioOverviewsQuery,
  useGetStudioSessionInvitationsLazyQuery,
  useSendRemindersMutation,
} from 'codegen/graphql';
import { useDataGridController } from 'hooks/useDataGridController';
import ErrorCard from 'components/ErrorCard';
import { usePermissions } from 'hooks/usePermissions';
import {
  ButtonBase,
  FormControl,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ChevronDown, Mail } from 'react-feather';
import { useToolbarStyles } from 'components/SubmissionsDataGridToolbar';
import { handleMutation } from 'lib/handleMutation';
import { toast } from 'components/GlobalSnackbar';
import { Undo } from '@material-ui/icons';

export function StudioSessionInvitations(): React.ReactElement {
  const classes = useToolbarStyles();
  const [studioId, setStudioId] = useState<string>();
  const perms = usePermissions();

  const [deleteSelectedSessions] = useDeleteStudioSessionsMutation();
  const [sendReminders] = useSendRemindersMutation();

  const [queryVariables, setQueryVariables] = useState<GetStudioSessionInvitationsQueryVariables>({
    studioId: null,
    sortBy: null,
    sortDirection: null,
  });

  const query = useGetStudioSessionInvitationsLazyQuery({
    variables: queryVariables,
    // needed to prevent apollo-client from running network request twice (or
    // strangely not updating state in hook result?) see
    // https://github.com/apollographql/apollo-client/issues/7689
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const openMenu = useCallback(() => setMenuOpen(true), []);
  const closeMenu = useCallback(() => setMenuOpen(false), []);
  const actionsButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    editQueryVariables({ studioId: studioId == 'all' ? null : studioId });
  }, [studioId]);

  // wrap this in a hook for now; will integrate with `useTranslation` hook in
  // the future.
  const mapStudioSessionsToRows = useCallback((session: StudioSessionRowFragment) => {
    const { id, contact, invitedAt } = session;
    const studio = session.studio?.name ?? '--';
    const email = contact?.email || '--';
    const firstName = contact?.firstName || '';
    const lastName = contact?.lastName || '';
    return { id, firstName, lastName, email, studio, invitedAt };
  }, []);

  const STUDIO_SESSION_COLUMNS: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      width: 300,
      hideSortIcons: false,
    },
    {
      field: 'firstName',
      headerName: 'First name',
      sortable: true,
      width: 200,
      hideSortIcons: false,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      sortable: true,
      width: 200,
      hideSortIcons: false,
    },
    ...(perms.auth?.canViewStudio
      ? [
          {
            field: 'studio',
            headerName: 'Studio',
            width: 200,
            hideSortIcons: false,
            sortable: false,
          },
        ]
      : []),
    {
      field: 'invitedAt',
      headerName: 'Invited at',
      sortable: true,
      width: 160,
      valueFormatter: ({ value }) => {
        const dateStr = value as string | null;
        return dateStr ? `${formatDistanceToNowStrict(new Date(dateStr))} ago` : '--';
      },
      hideSortIcons: false,
    } as GridColDef,
  ];

  const queryId = `studioSessions:${queryVariables.sortBy}:${queryVariables.sortDirection}:${queryVariables.studioId}`;
  const dataGridController = useDataGridController({
    connectionField: 'studioSessionInvitations',
    query,
    queryId,
    mapNodesToRows: mapStudioSessionsToRows,
    columns: STUDIO_SESSION_COLUMNS,
    initialPageSize: 50,
  });

  if ('error' in dataGridController) {
    console.error(dataGridController.error);
    return <ErrorCard body="Could not load data. Please try refreshing the page." />;
  }

  const { dataGridProps, refreshCurrentPage, rowCount, selectedIds, total } = dataGridController;

  return (
    <>
      <DataGrid
        {...dataGridProps}
        rowsPerPageOptions={[25]}
        rowHeight={50}
        sortingMode="server"
        onSortModelChange={({ sortModel }) => {
          // sort model is an array of sort items, but there is always only 1 sort item...
          handleSortModelChange(sortModel[0] || null);
        }}
        components={{
          ...dataGridProps.components,
          Toolbar: () => (
            <div className={classes.root}>
              <div className={classes.firstRow}>
                {selectedIds.length ? (
                  <div className={classes.chip}>
                    <Typography className={classes.text}>
                      {selectedIds.length} of {rowCount} selected
                    </Typography>
                    <>
                      <ButtonBase className={classes.chipActions} onClick={openMenu} ref={actionsButtonRef}>
                        <Typography variant="body2">Actions</Typography>
                        <ChevronDown size={12} style={{ display: 'inline-block', marginLeft: 8 }} />
                      </ButtonBase>
                      <Menu
                        open={menuOpen}
                        onClose={closeMenu}
                        anchorEl={actionsButtonRef.current}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <MenuItem onClick={handleSendRemindersClick}>
                          <ListItemIcon>
                            <Mail />
                          </ListItemIcon>
                          <Typography variant="body2">Send reminder</Typography>
                        </MenuItem>
                        <MenuItem onClick={handleDelete}>
                          <ListItemIcon>
                            <Undo />
                          </ListItemIcon>
                          <Typography variant="body2">Revoke invite</Typography>
                        </MenuItem>
                      </Menu>
                    </>
                  </div>
                ) : (
                  <div className={classes.chip} style={{ backgroundColor: 'white' }}>
                    <Typography className={classes.text}>
                      Showing {rowCount} of {total}
                    </Typography>
                  </div>
                )}
                <StudioSelect value={studioId} onChange={(value) => setStudioId(value)} />
              </div>
            </div>
          ),
        }}
        autoHeight
      />
    </>
  );

  async function handleDelete() {
    const len = selectedIds.length;
    await handleMutation({
      mutate: () => {
        return deleteSelectedSessions({
          variables: {
            input: {
              ids: selectedIds,
            },
          },
        });
      },
      payloadFieldPath: 'deleteStudioSessions?',
      confirm: `Are you sure you want to revoke ${len} invitation${len > 1 ? 's' : ''}?`,
      successCondition: (data) => (data.deleteStudioSessions?.deletedIds?.length || 0) > 0,
      onSuccess: () => {
        toast(`Invitation${len > 1 ? 's' : ''} revoked from studio`, 'success');
        refreshCurrentPage();
      },
      onError: () => {
        toast(`Could not delete invitation${len > 1 ? 's' : ''}`, 'error');
      },
    });
    closeMenu();
  }

  async function handleSendRemindersClick() {
    const len = selectedIds.length;

    await handleMutation({
      mutate: () => {
        return sendReminders({
          variables: {
            input: {
              ids: selectedIds,
            },
          },
        });
      },
      payloadFieldPath: 'sendReminders?',
      confirm: `Are you sure you want to send ${len} reminder${len > 1 ? 's' : ''}?`,
      successCondition: (data) => !!data.sendReminders?.success,
      onSuccess: () => {
        toast(`Reminder${len > 1 ? 's' : ''} sent`, 'success');
        refreshCurrentPage();
      },
      onError: (errors) => {
        const exceededMax = errors?.some(
          (err) => err.path?.includes('ids') && err.message.includes('exceeded the maximum'),
        );

        if (exceededMax) {
          toast('You can only send up to 2,000 reminders at a time', 'error');
          return;
        }

        toast(`Could not remind contact${len > 1 ? 's' : ''}`, 'error');
      },
    });

    closeMenu();
  }

  /**
   * Similar to `setVariables`, but merges incoming variables with existing
   * variables. Note that you should only call this once per render, since the
   * first invocation will be overriden by the second invocation.
   */
  function editQueryVariables(newQueryVariables: Partial<GetStudioSessionInvitationsQueryVariables>) {
    setQueryVariables({
      ...queryVariables,
      ...newQueryVariables,
    });
  }

  function handleSortModelChange(sortItem: GridSortItem | null) {
    if (sortItem === null) {
      editQueryVariables({
        sortDirection: null,
        sortBy: null,
      });
      return;
    }

    const sortDirection = sortItem.sort === 'asc' ? SortDirection.Asc : SortDirection.Desc;
    let sortBy: StudioSessionSortKey | null;

    switch (sortItem.field) {
      case 'firstName': {
        sortBy = StudioSessionSortKey.ContactFirstName;
        break;
      }
      case 'lastName': {
        sortBy = StudioSessionSortKey.ContactLastName;
        break;
      }
      case 'email': {
        sortBy = StudioSessionSortKey.Email;
        break;
      }
      case 'submittedAt': {
        sortBy = StudioSessionSortKey.InvitedAt;
        break;
      }
      default: {
        sortBy = null;
        break;
      }
    }

    editQueryVariables({ sortDirection, sortBy });
  }
}

type StudioSelectProps = {
  value: string | undefined;
  onChange: (id: string) => void;
};

function StudioSelect(props: StudioSelectProps) {
  const { data } = useGetStudioOverviewsQuery();
  const classes = useToolbarStyles();

  const STUDIO_MENU_ITEMS = [
    {
      label: 'All studios',
      value: 'all',
    },
    ...(data ? data.studios.edges.map((edge) => ({ label: edge.node.name, value: edge.node.id })) : []),
  ];

  return (
    <FormControl variant="outlined" className={classes.select}>
      <InputLabel>Studio</InputLabel>
      <Select
        value={props.value ?? 'all'}
        onChange={(e) => props.onChange(e.target.value as string)}
        variant="outlined"
        label="Studio"
      >
        {STUDIO_MENU_ITEMS.map((item, index) => (
          <MenuItem value={item.value === null ? 'all' : item.value} key={index}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
