import { useTranslation } from 'react-i18next';
import { Page } from '../Page/Page';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { useEffect, useRef } from 'react';
import { useGenerateMetricsTokenMutation } from 'codegen/graphql';

export function MetricsPage(): React.ReactElement {
  const { t } = useTranslation('glossary');
  const chartRef = useRef<HTMLDivElement>(null);
  const [generateMetricsToken, { data, loading }] = useGenerateMetricsTokenMutation();

  useEffect(() => {
    generateMetricsToken();
  }, []);

  useEffect(() => {
    if (loading) return;

    const token = data?.generateMetricsToken?.token;

    if (token) renderChart(token);
  }, [data, loading]);

  return (
    <Page
      maxWidth="xl"
      title={t('metrics')}
      breadcrumbs={[
        {
          body: 'Admin settings',
          to: '/admin',
        },
        {
          body: t('metrics'),
          to: '/admin/metrics',
        },
      ]}
    >
      <div ref={chartRef}></div>
    </Page>
  );

  function renderChart(token: string) {
    if (!chartRef.current) return;

    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-holar-pxlka',
      height: 1000,
      getUserToken: async function () {
        return token;
      },
    });

    const chart = sdk.createDashboard({ dashboardId: '62e02233-1ba0-4a56-8127-5ce0e4816875' });

    // render the chart into a container
    chart.render(chartRef.current);
  }
}
