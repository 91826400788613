import { Link, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Switch } from 'react-router-dom';
import { Disabled } from 'components/Disabled';

export type ColumnValueProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  label: string;
  value: React.ReactNode;
  dataType?: 'text' | 'email' | 'phone';
};

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '1em',
    marginRight: '1em',
  },
  label: {
    opacity: 0.8,
  },
  value: {
    whiteSpace: 'nowrap',
  },
}));

export function ColumnValue(props: ColumnValueProps): React.ReactElement {
  const { label, value, dataType = 'text' } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="caption" display="block" gutterBottom>
        {label}
      </Typography>

      <Typography className={classes.value}>
        <Switch>
          {dataType == 'email' ? (
            <Link href={`mailto:${value}`} component="a">
              {value}
            </Link>
          ) : undefined}

          <span>{value || <Disabled hideTooltip>No value</Disabled>}</span>
        </Switch>
      </Typography>
    </div>
  );
}
