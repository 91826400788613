import { memo } from 'react';
import {
  makeStyles,
  Avatar,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

export type DashboardReviewCardProps = {
  // add props here
};

/**
 * Spacing between avatars in units of theme.spacing (default 8px).
 */
const AVATAR_SPACING = 2;

const useStyles = makeStyles((theme) => {
  const ICON_H_OFFSET = theme.spacing(AVATAR_SPACING / 2) + 8;
  const ICON_V_OFFSET = theme.spacing(AVATAR_SPACING / 2) + 1;

  return {
    avatarWrapper: {
      position: 'relative',
    },
    avatar: {
      width: 84,
      height: 84,
    },
    avatarIcon: {
      position: 'absolute',
      left: ICON_H_OFFSET,
      bottom: ICON_V_OFFSET,
      backgroundColor: '#cfd8dc',
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '0.875rem',
      width: 20,
      height: 20,
      borderRadius: 10,
      lineHeight: 1.45,
    },
  };
});

export function DashboardReviewCard(props: DashboardReviewCardProps): React.ReactElement {
  const classes = useStyles();

  const ReviewButton = (
    <Button color="primary">
      <Typography variant="body2">VIEW ALL</Typography>
    </Button>
  );

  const KittyAvatar = memo(() => (
    <Grid item className={classes.avatarWrapper}>
      <Avatar className={classes.avatar} alt="kitty" src="http://placekitten.com/g/84/84" />
      <div className={classes.avatarIcon}>?</div>
    </Grid>
  ));

  return (
    <Card>
      <CardHeader
        title="Awaiting review"
        subheader="You have 14 submissions awaiting your review"
        action={ReviewButton}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={AVATAR_SPACING}>
          <KittyAvatar />
          <KittyAvatar />
          <KittyAvatar />
          <KittyAvatar />
        </Grid>
      </CardContent>
    </Card>
  );
}
