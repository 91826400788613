import Page, { Breadcrumb } from 'pages/Page';
import { Paper, Chip, Button, Typography, MenuItem } from '@material-ui/core';
import {
  useGetContactAttributesQuery,
  useUpdateStudioMutation,
  useGetStudioDetailsQuery,
  FormField,
  ContactAttribute,
} from 'codegen/graphql';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'components/GlobalSnackbar';
import { gid, id } from 'lib/gid';
import { FormFieldDialog } from 'components/CreateFormFieldDialog/FormFieldDialog';
import { ResourceList } from 'components/ResourceList/ResourceList';
import { isEqual, times } from 'lodash-es';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useLeaveWarning } from 'hooks/useLeaveWarning';

export const MANDATORY_FIELD_KEYS = ['firstName', 'lastName', 'email'];

type StudioContactFieldsPageProps = {
  studioId: string;
};

export type EditableFormField = Omit<FormField, 'id' | 'createdAt' | 'updatedAt' | 'contactAttribute'> & {
  contactAttribute: Omit<ContactAttribute, 'id' | 'createdAt' | 'updatedAt' | 'type'>;
};

export function StudioContactFieldsPage(props: StudioContactFieldsPageProps): React.ReactElement {
  const { t } = useTranslation();
  const { loading: loadingFields, data: studioDetailsQuery } = useGetStudioDetailsQuery({
    variables: { id: gid(props.studioId, 'Studio') },
  });

  const { loading: loadingAttributes, data: contactAttributesQuery } = useGetContactAttributesQuery();

  const attributes = useMemo(() => contactAttributesQuery?.contactAttributes || [], [contactAttributesQuery]);

  const loading = loadingAttributes || loadingFields;

  const [updateStudio, { loading: updatingStudio }] = useUpdateStudioMutation();

  const [showFormFieldDialog, setShowFormFieldDialog] = useState(false);
  const [editingFieldAttributeKey, setEditingFieldAttributeKey] = useState<string | null>(null);

  const [fields, setFields] = useState<EditableFormField[]>([]);

  useEffect(() => {
    const fields = studioDetailsQuery?.studio?.formFields || [];

    setFields(fields);
  }, [studioDetailsQuery]);

  const handleReorder = useCallback(
    ({ from, to }) => {
      if (loading) return;

      setFields((current) => {
        const newFields = [...current];

        newFields.splice(to, 0, newFields.splice(from, 1)[0]);

        return newFields;
      });
    },
    [loading],
  );

  const usedAttributes = useMemo(() => {
    return attributes.filter((attribute) => {
      return fields.find((field) => field.contactAttribute.key === attribute.key);
    });
  }, [attributes, fields]);

  const noFieldsExist = !loading && fields.length === 0;

  const breadcrumbs: Breadcrumb[] = [
    {
      to: '/studios',
      body: t('studio_plural_capital'),
    },
    {
      to: `/studios/${id(props.studioId)}/settings`,
      body: `${studioDetailsQuery?.studio?.name}`,
    },
    {
      to: location.pathname,
      body: 'Form fields',
    },
  ];

  const changesHaveBeenMade = !isEqual(fields, studioDetailsQuery?.studio?.formFields);

  const { prompt } = useLeaveWarning({
    warn: changesHaveBeenMade,
    message: 'Are you sure you want to leave? You currently have unsaved changes.',
  });

  return (
    <Page
      title={
        <>
          Form fields{' '}
          {changesHaveBeenMade && (
            <Chip
              size="small"
              label="Unsaved changes"
              color="secondary"
              variant="outlined"
              style={{ marginLeft: '0.5rem' }}
            />
          )}
        </>
      }
      htmlTitle="Form fields"
      breadcrumbs={breadcrumbs}
      pageActions={
        <>
          <Button variant="outlined" color="primary" disabled={loading} onClick={() => setShowFormFieldDialog(true)}>
            Add field
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            disabled={loading || updatingStudio || !changesHaveBeenMade}
          >
            {updatingStudio ? 'Saving...' : 'Save'}
          </Button>
        </>
      }
    >
      <Paper>
        {noFieldsExist && (
          <Typography style={{ width: '100%', textAlign: 'center', padding: '4em' }}>
            No contact fields to display. Start by adding a field.
          </Typography>
        )}

        {!noFieldsExist && (
          <ResourceList
            headings={['Label', 'Key', '']}
            canReorder
            onReorder={handleReorder}
            rows={
              loading
                ? times(5, () => times(3, (index) => <Skeleton key={`skeleton-${index}`} variant="text" />))
                : fields.map((field) => [
                    <Typography key="label">{field.label}</Typography>,
                    <Chip key="key" label={<pre>{field.contactAttribute.key}</pre>} />,
                    field.required ? (
                      <Typography key="required" style={{ color: '#888888', fontWeight: 500 }}>
                        Required
                      </Typography>
                    ) : (
                      <Typography key="optional" style={{ color: '#888888' }}>
                        Optional
                      </Typography>
                    ),
                  ])
            }
            renderMenuItems={({ rowIndex }) => {
              const field = fields[rowIndex];
              const mandatory = field ? MANDATORY_FIELD_KEYS.some((k) => k === field.contactAttribute.key) : false;

              return [
                <MenuItem
                  key="edit"
                  disabled={loading}
                  onClick={() => {
                    setEditingFieldAttributeKey(field.contactAttribute.key);
                    setShowFormFieldDialog(true);
                  }}
                >
                  Edit
                </MenuItem>,
                <MenuItem
                  key="remove"
                  disabled={mandatory || loading}
                  onClick={() => removeField(field.contactAttribute.key)}
                >
                  Remove
                </MenuItem>,
              ];
            }}
          />
        )}
      </Paper>

      <FormFieldDialog
        open={showFormFieldDialog}
        field={
          editingFieldAttributeKey
            ? fields.find((field) => field.contactAttribute.key === editingFieldAttributeKey)
            : undefined
        }
        onClose={closeFormFieldDialog}
        onExited={() => {
          setEditingFieldAttributeKey(null);
        }}
        attributes={attributes}
        usedAttributes={usedAttributes}
        onComplete={handleFormFieldDialogComplete}
      />

      {prompt}
    </Page>
  );

  async function handleSaveClick() {
    await updateStudio({
      variables: {
        input: {
          id: gid(props.studioId, 'Studio'),
          formFields: fields.map((field) => ({
            label: field.label,
            required: field.required,
            contactAttributeKey: field.contactAttribute.key,
            kind: field.kind,
            choices: field.choices,
          })),
        },
      },
    });

    toast('Updated studio', 'success');
  }

  function handleFormFieldDialogComplete(field: EditableFormField) {
    setFields((fields) => {
      if (editingFieldAttributeKey != null) {
        return fields.map((f) => {
          if (f.contactAttribute.key == field.contactAttribute.key) {
            return {
              ...f,
              ...field,
            };
          }

          return f;
        });
      } else {
        return [...fields, { ...field }];
      }
    });
  }

  function removeField(key: string) {
    setFields((fields) => fields.filter((field) => field.contactAttribute.key !== key));
  }

  function closeFormFieldDialog() {
    setShowFormFieldDialog(false);
    setEditingFieldAttributeKey(null);
  }
}
