import { Button, Chip, MenuItem, Paper, Typography, useTheme, makeStyles } from '@material-ui/core';
import { ContactAttribute, useDeleteContactAttributeMutation, useGetContactAttributesQuery } from 'codegen/graphql';
import { useState } from 'react';
import { ContactAttributeDialog } from 'components/CreateContactAttributeDialog/ContactAttributeDialog';
import { toast } from 'components/GlobalSnackbar';
import { ResourceList } from 'components/ResourceList/ResourceList';
import { times } from 'lodash-es';
import { Skeleton } from '@material-ui/lab';
import { useQueryString } from 'hooks/useQueryString';
import Page from 'pages/Page';

const useStyles = makeStyles((theme) => ({
  addButton: {
    textTransform: 'uppercase',
  },
}));

export function ContactAttributesPage(): React.ReactElement {
  const theme = useTheme();
  const classes = useStyles();
  const { editKey } = useQueryString(['editKey'], { clear: true });

  const { loading: loadingAttributes, data, refetch: reloadAttributes } = useGetContactAttributesQuery();
  const [deleteAttribute, { loading: deleting }] = useDeleteContactAttributeMutation();
  const [showContactAttributeDialog, setShowContactAttributeDialog] = useState(!!editKey);
  const [editingAttributeKey, setEditingAttributeKey] = useState<string | null>(editKey);

  const attributes = data?.contactAttributes || [];
  const noAttributesExist = !loadingAttributes && attributes.length === 0;

  return (
    <Page
      title="Contact attributes"
      pageActions={
        <Button
          variant="contained"
          color="primary"
          disabled={loadingAttributes}
          onClick={handleAddClick}
          className={classes.addButton}
        >
          Add Attribute
        </Button>
      }
    >
      <Paper>
        {noAttributesExist && (
          <Typography style={{ width: '100%', textAlign: 'center', padding: '4em' }}>
            No contact attributes to display. Start by adding one.
          </Typography>
        )}

        <ResourceList
          headings={['Key']}
          rows={
            loadingAttributes
              ? times(6, () => [<Skeleton key="key" variant="text" width={200} />])
              : attributes.map((attribute) => [<Chip key="key" label={<pre>{attribute.key}</pre>} />])
          }
          renderMenuItems={({ rowIndex }) => {
            const attribute = attributes[rowIndex];
            // No attribute can occur when rendering the skeleton rows
            if (!attribute || !attribute.custom) return [];

            return [
              <MenuItem
                key="edit"
                disabled={loadingAttributes}
                onClick={() => {
                  setEditingAttributeKey(attribute.key);
                  setShowContactAttributeDialog(true);
                }}
              >
                Edit
              </MenuItem>,
              <MenuItem
                key="delete"
                disabled={loadingAttributes || deleting}
                onClick={() => removeAttribute(attribute)}
                style={{ color: theme.palette.error.main }}
              >
                {deleting ? 'Deleting...' : 'Delete'}
              </MenuItem>,
            ];
          }}
        />
      </Paper>

      <ContactAttributeDialog
        attribute={editingAttributeKey ? attributes.find((a) => a.key === editingAttributeKey) : undefined}
        open={showContactAttributeDialog}
        onClose={closeAddFieldDialog}
        onComplete={handleFieldAdded}
      />
    </Page>
  );

  function handleAddClick() {
    setShowContactAttributeDialog(true);
    setEditingAttributeKey(null);
  }

  async function removeAttribute(attribute: Pick<ContactAttribute, 'key'>) {
    const message = `Are you sure you want to permanently delete the attribute "${attribute.key}"? This will remove any field on a studio form using this attribute.`;

    if (!window.confirm(message)) {
      return;
    }

    const result = await deleteAttribute({
      variables: { input: { key: attribute.key } },
    });

    if (result.data?.deleteContactAttribute?.deletedKey) {
      toast(`Deleted field "${attribute.key}"`, 'success');
      reloadAttributes();
    }
  }

  function closeAddFieldDialog() {
    setShowContactAttributeDialog(false);
  }

  function handleFieldAdded() {
    reloadAttributes();
    closeAddFieldDialog();
  }
}
