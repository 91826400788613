import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { Save, Trash } from 'react-feather';
import { z, ZodLiteralTuple } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, makeStyles } from '@material-ui/core';

import Page from 'pages/Page';
import TwoCardGrid from 'components/TwoCardGrid';
import PreviewCard from 'components/PreviewCard';
import { PresetFormCard, PresetFormCardSkeleton } from 'components/PresetFormCard';
import { CROP_FACTORS, CROP_GRAVITIES, IMAGE_FORMATS, PHOTO_SIZES } from 'models/Preset';
import {
  useCreatePresetMutation,
  PhotoSize,
  CropFactor,
  CropGravity,
  usePresetFormPageQuery,
  useGetPresetLazyQuery,
  useUpdatePresetMutation,
  useDeletePresetMutation,
  CreatePresetInput,
  CloudFileInput,
  BackgroundInput,
  UpdatePresetInput,
  usePreviewPresetMutation,
  Maybe,
  ImageFormat,
  UserError,
} from 'codegen/graphql';
import { toast } from 'components/GlobalSnackbar';
import { gid } from 'lib/gid';
import { handleMutation } from 'lib/handleMutation';
import { usePermissions } from 'hooks/usePermissions';
import { FormErrors } from 'components/FormErrors/FormErrors';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export type NewPresetProps = {
  /**
   * Model ID of the preset to edit. If not provided, a new preset is assumed.
   */
  id?: string;
};

const presetFormSchema = z
  .object({
    name: z
      .string()
      .max(50)
      .refine((name) => name.trim().length, 'Must not be blank'),
    cropFactor: z.union(
      CROP_FACTORS.map((cropFactor) => z.literal(cropFactor)) as ZodLiteralTuple<typeof CROP_FACTORS>,
    ),
    imageFormat: z.union(IMAGE_FORMATS.map((format) => z.literal(format)) as ZodLiteralTuple<typeof IMAGE_FORMATS>),
    cropGravity: z.union(
      CROP_GRAVITIES.map((cropGravity) => z.literal(cropGravity)) as ZodLiteralTuple<typeof CROP_GRAVITIES>,
    ),
    resize: z.union(PHOTO_SIZES.map((photoSize) => z.literal(photoSize)) as ZodLiteralTuple<typeof PHOTO_SIZES>),
    cloudinaryTransform: z.string().optional(),
    visibleToContact: z.boolean(),
    autoEnhance: z.boolean(),
  })
  .passthrough();

export type PresetFormType = z.infer<typeof presetFormSchema> & {
  photoFilterId?: string | 'NONE';
  overlayImage: CloudFileInput;
  backdrop: BackgroundInput;
  serverError: string;
};

export function PresetFormPage(props: NewPresetProps): React.ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const perms = usePermissions();

  /**
   * GID of the preset being edited. If this value is `null`, we are creating a
   * new preset.
   */
  const presetGid = props.id ? gid(props.id, 'Preset') : null;
  const [getExistingPreset, { data: existingPresetData, loading: existingPresetLoading }] = useGetPresetLazyQuery();

  // get existing preset if presetId specified on initial render
  useEffect(() => {
    if (presetGid) getExistingPreset({ variables: { id: presetGid } });
    // eslint-disable-next-line
  }, []);

  const {
    control,
    handleSubmit: generateSubmitHandler,
    setValue,
    getValues,
    formState,
    setError,
  } = useForm<PresetFormType>({
    resolver: zodResolver(presetFormSchema),
    defaultValues: {
      name: '',
      cropFactor: 'SQUARE',
      cropGravity: 'CENTER',
      resize: 'LARGE',
      photoFilterId: 'NONE',
      visibleToContact: true,
      imageFormat: 'jpeg',
      autoEnhance: true,
    },
  });

  const { loading, error, data } = usePresetFormPageQuery();

  const [createPreset, { loading: createPresetLoading }] = useCreatePresetMutation();
  const [updatePreset, { loading: updatePresetLoading }] = useUpdatePresetMutation();
  const [deletePreset, { loading: deletePresetLoading }] = useDeletePresetMutation();
  const [previewPreset, previewPresetState] = usePreviewPresetMutation();

  useEffect(() => {
    if (!existingPresetData?.preset) return;

    const {
      name,
      crop,
      resize,
      overlayImage,
      cloudinaryTransform,
      backdrop,
      photoFilter,
      visibleToContact,
      imageFormat,
      autoEnhance,
    } = existingPresetData.preset;

    if (name) setValue('name', name);
    if (crop?.factor) setValue('cropFactor', crop.factor);
    if (crop?.gravity) setValue('cropGravity', crop.gravity);
    if (resize) setValue('resize', resize);
    if (overlayImage) setValue('overlayImage', overlayImage);
    if (cloudinaryTransform) setValue('cloudinaryTransform', cloudinaryTransform);
    if (backdrop)
      setValue('backdrop', {
        color: backdrop.__typename == 'Color' ? backdrop.hex : null,
        image: backdrop.__typename == 'CloudFile' ? backdrop : null,
      });

    setValue('photoFilterId', photoFilter?.id ? photoFilter.id : 'NONE');
    setValue('imageFormat', imageFormat);
    setValue('autoEnhance', !!autoEnhance);

    if (visibleToContact != null) {
      setValue('visibleToContact', visibleToContact);
    }
  }, [existingPresetData, setValue]);

  const handleSaveClick = generateSubmitHandler(
    async (data: PresetFormType) => {
      const shared: SharedProperties<CreatePresetInput, UpdatePresetInput> = {
        name: data.name,
        resize: data.resize as PhotoSize,
        crop: {
          factor: data.cropFactor as CropFactor,
          gravity: data.cropGravity as CropGravity,
        },
        photoFilterId: data.photoFilterId == null || data.photoFilterId === 'NONE' ? null : data.photoFilterId,
        cloudinaryTransform: data.cloudinaryTransform,
        overlayImage: data.overlayImage,
        backdrop: data.backdrop,
        visibleToContact: data.visibleToContact,
        imageFormat: data.imageFormat as ImageFormat,
        autoEnhance: data.autoEnhance,
      };

      let response;
      let success = false;
      try {
        if (presetGid) {
          response = await updatePreset({ variables: { input: { ...shared, id: presetGid } } });
        } else {
          response = await createPreset({ variables: { input: shared } });
        }
        const { data, errors: gqlErrors } = response;
        const userErrors = data
          ? 'createPreset' in data
            ? data.createPreset?.errors
            : 'updatePreset' in data
            ? data.updatePreset?.errors
            : null
          : null;
        if (gqlErrors?.length) {
          console.error('GraphQL errors', gqlErrors);
          toast(`Could not save ${t('preset')}`, 'error');
        } else if (userErrors?.length) {
          handleUserErrors(userErrors);
        } else if (data) {
          success = true;
        }
      } catch (networkError) {
        console.error('Network error', networkError);
        toast(`Could not save ${t('preset')}`, 'error');
      }

      const updatingPreset = !!presetGid;
      if (success) {
        if (updatingPreset) {
          toast(`Updated ${t('preset')}`, 'success');
        } else {
          history.push('/presets');
          toast(`Created ${t('preset')}`, 'success');
        }
      }
    },
    (errors) => {
      console.error(errors);
    },
  );

  const actions = (
    <>
      {!existingPresetData?.preset?.default && presetGid && perms.auth?.canDeletePreset && (
        <Button
          onClick={handleDelete}
          disabled={deletePresetLoading}
          startIcon={<Trash />}
          variant="contained"
          className={classes.deleteButton}
        >
          {deletePresetLoading ? 'DELETING...' : 'DELETE'}
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        startIcon={<Save />}
        onClick={handleSaveClick}
        disabled={createPresetLoading || updatePresetLoading}
      >
        {createPresetLoading || updatePresetLoading ? 'SAVING...' : 'SAVE'}
      </Button>
    </>
  );

  const breadcrumbs = [
    {
      to: '/presets',
      body: t('preset_plural_capital'),
    },
    presetGid
      ? {
          to: location.pathname,
          body: existingPresetData?.preset?.name ? existingPresetData.preset.name : props.id ? 'Edit' : 'New',
        }
      : {
          to: location.pathname,
          body: t('new_capital'),
        },
  ];

  if (error) {
    console.error('Could not load filters for preset form page.');
  }

  return (
    <Page
      title={presetGid ? t('presetsRoute:editPreset') : t('presetsRoute:newPreset')}
      breadcrumbs={breadcrumbs}
      pageActions={actions}
    >
      <FormErrors errors={formState.errors} />
      <TwoCardGrid>
        {loading || existingPresetLoading ? (
          <PresetFormCardSkeleton />
        ) : (
          <PresetFormCard control={control} photoFilters={data?.photoFilters || []} />
        )}
        <PreviewCard
          originalUrl={previewPresetState.data?.previewPreset?.originalUrl ?? null}
          sampleUrl={previewPresetState.data?.previewPreset?.sampleUrl ?? null}
          onPreviewGenerate={handlePreviewGenerateIntent}
          loading={previewPresetState.loading}
        />
      </TwoCardGrid>
    </Page>
  );

  async function handleDelete() {
    if (!existingPresetData?.preset || !presetGid) return;

    await handleMutation({
      mutate: async () => await deletePreset({ variables: { input: { id: presetGid } } }),
      payloadFieldPath: 'deletePreset?',
      confirm: 'Are you sure you want to delete this preset?',
      successCondition: (data) => data.deletePreset?.deletedId === presetGid,
      onSuccess: () => {
        history.push('/presets');
        toast(`${t('preset_capital')} deleted`, 'success');
      },
      onError: () => {
        toast(`Could not delete ${t('preset_capital')}`, 'error');
      },
    });
  }

  async function handlePreviewGenerateIntent() {
    try {
      const cropFactor = getValues('cropFactor');
      const cropGravity = getValues('cropGravity');
      const crop =
        cropFactor && cropGravity
          ? {
              factor: cropFactor as CropFactor,
              gravity: cropGravity as CropGravity,
            }
          : null;
      const photoFilterId = getValues('photoFilterId');

      const { data, errors: gqlErrors } = await previewPreset({
        variables: {
          input: {
            crop,
            backdrop: getValues('backdrop'),
            cloudinaryTransform: getValues('cloudinaryTransform'),
            overlayImage: getValues('overlayImage'),
            resize: getValues('resize') as PhotoSize,
            photoFilterId: photoFilterId == 'NONE' ? null : photoFilterId,
            imageFormat: getValues('imageFormat') as ImageFormat,
            autoEnhance: getValues('autoEnhance'),
          },
        },
      });
      const userErrors = data?.previewPreset?.errors;
      if (gqlErrors?.length) {
        console.error('GraphQL errors', gqlErrors);
        toast(`Could not generate ${t('preset')} preview`, 'error');
      } else if (userErrors?.length) {
        handleUserErrors(userErrors);
      }
    } catch (networkError) {
      console.error('Network error', networkError);
      toast(`Could not generate ${t('preset')} preview`, 'error');
    }
  }

  function handleUserErrors(errors: UserError[]) {
    errors.forEach((error) => {
      const path = error.path && error.path[0];
      switch (path) {
        case 'cloudinaryTransform':
          setError('cloudinaryTransform', { message: error.message });
          break;
        default:
          setError('serverError', { message: error.message });
      }
    });
  }
}
