import { useState, createContext, useRef } from 'react';
import Page from 'pages/Page';
import PageActionButton from 'components/PageActionButton';
import { Paper, Typography } from '@material-ui/core';
import { NotificationsDataGrid } from 'components/NotificationsDataGrid';
import { NotificationDialog } from 'components/NotificationDialog';
import { NotificationDeleteDialog } from 'components/NotificationDeleteDialog';
import {
  FrequencyEnum,
  useGetCurrentUserQuery,
  useGetSubmissionNotificationsQuery,
  useGetStudioOverviewsQuery,
  useCreateSubmissionNotificationMutation,
  useDeleteSubmissionNotificationsMutation,
} from 'codegen/graphql';
import { MutableNotification } from 'pages/SubmissionNotifications/types';
import { useEffect } from 'react';
import { without } from 'lodash-es';
import { toast } from 'components/GlobalSnackbar';
import { TNotificationContext } from 'pages/SubmissionNotifications/types';
import { makeStyles } from '@material-ui/core';

export const NotificationContext = createContext<TNotificationContext>({
  userEmail: '',
  studios: [],
  notifications: [],
  selectedNotification: null,
});

const useStyles = makeStyles({
  sendingMessage: {
    marginBottom: '1rem',
  },
});

export function SubmissionNotifications() {
  const { data: userData } = useGetCurrentUserQuery();
  const {
    data: notificationData,
    loading: notificationDataLoading,
    error: notificationDataError,
  } = useGetSubmissionNotificationsQuery();
  const { data: studioData } = useGetStudioOverviewsQuery();

  const [createSubmissionNotification, { loading: createSubmissionNotificationLoading }] =
    useCreateSubmissionNotificationMutation();
  const [deleteSubmissionNotifications, { loading: deleteSubmissionNotificationLoading }] =
    useDeleteSubmissionNotificationsMutation();

  const [notifications, setNotifications] = useState<MutableNotification[]>([]);
  const [selectedNotification, setSelectedNotification] = useState<MutableNotification | null>(null);
  const [inputDialogOpen, setInputDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // Used for setting 'selectedNotification' to null after closing animation complete
  // Consequently, prevents title on <NotificationDialog /> from changing before it has closed
  const timerRef = useRef<number | null>();
  const classes = useStyles();

  useEffect(() => {
    if (!notificationData?.userSubmissionNotifications) return;
    setNotifications(notificationData.userSubmissionNotifications as MutableNotification[]);
  }, [notificationData?.userSubmissionNotifications]);

  useEffect(() => {
    return () => {
      if (!timerRef.current) return;
      window.clearTimeout(timerRef.current);
    };
  }, []);

  const userEmail = userData?.currentUser?.email || '';

  return (
    <NotificationContext.Provider
      value={{ userEmail, studios: studioData?.studios.edges || [], notifications, selectedNotification }}
    >
      <Page
        title="Manage submission notifications"
        pageActions={<PageActionButton onClick={() => setInputDialogOpen(true)}>Add new notification</PageActionButton>}
      >
        <Typography className={classes.sendingMessage}>
          Notifications will be sent to <strong>{userEmail}</strong>
        </Typography>
        <Paper>
          <NotificationsDataGrid
            notifications={notifications}
            loading={notificationDataLoading}
            error={notificationDataError}
            onManageClick={handleManageClick}
            onDeleteClick={handleDeleteClick}
          />
        </Paper>
      </Page>
      <NotificationDialog
        open={inputDialogOpen}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onClose={() => handleClose(300)}
        loading={createSubmissionNotificationLoading || deleteSubmissionNotificationLoading}
      />

      <NotificationDeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setSelectedNotification(null);
        }}
        onDelete={handleDelete}
        loading={deleteSubmissionNotificationLoading}
      />
    </NotificationContext.Provider>
  );

  async function handleCreate(newInput: { studioId: string; frequency: FrequencyEnum }) {
    const result = await createSubmissionNotification({
      variables: {
        input: {
          studioId: newInput.studioId,
          frequency: newInput.frequency,
        },
      },
    });

    const resultingNotification = result.data?.createSubmissionNotification
      ?.submissionNotification as MutableNotification;

    if (!resultingNotification) {
      toast('Notification creation failed', 'error');
      return;
    }

    setNotifications([...notifications, resultingNotification]);

    const matchingStudioName = resultingNotification.studio?.name;

    toast(`Notification turned on for ${matchingStudioName}`, 'success');
    setSelectedNotification(null);
    setInputDialogOpen(false);
    return resultingNotification;
  }

  async function handleUpdate(newInput: { notificationId: string; frequency: FrequencyEnum }) {
    const notification = notifications.find((x) => x.id === newInput.notificationId);
    const studioId = notification?.studio?.id;
    if (notification && studioId) {
      await handleDelete(notification);

      const result = await handleCreate({ studioId: studioId, frequency: newInput.frequency });

      if (result) {
        setNotifications([...without(notifications, notification), result]);
      }
    } else {
      toast('Notification update failed', 'error');
    }

    handleClose(300);
  }

  async function handleDelete(notification: MutableNotification) {
    const result = await deleteSubmissionNotifications({
      variables: {
        input: {
          ids: [notification.id],
        },
      },
    });

    if (!result) {
      toast('Notification deletion failed', 'error');
      return;
    }

    const matchingStudioName = notification.studio?.name;
    toast(`Notifications deleted for ${matchingStudioName}`, 'success');

    setNotifications(without(notifications, notification));

    setSelectedNotification(null);
    setDeleteDialogOpen(false);
  }

  function getNotificationById(notificationList: MutableNotification[], id: string): MutableNotification | undefined {
    return notificationList.find((notification) => notification.id === id);
  }

  function handleManageClick(notificationId: string) {
    const foundNotification = getNotificationById(notifications, notificationId);
    if (!foundNotification) return;
    setSelectedNotification(foundNotification);
    setInputDialogOpen(true);
  }

  function handleDeleteClick(notificationId: string) {
    const foundNotification = getNotificationById(notifications, notificationId);
    if (!foundNotification) return;
    setSelectedNotification(foundNotification);
    setDeleteDialogOpen(true);
  }

  function handleClose(delay: number) {
    setInputDialogOpen(false);
    if (timerRef.current) window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setSelectedNotification(null);
      timerRef.current = null;
    }, delay);
  }
}
