import { makeStyles, Button, CircularProgress } from '@material-ui/core';
import { Plus } from 'react-feather';
import { Link } from 'react-router-dom';

export type PageActionButtonProps = {
  /**
   * Body of the page action button.
   */
  children: string;
  /**
   * Where the button should route to on click.
   */
  to?: string;
  /**
   * Callback fired on click. Ignored if `to` is set.
   */
  onClick?: () => void;
  /**
   * Toggles whether the button appears to be loading. Ignored if `to` is set.
   */
  loading?: boolean;
  /**
   * An element to show as the start icon on left of the page action button text.
   */
  startIcon?: JSX.Element;

  color?: 'primary' | 'secondary';

  disabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'uppercase',
  },
}));

export function PageActionButton(props: PageActionButtonProps): React.ReactElement {
  const { color = 'primary' } = props;

  const classes = useStyles();

  return (
    <>
      {props.to ? (
        <Button variant="contained" color="primary" className={classes.button} component={Link} to={props.to}>
          {props.children}
        </Button>
      ) : (
        <Button
          variant="contained"
          color={color}
          className={classes.button}
          onClick={props.onClick}
          disabled={props.loading || props.disabled}
          startIcon={props.loading ? <CircularProgress size={18} /> : props.startIcon}
        >
          {props.children}
        </Button>
      )}
    </>
  );
}
