import { Typography, Button } from '@material-ui/core';
import { makeStyles, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SubmissionRowFragment, SubmissionState, useAcceptSubmissionsMutation } from 'codegen/graphql';
import Dialog from 'components/Dialog';
import { Check, X, Download, Eye, EyeOff } from 'react-feather';
import { SubmissionPhotos } from 'components/SubmissionPhotos';
import { id } from 'lib/gid';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'components/GlobalSnackbar';
import { handleMutation } from 'lib/handleMutation';
import { usePermissions } from 'hooks/usePermissions';
import { useState } from 'react';
import { useSubmissionsExport } from 'hooks/useSubmissionsExport';

export type SubmissionDialogProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  open: boolean;
  onClose: () => unknown;
  submission: SubmissionRowFragment;
  /**
   * Callback that allows parent component to handle rejection of a single
   * submission.
   */
  onReject: () => unknown;
  /**
   * Async callback that refreshes the current page of submissions.
   */
  refreshCurrentPage: () => Promise<void>;
};

type FieldInfo = {
  /**
   * Label of the field
   */
  label: string;
  /**
   * Value of the field
   */
  value: string | JSX.Element | null | undefined;
};

export type SubmissionExportProps = {
  ids: string[];
  filename?: string;
  filenamePattern?: string;
  format?: string | null;
};

const useStyles = makeStyles((theme) => ({
  root: {
    // add styles here
  },
  fieldsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
    marginTop: 10,
    marginBottom: 16,
    '& > section': {
      flex: '20%',
    },
  },
  fieldLabel: {
    color: '#546E7A',
  },
  actionButtons: {
    display: 'flex',
    gap: 10,
    float: 'right',
    margin: '15px 0',
  },
  photos: {
    marginTop: 20,
    paddingTop: 20,
    borderTop: '1px solid #eee',
  },
  dialogActions: {
    '& > *': {
      padding: 8,
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 16,
    },
  },
  archiveButton: {
    color: theme.palette.error.main,
  },
  rejectButton: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  acceptButton: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

export function SubmissionDialog(props: SubmissionDialogProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const perms = usePermissions();
  const { streamExport } = useSubmissionsExport();

  const { submission } = props;
  const [downloading, setDownloading] = useState(false);
  const [acceptSubmission, acceptSubmissionState] = useAcceptSubmissionsMutation({
    variables: { ids: [submission.id] },
  });
  const name = `${submission.contact.firstName || ''} ${submission.contact.lastName || ''}`.trim();

  const hasEnhanced = !!submission.photos.find((p) => p.appliedPresetsEnhanced.length);
  const [showEnhanced, setShowEnhanced] = useState(hasEnhanced);

  const fields: FieldInfo[] = [
    {
      label: 'First name',
      value:
        submission.contact.firstName &&
        (perms.auth?.canViewContact ? (
          <Link component={RouterLink} to={`/contacts/${id(submission.contact?.id || '')}/view`}>
            {submission.contact.firstName}
          </Link>
        ) : (
          submission.contact.firstName
        )),
    },
    {
      label: 'Last name',
      value:
        submission.contact.lastName &&
        (perms.auth?.canViewContact ? (
          <Link component={RouterLink} to={`/contacts/${id(submission.contact?.id || '')}/view`}>
            {submission.contact.lastName}
          </Link>
        ) : (
          submission.contact.lastName
        )),
    },
    {
      label: 'Email',
      value: submission.contact.email,
    },
    {
      label: 'Phone number',
      value: submission.contact.phone || 'None provided',
    },
  ];

  if (submission.contact.customAttributes) {
    fields.push(
      ...Object.keys(submission.contact.customAttributes).map((key) => ({
        label: key,
        value: submission.contact.customAttributes![key] || '',
      })),
    );
  }

  const notInvited = true; // TODO: props.submission.state !== SubmissionState.Invited;
  const canReject =
    notInvited && props.submission.state !== SubmissionState.Rejected && perms.auth?.canChangeSubmission;
  const canAccept =
    notInvited && props.submission.state !== SubmissionState.Accepted && perms.auth?.canChangeSubmission;

  return (
    <Dialog
      title={`Review ${name}`}
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      width={800}
      actions={
        <div className={classes.dialogActions}>
          {canReject && (
            <Button startIcon={<X />} className={classes.rejectButton} onClick={props.onReject}>
              Reject and retake
            </Button>
          )}
          {canAccept && (
            <Button
              startIcon={<Check />}
              className={classes.acceptButton}
              onClick={handleAccept}
              disabled={acceptSubmissionState.loading}
            >
              {acceptSubmissionState.loading ? 'Accepting...' : 'Accept'}
            </Button>
          )}
        </div>
      }
    >
      <div className={classes.fieldsWrapper} data-submission-id={submission.id}>
        {fields.map(({ label, value }, index) => (
          <section key={index}>
            <Typography variant="body2" className={classes.fieldLabel}>
              {label}
            </Typography>
            <Typography variant="body2">{value}</Typography>
          </section>
        ))}
      </div>

      <div className={classes.actionButtons}>
        {hasEnhanced && (
          <Button
            startIcon={showEnhanced ? <Eye /> : <EyeOff />}
            onClick={() => setShowEnhanced(!showEnhanced)}
            size="small"
          >
            AI enhanced
          </Button>
        )}
        {perms.auth?.canExportSubmission && (
          <Button
            startIcon={downloading ? null : <Download />}
            disabled={downloading}
            onClick={() => {
              setDownloading(true);
              streamExport({
                filename: `${submission.contact.firstName} ${submission.contact.lastName}`,
                originalPhotos: !showEnhanced,
                ids: [submission.id],
              });
              setTimeout(() => {
                setDownloading(false);
              }, 1200);
            }}
          >
            {downloading ? 'Downloading...' : 'Download'}
          </Button>
        )}
      </div>
      <SubmissionPhotos
        previewSize={200}
        submissionId={submission.id}
        photos={submission.photos}
        className={classes.photos}
        enhanced={showEnhanced}
      />
    </Dialog>
  );

  async function handleAccept(): Promise<void> {
    await handleMutation({
      mutate: acceptSubmission,
      payloadFieldPath: 'acceptSubmissions?',
      successCondition: (data) => data?.acceptSubmissions?.submissions?.[0]?.id === props.submission.id,
      onSuccess: () => {
        toast('Submission accepted', 'success');
        props.onClose();
      },
      onError: () => {
        toast('Could not accept submission', 'error');
      },
    });
  }
}
