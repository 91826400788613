import { EnhancementStatus } from 'codegen/graphql';
import { Tooltip } from '@material-ui/core';
import { Assistant, Error, WatchLater } from '@material-ui/icons';

export function EnhancementStatusTip(props: { status: EnhancementStatus }) {
  switch (props.status) {
    case EnhancementStatus.Failed:
      return (
        <Tooltip title="This photo failed to process by AI">
          <Error color="error" />
        </Tooltip>
      );
    case EnhancementStatus.Processing:
      return (
        <Tooltip title="This photo is being processed by AI">
          <WatchLater color="action" />
        </Tooltip>
      );
    case EnhancementStatus.Enhanced:
      return (
        <Tooltip title="This photo has been enhanced by AI">
          <Assistant />
        </Tooltip>
      );
    default:
      return <></>;
  }
}
