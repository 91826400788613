import { useState, useCallback } from 'react';

type UseDialogProps = {
  /**
   * Callback to be executed on dialog close
   */
  onClose?: () => unknown;
};

type UseDialogReturn = {
  /**
   * Whether this dialog is open.
   */
  open: boolean;
  /**
   * Callback that opens the dialog.
   */
  openDialog: () => void;
  /**
   * Callback that closes the dialog.
   */
  closeDialog: () => void;
  /**
   * Props that should be passed to the MUI `Dialog` component.
   */
  dialogProps: { open: boolean; onClose: () => void };
};

/**
 * Hook that provides stateful logic for the MUI controlled `Dialog` component.
 */
export default function useDialog(props?: UseDialogProps): UseDialogReturn {
  const onClose = props?.onClose;
  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose]);

  return {
    open,
    openDialog,
    closeDialog,
    dialogProps: { open, onClose: closeDialog },
  };
}
