import { useState } from 'react';
import { useForm, useController } from 'react-hook-form';
import { makeStyles, Typography, FormControlLabel, Switch } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Portrait } from '@material-ui/icons';
import SettingsCard from 'components/SettingsCard';
import RadioField from 'components/RadioField';
import { Studio, useUpdateStudioMutation, useUpdateReviewSettingMutation } from 'codegen/graphql';
import { toast } from 'components/GlobalSnackbar';

export type ReviewSettingsCardProps = {
  /**
   * Class name attached to the root element.
   */
  className?: string;
  studio: Pick<Studio, 'id' | 'requireSubmissionReview' | 'galleryEnabled' | 'poses' | 'suggestMobileDevice'>;
};

type ReviewSettingFormType = {
  requireSubmissionReview: 'enabled' | 'disabled';
};

const useStyles = makeStyles(() => ({
  radioField: {
    marginTop: '-0.2rem',

    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
    },
    '& .MuiButtonBase-root': {
      marginTop: '-5px',
    },
    '& .MuiFormControlLabel-root:nth-child(n)': {
      marginBottom: 16,
    },
  },
  galleryToggleContainer: {
    width: '100%',
    paddingTop: 8,
    borderTop: '1px solid #E0E0E0',
    marginLeft: 0,
    marginRight: 0,
    '& .MuiSwitch-root': { marginLeft: -8 },
  },
  alert: {
    marginTop: 16,
    border: '1px solid #FF9800',
  },
}));

export function ReviewSettingsCard(props: ReviewSettingsCardProps): React.ReactElement {
  const [reviewSettingLoading, setReviewSettingLoading] = useState(false);
  const [updateReviewSetting] = useUpdateReviewSettingMutation();
  const [updateStudioMutation, { loading: studioLoading }] = useUpdateStudioMutation();
  const classes = useStyles();

  const {
    control,
    handleSubmit: generateSubmitHandler,
    reset,
  } = useForm<ReviewSettingFormType>({
    defaultValues: { requireSubmissionReview: props.studio.requireSubmissionReview ? 'enabled' : 'disabled' },
  });

  const controller = useController({ control, name: 'requireSubmissionReview' });

  const items = [
    {
      value: 'enabled',
      label: (
        <>
          <Typography variant="overline" component="p">
            Require submission review
          </Typography>
          <Typography variant="body2" style={{ color: '#546e7a' }}>
            All submissions for this studio must be accepted or rejected.
          </Typography>
        </>
      ),
    },
    {
      value: 'disabled',
      label: (
        <>
          <Typography variant="overline" component="p">
            Auto-accept submissions
          </Typography>
          <Typography variant="body2" style={{ color: '#546e7a' }}>
            All submissions for this studio are automatically accepted.
          </Typography>
        </>
      ),
    },
  ];

  const handleSubmit = generateSubmitHandler(
    async (formData) => {
      setReviewSettingLoading(true);
      try {
        await updateReviewSetting({
          variables: {
            id: props.studio.id,
            requireSubmissionReview: formData.requireSubmissionReview === 'enabled',
          },
        });
        toast('Studio settings updated', 'success');
      } catch (e) {
        console.error(e);
        reset();
        toast('Could not update studio settings', 'error');
      }
      setReviewSettingLoading(false);
    },
    (errors) => console.error(errors),
  );

  const handleGalleryToggle = async (event: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
    try {
      await updateStudioMutation({ variables: { input: { id: props.studio.id, galleryEnabled: newValue } } });
      toast('Studio settings updated', 'success');
    } catch (e) {
      console.error(e);
      toast('Could not update review setting', 'error');
    }
  };

  const handleSuggestMobileToggle = async (event: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
    try {
      await updateStudioMutation({ variables: { input: { id: props.studio.id, suggestMobileDevice: newValue } } });
      toast('Studio settings updated', 'success');
    } catch (e) {
      console.error(e);
      toast('Could not update review setting', 'error');
    }
  };

  const noVisiblePresets = props.studio?.poses?.every((pose) =>
    pose.presets?.every((preset) => !preset.visibleToContact),
  );

  return (
    <SettingsCard title="Photo review & access" icon={<Portrait />}>
      <RadioField
        className={classes.radioField}
        controller={controller}
        items={items}
        fullWidthItems
        onChange={() => handleSubmit()}
        disabled={reviewSettingLoading}
      />
      <FormControlLabel
        className={classes.galleryToggleContainer}
        disabled={studioLoading}
        control={<Switch checked={props.studio.galleryEnabled} onChange={handleGalleryToggle} />}
        label={
          <>
            <Typography variant="overline" component="p">
              Show Gallery
            </Typography>
            <Typography variant="body2" style={{ color: '#546e7a' }}>
              Display a gallery screen to contacts once their submission is approved.
            </Typography>
          </>
        }
      />
      <FormControlLabel
        className={classes.galleryToggleContainer}
        disabled={studioLoading}
        control={<Switch checked={props.studio.suggestMobileDevice} onChange={handleSuggestMobileToggle} />}
        label={
          <>
            <Typography variant="overline" component="p">
              Suggest mobile device
            </Typography>
            <Typography variant="body2" style={{ color: '#546e7a' }}>
              If the user is using non-mobile device, show them a message suggesting they use their phone.
            </Typography>
          </>
        }
      />
      {props.studio.galleryEnabled && noVisiblePresets && (
        <Alert className={classes.alert} severity="warning">
          <strong>Warning:</strong> You have no visible outputs. Disable the gallery or make an output visible to users.
        </Alert>
      )}
    </SettingsCard>
  );
}
