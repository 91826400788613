import { useState } from 'react';
import { Button, makeStyles, Typography, FormControlLabel, Switch } from '@material-ui/core';
import { Disclaimer, useUpdateDisclaimerMutation } from 'codegen/graphql';
import { AlertCircle } from 'react-feather';
import { SettingsCard } from 'components/SettingsCard';
import { Dialog } from 'components/Dialog';
import { RichTextEditor } from 'components/RichTextEditor';
import useDialog from 'hooks/useDialog';
import { handleMutation } from 'lib/handleMutation';
import { toast } from 'components/GlobalSnackbar';
import { RichTextPreview } from 'components/RichTextPreview';

type DisclaimerSettings = Pick<Disclaimer, 'content' | 'enabled'>;

export type DisclaimerSettingsCardProps = {
  /**
   * ID of the parent studio.
   */
  studioId: string;
  /**
   * Disclaimer of the parent studio.
   */
  disclaimer: DisclaimerSettings;
};

const useStyles = makeStyles(() => ({
  root: {
    // add styles here
  },
  disclaimerDialog: {
    '& .MuiDialogContent-root': {
      padding: '16px 24px',
    },
  },
  previewBody: {
    padding: 16,
    paddingTop: 0,
    opacity: 0.5,
  },
  previewContainer: {
    margin: -16,
    marginTop: 8,
    borderRadius: '0px 0px 16px 16px',
  },
  rte: {
    minHeight: 400,
  },
  rteToolbar: {
    borderBottom: '1px solid #eee',
    marginLeft: -24,
    marginRight: -24,
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const enabledLabel = (
  <>
    <Typography variant="overline" component="p">
      Show disclaimer message
    </Typography>
    <Typography variant="body2" style={{ color: '#546e7a' }}>
      Display a customizable disclaimer that contacts must agree to before proceeding.
    </Typography>
  </>
);

export function DisclaimerSettingsCard(props: DisclaimerSettingsCardProps): React.ReactElement {
  const [content, setContent] = useState(() => props.disclaimer.content);
  const [enabled, setEnabled] = useState(props.disclaimer.enabled);
  const [updateDisclaimer] = useUpdateDisclaimerMutation();
  const [updateLoading, setUpdateLoading] = useState(false);
  const classes = useStyles();

  const { dialogProps, openDialog, closeDialog } = useDialog();

  return (
    <>
      <SettingsCard
        title="Disclaimer content"
        icon={<AlertCircle />}
        cardActions={<Button onClick={openDialog}>EDIT</Button>}
      >
        <FormControlLabel
          disabled={updateLoading}
          control={<Switch checked={enabled} onChange={handleDisclaimerToggle} />}
          label={enabledLabel}
        />
        {enabled && (
          <RichTextPreview
            classes={{
              container: classes.previewContainer,
              body: classes.previewBody,
            }}
            content={props.disclaimer.content}
          />
        )}
      </SettingsCard>
      <Dialog
        title="Edit disclaimer content"
        className={classes.disclaimerDialog}
        actions={
          <>
            <Button onClick={closeDialog}>CANCEL</Button>
            <Button variant="contained" color="primary" onClick={handleContentSave}>
              {updateLoading ? 'SAVING...' : 'SAVE'}
            </Button>
          </>
        }
        width={600}
        onExited={handleExit}
        {...dialogProps}
      >
        <RichTextEditor
          id="disclaimer-content"
          classes={{
            editor: classes.rte,
            toolbar: classes.rteToolbar,
          }}
          initialValue={props.disclaimer.content}
          onChange={handleContentHTMLChange}
          placeholder="Enter your custom disclaimer message here..."
        />
      </Dialog>
    </>
  );

  function handleContentHTMLChange(html: string) {
    setContent(html);
  }

  function handleExit() {
    // reset value to disclaimer in backend
    // setContent(deserialize(props.disclaimer.content));
  }

  /**
   * Called when user toggles disclaimer via switch field.
   */
  async function handleDisclaimerToggle(_: unknown, newValue: boolean) {
    const oldValue = enabled;
    setEnabled(newValue);
    setUpdateLoading(true);
    await handleMutation({
      mutate: () => updateDisclaimer({ variables: { id: props.studioId, disclaimer: { enabled: newValue } } }),
      payloadFieldPath: 'updateStudio?',
      successCondition: (data) => !!data.updateStudio?.studio?.disclaimer?.enabled === newValue,
      onSuccess: () => toast(`Disclaimer ${newValue ? 'enabled' : 'disabled'}`, 'success'),
      onError: () => {
        setEnabled(oldValue);
        toast(`Could not ${enabled ? 'enable' : 'disable'} disclaimer`, 'error');
      },
    });
    setUpdateLoading(false);
  }

  /**
   * Called when user saves disclaimer content via RTE dialog.
   */
  async function handleContentSave() {
    setUpdateLoading(true);
    await handleMutation({
      mutate: () => updateDisclaimer({ variables: { id: props.studioId, disclaimer: { content } } }),
      payloadFieldPath: 'updateStudio?',
      successCondition: (data) => !!data.updateStudio?.studio?.id,
      onSuccess: () => toast('Disclaimer content updated successfully', 'success'),
      onError: () => toast('Could not update disclaimer content', 'error'),
    });
    setUpdateLoading(false);
    closeDialog();
  }
}
