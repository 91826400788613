import { useEffect } from 'react';
import {
  makeStyles,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';
import { ErrorCard } from 'components/ErrorCard';
import { useFiveContactsLazyQuery } from 'codegen/graphql';

type ContactList = {
  id: string;
  contactsCount: number;
};

type ContactsTableProps = {
  selectedContactList: ContactList | null;
};

const useStyles = makeStyles(() => ({
  loadingWrapper: {
    width: '100%',
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
}));

export function ContactsTable(props: ContactsTableProps): JSX.Element | null {
  const classes = useStyles();
  const [fetchFive, fetchFiveState] = useFiveContactsLazyQuery();
  const { called, loading, error, data } = fetchFiveState;
  const totalContacts = props.selectedContactList?.contactsCount;
  const contacts = data?.contacts.edges.map(({ node }) => node);

  useEffect(() => {
    if (props.selectedContactList) {
      fetchFive({ variables: { id: props.selectedContactList.id } });
    }
  }, [fetchFive, props.selectedContactList]);

  if (!props.selectedContactList) {
    console.error('No selected contact list! This should never happen.');
    return null;
  }

  if (!totalContacts) {
    console.error('Contact list has no contacts! This should never happen.');
    return null;
  }

  if (loading || !called) {
    return (
      <div className={classes.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }

  if (error || !data || !totalContacts || !contacts?.length) {
    console.error(error);
    return <ErrorCard body="Could not load contacts from contact list." />;
  }

  const contactRows = contacts.map((contact, index) => (
    <TableRow key={index}>
      <TableCell>{[contact.firstName, contact.lastName].join(' ')}</TableCell>
      <TableCell>{contact.email}</TableCell>
    </TableRow>
  ));

  const additionalContacts = totalContacts - contacts.length;

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{contactRows}</TableBody>
      </Table>
      {!!additionalContacts && (
        <Typography variant="body2" style={{ color: 'darkgrey' }}>
          +{additionalContacts} more
        </Typography>
      )}
    </>
  );
}
