import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, makeStyles } from '@material-ui/core';
import { UseControllerReturn } from 'react-hook-form';
import clsx from 'clsx';
import CharCounter from '../CharCounter';

export type TextFieldProps = MuiTextFieldProps & {
  /**
   * `react-hook-form` controller.
   */
  controller?: UseControllerReturn<any, any>;
  /**
   * Set this to `true` if you do not want the input label to shrink on focus.
   */
  noShrink?: boolean;
  transformValue?: (value: string) => string;
  maxChars?: number;
};

const useStyles = makeStyles({
  noShrink: {
    '& .MuiInputLabel-shrink': {
      transform: 'none',
    },
  },
});

/**
 * Wrapper around the MUI `TextField` component that accepts a `react-hook-form`
 * controller.
 */
export function TextField(props: TextFieldProps): React.ReactElement {
  const classes = useStyles();

  const { controller, noShrink, onChange, maxChars, ...muiTextFieldProps } = props;

  let inputProps: MuiTextFieldProps = {
    className: clsx(noShrink && classes.noShrink, props.className),
    error: false,
    helperText: '',
    onChange: onChange,
    ...muiTextFieldProps,
  };

  if (controller) {
    inputProps = {
      ...inputProps,
      ...controller.field,
      inputRef: controller.field.ref,
      error: !!controller.fieldState.error,
      helperText: controller.fieldState.error?.message || muiTextFieldProps?.helperText || '',
    };
  }

  if (noShrink) {
    /*
     * this looks weird, but to disable shrinking you have to nuke the
     * transform CSS that occurs on shrink, and set shrink to true.
     */
    inputProps.InputLabelProps = { shrink: true };
  }

  return (
    <div style={{ position: 'relative' }}>
      <MuiTextField
        {...inputProps}
        /* Prevents the input from changing from uncontrolled to controlled in case value is null or undefined */
        value={inputProps.value ?? ''}
        InputProps={{
          endAdornment:
            controller && maxChars ? (
              <CharCounter currentChars={controller.field.value.length} maxChars={maxChars} />
            ) : (
              inputProps.InputProps?.endAdornment
            ),
        }}
      />
    </div>
  );
}
