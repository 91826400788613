import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { Trash2 } from 'react-feather';
import { MutableNotification } from 'pages/SubmissionNotifications/types';
import { ApolloError } from '@apollo/client';
import { FrequencyEnum } from 'codegen/graphql';

const useStyles = makeStyles(() => ({
  paper: {
    border: 0,
    height: 'auto',
  },
  root: {
    border: 0,
    '& .MuiDataGrid-cell:focus-within': {
      outline: 0,
    },
    '& .MuiDataGrid-columnHeader': {
      '&:focus-within': {
        outline: 0,
      },
      '&:last-child .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
  },
  detailsCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  manageButton: {
    marginRight: '10px',
    textTransform: 'uppercase',
  },
  toolbar: {
    fontSize: 16,
    paddingInline: '1rem',
    paddingTop: '1rem',
  },
}));

type NotificationsDataGridProps = {
  notifications: MutableNotification[];
  loading: boolean;
  error?: ApolloError;
  onManageClick: (notificationId: string) => void;
  onDeleteClick: (notificationId: string) => void;
};

export function NotificationsDataGrid({
  notifications,
  loading,
  error,
  onManageClick,
  onDeleteClick,
}: NotificationsDataGridProps) {
  const classes = useStyles();

  if (error) {
    console.error('Error while fetching notifications', error);
  }

  const getDetails = (notification: MutableNotification) => {
    switch (notification.frequency) {
      case FrequencyEnum.Immediate:
        return 'Unique emails sent for each submission';
      case FrequencyEnum.Daily:
        return 'Summary emails sent daily';
      case FrequencyEnum.Weekly:
        return 'Summary email sent every Monday (weekly)';
      case FrequencyEnum.Monthly:
        return 'Summary email sent on the first of each month';
    }
  };

  const NOTIFICATION_DATA_ROWS =
    notifications.map((notification) => ({
      id: notification.id,
      studioName: notification.studio?.name || '',
      details: getDetails(notification),
    })) || [];

  const NOTIFICATION_DATA_COLUMNS: GridColDef[] = [
    {
      field: 'studioName',
      headerName: 'Studio name',
      sortable: false,
      disableColumnMenu: true,
      width: 240,
    },
    {
      field: 'details',
      headerName: 'Details',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className={classes.detailsCell}>
            <span>{params.formattedValue}</span>
            <span>
              <Button variant="outlined" className={classes.manageButton} onClick={() => onManageClick(params.row.id)}>
                Manage
              </Button>
              <Button onClick={() => onDeleteClick(params.row.id)}>
                <Trash2 />
              </Button>
            </span>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Paper className={classes.paper}>
        <DataGrid
          autoHeight
          hideFooter
          hideFooterPagination
          rows={NOTIFICATION_DATA_ROWS}
          columns={NOTIFICATION_DATA_COLUMNS}
          loading={loading}
          error={error}
          className={classes.root}
          localeText={{
            noRowsLabel: 'No notifications configured.',
          }}
          components={{
            Toolbar: () => (
              <Box className={classes.toolbar}>
                <Typography variant={'h5'}>Current notifications</Typography>
              </Box>
            ),
          }}
        />
      </Paper>
    </>
  );
}
