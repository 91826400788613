import { useParams } from 'react-router';
import { useState } from 'react';
import { Page } from '../Page/Page';
import { Paper, Typography, Grid, Divider, Box, Button } from '@material-ui/core';
import { useSignExportMutation, useGetExportQuery } from 'codegen/graphql';
import { useEffect } from 'react';
import { Download } from 'react-feather';
import { toast } from 'components/GlobalSnackbar';

export type ExportParams = {
  id: string;
};

export type ExportPropsType = {
  modelName: 'SubmissionsExport' | 'ContactsExport';
};

export const Export = (props: ExportPropsType): JSX.Element => {
  const { id } = useParams<ExportParams>();
  const gid = `gid://holar/${props.modelName}/${id}`;
  const { loading, error, data } = useGetExportQuery({ variables: { id: gid } });
  const [signExport, signExportState] = useSignExportMutation({
    variables: {
      input: {
        id: gid,
      },
    },
  });

  const handleDownload = () => {
    toast('Download should begin in a few moments');

    signExport().then((response) => {
      const url = response.data?.signExport?.url;

      if (url) {
        location.href = url;
      } else {
        console.error(response.errors);
        toast('Failed to download export', 'error');
      }
    });
  };

  const message = (): string | undefined => {
    switch (data?.export?.status) {
      case 'CREATED':
      case 'PROCESSING':
        return 'The export is still being processed, please try again later.';
      case 'ERROR':
        return 'There was an error exporting the files.';
    }

    return 'Export could not be found or has expired.';
  };

  const path = data?.export?.file?.providerId?.split('/');
  const filesize = () => {
    const size = parseInt(data?.export?.file?.bytes || '0');

    if (size < 1000000) {
      return `${(size / 1000).toFixed(2)} KB`;
    } else {
      return `${(size / 1000000).toFixed(2)} MB`;
    }
  };

  const details = (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="overline">Total files</Typography>
          <Typography>{data?.export?.filesCount}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="overline">Download size</Typography>
          <Typography>{filesize()}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="overline">Total items processed</Typography>
          <Typography>{data?.export?.processedCount}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="overline">Total items failed</Typography>
          <Typography>{data?.export?.failedCount}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="overline">Filetype</Typography>
          <Typography>{data?.export?.file?.type}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="overline">Filename</Typography>
          <Typography>{path ? path[path.length - 1] : ''}</Typography>
        </Grid>
      </Grid>
      <Box marginY={2}>
        <Divider />
      </Box>
      <Button color="primary" startIcon={<Download />} onClick={handleDownload} disabled={signExportState.loading}>
        Download
      </Button>
    </Box>
  );

  return (
    <Page title="Download export" maxWidth="sm">
      {error && <Typography>There was an error loading export</Typography>}
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Paper style={{ padding: 16 }}>
          {data?.export?.status == 'DONE' ? details : <Typography>{message()}</Typography>}
        </Paper>
      )}
    </Page>
  );
};
