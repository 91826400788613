import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Alert, AlertTitle } from '@material-ui/lab';
import './FormErrors.sass';
import voca from 'voca';
import { useEffect, useRef } from 'react';

export type FormErrorsProps = {
  errors: FieldErrors<Record<any, any>>;
};

function digForErrorMessages(errors: Record<any, any>): string[] {
  let messages: string[] = [];

  Object.keys(errors).forEach((key) => {
    const error = errors[key];

    if (typeof error == 'object') {
      if (typeof error.message == 'string') {
        messages.push(formatMessage(key, error.message));
      } else {
        messages = messages.concat(digForErrorMessages(error));
      }
    }
  });

  return messages;
}

function formatMessage(path: string, error: string): string {
  const pathMessage = voca.capitalize(path).replace('_', ' ').trim();
  const errorMessage = voca.decapitalize(error).trim();

  return `${pathMessage} ${errorMessage}`.trim();
}

export function FormErrors(props: FormErrorsProps): JSX.Element {
  const errors = props.errors;
  const anchorRef = useRef<HTMLDivElement>(null);
  const errorKeys = JSON.stringify(errors);

  useEffect(() => {
    if (Object.keys(props.errors).length > 0) {
      anchorRef.current?.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [errorKeys, props.errors]);

  if (Object.keys(errors).length < 1) return <></>;

  const messages = digForErrorMessages(errors).map((message, index) => {
    return <li key={index}>{message}</li>;
  });

  return (
    <div className="FormErrors">
      <div className="FormErrors--Anchor" ref={anchorRef} />
      <Alert severity="error">
        <AlertTitle>Please resolve the following problem{messages.length > 1 ? 's' : ''}:</AlertTitle>
        <ul>{messages}</ul>
      </Alert>
    </div>
  );
}
