import { useCallback, useEffect, useRef, useState } from 'react';
import { FormControl, FormLabel, makeStyles } from '@material-ui/core';
import type { UseControllerReturn } from 'react-hook-form';
import { Color, ColorPicker, createColor } from 'material-ui-color';
import { FieldDescription } from 'components/FieldDescription/FieldDescription';
import './ColorField.sass';

export type ColorFieldProps = {
  /**
   * Label of the color field.
   */
  label: string;
  description?: React.ReactNode;
  /**
   * `react-hook-form` controller. Field stores color value as a hex string.
   */
  controller: UseControllerReturn<any, any>;
  delay?: number;
};

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.muicc-colorbox-error': {
      ...theme.typography.body1,
    },
    '.muicc-colorbox-controls > button': {
      border: '1px solid lightgrey',
    },
  },
  colorPickerContainer: {
    marginLeft: -6,
  },
  root: {
    '& button + div': {
      ...theme.typography.body1,
      display: 'flex',
      alignItems: 'center',
    },
  },
}));
export function ColorField(props: ColorFieldProps): React.ReactElement {
  const { onChange, ...renderProps } = props.controller.field;
  const classes = useStyles();
  const [color, setColor] = useState<Color>();
  const [focused, setFocused] = useState(false);
  const debounceRef = useRef<number>();

  useEffect(() => {
    setColor(renderProps.value ? createColor(renderProps.value) : undefined);
  }, [renderProps.value]);

  const handleColorChange = useCallback(
    (input: Color | string) => {
      window.clearTimeout(debounceRef.current);
      let color: Color;

      if (typeof input == 'string') {
        color = createColor(`#${input.replace(/#/g, '')}`);
      } else {
        color = input;
      }

      setColor(color);

      if (color.format == 'unknown') {
        console.log(`Color '${color.raw}' is invalid`);
        return;
      }

      const delay = props.delay || 250;

      debounceRef.current = window.setTimeout(() => {
        // Importing ColorFormat from MUI library doesn't compile to browser, so we must typecast it here
        const hex = (color.format as any) == 'hex' ? color.raw : `#${color.hex}`;
        onChange(hex);
      }, delay);
    },
    [onChange],
  );

  return (
    <FormControl
      focused={focused}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={classes.root}
    >
      <FormLabel>{props.label}</FormLabel>
      {props.description && <FieldDescription>{props.description}</FieldDescription>}

      <div className={classes.colorPickerContainer}>
        <ColorPicker {...renderProps} value={color} disablePlainColor onChange={handleColorChange} />
      </div>
    </FormControl>
  );
}
